import React,{useEffect,useState} from 'react'
import reactDom from 'react-dom'
import {Link} from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import {  TextField } from '@material-ui/core'
import {connect} from 'react-redux'
import ExpenseReport_Modal from '../Reports/ExpenseReports/ExpenseReport_Modal'
import FinanceReport_Modal from '../Reports/FinanceReports/FinanceReport_Modal'
import InventoryReport_Modal from '../Reports/InventoryReports/InventoryReport_Modal'
import PLReports_Modal from '../Reports/PL/PLReports_Modal'
import PurchaseReport_Modal from '../Reports/PurchaseReports/PurchaseReport_Modal'
import SaleReport_Modal from '../Reports/SaleReports/SaleReport_Modal'
import { SelectUserProjects } from '../../Services/Projects'
import { setGlobal_Project } from '../../React_Redux/Actions'
import  ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal'
import { SelectGroupModuleList, SelectGroupScreensList } from "../../Services/SecurityAPI"


function Sidebar({Comp_Id,User_Id,setGlobal_Project,User_Info, Group_Id,Global_Project_List,Global_Selected_Project}) {
     
  
  const [ProjectData, setProjectData] = useState([]);
  const [SelectedProject, setSelectedProject] = useState(null);
  const [ShowSaleReport_modal, setShowSaleReport_modal] = useState(false)
  const [ShowPurchaseReport_modal, setShowPurchaseReport_modal] = useState(false)
  const [ShowInventoryReport_modal, setShowInventoryReport_modal] = useState(false)
  const [ShowPLReport_modal, setShowPLReport_modal] = useState(false)
  const [ShowExpenseReport_modal, setShowExpenseReport_modal] = useState(false)
  const [ShowFinanceReport_modal, setShowFinanceReport_modal] = useState(false)
  var   [ReportPath, setReportPath] = useState('#')
  const [ReportTitle, setReportTitle] = useState('')
  var [SaleReport_ReadOnlyProp, setSaleReport_ReadOnlyProp] = useState({
    FromDate: false,
    ToDate: false,
    finYear: false,
    Project: false,
    Customer: false,
    ToCustomer: false,
    ToItem: false,
    FromItem: false,
    NoOfItems: false,
    Supplier:false,
    WareHouse:false,
    Classification:false,
    E_type:false,
    Bank:false,
    User:false
  })
const [ShowChangePassword_modal, setShowChangePassword_modal] = useState(false);

// Modules 
const [Control_Head, setControl_Head] = useState(true);
const [Main_Form, setMain_Form] = useState(true);
const [Reports, setReports] = useState(true);

// CONTROL HEAD
const [Admin_Dashboard, setAdmin_Dashboard] = useState(true);
const [Unit_Of_Measurement, setUnit_Of_Measurement] = useState(true);
const [Item_Main_Type, setItem_Main_Type] = useState(true);
const [Item_Sub_Type, setItem_Sub_Type] = useState(true);
const [WareHouse, setWareHouse] = useState(true);
const [City, setCity] = useState(true);
const [Menufacture_By, setMenufacture_By] = useState(true);
const [Bank, setBank] = useState(true);
const [Expense_Classification, setExpense_Classification] = useState(true);
const [Expense_Type, setExpense_Type] = useState(true);
const [Expense, setExpense] = useState(true);

// MAIN FORMS
const [Items, setItems] = useState(true);
const [Supplier, setSupplier] = useState(true);
const [Customers, setCustomers] = useState(true);
const [Opening, setOpening] = useState(true);
const [Stock_Adjustment, setStock_Adjustment] = useState(true);
const [Receipt, setReceipt] = useState(true);
const [Payment, setPayment] = useState(true);
const [Stock_Transfer, setStock_Transfer] = useState(true);
const [Purchase_Order, setPurchase_Order] = useState(true);
const [Purchase_Invoice, setPurchase_Invoice] = useState(true);
const [Purchase_Return, setPurchase_Return] = useState(true);
const [Sale_Order, setSale_Order] = useState(true);
const [Sale_Invoice, setSale_Invoice] = useState(true);
const [Sale_Return, setSale_Return] = useState(true);

// REPORTS
const [CUSTOMER_LEDGER, setCUSTOMER_LEDGER] = useState(true);
const [SALE_SUMMARY_CUSTOMER_WISE, setSALE_SUMMARY_CUSTOMER_WISE] = useState(true);
const [SALE_SUMMARY_SAELMAN_WISE, setSALE_SUMMARY_SAELMAN_WISE] = useState(true);
const [SALE_SUMMARY_ITEMS_WISE, setSALE_SUMMARY_ITEMS_WISE] = useState(true);
const [SALE_RETURN_LEDGER_CUSTOMER_WISE, setSALE_RETURN_LEDGER_CUSTOMER_WISE] = useState(true);
const [SALE_MARGIN_CUSTOMER_WISE, setSALE_MARGIN_CUSTOMER_WISE] = useState(true);
const [SALE_MARGIN_SUPPLIER_WISE, setSALE_MARGIN_SUPPLIER_WISE] = useState(true);
const [MOST_SOLD_ITEMS, setMOST_SOLD_ITEMS] = useState(true);
const [MINIMUM_SOLD_ITEMS, setMINIMUM_SOLD_ITEMS] = useState(true);
const [RECEIVABLES, setRECEIVABLES] = useState(true);
const [SUPPLIER_LEDGER, setSUPPLIER_LEDGER] = useState(true);
const [PURCHASE_SUMMARY_CATEGORY_WISE, setPURCHASE_SUMMARY_CATEGORY_WISE] = useState(true);
const [PURCHASE_LEDGER_SUPPLIER_WISE, setPURCHASE_LEDGER_SUPPLIER_WISE] = useState(true);
const [STOCK_BALANCE_WAREHOUSE_WISE, setSTOCK_BALANCE_WAREHOUSE_WISE] = useState(true);
const [CASH_IN_BANK, setCASH_IN_BANK] = useState(true);
const [CHASH_IN_HAND, setCHASH_IN_HAND] = useState(true);
const [EXPENSES_TYPE_WISE, setEXPENSES_TYPE_WISE] = useState(true);
const [EXPENSES_CLASSIFICATION_WISE, setEXPENSES_CLASSIFICATION_WISE] = useState(true);
const [EXPENSES, setEXPENSES] = useState(true);
const [PROFIT_AND_LOSS, setPROFIT_AND_LOSS] = useState(true);
const [DAILY_ACTIVITIES, setDAILY_ACTIVITIES] = useState(true);
const [MAX_INVENTORY_ITEMS, setMAX_INVENTORY_ITEMS] = useState(true);
const [SHORT_INVENTORY_ITEMS, setSHORT_INVENTORY_ITEMS] = useState(true);
const [STOCK_BALANCE_BATCH_WISE, setSTOCK_BALANCE_BATCH_WISE] = useState(true);
const [DAILY_SALES_OF_LAST_MONTH, setDAILY_SALES_OF_LAST_MONTH] = useState(true);
const [PURCHASE_LEDGER_ITEMS_WISE, setPURCHASE_LEDGER_ITEMS_WISE] = useState(true);
const [PAYABLES, setPAYABLES] = useState(true);
const [STOCK_LEDGER, setSTOCK_LEDGER] = useState(true);

useEffect(() => {
  if(Global_Project_List){
    setProjectData(Global_Project_List)
    setSelectedProject(Global_Selected_Project && Global_Selected_Project)

  }
  if(Group_Id === 0 || Group_Id){
    SelectGroupModuleList(Group_Id,(GM_Data)=>{
      
      GM_Data.map(async (MValue, MIndex) =>{
      if(MValue.Mod_Id === 10)
      {
        if(MValue.Allow_Access === 1)
        {
          setControl_Head(true)
        }
        else
        {
          setControl_Head(false)
        }
      }
      else if(MValue.Mod_Id === 11)
      {
        if(MValue.Allow_Access === 1)
        {
          setMain_Form(true)
        }
        else
        {
          setMain_Form(false)
        }
      }
      else if(MValue.Mod_Id === 12)
      {
        if(MValue.Allow_Access === 1)
        {
          setReports(true)
        }
        else
        {
          setReports(false)
        }
      }

      
      await new Promise((resolve) =>{

        SelectGroupScreensList(Group_Id, MValue.Mod_Id,(ScreenData) =>{
          
          ScreenData.map((SValue, SIndex) =>{
            if(MValue.Mod_Id === 10)
            {
                if(SValue.Screen_Id === 80)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setAdmin_Dashboard(true)
                  }
                  else
                  {
                    setAdmin_Dashboard(false)
                  }
                }
                else if(SValue.Screen_Id === 81)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setUnit_Of_Measurement(true)
                  }
                  else
                  {
                    setUnit_Of_Measurement(false)
                  }
                }
                else if(SValue.Screen_Id === 82)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setItem_Main_Type(true)
                  }
                  else
                  {
                    setItem_Main_Type(false)
                  }
                }
                else if(SValue.Screen_Id === 83)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setItem_Sub_Type(true)
                  }
                  else
                  {
                    setItem_Sub_Type(false)
                  }
                }
                else if(SValue.Screen_Id === 84)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setWareHouse(true)
                  }
                  else
                  {
                    setWareHouse(false)
                  }
                }
                else if(SValue.Screen_Id === 85)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setCity(true)
                  }
                  else
                  {
                    setCity(false)
                  }
                }
                else if(SValue.Screen_Id === 86)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setMenufacture_By(true)
                  }
                  else
                  {
                    setMenufacture_By(false)
                  }
                }
                else if(SValue.Screen_Id === 87)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setBank(true)
                  }
                  else
                  {
                    setBank(false)
                  }
                }
                else if(SValue.Screen_Id === 88)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setExpense_Classification(true)
                  }
                  else
                  {
                    setExpense_Classification(false)
                  }
                }
                else if(SValue.Screen_Id === 89)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setExpense_Type(true)
                  }
                  else
                  {
                    setExpense_Type(false)
                  }
                }
                else if(SValue.Screen_Id === 90)
                {
                  if(SValue.Allow_Access === 1)
                  {
                    setExpense(true)
                  }
                  else
                  {
                    setExpense(false)
                  }
                }
            }
            else if(MValue.Mod_Id === 11)
            {
              if(SValue.Screen_Id === 91)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setItems(true)
                  }
                  else
                  {
                    setItems(false)
                  }
              }
              else if(SValue.Screen_Id === 92)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSupplier(true)
                  }
                  else
                  {
                    setSupplier(false)
                  }
              }
              else if(SValue.Screen_Id === 93)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setCustomers(true)
                  }
                  else
                  {
                    setCustomers(false)
                  }
              }
              else if(SValue.Screen_Id === 94)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setOpening(true)
                  }
                  else
                  {
                    setOpening(false)
                  }
              }
              else if(SValue.Screen_Id === 95)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setStock_Adjustment(true)
                  }
                  else
                  {
                    setStock_Adjustment(false)
                  }
              }
              else if(SValue.Screen_Id === 96)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setReceipt(true)
                  }
                  else
                  {
                    setReceipt(false)
                  }
              }
              else if(SValue.Screen_Id === 97)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPayment(true)
                  }
                  else
                  {
                    setPayment(false)
                  }
              }
              else if(SValue.Screen_Id === 98)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setStock_Transfer(true)
                  }
                  else
                  {
                    setStock_Transfer(false)
                  }
              }
              else if(SValue.Screen_Id === 99)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPurchase_Order(true)
                  }
                  else
                  {
                    setPurchase_Order(false)
                  }
              }
              else if(SValue.Screen_Id === 100)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPurchase_Invoice(true)
                  }
                  else
                  {
                    setPurchase_Invoice(false)
                  }
              }
              else if(SValue.Screen_Id === 101)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPurchase_Return(true)
                  }
                  else
                  {
                    setPurchase_Return(false)
                  }
              }
              else if(SValue.Screen_Id === 102)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSale_Order(true)
                  }
                  else
                  {
                    setSale_Order(false)
                  }
              }
              else if(SValue.Screen_Id === 103)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSale_Invoice(true)
                  }
                  else
                  {
                    setSale_Invoice(false)
                  }
              }
              else if(SValue.Screen_Id === 104)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSale_Return(true)
                  }
                  else
                  {
                    setSale_Return(false)
                  }
              }
            }
            else if(MValue.Mod_Id === 12)
            {
              if(SValue.Screen_Id === 105)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setCUSTOMER_LEDGER(true)
                  }
                  else
                  {
                    setCUSTOMER_LEDGER(false)
                  }
              }
              else if(SValue.Screen_Id === 106)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSALE_SUMMARY_CUSTOMER_WISE(true)
                  }
                  else
                  {
                    setSALE_SUMMARY_CUSTOMER_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 107)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSALE_SUMMARY_SAELMAN_WISE(true)
                  }
                  else
                  {
                    setSALE_SUMMARY_SAELMAN_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 108)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSALE_SUMMARY_ITEMS_WISE(true)
                  }
                  else
                  {
                    setSALE_SUMMARY_ITEMS_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 109)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSALE_RETURN_LEDGER_CUSTOMER_WISE(true)
                  }
                  else
                  {
                    setSALE_RETURN_LEDGER_CUSTOMER_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 110)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSALE_MARGIN_CUSTOMER_WISE(true)
                  }
                  else
                  {
                    setSALE_MARGIN_CUSTOMER_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 111)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSALE_MARGIN_SUPPLIER_WISE(true)
                  }
                  else
                  {
                    setSALE_MARGIN_SUPPLIER_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 112)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setMOST_SOLD_ITEMS(true)
                  }
                  else
                  {
                    setMOST_SOLD_ITEMS(false)
                  }
              }
              else if(SValue.Screen_Id === 113)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setMINIMUM_SOLD_ITEMS(true)
                  }
                  else
                  {
                    setMINIMUM_SOLD_ITEMS(false)
                  }
              }
              else if(SValue.Screen_Id === 114)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    
                    setDAILY_SALES_OF_LAST_MONTH(true)
                  }
                  else
                  {
                    setDAILY_SALES_OF_LAST_MONTH(false)
                  }
              }
              else if(SValue.Screen_Id === 115)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setRECEIVABLES(true)
                    
                  }
                  else
                  {
                    setRECEIVABLES(false)
                  }
              }
              else if(SValue.Screen_Id === 116)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSUPPLIER_LEDGER(true)
                  }
                  else
                  {
                    setSUPPLIER_LEDGER(false)
                  }
              }
              else if(SValue.Screen_Id === 117)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPURCHASE_SUMMARY_CATEGORY_WISE(true)
                  }
                  else
                  {
                    setPURCHASE_SUMMARY_CATEGORY_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 118)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPURCHASE_LEDGER_SUPPLIER_WISE(true)
                  }
                  else
                  {
                    setPURCHASE_LEDGER_SUPPLIER_WISE(false)                    
                  }
              }
              else if(SValue.Screen_Id === 119)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPURCHASE_LEDGER_ITEMS_WISE(true)
                  }
                  else
                  {
                    setPURCHASE_LEDGER_ITEMS_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 120)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    
                    setPAYABLES(true)
                  }
                  else
                  {
                    setPAYABLES(false)
                  }
              }
              else if(SValue.Screen_Id === 121)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    
                    setSTOCK_LEDGER(true)
                  }
                  else
                  {
                    setSTOCK_LEDGER(false)                    
                  }
              }
              else if(SValue.Screen_Id === 122)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSTOCK_BALANCE_BATCH_WISE(true)
                  }
                  else
                  {
                    setSTOCK_BALANCE_BATCH_WISE(false)                    
                  }
              }
              else if(SValue.Screen_Id === 123)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSTOCK_BALANCE_WAREHOUSE_WISE(true)
                  }
                  else
                  {
                    setSTOCK_BALANCE_WAREHOUSE_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 124)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setSHORT_INVENTORY_ITEMS(true)
                  }
                  else
                  {
                    setSHORT_INVENTORY_ITEMS(false)
                  }
              }
              else if(SValue.Screen_Id === 125)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setMAX_INVENTORY_ITEMS(true)
                  }
                  else
                  {
                    setMAX_INVENTORY_ITEMS(false)                    
                  }
              }
              else if(SValue.Screen_Id === 126)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setDAILY_ACTIVITIES(true)
                  }
                  else
                  {
                    setDAILY_ACTIVITIES(false)
                  }
              }
              else if(SValue.Screen_Id === 127)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setPROFIT_AND_LOSS(true)
                  }
                  else
                  {
                    setPROFIT_AND_LOSS(false)
                  }
              }
              else if(SValue.Screen_Id === 128)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setExpense(true)
                  }
                  else
                  {
                    setExpense(false)
                  }
              }
              else if(SValue.Screen_Id === 129)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setEXPENSES_CLASSIFICATION_WISE(true)
                  }
                  else
                  {
                    setEXPENSES_CLASSIFICATION_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 130)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setEXPENSES_TYPE_WISE(true)
                  }
                  else
                  {
                    setEXPENSES_TYPE_WISE(false)
                  }
              }
              else if(SValue.Screen_Id === 131)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setCHASH_IN_HAND(true)
                  }
                  else
                  {
                    setCHASH_IN_HAND(false)
                  }
              }
              else if(SValue.Screen_Id === 132)
              {
                  if(SValue.Allow_Access === 1)
                  {
                    setCASH_IN_BANK(true)
                  }
                  else
                  {
                    setCASH_IN_BANK(false)
                  }
              }
            }
          })

          resolve()
        })
      })
    })
  })
  }

}, [Group_Id]);

  const Show_SaleReport_Modal = ()=>{
    setShowSaleReport_modal(true)
  }
  const Hide_SaleReport_Modal = ()=>{
    setShowSaleReport_modal(false)
  }
  const Show_PurchaseReport_Modal = ()=>{
    setShowPurchaseReport_modal(true)
  }
  const Hide_PurchaseReport_Modal = ()=>{
    setShowPurchaseReport_modal(false)
  }
  const Show_InventoryReport_Modal = ()=>{
    setShowInventoryReport_modal(true)
  }
  const Hide_InventoryReport_Modal = ()=>{
    setShowInventoryReport_modal(false)
  }
  const Show_PLReport_Modal = ()=>{
    setShowPLReport_modal(true)
  }
  const Hide_PLReport_Modal = ()=>{
    setShowPLReport_modal(false)
  }
  const Show_ExpenseReport_Modal = ()=>{
    setShowExpenseReport_modal(true)
  }
  const Hide_ExpenseReport_Modal = ()=>{
    setShowExpenseReport_modal(false)
  }
  const Show_FinanceReport_Modal = ()=>{
    setShowFinanceReport_modal(true)
  }
  const Hide_FinanceReport_Modal = ()=>{
    setShowFinanceReport_modal(false)
  }
  const Show_ChangePassword_Modal = ()=>{
    setShowChangePassword_modal(true)
  }
  const Hide_ChangePassword_Modal = ()=>{
    setShowChangePassword_modal(false)
  }
    return reactDom.createPortal(
        <>
  <ul className="nav nav-tabs">
    <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#dashboard"><i className="zmdi zmdi-home m-r-5" />LT</a></li>
    <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#user"><i className="zmdi zmdi-account m-r-5" />User</a></li>
  </ul>
  <div className="tab-content">
    <div className="tab-pane stretchRight active" id="dashboard">
      <div className="menu">
        <ul className="list">
          <li>
            <div className="user-info">
              <div className="image"><a href="profile.html"><img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="User" /></a></div>
              <div className="detail">
                <h4>{User_Info && User_Info.User_name}</h4>
                <small>{User_Info && User_Info.User_Type === 'A' ? 'Admin' : User_Info && User_Info.User_Type}</small>                        
              </div>
              <a title="facebook" href="https://www.facebook.com/" target={"_blank"}><i className="zmdi zmdi-facebook" /></a>
              <a title="twitter" href="https://twitter.com/?lang=en/"  target={"_blank"}><i className="zmdi zmdi-twitter" /></a>
              <a title="instagram" href="https://www.instagram.com/?hl=en"  target={"_blank"}><i className="zmdi zmdi-instagram" /></a>                            
            </div>
          </li>
          <li className="header">CONTROL HEAD</li>
          { Control_Head && (<><li className="active open"> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-home" /><span>Dashboard</span></a>
            <ul className="ml-menu">
                   
                    { Admin_Dashboard &&(<li className="active"><Link to="/Index">Pharmacy Dashboard</Link> </li>)}
                   <li><Link to="/hmsdashboard">Hospital Dashboard</Link></li>
            </ul>
          </li>
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-apps" /><span>Records</span> </a>
            <ul className="ml-menu">
                    <div>
                      {Unit_Of_Measurement &&(<li> <Link to="/UOM">Unit of Measurment</Link></li>)}
                      {Item_Main_Type &&(<li> <Link to="/ItemMainType">Item Main Type</Link></li>)}
                      {Item_Sub_Type &&(<li> <Link to="/ItemSubType">Item Sub Type</Link></li>)}
                      {WareHouse &&(<li>  <Link to="/WareHouse">WareHouse</Link></li>)}
                      {City &&(<li> <Link to="/City">City</Link></li>)}
                      {Menufacture_By &&(<li> <Link to="/manufacture">Manufacture By</Link></li>)}
                      {Bank &&(<li> <Link to="/Bank">Banks</Link></li>)}
                    </div>

            </ul>
          </li>
          
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-shopping-cart" /><span>Expenses</span> </a>
            <ul className="ml-menu">
          <div>
          {Expense_Classification &&(<li> <Link to="/ExpenseClassification">Expense Classification</Link></li>)}
      {Expense_Type &&(<li> <Link to="/ExpenseType">Expense Type</Link></li>)}
      {Expense &&(<li> <Link to="/ExpenseList">Expences</Link></li>)}
</div>

            </ul>
          </li>
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-swap-alt" /><span>Security</span> </a>
            <ul className="ml-menu">
              <li> <Link to={'/Groups'}>User Security</Link> </li>                    
              <li> <a href="alerts.html">Users</a> </li>                    
              <li> <Link to="/ControlPanel">Control Panel</Link> </li>
            </ul>
          </li></>)}
          <li className="header">MAIN  FORMS</li>
          { Main_Form && (<><li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-assignment" /><span>Main Forms</span> </a>
            <ul className="ml-menu">
                    {Items &&(<li><Link to="/ItemList">Items</Link></li>)}
                    {Supplier &&(<li><Link to="/SuppliersList">Suppliers</Link> </li>)}
                    {Customers &&(<li><Link to="/CustomerList">Customers</Link> </li>)}
                    {Opening &&(<li><Link to="/OpeningList">Opening</Link></li>)}
                    {Stock_Adjustment &&(<li><Link to="/StockAdjustmentList">Stock Adjustment(FOC)</Link> </li>)}
                    {Receipt &&(<li><Link to="/Receipts">Receipt</Link> </li>)}
                    {Payment &&(<li><Link to="/Payments">Payments</Link> </li>)}
                    {Stock_Transfer &&(<li><Link to="/StockTransferList">Stock Transfer</Link> </li>)}
            </ul>
          </li>
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-grid"></i><span>Purchase Module</span> </a>
                        <ul className="ml-menu">                        
                        {Purchase_Order &&(<li> <Link to="/PurchaseOrderList">Purchase Order</Link> </li>)}
                        {Purchase_Invoice &&(<li> <Link to="/PurchaseInvoiceList">Purchase Invoice</Link> </li>)}
                        {Purchase_Return &&(<li> <Link to="/PurchaseReturnList">Purchase Return</Link></li>)}
                         
                        </ul>
          </li>           
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-chart"></i><span>Sale Module</span> </a>
              <ul className="ml-menu">
              {Sale_Order &&(<li><Link to="/SaleOrderList">Sale Order</Link></li>)}
              {Sale_Invoice &&(<li> <Link to="/SaleInvoiceList">Sale Invoice</Link> </li>)}
              {Sale_Return &&(<li> <Link to="/SaleReturnList">Sale Return</Link> </li>)}
                  
              </ul>
          </li>
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-chart"></i><span>OPD Module</span> </a>
          <ul className="ml-menu">
         <li><Link to="/Diseases">Diseases</Link></li>
         <li> <Link to="/Symptoms">Symptoms</Link> </li>
         <li> <Link to="/Tests">Tests</Link> </li>
         <li> <Link to="/DosageList">Dosage</Link> </li>
         <li> <Link to="/Days">Days</Link> </li>
         <li> <Link to="/OPDRegistration">OPD Registration</Link> </li>
         <li> <Link to="/PendingPatients">Pending Patients</Link> </li>
         <li> <Link to="/OPDBilling">OPD Billing</Link> </li>   
         <li> <Link to="/OPDCheckup">OPD Checkup</Link> </li>   
         <li> <Link to="/DoctorStattistics">Doctor Statistics</Link> </li>  
     
          </ul>
      </li>
      <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-chart"></i><span>IPD Module</span> </a>
      <ul className="ml-menu">
     <li><Link to="/Wards">Wards</Link></li>
     <li><Link to="/RoomCategory">Room Category</Link></li>
     <li><Link to="/Room">Rooms</Link></li>
     <li><Link to="/BedNo">Bed No</Link></li>
     <li><Link to="/Beds">Beds</Link></li>
     <li><Link to="/Services">Services</Link></li>
     <li><Link to="/OPT">Operation Theator</Link></li>
     <li><Link to="/OPTAssignLIst">OPT Assign</Link></li>
     <li><Link to="/DoctorVisitsList">Doctor Visits</Link></li>
     <li><Link to="/PatientServices">Patient Services</Link></li>
     <li><Link to="/PatientMedicain">Patient Medicain</Link></li>
     <li><Link to="/IPDRegistration">IPD Registration</Link></li>
     <li><Link to="/IPDBillingList">IPD Billing / Discharge</Link></li>
      </ul>
  </li>
          </>)}
          <li className="header">REPORTS</li>  
            {Reports && (<><li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-delicious" /><span>Sale</span> </a>
              <ul className="ml-menu">
                
                {CUSTOMER_LEDGER &&(<li><Link to={'#'} onClick={e=>{
                  setReportPath('/CustomerLedgerReport')
                  setReportTitle('Customer Ledger')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:false,
                    Project:false,
                    Customer:false,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Customer Ledger</Link></li>)}


                {SALE_SUMMARY_CUSTOMER_WISE &&(<li><Link to={'#'} onClick={e=>{
                  setReportPath('/SaleSummaryCustomerWiseReport')
                  setReportTitle('Sale Summary Customer-Wise')
                  setSaleReport_ReadOnlyProp({FromDate:false,ToDate:false,finYear:true,Project:false,Customer:false,ToCustomer:true,FromItem:true,ToItem:true,NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Sale Summary -Customer Wise</Link></li>)}


                {SALE_SUMMARY_SAELMAN_WISE &&(<li><Link to="#" onClick={e=>{
                  setReportPath('/SaleSummarySalesManWise')
                  setReportTitle('Sale Summary SalesMan-Wise')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:false
                  })
                  Show_SaleReport_Modal()
                  }}>Sale Summary -Saleman Wise</Link></li>)} 
                
                {SALE_SUMMARY_ITEMS_WISE &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/SaleLedgerItemWise')
                  setReportTitle('Sale Ledger Item-Wise')
                  setSaleReport_ReadOnlyProp({FromDate:false,ToDate:false,finYear:true,Project:false,Customer:true,ToCustomer:true,FromItem:false,ToItem:false,NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Sale Ledger -Item Wise</Link></li>)}

                {SALE_RETURN_LEDGER_CUSTOMER_WISE&&(<li><Link to='#' onClick={e=>{
                  setReportPath('/SaleReturnLedgerCustWise')
                  setReportTitle('Sale Return Ledger Customer-Wise')
                  setSaleReport_ReadOnlyProp({FromDate:false,ToDate:false,finYear:true,Project:false,Customer:true,ToCustomer:true,FromItem:true,ToItem:true,NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Sale Return Ledger -Customer Wise</Link></li>)}

                {SALE_MARGIN_CUSTOMER_WISE &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/SaleMarginCustomerWise')
                  setReportTitle('Sale Margin Customer-Wise')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:false,
                    ToCustomer:false,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Sale Margin -Customer Wise</Link></li>)}

                {/* {SALE_MARGIN_SUPPLIER_WISE &&(<li><Link to='#'>Sale Margin -Supplier Wise</Link></li>)}*/}
                
                {/* { SALE_RETURN_LEDGER_CUSTOMER_WISE &&(<li><Link to='#'  onClick={e=>{
                  setReportPath('/SaleReturnLedgerCustWise')
                  setReportTitle('Sale Return Ledger Customer-Wise')
                  setSaleReport_ReadOnlyProp({FromDate:false,ToDate:false,finYear:true,Project:false,Customer:true,ToCustomer:true,FromItem:true,ToItem:true,NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_SaleReport_Modal()
                  }}>Sale Return Ledger -Customer Wise</Link></li>)} */}

                {MOST_SOLD_ITEMS &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/MostSoldItems')
                  setReportTitle('Most Sold Items')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:false,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Most Sold Items</Link></li>)}

                { MINIMUM_SOLD_ITEMS &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/MinimumSoldItems')
                  setReportTitle('Minimum Sold Items')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:false,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Minimum Sold Items</Link></li>)}

                { DAILY_SALES_OF_LAST_MONTH &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/DailySaleOfLastMOnth')
                  setReportTitle('Daily Sale of Last Month')
                  setSaleReport_ReadOnlyProp({
                    FromDate:true,
                    ToDate:true,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Daily Sales Of Last Month</Link></li>)}

                { RECEIVABLES&&(<li><Link to='#'  onClick={e=>{
                  setReportPath('/Receiveable')
                  setReportTitle('Receivable')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false,
                    User:true
                  })
                  Show_SaleReport_Modal()
                  }}>Recivables</Link></li>)}

              </ul>
            </li>
            <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-lock" /><span>Purchase</span> </a>
              <ul className="ml-menu">
                { SUPPLIER_LEDGER &&(<li><Link to="#" onClick={e=>{
                  setReportPath('/SupplierLedgerReport')
                  setReportTitle('Supplier Ledger')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:false,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_PurchaseReport_Modal()
                  }}>Supplier Ledger</Link> </li>)}

                { PURCHASE_SUMMARY_CATEGORY_WISE &&(<li><Link to="#" >Purchase Summary Category Wise</Link> </li>)}
                
                {PURCHASE_LEDGER_SUPPLIER_WISE &&(<li><Link to="#"  onClick={e=>{
                  setReportPath('/SupplierWiseLedgerReport')
                  setReportTitle('Purchase Ledger Supplier-Wise')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_PurchaseReport_Modal()
                  }}>Purchase Ledger Supplier Wise</Link> </li>)}
                
                {PURCHASE_LEDGER_ITEMS_WISE &&(<li><Link to="#" onClick={e=>{
                  setReportPath('/PurchaseSummaryItemWiseReport')
                  setReportTitle('Purchase Summary Item-Wise')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:false,
                    ToItem:false,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_PurchaseReport_Modal()
                  }}>Purchase Ledger Item Wise</Link> </li>)}
                
                {PAYABLES &&(<li><Link to="#"  onClick={e=>{
                  setReportPath('/Payables')
                  setReportTitle('Payables')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_PurchaseReport_Modal()
                  }}>Payables</Link> </li>)}

              </ul>
            </li>
            <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-copy" /><span>Inventory</span> </a>
              <ul className="ml-menu">
                { STOCK_LEDGER&&(<li><Link to="#" onClick={e=>{
                  setReportPath('/StockBalanceReport')
                  setReportTitle('Stock Ledger')
                  setSaleReport_ReadOnlyProp({
                    FromDate:true,
                    ToDate:true,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_InventoryReport_Modal()
                  }}>Stock Ledgers</Link> </li>)}

                { STOCK_BALANCE_BATCH_WISE &&(<li> <Link to="#" onClick={e=>{
                  setReportPath('/StockBatchWiseReport')
                  setReportTitle('Batch-Wise Stock')
                  setSaleReport_ReadOnlyProp({
                    FromDate:true,
                    ToDate:true,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_InventoryReport_Modal()
                  }}>Stock Balance -Batch Wise</Link> </li>)}
                
                { STOCK_BALANCE_WAREHOUSE_WISE &&(<li><Link to="#" onClick={e=>{
                  setReportPath('/StockWareHouseWiseReport')
                  setReportTitle('Warehouse-Wise Stock')
                  setSaleReport_ReadOnlyProp({
                    FromDate:true,
                    ToDate:true,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:false,
                    ToItem:false,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_InventoryReport_Modal()
                  }}>Stock Balance -WareHouse Wise</Link></li>)}
                
                { SHORT_INVENTORY_ITEMS &&(<li><Link to="#" onClick={e=>{
                  setReportPath('/ShortInventoryItemsReport')
                  setReportTitle('Short Inventory Items')
                  setSaleReport_ReadOnlyProp({
                    FromDate:true,
                    ToDate:true,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_InventoryReport_Modal()
                  }}>Short Inventory Items</Link></li>)}
                
                {MAX_INVENTORY_ITEMS&&(<li><Link to="#" onClick={e=>{
                  setReportPath('/MaxInventoryItemsReport')
                  setReportTitle('Max Inventory Items')
                  setSaleReport_ReadOnlyProp({
                    FromDate:true,
                    ToDate:true,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_InventoryReport_Modal()
                  }}>Max Inventory Items</Link></li>)}
              </ul>
            </li>
          
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-map" /><span>Profit &amp; Loss</span> </a>
            <ul className="ml-menu">
              { DAILY_ACTIVITIES&&(<li><Link to='#' onClick={e=>{
                  setReportPath('/DailyActivityReport')
                  setReportTitle('Daily Activity')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_PLReport_Modal()
                  }} >Daily Activity</Link></li>)}
              
              { PROFIT_AND_LOSS&&(<li><Link to='#' onClick={e=>{
                  setReportPath('/PLReport')
                  setReportTitle('Profit & Loss')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_PLReport_Modal()
                  }}>Profit &amp; Loss</Link></li>)}

            </ul>
          </li>
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-map" /><span>Expense</span> </a>
            <ul className="ml-menu">
            { Expense&&(<li><Link to='#' onClick={e=>{
                  setReportPath('/ExpenseReport')
                  setReportTitle('Expense')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:true,
                    E_type:true
                  })
                  Show_ExpenseReport_Modal()
                  }}>Expenses</Link></li>)}
              
              {EXPENSES_CLASSIFICATION_WISE &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/ExpenseClassificationReport')
                  setReportTitle('Expense Classification')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:true,
                    Bank:false
                  })
                  Show_ExpenseReport_Modal()
                  }} >Expense - ClassificationWise</Link></li>)}
              
              { EXPENSES_TYPE_WISE&&(<li><Link to='#' onClick={e=>{
                  setReportPath('/ExpenseTypeReport')
                  setReportTitle('Expense Type')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:true,
                    E_type:false,
                    Bank:false
                  })
                  Show_ExpenseReport_Modal()
                  }}>Expense - TypeWise</Link></li>)}
            </ul>
          </li>
          <li> <a href="javascript:void(0);" className="menu-toggle"><i className="zmdi zmdi-map" /><span>Finance</span> </a>
            <ul className="ml-menu">
            { CHASH_IN_HAND&&(<li><Link to='#' onClick={e=>{
                  setReportPath('/CashInHandReport')
                  setReportTitle('Cash In Hand')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:true,
                    E_type:true,
                    Bank:true
                  })
                  Show_FinanceReport_Modal()
                  }}>Cash In Hand</Link></li>)}
              
            { CASH_IN_BANK &&(<li><Link to='#' onClick={e=>{
                  setReportPath('/CashInBankReport')
                  setReportTitle('Cash In Bankt')
                  setSaleReport_ReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    Customer:true,
                    ToCustomer:true,
                    FromItem:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:true,
                    WareHouse:true,
                    Classification:false,
                    E_type:true,
                    Bank:false
                  })
                  Show_FinanceReport_Modal()
                  }} >Cash In Bank</Link></li>)}
            </ul>
          </li></>)}
          <li>
                

            <div className="progress-container progress-primary m-t-10">
              <span className="progress-badge">Project</span>
              <Autocomplete
                    id="Project"
                    options={Global_Project_List && Global_Project_List}
                    // options={ProjectData}
                    // value={SelectedProject}
                    value={Global_Selected_Project && Global_Selected_Project}
                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                    getOptionSelected={(option, value) => option.Project_ID === value.Project_ID}
                    onChange={(e, option) => {
                      if (option) {
                        setSelectedProject(option)
                        setGlobal_Project(option)
                        document.getElementById('redirect').click()
                      }
                      else {
                        setSelectedProject([0])
                        setGlobal_Project([0])
                      }
                    }}

                    renderInput={(params) => <TextField {...params}  />}
                  /> 
              {/* <div className="progress">
                <div className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow={67} aria-valuemin={0} aria-valuemax={100} style={{width: '67%'}}>
                  <span className="progress-value">67%</span>
                </div>
              </div> */}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div className="tab-pane stretchLeft" id="user">
      <div className="menu">
        <ul className="list">
          <li>
            <div className="user-info m-b-20 p-b-15">
              <div className="image"><a href="profile.html"><img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="User" /></a></div>
              <div className="detail">
              <h4>{User_Info && User_Info.User_name}</h4>
                <small>{User_Info && User_Info.User_Type === 'A' ? 'Admin' : User_Info && User_Info.User_Type}</small>                       
              </div>
              <a title="facebook" href="https://www.facebook.com/" target={"_blank"}><i className="zmdi zmdi-facebook" /></a>
              <a title="twitter" href="https://twitter.com/?lang=en/"  target={"_blank"}><i className="zmdi zmdi-twitter" /></a>
              <a title="instagram" href="https://www.instagram.com/?hl=en"  target={"_blank"}><i className="zmdi zmdi-instagram" /></a>   
              {/* <p className="text-muted">795 Folsom Ave, Suite 600 San Francisco, CADGE 94107</p> */}
              {/* <div className="row">
                <div className="col-4">
                  <h5 className="m-b-5">852</h5>
                  <small>Following</small>
                </div>
                <div className="col-4">
                  <h5 className="m-b-5">13k</h5>
                  <small>Followers</small>
                </div>
                <div className="col-4">
                  <h5 className="m-b-5">234</h5>
                  <small>Post</small>
                </div>                            
              </div> */}
            </div>
          </li>
          <li>
            <small className="text-muted">Email address: </small>
            <p>{User_Info && User_Info.Email}</p>
            <hr />
            <small className="text-muted">Phone: </small>
            <p>{User_Info && User_Info.PhoneNo}</p>
            <hr />
            <small className="text-muted">Change Password: </small>
            <Link to="#" style={{  color: "#806c8c" }} onClick={Show_ChangePassword_Modal} > Change Password </Link>
            <hr />
           {/*  <ul className="list-unstyled">
              <li>
                <div>Photoshop</div>
                <div className="progress m-b-20">
                  <div className="progress-bar l-blue " role="progressbar" aria-valuenow={89} aria-valuemin={0} aria-valuemax={100} style={{width: '89%'}}> <span className="sr-only">62% Complete</span> </div>
                </div>
              </li>
              <li>
                <div>Wordpress</div>
                <div className="progress m-b-20">
                  <div className="progress-bar l-green " role="progressbar" aria-valuenow={56} aria-valuemin={0} aria-valuemax={100} style={{width: '56%'}}> <span className="sr-only">87% Complete</span> </div>
                </div>
              </li>
              <li>
                <div>HTML 5</div>
                <div className="progress m-b-20">
                  <div className="progress-bar l-amber" role="progressbar" aria-valuenow={78} aria-valuemin={0} aria-valuemax={100} style={{width: '78%'}}> <span className="sr-only">32% Complete</span> </div>
                </div>
              </li>
              <li>
                <div>Angular</div>
                <div className="progress m-b-20">
                  <div className="progress-bar l-blush" role="progressbar" aria-valuenow={43} aria-valuemin={0} aria-valuemax={100} style={{width: '43%'}}> <span className="sr-only">56% Complete</span> </div>
                </div>
              </li>
            </ul>                         */}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Link hidden id="redirect" to="/Index" ></Link>
        <SaleReport_Modal
          show={ShowSaleReport_modal}
          onHide={Hide_SaleReport_Modal}
          toPath={ReportPath}
          readonlyProps={SaleReport_ReadOnlyProp}
          title={ReportTitle}
        />
        <PurchaseReport_Modal
          show={ShowPurchaseReport_modal}
          onHide={Hide_PurchaseReport_Modal}
          toPath={ReportPath}
          readonlyProps={SaleReport_ReadOnlyProp}
          title={ReportTitle}
        />
        <InventoryReport_Modal
          show={ShowInventoryReport_modal}
          onHide={Hide_InventoryReport_Modal}
          toPath={ReportPath}
          readonlyProps={SaleReport_ReadOnlyProp}
          title={ReportTitle}
        />
        <PLReports_Modal
          show={ShowPLReport_modal}
          onHide={Hide_PLReport_Modal}
          toPath={ReportPath}
          readonlyProps={SaleReport_ReadOnlyProp}
          title={ReportTitle}
        />
        <ExpenseReport_Modal
          show={ShowExpenseReport_modal}
          onHide={Hide_ExpenseReport_Modal}
          toPath={ReportPath}
          readonlyProps={SaleReport_ReadOnlyProp}
          title={ReportTitle}
        />
        <FinanceReport_Modal
          show={ShowFinanceReport_modal}
          onHide={Hide_FinanceReport_Modal}
          toPath={ReportPath}
          readonlyProps={SaleReport_ReadOnlyProp}
          title={ReportTitle}
        />
        <ChangePasswordModal 
          show={ShowChangePassword_modal}
          hide={Hide_ChangePassword_Modal}
        />
        </>, document.getElementById("leftsidebar")
    )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
  User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  User_Info: state.Login_User && state.Login_User.User_Data,
  Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
  Global_Project_List: state.GlobalProjectsList && state.GlobalProjectsList.GlobalProjectsList,
  Global_Selected_Project: state.Global_Projects && state.Global_Projects.GlobalProjects,
})
export default connect(mapStateToProps,{setGlobal_Project})(Sidebar)
