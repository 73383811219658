import axios from 'axios'
import { domain } from "../Services/Common"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectGroups = (cb) => {
    axios.get(`${domain}/Groups/SelectGroup`)
        .then(response => {
            cb(response.data.Data)
        })
        .catch(err => console.log(err))
}

export const SaveGroups = (Groups,DeletedGroups,callback) =>{
 
    axios.post(`${domain}/Groups/SaveGroups`,{
        Groups:Groups,
      DeletedGroups:DeletedGroups
    })
        .then(response=>{
          if(response.data.status === true)
          {
            Swal.fire({
              icon: 'info',
              title: 'Sorry...',
              text: `${response.data.message}`,
            })
          }
          else
          {
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
          }
            callback()
        })
        .catch(err=>console.error(err))
  }