import React, {useEffect, useState} from 'react'
import { TextField } from "@material-ui/core"
import { Login, LoginVerifyToken } from './../../Services/LoginAPI'
import { connect } from 'react-redux'
import { setLoginData, setSelectedGlobalVariable, setCompanies,setGlobal_Project,setGlobal_Config,setGlobal_Project_List } from "./../../React_Redux/Actions/index"
import { FinYear } from "./../../Services/FinYear"
import {Link} from 'react-router-dom'
import storage from 'redux-persist/lib/storage'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { SelectConfigData } from './../../Services/ControlPanelAPI'
import { SelectUserProjects } from './../../Services/Projects'

function Logins(props) {
    const [UserName, setUserName] = useState('')
    const [Password, setPassword] = useState('')
    const [LoginPath, setLoginPath] = useState({to: '#'})
   
    useEffect(() => {
         // for all keys defined in your persistConfig(s)
            storage.removeItem('persist:root')
            localStorage.clear()

        document.title = "Pharmacy / Login" 
    
        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden =  true;
        // document.getElementById("page-loader-wrapper").hidden = true;
        document.getElementById("username").focus()
    }, [])
    const LoginSubmit = (e) =>{

        e.preventDefault();

        var validate = true;
        var ErrMessage = '';
        if(UserName === ''){
          validate = false; 
          ErrMessage = 'Enter UserName'
        }
        else if (Password === ''){
          validate = false
          ErrMessage = 'Enter Password'
        }
    
        if(validate){
            debugger
        
        Login(UserName, Password, props, (token) => {

            LoginVerifyToken(token, props, (res) => {

                // props.setHms_User(res.data.user)
                props.setLoginData(res.data.auth.data[0], token);
                props.setCompanies(res.data.comp)
                localStorage.setItem('token', token)

                FinYear(res.data.comp[0].Branch_Id, res.data.auth.data[0].User_id, (data) => {

                    SelectConfigData(res.data.comp[0].Branch_Id, (mConfigData) => {
                        SelectUserProjects(res.data.comp[0].Branch_Id, res.data.auth.data[0].User_id, (mProjects) => {

                            props.setGlobal_Project_List(mProjects)
                            props.setGlobal_Project(mProjects[0])

                            props.setGlobal_Config(mConfigData[0])
                            props.setSelectedGlobalVariable(data[0], res.data.comp[0])

                            setLoginPath({ to: '/index' })

                            document.getElementById('btnAdminDashboard').click()
                            document.getElementById("mainContent").hidden = false;
                            document.getElementById("chat-wrapper").hidden = false;
                            document.getElementById("rightsidebar").hidden = false;
                            document.getElementById("leftsidebar").hidden = false;
                            document.getElementById("TopBar").hidden = false;
                            // document.getElementById("page-loader-wrapper").hidden = false;
                        })
                    })
                })
            })
        })
  
        }
        else {
            toast.configure()
            toast.error(ErrMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
      }
    return (
        <>
        <Link to={LoginPath.to} id="btnAdminDashboard" ></Link>
         <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form validate-form">
                        <span className="login100-form-title p-b-26">
                        Login
                        </span>
                    {/* <div className="wrap-input100 validate-input" > */}
                        <TextField 
                            name='username'
                            id='username'
                            value={UserName}
                            label="User Name"
                            onChange={e =>{
                                setUserName(e.target.value)
                            }}
                            fullWidth
                        />
                    {/* </div> */}
                    {/* <div className="wrap-input100 validate-input" > */}
                        <TextField 
                            name='password'
                            style={{marginTop: '13%', marginBottom: '13%'}}
                            id='password'
                            type='password'
                            value={Password}
                            label="Password"
                            onChange={e =>{
                                setPassword(e.target.value)
                            }}
                            fullWidth
                        />
                    {/* </div> */}
                    <div className="container-login100-form-btn">
                    <div className="wrap-login100-form-btn">
                        <div className="login100-form-bgbtn" />
                        <button type='submit' onClick={LoginSubmit} className="login100-form-btn">
                        Login
                        </button>
                    </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>

        </>
    )
}

export default connect(null, { setLoginData, 
    setSelectedGlobalVariable, setCompanies,setGlobal_Project,setGlobal_Config,setGlobal_Project_List })(Logins)
