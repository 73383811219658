import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card ,Spinner } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectUOMList } from '../../Services/UOMAPI'
import { SelectitemmaintypeList } from '../../Services/ItemMainTypeAPI'
import { SelectItemSubTypeList } from '../../Services/ItemSubTypeAPI'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { GetMaxItemCode, SaveItems,SelectItem } from '../../Services/ItemsAPI'
import { GetCurrentDate } from '../../Services/Common'
import { SelectConfigData } from '../../Services/ControlPanelAPI'

function ItemEntry({ Comp_Id, isAddNew, ItemID,UserID,Comp_Address, show_screen,ProjectID }) {
    const [IsLoader, setIsLoader] = useState(false)
    const [isExpire, setisExpire] = useState(true)
    const [isBarcode, setisBarcode] = useState(true)
    const [is_Pack, setis_Pack] = useState(false)
    const [IsDirect, setIsDirect] = useState(false)
   const [ManufacturedBy_List, setManufacturedBy_List] = useState([])
    const [SelectedManufacturedBy_ListOption, setSelectedManufacturedBy_ListOption] = useState(null)
    const [ItemMainType_List, setItemMainType_List] = useState([])
    const [SelectedItemMainTypeOption, setSelectedItemMainTypeOption] = useState(null)
    const [UOM_List, setUOM_List] = useState([])
    const [SelectedUOM, setSelectedUOM] = useState(null)
    const [ItemSubType_List, setItemSubType_List] = useState([])
    const [SelectedItemSubTypeOption, setSelectedItemSubTypeOption] = useState(null)
    const [PackUnit_List, setPackUnit_List] = useState([])
    const [SelectedPackUnitOption, setSelectedPackUnitOption] = useState(null)
     const [btn_edit, setBtn_edit] = useState(true)
     const [Pack, setPack] = useState(false)
     const [PurchaseRatePer, setPurchaseRatePer] = useState(0)
    const [Item_Data,setItem_Data] = useState({
        ItemID:ItemID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        UserID:UserID,
        ItemCode:'',
        ItemDiscription:'',
        PurchaseRate:0,
        // IsPack:0,
        PackQty:0,  
        StockQty:0,
        IsActive:1,
        SaleRate:0,
        MinStockLevel:0,
        MaxStockLevel:0,
        ReorderStockLevel:0,
        ManufactureID:0,
        CreatedDate:GetCurrentDate(),
        LastSaleRate:0,
        LastPurRate:0,
        IsExpair:0,
        ExpairyDate:GetCurrentDate(),
        IsBarcode:0,
        Barcode:'',
        Remarks:'',
        PackPurRate:0,
        PackSaleRate: 0,
        btn_Disabled:false,
    })
    const [Item_Err, setItem_Err] = useState({
        Name_Err: '',
        PurPrice_Err: '',
        SellPrice_Err: '',
        Cat_Err: '',
        UOM_Err: '',
        SUBUOM_Err: '',
        ManufacturedBy_Err: '',
        PackQty_Err:''
    })
    const [disabledProp, setDisabledProp] = useState(false);
    const [PurRatePer_Config, setPurRatePer_Config] = useState(0)
    useEffect(() => {
        document.title = " PHARMACY / Items"

        SelectUOMList(Comp_Id, ProjectID, (mUOM) => {

            setUOM_List(mUOM)
            setPackUnit_List(mUOM)

            SelectitemmaintypeList(Comp_Id, ProjectID, (mCategory) => {
                setItemMainType_List(mCategory)
                SelectItemSubTypeList(Comp_Id, ProjectID, (mSubType) => {
                    setItemSubType_List(mSubType)

                    SelectManufacturedByList(Comp_Id, ProjectID, (mManufacture) => {
                        setManufacturedBy_List(mManufacture)

                        SelectConfigData(Comp_Id, (mConfig) => {
                            if (mConfig.length > 0) {
                                setPurRatePer_Config(mConfig[0].PurRatePer)
                                setPurchaseRatePer(mConfig[0].PurRatePer)

                                // var isAddNew = true
                                if (isAddNew) {
                                    showLoader()
                                    GetMaxItemCode(Comp_Id, ProjectID, (mCode) => {
                                        hideLoader()
                                        setItem_Data({ ...Item_Data, ItemCode: mCode })

                                    })
                                }
                                else {

                                    setBtn_edit(show_screen.edit)
                                    showLoader()
                                    
                                    SelectItem(Comp_Id, ProjectID, ItemID, (mData) => {
                                        if (mData[0].IsActive === 1)
                                            mData[0].IsActive = true
                                        else
                                            mData[0].IsActive = false
                                        if (mData[0].isExpire == 1)
                                            mData[0].isExpire = true
                                        else
                                            mData[0].isExpire = false
                                        if (mData[0].IsBarcode == 1)
                                            mData[0].IsBarcode = true
                                        else
                                            mData[0].IsBarcode = false
                                        if (mData[0].IsPack == 1)
                                            mData[0].IsPack = true
                                        else
                                            mData[0].IsPack = false

                                        setPack(mData[0].IsPack )
                                        setPurchaseRatePer(mData[0].PurchaseRatePer)
                                        setItem_Data({
                                            ItemID: ItemID,
                                            Comp_Id: mData[0].Comp_Id,
                                            ProjectID: mData[0].ProjectID,
                                            UserID: mData[0].UserID,
                                            ItemCode: mData[0].ItemCode,
                                            ItemDiscription: mData[0].ItemDiscription,
                                            SaleRate: mData[0].SaleRate,
                                            PurchaseRate: mData[0].PurchaseRate,
                                            StockQty: mData[0].StockQty,
                                            // IsPack: mData[0].IsPack,
                                            PackQty: mData[0].PackQty,
                                            MaxStockLevel: mData[0].MaxStockLevel,
                                            MinStockLevel: mData[0].MinStockLevel,
                                            ReorderStockLevel: mData[0].ReorderStockLevel,
                                            IsActive: mData[0].IsActive,
                                            CreatedDate: mData[0].CreatedDate,
                                            IsExpair: mData[0].IsExpair,
                                            ExpairyDate: mData[0].ExpairyDate,
                                            IsBarcode: mData[0].IsBarcode,
                                            Barcode: mData[0].Barcode,
                                            Remarks: mData[0].Remarks,
                                            PackPurRate: mData[0].PackPurRate,
                                            PackSaleRate: mData[0].PackSaleRate,
                                            btn_Disabled:false,
                                            // IsPack: mData[0].IsPack
                                        })

                                        if (mData[0].IsPack) {
                                            setDisabledProp(true)
                                            setis_Pack(false)
                                        }
                                        mCategory.map(value => {
                                            if (value.ItemMainTypeID === mData[0].ItemMainTypeID)
                                                setSelectedItemMainTypeOption(value)
                                        })

                                        mUOM.map(value => {
                                            if (value.UOMID === mData[0].UOMID) {
                                                setSelectedUOM(value)
                                            }
                                            if (value.UOMID === mData[0].PackUnit) {
                                                setSelectedPackUnitOption(value)
                                            }
                                        })
                                        mSubType.map(value => {
                                            if (value.ItemSubTypeID === mData[0].ItemSubTypeID)
                                                setSelectedItemSubTypeOption(value)
                                        })
                                        mManufacture.map(value => {
                                            if (value.ManufacturedByID === mData[0].ManufacturedByID)
                                                setSelectedManufacturedBy_ListOption(value)
                                        })
                                    })
                                    hideLoader()
                                }
                            }
                        })
                })
                })
            })
        })
      
       
    }, [])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
   
   const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
  const validate =() =>{
        var  NameErr=""
        var CatErr = ""
        var UOMErr = ""
        var SubUOMErr = ""
        var PurPriceErr = ""
        var SellPriceErr = ""
        var ManufacturedBy = ""
        var PackQtyErr = "";
        if(!Item_Data.ItemDiscription)
        {
         NameErr = "Name is required"
        }
        if(!SelectedItemMainTypeOption)
        {
        CatErr = "Item Main Type is required"
        }
        
        if(!SelectedUOM)
        {
            UOMErr = "required"
        }
     
        if(!SelectedItemSubTypeOption)
        {
            SubUOMErr="required"
        }
        if(Item_Data.PurchaseRate == 0 || Item_Data.PurchaseRate == undefined )
        {
            PurPriceErr="required"
        }
        if(Item_Data.SaleRate == 0 || Item_Data.PurchaseRate == undefined)
        {
            SellPriceErr="required"
        }
        if(!SelectedManufacturedBy_ListOption)
        {
            ManufacturedBy = "required"
        }


        if(is_Pack){
            if(parseFloat(Item_Data.PackQty) < 0 ||
             parseFloat(Item_Data.PackQty) == 0){
                PackQtyErr = "required"
            }
        }
        if(NameErr)
        {
           setItem_Err({...Item_Err,Name_Err:NameErr})
           document.getElementById('ItemDiscription').focus()
            return false
        }
        
        if(CatErr)
        {
            setItem_Err({...Item_Err,Name_Err:'',Cat_Err:CatErr})
            document.getElementById('ItemMainType').focus()
             return false
        }
     if(UOMErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:UOMErr})
        document.getElementById('UOM').focus()
         return false
     }
     if(SellPriceErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:SellPriceErr})
        document.getElementById('SaleRate').focus()
        return false
     }
     if(PurPriceErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:PurPriceErr})
        document.getElementById('PurchaseRate').focus()
        return false
     }
    
     if(SubUOMErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:SubUOMErr})
        document.getElementById('ItemSubType').focus()
         return false
     }
    if(ManufacturedBy)
    {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:ManufacturedBy})
        document.getElementById('ManufacturedBy').focus()
         return false

    }
    if(PackQtyErr)
    {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:'',PackQty_Err:PackQtyErr})
        document.getElementById('PackQty').focus()
         return false

    }
     setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:'', PackQty_Err:''})
    
      return true
    
    }
     const Save_Handler = () =>{
        if(validate())
        {
            var ItemData = {
                ItemID: Item_Data.ItemID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                ItemCode:Item_Data.ItemCode,
                ItemDiscription:Item_Data.ItemDiscription,
                SaleRate:parseFloat(Item_Data.SaleRate), 
                PurchaseRate:parseFloat(Item_Data.PurchaseRate),
                UOMID:SelectedUOM.UOMID,
                // IsPack: Item_Data.IsPack ? 1 : 0,
                IsPack: Pack ? 1: 0,
                PackQty:parseFloat(Item_Data.PackQty),
                PackUnit:SelectedUOM.UOMID,
                ItemMainTypeID:SelectedItemMainTypeOption.ItemMainTypeID,
                ItemSubTypeID:SelectedItemSubTypeOption.ItemSubTypeID,
                ManufacturedByID:SelectedManufacturedBy_ListOption.ManufacturedByID,
                StockQty:parseFloat(Item_Data.StockQty),
                MinStockLevel:parseFloat(Item_Data.MinStockLevel),
                MaxStockLevel:parseFloat(Item_Data.MaxStockLevel),
                ReorderStockLevel:parseFloat(Item_Data.ReorderStockLevel),
                IsActive: Item_Data.IsActive ? 1 : 0,
                CreatedDate:Item_Data.CreatedDate,
                LastSaleRate:0,
                LastPurRate:0,
                IsExpair: Item_Data.IsExpair ? 1 : 0,
                ExpairyDate:Item_Data.ExpairyDate,
                IsBarcode: Item_Data.IsBarcode ? 1 : 0,
                Barcode:Item_Data.Barcode,
                Remarks:Item_Data.Remarks,
                PackPurRate:Item_Data.PackPurRate , 
                PackSaleRate:Item_Data.PackSaleRate,
                PurchaseRatePer:PurchaseRatePer
                
            }
            setItem_Data({...Item_Data,btn_Disabled:true})
            SaveItems(ItemData, isAddNew,()=>{
         
                setItem_Data({...Item_Data,btn_Disabled:false})
                document.getElementById('btnCancel').click()
            })
        }
   
    }

    return ReactDOM.createPortal (
        <>
        <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title text-white" >
                        <h4>Items</h4>
                    </Col>
                </Row>
            <Card> 
                <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="ItemCode"
                            id="ItemCode"
                            label="Code"
                            value={Item_Data.ItemCode}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="ItemDiscription"
                            id="ItemDiscription"
                            label="Item Name"
                            value={Item_Data.ItemDiscription}
                            onChange={e=>setItem_Data({...Item_Data,ItemDiscription:e.target.value})}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                        <span className="text-danger">{Item_Err.Name_Err}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Remarks"
                                id="Remarks"
                                label="Farmulation"
                                value={Item_Data.Remarks}
                                onChange={e=>setItem_Data({...Item_Data,Remarks:e.target.value})}
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="ManufacturedBy"
                            id="ManufacturedBy"
                            options={ManufacturedBy_List}
                            getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""} 
                            value={SelectedManufacturedBy_ListOption}
                            onChange={(e,value) =>{
                            if(value)
                            setSelectedManufacturedBy_ListOption(value)
                            else
                            setSelectedManufacturedBy_ListOption(null)
                                
                            }}
                            renderInput={(params) => 
                            <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                            />}
                        />
                        <span className="text-danger">{Item_Err.ManufacturedBy_Err}</span>
                    </Col>
                  
                   
                        
                        
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right m-4 p-0" >
                        <Col lg={1} xl={1} md={1} className={"p-0"} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Pack}
                                    id="IsPack"
                                    label="IsPack"
                                  
                                    onChange={e => {
                                        setPack(e.target.checked)
                                       
                                        setis_Pack(e.target.checked)
                                        if(e.target.checked){
                                          setDisabledProp(true)
                                        }
                                        else{
                                            setItem_Data({...Item_Data,PackSaleRate:0,SaleRate:0,PackPurRate:0,PurchaseRate:0})
                                            setDisabledProp(false)
                                        }
                                    }}
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"IsPack"}
                            style={{
                                padding: "0px",
                                marginTop: "10%"
                            }}
                        />

                    </Col>
                    <Col lg={1} xl={1} md={1} >
                        <TextField
                            name="PackQty"
                            id="PackQty"
                            label="PackingQty"
                            type="number"
                            disabled={!is_Pack}
                            value={Item_Data.PackQty}
                            onChange={e=>{
                                if(e.target.value){
                                    var mRate = parseFloat(Item_Data.PackSaleRate)
                                    var mQty = parseFloat(e.target.value)
                                    var mSaleRate =  parseFloat(mRate/mQty)
                                    var mPackPurRate = parseFloat((mRate - mRate * (PurchaseRatePer / 100)).toFixed(2))
                                    var PurRate = parseFloat( (mPackPurRate / mQty ).toFixed(2))
                                    setItem_Data({...Item_Data,PackQty:e.target.value,
                                        PackSaleRate:mRate,
                                        SaleRate:mSaleRate,
                                        PackPurRate:mPackPurRate,
                                        PurchaseRate:PurRate
                                    })

                                }
                                else{
                                    setItem_Data({...Item_Data,PackSaleRate:0,SaleRate:0,PackPurRate:0,PurchaseRate:0,PackQty:''})
                                }
                            }}
                            fullWidth
                            
                        />
                         <span className="text-danger">{Item_Err.PackQty_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="PackSaleRate"
                            id="PackSaleRate"
                            label="Packing Sale Rate"
                            type="number"
                             disabled={!is_Pack}
                            value={Item_Data.PackSaleRate}
                            onChange={e=>{
                                if(e.target.value){
                                var mRate = parseFloat(e.target.value)
                                var mQty = parseFloat(Item_Data.PackQty)
                                var mSaleRate =  parseFloat(mRate/mQty)
                                var mPackPurRate = parseFloat((mRate - mRate * (PurchaseRatePer / 100)).toFixed(2))
                                var PurRate = parseFloat( (mPackPurRate / mQty ).toFixed(2))
                                setItem_Data({...Item_Data,
                                    PackSaleRate:e.target.value,
                                    SaleRate:parseFloat((mRate/mQty).toFixed(2)),
                                    PackPurRate:mPackPurRate,
                                    PurchaseRate:PurRate
                                })
                                }
                                else{
                                    setItem_Data({...Item_Data,PackSaleRate:0,SaleRate:0,PackPurRate:0,PurchaseRate:0})
                                }
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="PackPurRate"
                            id="PackPurRate"
                            label="Packing Pur Rate"
                            type="number"
                            // disabled = {!IsDirect}
                            value={Item_Data.PackPurRate}
                            onChange = {e=>{
                                setItem_Data({...Item_Data,PackPurRate:e.target.value})
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="PurchaseRatePer"
                            id="PurchaseRatePer"
                            label="Purchase Rate %"
                            type="number"                         
                            value={PurchaseRatePer}
                            onChange = {e=>{
                               setPurchaseRatePer(e.target.value)
                                if(is_Pack){
                                    var mRate = parseFloat(Item_Data.PackSaleRate)
                                    var mQty = parseFloat(Item_Data.PackQty)
                                    var mSaleRate =  parseFloat(mRate/mQty)
                                    var mPackPurRate = parseFloat((mRate - mRate * (parseFloat(e.target.value) / 100)).toFixed(2))
                                    var PurRate = parseFloat( (mPackPurRate / mQty ).toFixed(2))
                                    setItem_Data({...Item_Data,
                                        PackSaleRate:Item_Data.PackSaleRate,
                                        SaleRate:parseFloat((mRate/mQty).toFixed(2)),
                                        PackPurRate:mPackPurRate,
                                        PurchaseRate:PurRate
                                    })
                                }else{
                                    var mSaleRate = parseFloat(Item_Data.SaleRate);
                                    var mPurRate = parseFloat((mSaleRate - (mSaleRate * (parseFloat(e.target.value) / 100))).toFixed(2))
                                    setItem_Data({...Item_Data,SaleRate:Item_Data.SaleRate,
                                        PurchaseRate:mPurRate})
                                }
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="PurchaseRate"
                        id="PurchaseRate"
                        type="number"
                        label="Pur. Price"
                        // disabled = {!IsDirect}
                        value={Item_Data.PurchaseRate}
                        onChange={e=>setItem_Data({...Item_Data,PurchaseRate:e.target.value})}
                        fullWidth
                    />
                    <span className="text-danger">{Item_Err.PurPrice_Err}</span>
                </Col>
                <Col lg={2} xl={2} md={2} >
                <TextField
                    name="SaleRate"
                    id="SaleRate"
                    type="number"
                    label="Selling Price"
                    disabled = {disabledProp}
                    value={Item_Data.SaleRate}
                    onChange={e=>{
                        if(e.target.value){
                            var mSaleRate = parseFloat(e.target.value);
                            
                                var mPurRate = parseFloat((mSaleRate - (mSaleRate * (PurchaseRatePer / 100))).toFixed(2))
                                setItem_Data({...Item_Data,SaleRate:e.target.value,
                                    PurchaseRate:mPurRate})
                          
                          

                        }
                        else{
                            setItem_Data({...Item_Data,SaleRate:0,PurchaseRate:0})
                        }
                    }}
                    fullWidth
                />
                <span className="text-danger">{Item_Err.SellPrice_Err}</span>
            </Col>
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                     
                    <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="MinStockLevel"
                                id="MinStockLevel"
                                label="MinStockLevel"
                                type="number"
                                value={Item_Data.MinStockLevel}
                                onChange={e=>setItem_Data({...Item_Data,MinStockLevel:e.target.value})}
                                fullWidth
                            />
                        </Col>
                                           
                    <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="MaxStockLevel"
                                id="MaxStockLevel"
                                label="MaxStockLevel"
                                type="number"
                                value={Item_Data.MaxStockLevel}
                                onChange={e=>setItem_Data({...Item_Data,MaxStockLevel:e.target.value})}
                                fullWidth
                            />
                        </Col>
                    <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="ReorderStockLevel"
                                id="ReorderStockLevel"
                                label="ReorderStockLevel"
                                type="number"
                                value={Item_Data.ReorderStockLevel}
                                onChange={e=>setItem_Data({...Item_Data,ReorderStockLevel:e.target.value})}
                               
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="ItemMainType"
                            id="ItemMainType"
                            options={ItemMainType_List}
                            getOptionLabel={(option) => option.ItemMainType ? option.ItemMainType : ""} 
                            value={SelectedItemMainTypeOption}
                            onChange={(e,value) =>{
                            if(value)
                            setSelectedItemMainTypeOption(value)
                            else
                            setSelectedItemMainTypeOption(null)
                                
                            }}
                            renderInput={(params) => 
                            <TextField {...params} name="ItemMainType" label="ItemMainType"
                            />
                        }
                        />
                        <span className="text-danger">{Item_Err.Cat_Err}</span>
                    </Col>
                        <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="ItemSubType"
                            id="ItemSubType"
                            options={ItemSubType_List}
                            getOptionLabel={(option) => option.ItemSubType ? option.ItemSubType : ""} 
                            value={SelectedItemSubTypeOption}
                            onChange={(e,value) =>{
                            if(value)
                            {
                                setSelectedItemSubTypeOption(value)
                            
                            }
                            else
                            setSelectedItemSubTypeOption(null)
                                
                            }}
                            renderInput={(params) => 
                            <TextField {...params} name="ItemSubType" label="ItemSubType"
                            />}
                        />
                        <span className="text-danger">{Item_Err.SUBUOM_Err}</span>
                    </Col>
                        <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="UOM"
                            id="UOM"
                            options={UOM_List}
                            getOptionLabel={(option) => option.UOM ? option.UOM : ""} 
                            value={SelectedUOM}
                            onChange={(e,value) =>{
                            if(value)
                            {
                                setSelectedUOM(value)
                               
                            }
                            else
                            setSelectedUOM(null)
                                
                            }}
                            renderInput={(params) => 
                            <TextField {...params} name="UOM" label="UOM"
                            />
                        }
                        />
                        <span className="text-danger">{Item_Err.UOM_Err}</span>
                    </Col>
                      
                        </Row>
                      
                   
                        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                        
                        <Col lg={1} xl={1} md={1} className={"p-0"} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Item_Data.IsActive}
                                    id="IsActive"
                                    label="IsActive"
                                    onChange={e => setItem_Data({ ...Item_Data, IsActive: e.target.checked })}
                                  
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"IsActive"}
                            style={{
                                padding: "0px",
                                marginTop: "20%"
                            }}
                        />

                    </Col>
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="CreatedDate"
                        id="CreatedDate"
                        label="CreatedDate"
                        type="date"
                         value={Item_Data.CreatedDate}
                        onChange={e=>setItem_Data({...Item_Data,CreatedDate:e.target.value})}
                        fullWidth
                    />
                </Col>
                 
                           
                           
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-4 p-0" >
                  
                    {/* <Col lg={1} xl={1} md={1} className={"p-0"} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Item_Data.IsExpair}
                                    id="IsExpair"
                                    label="IsExpire"
                                    onChange={e => {
                                        setItem_Data({ ...Item_Data, IsExpair: e.target.checked })

                                        setisExpire(!e.target.checked)
                                    }}
                                  
                                     name="Access"
                                    color="primary"
                                />
                            }
                            label={"IsExpire"}
                            style={{
                                padding: "0px",
                                marginTop: "20%"
                            }}
                        />

                    </Col>
                    <Col lg={3} xl={3} md={3} >
                            <TextField
                                name="ExpairyDate"
                                id="ExpairyDate"
                                label="ExpairyDate"
                                type="date"
                                disabled={isExpire}
                                 value={Item_Data.ExpairyDate}
                                onChange={e=>setItem_Data({...Item_Data,ExpairyDate:e.target.value})}
                                fullWidth
                            />
                    </Col> */}
                   
                    
                    
                          
                        {/* <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="StockQty"
                                id="StockQty"
                                label="StockQty"
                                type="number"
                                value={Item_Data.StockQty}
                                onChange={e=>setItem_Data({...Item_Data,StockQty:e.target.value})}
                                fullWidth
                            />
                        </Col> */}
                    
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right m-4 p-0" >
                        {/* <Col lg={1} xl={1} md={1} className={"pl-1 p-0"} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Item_Data.IsBarcode}
                                    id="IsBarcode"
                                    label="IsBarcode"
                                  
                                    onChange={e => {
                                        setItem_Data({ ...Item_Data, IsBarcode: e.target.checked })

                                        setisBarcode(!e.target.checked)
                                    }}
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"IsBarcode"}
                            style={{
                                padding: "0px",
                                marginTop: "20%"
                            }}
                        />

                    </Col>
                    <Col lg={3} xl={3} md={3} >
                        <TextField
                            name="Barcode"
                            id="Barcode"
                            label="Barcode"
                            disabled={isBarcode}
                            value={Item_Data.Barcode}
                            onChange={e=>setItem_Data({...Item_Data,Barcode:e.target.value})}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                    </Col> */}
                        </Row>
                        
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                    {btn_edit && (
                        <button 
                        type="button" 
                        className="btn btn-primary"  
                        style={{border: "none"}} 
                        onClick={Save_Handler}
                        disabled={Item_Data.btn_Disabled}
                        > 
                        {Item_Data.btn_Disabled ? (<><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Saving... </>) : <> <Save /> Save </>}</button>)}
                      {/*   {btn_edit && (<button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={Save_Handler} > <Save />  Save</button>)}*/}
                        {/* <Link id="btnPrint" className="btn btn-outline-primary" style={{ border: "none" }} target ="_blank" to='/IPD_Registration_Report' onClick={Print_Handler}> <Print /> <u>Print</u></Link> */}
                        <Link id="btnCancel" className="btn btn-primary" style={{ border: "none" }} to='/ItemList' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                <Loader
                show={IsLoader}
                hide={hideLoader}
              />
            </Card>
        </> , document.getElementById('mainContent')
    )

 
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.Items && state.Items.isAddNew,
    ItemID: state.Items && state.Items.ItemID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(ItemEntry)
