import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"
import { GetCurrentDate } from "./../../Services/Common"
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {SelectDoctorsList} from '../../Services/OPDRegistrationAPI'
import {SelectWards} from '../../Services/WardsAPI'
import { SelectPatientWRTWard,SaveDoctorVisits } from "../../Services/DoctorVisitsAPI";
const DoctorVisitsEntry = ({show, onHide, VisitID, is_add_new,Comp_Id,ProjectID}) => {
    const [Doctor_List, setDoctor_List] = useState([])
    const [SelectedDoctor, setSelectedDoctor] = useState(null)
    const [VisitsDate, setVisitsDate] = useState(GetCurrentDate())
    const [btn_edit, setBtn_edit] = useState(true)
    const [Visits, setVisits] = useState(1)
    const [Error, setError] = useState({
        DoctorErr: '',
        Ward_Err:'',
        PatinetErr: '',
       VisitErr:''
    })
    const [WardsList, setWardsList] = useState([])
    const [SelectedWards, setSelectedWards] = useState(null)
    const [PatientList, setPatientList] = useState([])
    const [SelectedPatient, setSelectedPatient] = useState(null)
    useEffect(()=>{
        document.title = "Doctor Visits"
        if(is_add_new === true){
            document.getElementById("Doctor").focus()
        }else if(is_add_new === false){
            if(VisitID){

            }
        }
        Select_Doctor_List()
        Select_Wards_List()

    },[is_add_new,VisitID])
    const Select_Doctor_List = () =>{

        SelectDoctorsList(Comp_Id,(data) =>{

            setDoctor_List(data)
        })
    }
    const Select_Wards_List = () =>{

        SelectWards(Comp_Id,ProjectID,(mWards) =>{

           setWardsList(mWards)
        })
    }
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    const clearFields = () => {

        onHide()
        setWardsList([])
        setSelectedWards(null)
        setDoctor_List([])
        setSelectedDoctor(null)
        setPatientList([])
        setSelectedPatient(null)
        setVisitsDate(GetCurrentDate())
        setVisits(1)
        setError({DoctorErr: "", Ward_Err: "", PatinetErr: ""})
    }
    const validation = () =>{
        
        if(!SelectedDoctor)
        {
            setError({DoctorErr: "Must be Required", Ward_Err: "", PatinetErr: ""})
            document.getElementById("Doctor").focus()
            return false
        } else if (!SelectedWards){
            setError({DoctorErr: "", Ward_Err: "Must be Required", PatinetErr: ""})
            document.getElementById("Ward").focus()
            return false
        } else if (!SelectedPatient){
            setError({DoctorErr: "", Ward_Err: "",PatinetErr:'Required'})
            document.getElementById("PatientName").focus()
            return false
        }
         else if (Visits === 0.00 || Visits === ''){
            setError({DoctorErr: "", Ward_Err: "",PatinetErr:"", VisitErr: "Must be Required"})
            document.getElementById("Visits").focus()
            return false
        }

        setError({DoctorErr: "", Ward_Err: "",PatinetErr:"", VisitErr: ""})
            return true
    }
    const Save_Handler = () =>{
        if(validation())
        {
           
            var obj_save = {
                VisitID: VisitID,
                PatientID: SelectedPatient.RegistrationID,
                Visits: Visits,
                DoctorID: SelectedDoctor.User_id,
                VisitsDate:VisitsDate,
                Comp_Id: Comp_Id,
                ProjectID:ProjectID ,
            }

            SaveDoctorVisits(obj_save,  is_add_new,(message) =>{
                clearFields()
            })
        }
    }
    return (
        <>
            <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Doctor Visits </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>

                        <Row className=" row_margin_top row_margin_left row_margin_right" >

                            <Col lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Doctor"
                                    id="Doctor"
                                    options={Doctor_List}
                                    getOptionLabel={(option) => option.User_name ? option.User_name : ""}
                                    value={SelectedDoctor}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelectedDoctor(value)
                                           
                                        }
                                        else {
                                            setSelectedDoctor(null)
                                            
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Doctor" id="Doctor" label="Doctor"
                                        />}

                                />
                                <span style={{color: "red"}} >{Error.DoctorErr}</span>
                            </Col>
                            <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="Ward"
                                id="Ward"
                                options={WardsList}
                                getOptionLabel={(option) => option.Ward ? option.Ward : ""} 
                                value={SelectedWards}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedWards(value)
                                    SelectPatientWRTWard(Comp_Id,ProjectID,value.WardID,(mPatient)=>{
                                        setPatientList(mPatient)
                                    })

                                }
                                else{
                                    setSelectedWards(null)
                                    setSelectedPatient(null)

                                }
                               
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Ward" label="Wards"
                                />}
                            />
                           <span className="text-danger">{Error.Ward_Err}</span>
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PatientName"
                            id="PatientName"
                            options={PatientList}
                            getOptionLabel={(option) => option.PatientName ? option.PatientName : ""}
                            value={SelectedPatient}
                            onChange={(e, value) => {

                                if (value) {
                                    setSelectedPatient(value)
                                   
                                }
                                else {
                                    setSelectedPatient(null)
                                    
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PatientName" id="PatientName" label="PatientName"
                                />}

                        />
                        <span style={{color: "red"}} >{Error.PatinetErr}</span>
                    </Col>
                        </Row>

                        <Row className=" row_margin_top row_margin_left row_margin_right" style={{marginTop: "4%", marginBottom: "4%"}} >
                            <Col lg={4} xl={4} md={4} >
                                <TextField
                                    name="VisitsDate"
                                    id="VisitsDate"
                                    label="VisitsDate"
                                    value={ VisitsDate }
                                    onChange = {e => { setVisitsDate(e.target.value) }}
                                    type="date"
                                    fullWidth
                                />
                            </Col>
                            <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Visits"
                                id="Visits"
                                label="Visits"
                                value={ Visits }
                                onChange = {e => { setVisits(e.target.value) }}
                                type="number"
                                fullWidth
                            />
                        </Col>
                            
                        </Row>
                       
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary" style={{ border: "none" }} onClick={Save_Handler} > <Save /> <u>Save</u></Button>)}
                                <Button variant="btn btn-primary" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id, 
    ProjectID : state.Global_Projects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(DoctorVisitsEntry)