import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { TextField } from "@material-ui/core"
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit , Print} from '@material-ui/icons'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { Autocomplete } from '@material-ui/lab'

import { SelectIPDPatientBillList,CancleBill } from '../../Services/IPDBillingAPI';
import { SelectProjects, SelectUserProjects } from "../../Services/Projects"
import { GetCurrentDate } from '../../Services/Common'
function IPDBillingList({Comp_Id,p_userid}) {

    const [IPDBilling_Data, setIPDBilling_Data] = useState([])
 
    const [ListDate , setListDate] = useState(GetCurrentDate())
    const [btn_add, setBtn_add] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    useEffect(()=>{
      document.title= "IPD Billing "
      
      SelectUserProjects(Comp_Id,p_userid,(mProjects)=>{
        setProjectList(mProjects)
        setSelectedProject(mProjects[0])
        if(mProjects.length > 0)
        Select_IPDBilling_List(mProjects[0].Project_ID,ListDate)
       
      })
    //   SelectGroupScreenAccess(props.Group_Id,5,39,(data)=>{
            
    //     setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
  
    //     props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
    //     parseInt(data.Edit) === 0 ? false : true,
    //     parseInt(data.Del) === 0 ? false : true,
    //     data.OtherAccess
    //     )
        
    // }) 
    //   var date = new Date()
    //   var ToDay = date.getFullYear() + "-"+ (date.getMonth() + 1) +"-"+ String(date.getDate()).padStart(2, '0')
   
      

    },[])
    
  const Select_IPDBilling_List = (ProjectID,BillingDate)=>{
    
    SelectIPDPatientBillList(Comp_Id,ProjectID,BillingDate,(mData)=>{
     
        setIPDBilling_Data(mData)
    })
  }
   
  const column_IPDBilling = [
    {
      name: "RegistrationNo",
      label: "Registration No",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "PatientName",
      label: "Patient Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "PhoneNo",
      label: "Patient Phone#",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "BillDate",
      label: "Billing Date",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "Bill",
      label: "Print Bill",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {
          var v_id = IPDBilling_Data
          if (v_id[rowIndex.rowIndex] != null)
            return (
              <>
                <Link to={`/IPDBilling_Report/${IPDBilling_Data[rowIndex.rowIndex].BillID}`} onClick={e=>{
                  localStorage.setItem('token',sessionStorage.getItem('token'))
                }} target="_blank" > <Print className="text-primary" /></Link> 
              </>
            );
        }
      }
    },
    {
      name: "DischargeSlip",
      label: "Discharge Slip",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {
          var v_id = IPDBilling_Data
          if (v_id[rowIndex.rowIndex] != null)
            return (
              <>
                <Link to={`/DischargeSlip/${IPDBilling_Data[rowIndex.rowIndex].RegistrationID}`} onClick={e=>{
                  localStorage.setItem('token',sessionStorage.getItem("token"))
                }} target="_blank" > <Print className="text-primary" /></Link> 
              </>
            );
        }
      }
    },
    {
      name: "IsCancleBill",
      label: "Cancelled",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {
             
          var BillID =IPDBilling_Data
          if (BillID[rowIndex.rowIndex] != null)
          
            return (
              <Link to={"#"} 
              onClick={e=>CancleHandler(BillID[rowIndex.rowIndex])}
             >{BillID[rowIndex.rowIndex].IsCancleBill === 1 ?  <CheckCircleIcon className="text-success" /> : <CancelIcon className="text-danger" /> }</Link>
          
            );
            
        }
      }
    },
  ];
 const CancleHandler= (BillID)=>{
  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    
    },
    buttonsStyling: false
  })
  var a = BillID.IsCancleBill === 1 ? 'Yes UnCancle Bill' : 'Yes Cancle Bill!'
  var b = BillID.IsCancleBill === 1 ? 'Your Record has been UnCancled.' : 'Your Record has been Cancled.'
  swalWithBootstrapButtons.fire({
    
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: a,
    cancelButtonText: 'No, cancel!',
    reverseButtons: true,
    background: ' rgba(43, 165, 137, 0.45)',
  }).then((result) => {
    if (result.isConfirmed) {
      CancleBill(BillID.BillID,BillID.IsCancleBill,()=>{
        swalWithBootstrapButtons.fire(
         
          'Awww! Success',
          b,
          'success'
        )
        Select_IPDBilling_List(SelectedProject.Project_ID,ListDate)
      })
    
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        
        'Cancelled',
        'Your Bill is safe :)',
        'error'
      )
    }
  })
 }
   
    return ReactDOM.createPortal(
      <>
      <Card>
          <Row className="row_margin_top row_margin_left row_margin_right p-2" >
            <Col lg={6} xl={6} md={6} sm={6} className="header_title" >
                <h4>IPD Billing</h4>
            </Col>
          </Row>
                <Row className="row_margin_top row_margin_left row_margin_right pl-2" >
                    <Col xl={3} lg={3} md={3} sm={2} >
                        <TextField
                            name="BillingDate"
                            id="BillingDate"
                            label="Date"
                            value={ListDate}BillDate
                            type="date"
                            onChange={e => {
                                if(e.target.value)
                                {
                                    setListDate(e.target.value)
                                    
                                    Select_IPDBilling_List(SelectedProject.Project_ID,e.target.value)

                                }
                                else
                                {
                                  setIPDBilling_Data([])
                                }
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                    <Autocomplete
                        name="Project"
                        id="Project"
                        options={ProjectList}
                        getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                        value={SelectedProject}
                        onChange={(e, value) => {
                            if (value)
                            {
                                setSelectedProject(value)

                               
                                Select_IPDBilling_List(Comp_Id,value.Project_ID,ListDate)
                            }
                            else
                            {
                              setSelectedProject(null)
                              setIPDBilling_Data([])
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="Project" label="Select Project"
                            />}
                    />
                </Col>
                    <Col lg={6} xl={6} md={6} sm={6}  > </Col>
                    <Col lg={10} xl={10} md={10} sm={10}  > </Col>
                    <Col xl={2} lg={2} md={3} sm={4} style={{ textAlign: "right" }} >
                        { btn_add && (<Link className="btn btn-primary mr-2" to={"/IPDBilling"} style={{ border: "none" }}> <Add />  Create New</Link>)}
                    </Col>
                </Row>
          <Row className=" row_margin_left row_margin_right" >
          <Col style={{zIndex:1}}>           
                <div className="table-responsive" >
                    <MUIDataTable
                        name = "IPDBilling_Datatables"
                        data={IPDBilling_Data}
                        columns={column_IPDBilling}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                </div>
              </Col>
          </Row>
         
      </Card>                   
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state =>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
   
    Group_Id : state.Login_User.User_Data.Group_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps)(IPDBillingList)
