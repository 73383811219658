import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptDailySaleOfLastMOnth } from '../../../Services/Sale_ReportAPI'

function DailySaleOfLastMOnth({Comp_Name,Comp_Id,User_Name}) {
    const { ProjectID,FromDate,ToDate,Num } = useParams();
    const [ItemData, setItemData] = useState([])
    const [DisAmt, setDisAmt] = useState(0)
    const [Qty, setQty] = useState(0)
    const [TotalValue, setTotalValue] = useState(0)

    useEffect(() => {
        document.title = "Pharmacy / Daily Sale of Last Month"
        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;

        rptDailySaleOfLastMOnth(Comp_Id,ProjectID,(mData)=>{
            var Totals = mData.splice(-1);
            setDisAmt(Totals[0].TotalDiscount.split('-')[1])
            setQty(Totals[0].TotalQuantity.split('-')[1])
            setTotalValue(Totals[0].GrandTotal.split('-')[1])
            setItemData(mData)
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={12} md={12} xl={12}>
                    <h2 > {Comp_Name} </h2> <br /> <h3>Daily Sale of Last Month</h3>
                </Col>
                {/* <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row >
                        <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px', position: 'absolute', left: '10%' }}>
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} style={{marginLeft:'26%'}}>
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }}>
                     <Row >
                            <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px',left:'60%',textAlign:'left'}}>
                            <b>To Date:</b>
                            </Col>
                            <Col lg={9} md={9} xl={9} style={{marginLeft:'-8%'}}>
                            {ReverseDate(ToDate)}
                            </Col>
                    </Row>
                </Col> */}
            </Row>
            
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >INVNo.</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >WareHouse</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Customer Name</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >DisAmt</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >GrandTotal</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                                
                        {
                            ItemData.map((Itemdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.InvoiceDate || Itemdata.InvoiceDate != '' && ReverseDate(Itemdata.InvoiceDate)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleInvoiceNo === "" ? "-" : Itemdata.SaleInvoiceNo}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.WareHouse}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.CustomerName} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TotalQuantity}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TotalDiscount}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.GrandTotal} </td>
                                    </tr>
                 
                                 </>
                            ))

                        } 
                         <tr id='footer_balance' style={{border:'2px solid black',borderLeft:'none',borderRight:'none'}}>
                            <td id='footer_td_cb' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  >Totals: </td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  > {Qty}</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{DisAmt}</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalValue}</td>
                        </tr>
                     
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
         </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})

export default connect(mapStateToProps)(DailySaleOfLastMOnth)
