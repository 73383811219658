import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptCustomerLedger } from '../../../Services/Sale_ReportAPI'
import { SelectcustomersList } from '../../../Services/CustomersAPI'

function CustomerLedger({Comp_Name,Comp_Id,User_Name}) {

    const { ProjectID,FromDate,ToDate,FinYear,CustomerID } = useParams();
    const [CustomerData, setCustomerData] = useState([])
    const [Customer, setCustomer] = useState(null)
    const [TotalBalance, setTotalBalance] = useState(0)
    const [TotalPaid, setTotalPaid] = useState(0)
    const [GrandTotal, setGrandTotal] = useState(0)
    useEffect(() => {
        document.title = "Pharmacy / Customer Ledger" 

        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;

        rptCustomerLedger(Comp_Id,ProjectID,FromDate,ToDate,FinYear,CustomerID,(mData)=>{
            var Totals = mData.splice(-1);
            setTotalBalance(Totals[0].Balance.split('-')[1])
            setTotalPaid(Totals[0].PaidAmt.split('-')[1])
            setGrandTotal(Totals[0].GrandTotal.split('-')[1])
            setCustomerData(mData)
        })
        SelectcustomersList(Comp_Id,ProjectID,(mCuctomerData)=>{
            mCuctomerData.map(value=>{
                if(value.CustomerID == CustomerID){
                    setCustomer(value)
                }
            })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            {/* <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '1%' }}>
                <Col sm={12} md={12} xl={12}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Customer Ledger</h3>
                </Col>
            </Row> */}
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                 <Col sm={12} md={12} xl={12}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Customer Ledger</h3>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={9} md={9} xl={9} >
                        <b>Financial Year:</b>
                        </Col>
                        <Col lg={3} md={3} xl={3} >
                        {FinYear}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                        <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={9} md={9} xl={9} >
                        <b>Customer:</b>
                        </Col>
                        <Col lg={3} md={3} xl={3} >
                        {Customer && Customer.CustomerName}
                        </Col>
                    </Row>
                </Col>

            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >TNo.</th>
                            <th style={{ width: '2%', textAlign: 'center', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >PaymentType</th>
                            <th style={{ width: '23%', textAlign: 'left', fontWeight: "bold" }} >Narration</th>
                            <th style={{ width: '10%', textAlign: 'right', fontWeight: "bold" }} >GrandTotal</th>
                            <th style={{ width: '10%', textAlign: 'right', fontWeight: "bold" }} >PaidAmt</th>
                            <th style={{ width: '15%', textAlign: 'right', fontWeight: "bold",borderTop:"2px solid #000" }} >Balance</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                       
                        {
                            CustomerData.map((customerdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{customerdata.TransactionDate || customerdata.TransactionDate != '' && ReverseDate(customerdata.TransactionDate)}</td>
                                        <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TransactionNo === "" ? "-" : customerdata.TransactionNo}</td>
                                        <td style={{ width: '2%', textAlign: 'Center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TransactionType}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {customerdata.PaymentType} </td>
                                        <td style={{ width: '23%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.Naration}</td>
                                        <td style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.GrandTotal}</td>
                                        <td style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.PaidAmt} </td>
                                        <td style={{ width: '15%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.Balance}</td>
                                    </tr>
                                  
                                </>
                            ))

                        }
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Grand Total:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal}</td>
                        </tr>
                        <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Total Paid:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalPaid}</td>
                        </tr>
                        <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Balance:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalBalance}</td>
                        </tr>
                    </tbody>


                </table>
                {/* </div> */}

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})
export default connect(mapStateToProps)(CustomerLedger)
