import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom"
import { Row, Col, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { ExpansionPanelDetails, TextField } from "@material-ui/core"
import MUIDataTable from "mui-datatables";
import { SelectTotalServices,SelectTotalPateintsBillDetails,SelectTopPatients,SelectTotalValues,SelectOPDData } from '../../Services/IPDDashboardAPI'
import './../index/style.css'
import { ThousandSeparater,GetCurrentDate } from "../../Services/Common"
import CanvasJSReact from '../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const HospitalDashboard = ({Comp_Id,Project_ID}) => {
    
    const [Total_Staff, setTotal_Staff] = useState(0.00)
    const [Services_Data, setServices_Data] = useState([])
    const [Total_Doctors, setTotal_Doctors] = useState(0.00)
    const [PatinetsEarning, setPatinetsEarning] = useState([])
    const [Total_Patient_Registered, setTotal_Patient_Registered] = useState(0.00)
    const [PatientRegistered_Data, setPatientRegistered_Data] = useState([])
    const [Total_Doctor_Earning, setTotal_Doctor_Earning] = useState(0.00)
    var [FromDate, setFromDate] = useState(GetCurrentDate)
    var [ToDate, setToDate] = useState(GetCurrentDate)
    var [nav_to_link, setNav_to_link] = useState({ nav: "#" })
    const [Doctor_Statistic_Data, setDoctor_Statistic_Data] = useState([])
    const [TotalStaff, setTotalStaff] = useState(0.00)
    const [TotalDoctors, setTotalDoctors] = useState(0.00)
    const [TotalAdmitted, setTotalAdmitted] = useState(0.00)
    const [IPD_Registration_Data, setIPD_Registration_Data] = useState([])
    const [TotalGross, setTotalGross] = useState(0.00)
    useEffect(() => {
        document.title = 'HMS / IPD DASHBOARD'
        Select_Total_Services()
       Select_Top_Patients()
        Select_Total_Values()
        Select_TotalPateintsBill_Details()
        select_OPDData()
    }, [Comp_Id])
    const options = {
        title: {
            text: `Service Wise Amount`,
            fontFamily: "Century Gothic",
        },
        dataPointWidth: 15,
        axisX: {
            title: "",
            gridThickness: 0,
            tickLength: 10,
            lineThickness: 0,
            // labelFormatter: function () {
            //     return ``;
            // }
        },
        axisY: {
            title: "",
            // labelFormatter: addSymbols
            // suffix: " M"

        },
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                dataPoints: Services_Data,
                toolTipContent: `<strong>{label}</strong></br>Rs. {y} `

            }
        ]

    }

    const options1 = {
        title: {
            text: `Patient Earnings`,
            fontFamily: "Century Gothic",
        },
        dataPointWidth: 15,
        axisX: {
            title: "",
            gridThickness: 0,
            tickLength: 10,
            lineThickness: 0,
            // labelFormatter: function () {
            //     return ``;
            // }
        },
        axisY: {
            title: "",
            // labelFormatter: addSymbols
            // suffix: " M"

        },
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "pie",
                dataPoints:PatinetsEarning,
                toolTipContent: `<strong>{label}</strong></br> {y} M`

            }
        ]

    }
    const column_IPD_Registration = [
        {
         name: "Name",
         label: "Patient Name",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "RegFee",
            label: "Registration Fee",
            
            options: {
             filter: true,
             sort: true,
            
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
              )
             },
             
            }
           },
        {
         name: "DoctorFee",
         label: "DoctorFee",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "RoomRent",
         label: "Room Rent",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        {
            name: "Services",
            label: "Services",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
            }
           },
        
        

         {
            name: "Discount",
            label: "Discount",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
         {
            name: "GrossTotal",
            label: "Gross Total",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
            }
           }
        
    ];
    const select_OPDData = () =>{
        SelectOPDData(Comp_Id,Project_ID,FromDate,ToDate,(mData)=>{
           
            setTotalStaff(mData[0].ConsultedPatients)
            setTotalDoctors(mData[0].OPDEarnings)
        })
    }
    const Select_Total_Services = ()=>{
        var TotalServicesAmt = 0;
        SelectTotalServices(Comp_Id,Project_ID,FromDate,ToDate,(mData)=>{
            if(mData.length > 0){
                mData.map(value=>{
                    value.label = value.s_name
                    value.y = value.price
    
                    TotalServicesAmt += value.price
                })
                setServices_Data(mData)
            }else{
              
            }
          
        })

    }
    const Select_TotalPateintsBill_Details = () =>{
        var sum =0;
    SelectTotalPateintsBillDetails(Comp_Id,Project_ID,FromDate,ToDate,(mData)=>{
       if(mData.length > 0)
       {
        mData.map(value=>{
            sum += value.GrossTotal;
            
        })
        setTotalGross(sum) 
        setIPD_Registration_Data(mData)
       }
       else
       {
        setTotalGross(0) 
        setIPD_Registration_Data([])

       }


    })
}
const Select_Top_Patients = () => {

    SelectTopPatients(Comp_Id,Project_ID,FromDate,ToDate,(mData)=>{
        mData.map(value =>{
            value.label = value.pt_name
            value.y = value.b_grosstotal
        })
        setPatinetsEarning(mData)
    })
}
const Select_Total_Values = () =>{
    SelectTotalValues(Comp_Id,Project_ID,(values)=>{
    // setTotalStaff(values[0].TotalStaff);
    // setTotalDoctors(values[0].TotalDoctor);
    setTotalAdmitted(values[0].AdmittedPatient);
    })

}
    return ReactDOM.createPortal (
        <>
        <Card className='mt-5'>
                <Link target='_blank' id='redirtect_to_link' to={nav_to_link.nav} ></Link>
                <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={3} md={3} xl={3}>
                        <div id='ttl_rec_main' >
                            <div className="w3-panel w3-card" >
                                <div id='total_rec_amnt' className="w3-panel w3-card mt-3"  >
                                    <p id='total_rec_amnt_p'  >Consulted Patients:</p>
                                    <h6 id='total_rec_amnt_h6' style={{ textAlign: "right", marginTop: "3%" }} ><a>  {TotalStaff} </a></h6>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} md={3} xl={3}>
                        <div id='ttl_rec_main'>
                            <div className="w3-panel w3-card" >
                                <div id='total_rec_amnt' className="w3-panel w3-card  mt-3"  >
                                    <p id='total_rec_amnt_p'  >OPD Earings:</p>
                                    <h6 id='total_rec_amnt_h6' style={{ textAlign: "right", marginTop: "3%" }} ><a> {TotalDoctors} </a></h6>

                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} md={3} xl={3}>
                        <div id='ttl_rec_main'>
                            <div className="w3-panel w3-card  mt-3" >
                                <div id='total_rec_amnt' className="w3-panel w3-card"  >
                                    <p id='total_rec_amnt_p'  > Patient Registered:</p>
                                    <h6 id='total_rec_amnt_h6' style={{ textAlign: "right", marginTop: "3%" }} ><a>  {TotalAdmitted} &nbsp; </a></h6>

                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} md={3} xl={3}>
                        <div id='ttl_rec_main'>
                            <div className="w3-panel w3-card  mt-3 pr-3" >
                                <div id='total_rec_amnt' className="w3-panel w3-card" >
                                    <p id='total_rec_amnt_p'  > IPD Earnings:</p>
                                    <h6 id='total_rec_amnt_h6' style={{ textAlign: "right", marginTop: "3%" }} ><a> {TotalGross}  </a></h6>

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className=" row_margin_top row_margin_left row_margin_right pl-3" >
                    <Col lg={3} md={3} xl={3}>
                        <TextField
                            name="FromDate"
                            id="FromDate"
                            label="From Date"
                            type="date"
                            value={FromDate}
                            onChange={e => {
                                setFromDate(e.target.value)
                                FromDate = e.target.value
                                Select_Total_Services()
                                Select_TotalPateintsBill_Details()
                                 Select_Top_Patients()
                                 select_OPDData()
                            }}
                            fullWidth
                        />
                    </Col>

                    <Col lg={3} md={3} xl={3}>
                        <TextField
                            name="ToDate"
                            id="ToDate"
                            label="To Date"
                            type="date"
                            value={ToDate}
                            onChange={e => {
                                setToDate(e.target.value)
                                ToDate = e.target.value
                                Select_Total_Services()
                                 Select_TotalPateintsBill_Details()
                               Select_Top_Patients()
                               select_OPDData()
                            }}
                            fullWidth
                        />
                    </Col>
                </Row>

                <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} md={6} xl={6}>
                            <CanvasJSChart options={options}
                            /* onRef={ref => this.chart = ref} */
                            />
                    </Col>

                    <Col lg={6} md={6} xl={6}>
                            <CanvasJSChart options={options1}
                            /* onRef={ref => this.chart = ref} */
                            />
                    </Col>
                </Row>
  <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                    <MUIDataTable
                        name = "IPD_Datatables"
                        data={IPD_Registration_Data}
                        columns={column_IPD_Registration}
                        options={{
                        selectableRows: 'none',
                        print: true,
                        search: false,
                        download: true,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                </div>
              </Col>
          </Row>
            </Card>
        </>, document.getElementById("mainContent")
  )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
  
  })
  export default connect(mapStateToProps)(HospitalDashboard)
