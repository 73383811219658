import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";

import { SelectItemsWRTReOrderStock } from '../../Services/PurchaseOrderAPI' 

function ReOrderStockModal({show, onHide, mSaveStock}) {
    var Comp_Id = 1
    var ProjectID = 1
    const [Min_Stock_Data, setMin_Stock_Data] = useState([])
    const [LoadAlll, setLoadAlll] = useState(false)
    useEffect(()=>{
        SelectItemsWRTReOrderStock(Comp_Id,ProjectID,(mStockData)=>{
            mStockData.map(value=>{
                value.chkForSelect = false
            })
            setMin_Stock_Data(mStockData)
        })

    },[show])

    const column_Min_Stock = [
        {
            name: "Select",
            label: "Select",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {
                    var dopt_id = Min_Stock_Data
                    if (dopt_id[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Min_Stock_Data[rowIndex.rowIndex].chkForSelect}
                                            id={`chk${rowIndex.rowIndex}`}
                                            onChange={e => {
                                                let newMin_Stock_Data = [...Min_Stock_Data]
                                                newMin_Stock_Data[rowIndex.rowIndex].chkForSelect = e.target.checked 
                                               setMin_Stock_Data(newMin_Stock_Data)
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </>
                        );
                }
            }
        },
        {
          name: "ItemID",
          label: "ItemID",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "ItemDiscription",
          label: "ItemDiscription",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "UOM",
            label: "UOM",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
        {
          name: "Barcode",
          label: "Barcode",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "PurchaseRate",
          label: "PurchaseRate",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "StockQty",
            label: "StockQty",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
    ];

    const save_Handler = ()=>{
      var  mArray = []
        for(let i=0; i<Min_Stock_Data.length; i++ )
        {
            if(Min_Stock_Data[i].chkForSelect)
            {
                Min_Stock_Data[i].Qty = 1
                Min_Stock_Data[i].PurchaseRate =  Min_Stock_Data[i].PurchaseRate
                Min_Stock_Data[i].DisPer = 0 
                Min_Stock_Data[i].DisAmt = 0 
                Min_Stock_Data[i].TaxPer = 0 
                Min_Stock_Data[i].TaxAmt = 0 
                Min_Stock_Data[i].Value = Min_Stock_Data[i].PurchaseRate
                Min_Stock_Data[i].action = 'add'
                mArray.push(Min_Stock_Data[i])
            }
            
        }
        if(mArray.length > 0)
        mSaveStock(mArray)
        else
        onHide()
        debugger

    }


    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title>ReOrder Stock </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={LoadAlll}
                                            id="LoadAlll"
                                            label="LoadAlll"
                                            onChange={e => {
                                                setLoadAlll(e.target.checked)
                                                Min_Stock_Data.map(value=>{
                                                    if(e.target.checked)
                                                    value.chkForSelect = true
                                                    else
                                                    value.chkForSelect = false
                                                })
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label="Select All"
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <div className="table-responsive" >
                                    <MUIDataTable
                                        name="Min_Stock_Datatables"
                                        data={Min_Stock_Data}
                                        columns={column_Min_Stock}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={save_Handler} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address
  })
export default connect(mapStateToProps)(ReOrderStockModal)
