import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import Loader from '../Loader'
import {GetMaxRegistrationCode,SelectDoctorsList,SaveOPDRegistration,SelectDoctorsListWRTDoctorID,SelectPatientDetailsWRTPhoneNo,SelectRegistrationMaster,SelectRegistrationAppointment} from '../../Services/OPDRegistrationAPI'
import { GetCurrentDate } from '../../Services/Common'
const OPDRegistrationEntry = ({Comp_Id,ProjectID,isAddNew,UserID,RegistrationID,AppointmentID}) => {
    const [Doctor_List, setDoctor_List] = useState([])
    const [SelectedDoctor, setSelectedDoctor] = useState(null)
    const [SelectedAgeType, setSelectedAgeType] = useState(null)
    const [IsLoader, setIsLoader] = useState(false)
    const [Registration_Data, setRegistration_Data] = useState({
        RegistrationID:RegistrationID,
        UserID:UserID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        RegistrationNo:'',
        PatientName:'',
        Guardian:'',
        Age:1,
        PhoneNo:'',
        Address:'',
        AppointmentDate:GetCurrentDate(),
        Fees:1,
        CreatedDate:GetCurrentDate(),
        IsCheackupAfterFee:0,
        HAppointmentDate:GetCurrentDate(),
        ConsultedTo: '',
        Status: '',
        CNIC:'',
        btn_Disabled:false,
        CancelAppointment:0,
        btn_Disabled:false,
        FirstCheckupFee: 1,
        SecondCheckupFee: 0,
        AltrasoundFee: 0,
        FirstUltrsoundFee: 0,
        SecondUltrasoundFee: 0
    }) 
    const [Registration_Err, setRegistration_Err] = useState({
        Name_Err: '',
        Phone_Err: '',
        CNIC_Err: '',
        Doctor_Err: ''
    })
    const validate =() =>{
        var  NameErr=""
        // var PhoneErr = ""
        // var CNICErr = ""
        var DoctorErr = ""

        if(!Registration_Data.PatientName)
        {
         NameErr = "Patient Name is required"
        }
        // if(!Registration_Data.PhoneNo)
        // {
        //     PhoneErr = "Phone No is required"
        // }
        
        if(!SelectedDoctor)
        {
            DoctorErr = "required"
        }
     
        // if(!Registration_Data.CNIC)
        // {
        //     CNICErr="required"
        // }
       
        if(NameErr)
        {
           setRegistration_Err({...Registration_Err,Name_Err:NameErr})
           document.getElementById('PatientName').focus()
            return false
        }
        
        // if(PhoneErr)
        // {
        //     setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:PhoneErr})
        //     document.getElementById('PhoneNo').focus()
        //      return false
        // }
    //  if(CNICErr)
    //  {
    //     setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:CNICErr})
    //     document.getElementById('CNIC').focus()
    //      return false
    //  }
     if(DoctorErr)
     {
        setRegistration_Err({...Registration_Err,Name_Err:'',Doctor_Err:DoctorErr})
        document.getElementById('Doctor').focus()
        return false
     }
   
     setRegistration_Err({...Registration_Err,Name_Err:'',Doctor_Err:''})
    
      return true
    
    }
    const _AgeType = [
        {title:'YEARS', value:'YEARS'},
        {title:'MONTHS', value:'MONTHS'},
        {title:'DAYS', value:'DAYS'}
    ]
    useEffect(()=>{
        document.title = "OPD Registration"
        SelectDoctorsList(Comp_Id,(mDoctors)=>{
            
            setDoctor_List(mDoctors)
            if (isAddNew) {
                showLoader()
                GetMaxRegistrationCode(Comp_Id,ProjectID,(mData)=>{
                    hideLoader()
                    setRegistration_Data({...Registration_Data,RegistrationNo:mData})
                })
            }else{
                showLoader()
                SelectRegistrationMaster(Comp_Id,ProjectID,RegistrationID,(mData)=>{
                  
                    SelectRegistrationAppointment(AppointmentID,(mAppData)=>{
                        setRegistration_Data({
                            RegistrationID:RegistrationID,
                            Comp_Id:mData[0].Comp_Id,
                            ProjectID:mData[0].ProjectID,
                            UserID:mData[0].UserID,
                            AppointmentID:AppointmentID,
                            RegistrationNo:mData[0].RegistrationNo,
                            PatientName: mData[0].PatientName,
                            Guardian: mData[0].Guardian,
                            PhoneNo: mData[0].PhoneNo,
                            Address: mData[0].Address,
                            Age: mData[0].Age,
                            CNIC: mData[0].CNIC,
                            CreatedDate: mData[0].CreatedDate,
                            FirstCheckupFee: mData[0].IsFirstCheckup ? 1 : 0,
                            SecondCheckupFee: mData[0].IsSecondFee? 1: 0,
                            AltrasoundFee: mData[0].AltrasoundFee,
                            FirstUltrsoundFee: mData[0].IsFirstUltraSound ? 1: 0,
                            SecondUltrasoundFee: mData[0].IsSecondUltraSound ? 1: 0,
                            AppointmentDate:mAppData[0].AppointmentDate,
                            Status: mAppData[0].Status,
                            IsCheackupAfterFee: mAppData[0].IsCheakupAfterFee ? 1 : 0,
                            ConsultedTo:mAppData[0].Doctor,
                            Fees:mAppData[0].DoctorFee,
                            CancelAppointment:mAppData[0].CancelAppointment
                            
                        })
                        
                        mDoctors.map(value => {
                            if (value.User_id === mAppData[0].DoctorID)
                                setSelectedDoctor(value)
                        })
                        
                        _AgeType.map(value =>{
                            if(value.value === mData[0].AgeType){
                                setSelectedAgeType(value)
                            }
                        })
                    })
                })
                hideLoader()
            }
        })
       
       
    },[])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const Save_Handler =() =>{
        if(validate()){
            var RegistrationData = {
                RegistrationID:RegistrationID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                AppointmentID:AppointmentID,
                RegistrationNo:Registration_Data.RegistrationNo,
                PatientName: Registration_Data.PatientName,
                Guardian: Registration_Data.Guardian,
                PhoneNo: Registration_Data.PhoneNo,
                Address: Registration_Data.Address,
                Age: Registration_Data.Age,
                CNIC: Registration_Data.CNIC,
                CreatedDate: Registration_Data.CreatedDate,
                AppointmentDate:Registration_Data.AppointmentDate,
                DoctorID: SelectedDoctor.User_id,
                Status: 'Pending',
                IsCheakupAfterFee: Registration_Data.IsCheackupAfterFee ? 1 : 0,
                CancelAppointment: Registration_Data.CancelAppointment ? 1 : 0,
                DoctorFee:parseFloat(Registration_Data.Fees),
                AgeType: SelectedAgeType.value,
                IsFirstCheckup: Registration_Data.FirstCheckupFee ? 1: 0,
                IsSecondFee:Registration_Data.SecondCheckupFee ? 1: 0,
                IsFirstUltraSound:Registration_Data.FirstUltrsoundFee ? 1: 0,
                IsSecondUltraSound:Registration_Data.SecondUltrasoundFee ? 1: 0,
                AltrasoundFee: parseFloat(Registration_Data.AltrasoundFee) ?  parseFloat(Registration_Data.AltrasoundFee) : 0 ,

            }
            
            SaveOPDRegistration(RegistrationData,isAddNew,()=>{
                document.getElementById('btnCancel').click()
            })
           
        }
    }
    const Print_Handler = () =>{
        if(validate()){
            debugger
            var mobj = {
                RegNo: Registration_Data.RegistrationNo,
                TokenNo: '',
                PatientName:Registration_Data.PatientName,
                Guardian:Registration_Data.Guardian,
                AppointmentDate:Registration_Data.AppointmentDate,
                Doctor:SelectedDoctor.User_name,
                DoctorFee: Registration_Data.Fees,
                Age: Registration_Data.Age,
                AgeType: SelectedAgeType.value,
                IsFirstCheckup: Registration_Data.FirstCheckupFee ? 1: 0,
                IsSecondFee:Registration_Data.SecondCheckupFee ? 1: 0,
                IsFirstUltraSound:Registration_Data.FirstUltrsoundFee ? 1: 0,
                IsSecondUltraSound:Registration_Data.SecondUltrasoundFee ? 1: 0,
                AltrasoundFee: Registration_Data.AltrasoundFee,
               }
               debugger
               localStorage.setItem('TokenData',JSON.stringify(mobj))
               Save_Handler()
               document.getElementById("rpt").click()
        }
    }
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    return ReactDOM.createPortal (
        <>
        <Link to="/InvoicePrintThermal" target="_blank" id="rpt"></Link>
        <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title text-white" >
                        <h4>OPD Registration</h4>
                    </Col>
                </Row>
            <Card> 
                <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="RegistrationNo"
                            id="RegistrationNo"
                            label="RegistrationNo"
                             value={Registration_Data.RegistrationNo}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,RegistrationNo:e.target.value})
                                if(e.target.value.length > 6){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid Registration No',
                                        text: `Please Use 6 digit Registration No`,
                                      })
                                      setRegistration_Data({...Registration_Data,RegistrationNo:''}) 
                                }
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="PhoneNo"
                        id="PhoneNo"
                        label="PhoneNo"
                        value= {Registration_Data.PhoneNo}
                        onChange={e=>{
                            setRegistration_Data({...Registration_Data,PhoneNo:e.target.value})
                            if(e.target.value.length > 11){
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Invalid length',
                                    text: `Please Use 11 digits Number`,
                                  })
                                  setRegistration_Data({...Registration_Data,PhoneNo:''})
                            }
                            // if(e.target.value.length == 11){
                            //     SelectPatientDetailsWRTPhoneNo(Comp_Id,ProjectID,e.target.value,(mData)=>{
                            //         if(mData.length > 0){
                            //             setRegistration_Data({
                            //                 RegistrationID:mData[0].RegistrationID,
                            //                 Comp_Id:mData[0].Comp_Id,
                            //                 ProjectID:mData[0].ProjectID,
                            //                 UserID:mData[0].UserID,
                            //                 // AppointmentID:AppointmentID,
                            //                 RegistrationNo:mData[0].RegistrationNo,
                            //                 PatientName: mData[0].PatientName,
                            //                 Guardian: mData[0].Guardian,
                            //                 PhoneNo: mData[0].PhoneNo,
                            //                 Address: mData[0].Address,
                            //                 Age: mData[0].Age,
                            //                 CNIC: mData[0].CNIC,
                            //                 CreatedDate: mData[0].CreatedDate,
                            //                 AppointmentDate:mData[0].AppointmentDate,
                            //                 Status: mData[0].Status,
                            //                 IsCheackupAfterFee: mData[0].IsCheakupAfterFee ? 1 : 0,
                            //                 ConsultedTo:mData[0].Doctor,
                            //                 Fees:mData[0].Fees,
                            //                 CancelAppointment:mData[0].CancelAppointment
                                           
                                            
                            //             })
                                        
                            //                 Doctor_List.map(value => {
                            //                     if (value.User_id === mData[0].DoctorID)
                            //                         setSelectedDoctor(value)
                            //                 })
                                       
                            //         }
                                   
                                    
                            //     })
                            // }
                          
                           
                        }}
                        fullWidth
                    />
                    <span className="text-danger">{Registration_Err.Phone_Err}</span>
                </Col>
                    <Col lg={3} xl={3} md={3} >
                        <TextField
                            name="PatientName"
                            id="PatientName"
                            label="PatientName"
                            value={Registration_Data.PatientName}
                             onChange={e=>{
                                setRegistration_Data({...Registration_Data,PatientName:e.target.value})
                                if(e.target.value.length > 50){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid length',
                                        text: `Please Use 50 Characters letter Name`,
                                      })
                                      setRegistration_Data({...Registration_Data,PatientName:''})
                                }
                            }}
                             onInput={toInputUppercase}
                            fullWidth
                        />
                        <span className="text-danger">{Registration_Err.Name_Err}</span>
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                        <TextField
                            name="Guardian"
                            id="Guardian"
                            label="Father/Mother/Guardian"
                            value={Registration_Data.Guardian}
                            onChange={e=>{
                               setRegistration_Data({...Registration_Data,Guardian:e.target.value})
                               if(e.target.value.length > 50){
                                   Swal.fire({
                                       icon: 'info',
                                       title: 'Invalid length',
                                       text: `Please Use 50 Characters letter Name`,
                                     })
                                     setRegistration_Data({...Registration_Data,Guardian:''})
                               }
                           }}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                       {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="Age"
                            id="Age"
                            label="Age"
                            type="number"
                            value={Registration_Data.Age}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,Age:e.target.value})
                                if(parseFloat(e.target.value) == 0){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid Age',
                                        text: `Age Must be Greater then 0`,
                                      })
                                      setRegistration_Data({...Registration_Data,Age:1})
                                }
                            }
                                
                            }
                            fullWidth
                        />
                       {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
                    </Col>
                   
                        
                        </Row>
                       
                        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                        <Col lg={2} xl={2} md={2} >
                            <Autocomplete
                                name="AgeType"
                                id="AgeType"
                                options={_AgeType}
                                getOptionLabel={(option) => option.value ? option.value : ""} 
                                value={SelectedAgeType}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    
                                    setSelectedAgeType(value)
                                 
                                
                                }
                                else{
                                    setSelectedAgeType(null)
                                    
                                }
                               
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="AgeType" label="AgeType"
                                />}
                            />
                        
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="CNIC"
                            id="CNIC"
                            label="CNIC"
                            value={Registration_Data.CNIC}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,CNIC:e.target.value})
                                if(e.target.value.length > 13){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid length',
                                        text: `Please Use 13 character CNIC || CNIC must be without dashes`,
                                      })
                                      setRegistration_Data({...Registration_Data,CNIC:''})
                                }
                            }}
                            onInput={toInputUppercase}
                            fullWidth
                        />

                        <span className="text-danger">{Registration_Err.CNIC_Err}</span>
                    </Col>
                        <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Address"
                            id="Address"
                            label="Address"
                            value={Registration_Data.Address}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,Address:e.target.value})
                                if(e.target.value.length > 100){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid length',
                                        text: `Please Use 100 character address`,
                                      })
                                      setRegistration_Data({...Registration_Data,Address:''})
                                }
                            }}
                            onInput={toInputUppercase}
                            fullWidth
                        />

                       {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="AppointmentDate"
                        id="AppointmentDate"
                        label="AppointmentDate"
                        type="date"
                         value={Registration_Data.AppointmentDate}
                        onChange={e=>setRegistration_Data({...Registration_Data,AppointmentDate:e.target.value})}
                        fullWidth
                    />
                </Col>
                <Col lg={2} xl={2} md={2} >
                                    <Autocomplete
                                        name="Doctor"
                                        id="Doctor"
                                        options={Doctor_List}
                                        getOptionLabel={(option) => option.User_name ? option.User_name : ""} 
                                        value={SelectedDoctor}
                                        onChange={(e,value) =>{
                                        if(value)
                                        {
                                            
                                            setSelectedDoctor(value)
                                            if(Registration_Data.FirstCheckupFee){
                                                setRegistration_Data({...Registration_Data,Fees:value.DoctorFee})
                                            } else if(Registration_Data.SecondCheckupFee){
                                                setRegistration_Data({...Registration_Data,Fees:value.SecondCheckupFee})
                                            }else{
                                                Swal.fire({
                                                    title:'Informations',
                                                    text:'Please Select Your Checkup fee',
                                                    icon:'info'
                                                })
                                            }
                                           
                                        
                                        }
                                        else{
                                            setSelectedDoctor(null)
                                            setRegistration_Data({...Registration_Data,Fees:1})
                                        }
                                       
                                        }}
                                        renderInput={(params) => 
                                        <TextField {...params} name="Doctor" label="Doctor"
                                        />}
                                    />
                                    <span className="text-danger">{Registration_Err.Doctor_Err}</span>
                                </Col>
                
                      
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                        <Col lg={2} xl={2} md={2} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Registration_Data.FirstCheckupFee}
                            id="FirstCheckupFee"
                            onChange={e =>
                           {
                            if(SelectedDoctor){
                                setRegistration_Data({...Registration_Data, FirstCheckupFee: e.target.checked})
                                showLoader()
                                SelectDoctorsListWRTDoctorID(Comp_Id, SelectedDoctor.User_id, (mData)=>{
                                    hideLoader()
                                    setRegistration_Data({...Registration_Data,Fees: mData[0].DoctorFee, FirstCheckupFee: e.target.checked , SecondCheckupFee: 0 })

                                })
                            }else{
                                Swal.fire({
                                    title:'Informations',
                                    text:'Please Select Your Doctor first',
                                    icon:'info'
                                })
                            }
                           }
                              
                            
                            }
                            name="Access"
                            color="primary"
                        />
                    }
                    label={"First Checkup Fee"}
                    style={{
                        padding: "0px",
                        marginTop: "10%"
                    }}
                />

            </Col>
            
                        <Col lg={2} xl={2} md={2} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Registration_Data.SecondCheckupFee}
                                    id="SecondCheckupFee"
                                    onChange={e => {
                                      
                                        if(SelectedDoctor){
                                            setRegistration_Data({...Registration_Data, SecondCheckupFee: e.target.checked})
                                            showLoader()
                                            SelectDoctorsListWRTDoctorID(Comp_Id, SelectedDoctor.User_id, (mData)=>{
                                                hideLoader()
                                                setRegistration_Data({...Registration_Data, SecondCheckupFee: e.target.checked,Fees: mData[0].SecondCheckupFee, FirstCheckupFee: 0 })
            
                                            })
                                        }else{
                                            Swal.fire({
                                                title:'Informations',
                                                text:'Please Select Your Doctor first',
                                                icon:'info'
                                            })
                                        }
                                    }
                                    }
                                      
                                        
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Second Checkup Fee"}
                            style={{
                                padding: "0px",
                                marginTop: "10%"
                            }}
                        />
        
                    </Col>
                       
                        <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="Fees"
                            id="Fees"
                            type="number"
                            label="Fees"     
                        value={Registration_Data.Fees}
                        onChange={e=>{
                           setRegistration_Data({...Registration_Data,Fees:e.target.value})
                            // if(parseFloat(e.target.value) == 0){
                            //     Swal.fire({
                            //         icon: 'info',
                            //         title: 'Invalid Fees',
                            //         text: `Fees Must be Greater then 0`,
                            //       })
                            //       setRegistration_Data({...Registration_Data,Fees:1})
                            // }
                        }
                            
                        }
                            fullWidth
                        />
                         {/*<span className="text-danger">{Registration_Err.PurPrice_Err}</span>*/}
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Registration_Data.FirstUltrsoundFee}
                                id="FirstUltrsoundFee"
                                onChange={e => {
                                  
                                    if(SelectedDoctor){
                                        setRegistration_Data({...Registration_Data, FirstUltrsoundFee: e.target.checked})
                                        showLoader()
                                        SelectDoctorsListWRTDoctorID(Comp_Id, SelectedDoctor.User_id, (mData)=>{
                                            hideLoader()
                                            if(e.target.checked){
                                                setRegistration_Data({...Registration_Data, FirstUltrsoundFee: e.target.checked,AltrasoundFee: mData[0].AltrasoundFee, SecondUltrasoundFee: 0})

                                            }else{
                                                setRegistration_Data({...Registration_Data, FirstUltrsoundFee: e.target.checked,AltrasoundFee: 0})

                                            }
        
                                        })
                                    }else{
                                        Swal.fire({
                                            title:'Informations',
                                            text:'Please Select Your Doctor first',
                                            icon:'info'
                                        })
                                    }
                                }
                                }
                                  
                                    
                                name="Access"
                                color="primary"
                            />
                        }
                        label={"First Ultrasound Fee"}
                        style={{
                            padding: "0px",
                            marginTop: "10%"
                        }}
                    />
    
                </Col> 
                <Col lg={2} xl={2} md={2} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Registration_Data.SecondUltrasoundFee}
                            id="SecondUltrasoundFee"
                            onChange={e => {
                              
                                if(SelectedDoctor){
                                    setRegistration_Data({...Registration_Data, SecondUltrasoundFee: e.target.checked})
                                    showLoader()
                                    SelectDoctorsListWRTDoctorID(Comp_Id, SelectedDoctor.User_id, (mData)=>{
                                        hideLoader()
                                        if(e.target.checked){
                                            setRegistration_Data({...Registration_Data, SecondUltrasoundFee: e.target.checked,AltrasoundFee: mData[0].SecondUltrasoundFee, FirstUltrsoundFee: 0})

                                        }else{
                                            setRegistration_Data({...Registration_Data, SecondUltrasoundFee: e.target.checked,AltrasoundFee:0})
                                        }
    
                                    })
                                }else{
                                    Swal.fire({
                                        title:'Informations',
                                        text:'Please Select Your Doctor first',
                                        icon:'info'
                                    })
                                }
                            }
                            }
                              
                                
                            name="Access"
                            color="primary"
                        />
                    }
                    label={"Second Ultrasound Fee"}
                    style={{
                        padding: "0px",
                        marginTop: "10%"
                    }}
                />

            </Col> 
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="AltrasoundFee"
                        id="AltrasoundFee"
                        type="number"
                        label="AltrasoundFee"     
                    value={Registration_Data.AltrasoundFee}
                    onChange={e=>{
                        setRegistration_Data({...Registration_Data,AltrasoundFee:e.target.value})
                      
                    }
                        
                    }
                        fullWidth
                    />
                     {/*<span className="text-danger">{Registration_Err.PurPrice_Err}</span>*/}
                </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="CreatedDate"
                                id="CreatedDate"
                                label="CreatedDate"
                                type="date"
                                 value={Registration_Data.CreatedDate}
                                onChange={e=>setRegistration_Data({...Registration_Data,CreatedDate:e.target.value})}
                                fullWidth
                            />
                        </Col>
                       
                        <Col lg={2} xl={2} md={2} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Registration_Data.IsCheackupAfterFee}
                                    id="IsCheackupAfterFee"
                                    label="Fee After Checkup"
                                    onChange={e => setRegistration_Data({ ...Registration_Data, IsCheackupAfterFee: e.target.checked })}
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Fee After Checkup"}
                            style={{
                                padding: "0px",
                                marginTop: "10%"
                            }}
                        />

                    </Col> 
                    <Col lg={2} xl={2} md={2} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Registration_Data.CancelAppointment}
                                    id="CancelAppointment"
                                    label="Cancel Appointment"
                                    onChange={e => setRegistration_Data({ ...Registration_Data, CancelAppointment: e.target.checked })}
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Cancel Appointment"}
                            style={{
                                padding: "0px",
                                marginTop: "10%"
                            }}
                        />

                    </Col>      
                    </Row>
                    <Row className="row_margin_left row_margin_right" >   
                    <Col lg={5} xl={5} md={5} ></Col>
                    <Col lg={2} xl={2} md={2} >--------Patient History-------- </Col>
                    <Col lg={5} xl={5} md={5} ></Col>
                 </Row>
                 <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                 <Col lg={3} xl={3} md={3} ></Col>
                 <Col lg={2} xl={2} md={2} >
                 <TextField
                     name="ConsultedTo"
                     id="ConsultedTo"
                     label="ConsultedTo"
                     value={Registration_Data.ConsultedTo}
                     onChange={e=>setRegistration_Data({...Registration_Data,ConsultedTo:e.target.value})}
                     onInput={toInputUppercase}
                     fullWidth
                 />

                {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
             </Col>
             <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="HAppointmentDate"
                        id="HAppointmentDate"
                        label="AppointmentDate"
                        type="date"
                         value={Registration_Data.AppointmentDate}
                        onChange={e=>setRegistration_Data({...Registration_Data,AppointmentDate:e.target.value})}
                        fullWidth
                    />
                </Col>
                <Col lg={2} xl={2} md={2} >
                <TextField
                    name="Status"
                    id="Status"
                    label="Status"
                    value={Registration_Data.Status
                    }
                    onChange={e=>setRegistration_Data({...Registration_Data,Status:e.target.value})}
                    onInput={toInputUppercase}
                    fullWidth
                />

               {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
            </Col>
            <Col lg={3} xl={3} md={3} ></Col>
                 </Row>
                        
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                        {
                            // btn_edit &&
                             (<button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={Save_Handler} > <Save />  Save</button>)}
                          
                              
                        <button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={Print_Handler} > <Save />  Save & Print</button>
                        {/* <Link id="btnPrint" className="btn btn-outline-primary" style={{ border: "none" }} target ="_blank" to='/IPD_Registration_Report' onClick={Print_Handler}> <Print /> <u>Print</u></Link> */}
                        <Link id="btnCancel" className="btn btn-primary" style={{ border: "none" }} to='/OPDRegistration' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                <Loader
                show={IsLoader}
                hide={hideLoader}
              />
            </Card>
        </> , document.getElementById('mainContent')
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.OPD_Reg && state.OPD_Reg.isAddNew,
    RegistrationID: state.OPD_Reg && state.OPD_Reg.pt_id,
    AppointmentID:state.OPD_Reg && state.OPD_Reg.AppointmentID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(OPDRegistrationEntry)
