import React, { useState, useEffect } from 'react';
import './ReceiptPrint.css'
import { GetCurrentDate, GetCurrentTime, Domain_for_Image } from '../../Services/Common'
import { searchBegin } from '@syncfusion/ej2-react-grids';
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux"
import { SelectcustomersList } from '../../Services/CustomersAPI'
import { Selectbank } from '../../Services/bankAPI'

function ReceiptPrint({ Comp_Name,ProjectID,Comp_Id }) {

    const [ReceiptDetail, setReceiptDetail] = useState([]);
    const [ReceiptNo, setReceiptNo] = useState();
    const [ReceiptDate, setReceiptDate] = useState();
    const [PaymentType, setPaymentType] = useState();
    const [Remarks, setRemarks] = useState();
    const [Customer, setCustomer] = useState();
    const [Bank, setBank] = useState();
    const [TotalPaidAmt, setTotalPaidAmt] = useState(0.00);
    const [TotalSaleAmt, setTotalSaleAmt] = useState(0.00);

    useEffect(() => {
      
        document.title = "Pharmacy / Receipt Report" 

        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;

        var ReceiptData = JSON.parse(localStorage.getItem('Receipt'))
                debugger
                SelectcustomersList(Comp_Id,ProjectID,(mCustomers)=>{
                    mCustomers.map(value=>{
                        if(value.CustomerID === ReceiptData.Main[0].CustomerID){
                            setCustomer(value.CustomerName)
                        }
                    })
                })
                Selectbank(Comp_Id,ProjectID,(mBanks)=>{
                    mBanks.map(value=>{
                        if (value.BankID === ReceiptData.Main[0].BankID){
                            setBank(value.BankName)
                        }
                    })
                })

        setTimeout(() => {
            setReceiptDetail(ReceiptData.Detail)
            setReceiptNo(ReceiptData.Main[0].ReceiptNo)
            setReceiptDate((ReceiptData.Main[0].ReceiptDate).split('-').reverse().join('-'))
            setPaymentType(ReceiptData.Main[0].PaymentType)
            setRemarks(ReceiptData.Main[0].Remarks)

            var _totalPaidAmt = 0, _totalSaleAmt = 0,PrevInvID;

            ReceiptData.Detail.map((value,index)=>{
                value.SrNo = index + 1 
                _totalPaidAmt += value.PaidAmt

                if(PrevInvID !== value.SaleInvoiceID) 
                _totalSaleAmt+= value.GrandTotal 
                else
                _totalSaleAmt = value.GrandTotal 

                PrevInvID = value.SaleInvoiceID
            })
            setTotalSaleAmt(_totalSaleAmt)
            setTotalPaidAmt(_totalPaidAmt)
        }, 200);

        // window.localStorage.removeItem("Receipt");

        // setTimeout(() => {
        //     window.print()
        // }, 1000);
    }, [])

    return (

        <div width='100%' height='100%' id='main_body_invoicepra4'>
            <Row sm={12} md={12} xl={12} style={{ marginTop: '1%' }}>
               
                <Col sm={12} md={12} xl={12}>
                    <h1 style={{ textAlign: 'center' }}> {Comp_Name} </h1> <br /> <h3 style={{ textAlign: 'center' }} >Receipt REPORT</h3>
                </Col>
                
            </Row>
            <Row style={{ border: '1px solid black', borderBottom: 'none', borderLeft: "2px solid black", borderTop: "1.5px solid black", borderRight: "2px solid black", marginLeft: "0px", marginRight: "0px", marginTop: '2%' }}>
                    <Col style={{ textAlign: 'left', padding: "8px" }} sm={4} md={4} xl={4}>
                    <Row sm={12} md={12} xl={12} style={{ marginLeft: '1px' }} >
                            <Col sm={5} md={5} xl={5}>
                            Receipt No :
                            </Col>
                            <Col style={{ fontSize: '18px', textAlign: 'left' }} sm={7} md={7} xl={7}>
                                <b>{ReceiptNo}</b>
                            </Col>
                        </Row>
                    </Col>

                    <Col style={{ textAlign: 'center', borderLeft: "1px solid black", padding: "8px" }} sm={4} md={4} xl={4}>
                    <Row sm={6} md={6} xl={6} style={{ marginLeft: '1px' }}>
                            <Col sm={5} md={5} xl={5}>
                            Receipt Date :
                            </Col>
                            <Col style={{ fontSize: '18px' }} sm={7} md={7} xl={7}>
                                <b>{ReceiptDate}</b>
                            </Col>
                        </Row>
                    </Col>

                    <Col style={{ textAlign: 'center', borderLeft: "1px solid black", padding: "8px" }} sm={4} md={4} xl={4}>
                    <Row sm={6} md={6} xl={6} style={{ marginLeft: '1px', marginRight: '1px' }}>
                            <Col sm={6} md={6} xl={6}>
                                Receipt Type:
                            </Col>
                            <Col style={{ fontSize: '18px' }} sm={6} md={6} xl={6}>
                                <b>{PaymentType}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Row style={{ width: '100%', marginLeft: '0px', marginTop: "0px" }} sm={12} md={12} xl={12}>
                    <Col style={{ textAlign: 'left', border: '1px solid black', borderRight: 'none', padding: '8px' }} sm={8} md={8} xl={8}>
                    <Row style={{ textAlign: 'left', fontSize: '16px', marginLeft: '1px' }}>
                    <Col style={{ fontSize: '16px', textAlign: 'Left' }} sm={5} md={5} xl={5}>Sale Amount :</Col>
                            <Col style={{ fontSize: '18px', paddingTop: '0px', textAlign: 'left', fontWeight: 'bold' }} sm={7} md={7} xl={7}>{TotalSaleAmt}</Col>
                        </Row>
                    <Row style={{ textAlign: 'left',  marginLeft: '1px' }}>
                    <Col style={{ fontSize: '16px', textAlign: 'Left' }} sm={5} md={5} xl={5}>Total Paid Amount :</Col>
                            <Col style={{ fontSize: '18px', paddingTop: '0px', textAlign: 'left', fontWeight: 'bold' }} sm={7} md={7} xl={7}>{TotalPaidAmt}</Col>
                        </Row>
                    <Row style={{ textAlign: 'left', marginLeft: '1px' }}>
                            <Col style={{ textAlign: 'left', fontSize: '16px' }} sm={12} md={12} xl={12}>Remarks :</Col>
                        </Row>
                    <Row style={{ textAlign: 'left', fontWeight: 'bold', marginLeft: '1px' }}>
                            <Col style={{ textAlign: 'left', fontSize: '18px', paddingLeft: "40px" }} sm={12} md={12} xl={12}>{Remarks}</Col>
                        </Row>
                   
                    </Col>

                    <Col style={{ border: '1px solid black', padding: '8px' }} sm={4} md={4} xl={4}>
                    <Row style={{ marginLeft: '1px', marginRight: '1px' }}>
                            <Col style={{ fontSize: '16px', textAlign: 'Left' }} sm={5} md={5} xl={5}>Customer :</Col>
                            <Col style={{ fontSize: '18px', paddingTop: '0px', textAlign: 'right', fontWeight: 'bold' }} sm={7} md={7} xl={7}>{Customer}</Col>
                        </Row>
                    <Row style={{ marginLeft: '1px', marginRight: '1px' }}>
                            <Col style={{ fontSize: '16px', paddingTop: '8px', textAlign: 'Left' }} sm={5} md={5} xl={5}>Bank:</Col>
                            <Col style={{ fontSize: '18px', paddingTop: '8px', textAlign: 'right', fontWeight: 'bold' }} sm={7} md={7} xl={7}>{Bank}</Col>
                        </Row>
                    </Col>
                </Row>
                <h2 style={{ textAlign: 'center', marginTop: "18px" }}>Receipt Detail</h2>
                <table width="100%" style={{ fontSize: '14px', border: '1px solid black', marginBottom: "40px" }} id='table_prodprnt' border="0" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center', fontWeight: 'bold' }}> Sr # </th>
                            <th style={{ textAlign: 'left', fontWeight: 'bold', borderLeft: '1px solid black' }}> Inv # </th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold', borderLeft: '1px solid black' }}> Date </th>
                            <th style={{ textAlign: 'right', fontWeight: 'bold', borderLeft: '1px solid black' }}> Grand Total </th>
                            <th style={{ textAlign: 'right', fontWeight: 'bold', borderLeft: '1px solid black' }}> Already Paid Amt </th>
                            <th style={{ textAlign: 'right', fontWeight: 'bold', borderLeft: '1px solid black' }}> PaidAmt </th>
                            <th style={{ textAlign: 'right', fontWeight: 'bold', borderLeft: '1px solid black' }}> Balance </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ReceiptDetail.map((Receiptdetail, index) => (
                                <>
                                    <tr key={index} id='data_values_prodprnt' style={{ background: '' }}>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{index + 1} </td>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}> {Receiptdetail.SaleInvoiceNo} </td>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}> {Receiptdetail.InvoiceDate} </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}> {(Receiptdetail.GrandTotal).toFixed(2)} </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}> {(Receiptdetail.AlreadyPaidAmt).toFixed(2)} </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}> {(Receiptdetail.PaidAmt).toFixed(2)} </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}> {(Receiptdetail.Balance).toFixed(2)} </td>
                                    </tr>

                                </>
                            ))
                        }
                    </tbody>

                </table>

           
            {/* <div className='page-break-Receipt' >
                <div style={{ marginTop: '-30%' }}> Username: {username}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Date: {`${GetCurrentDate()} ${GetCurrentTime()}`}</div>
            </div> */}
        </div>

    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ProjectID:state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedProject.Project_ID,

})

export default connect(mapStateToProps)(ReceiptPrint)