import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import  { connect } from 'react-redux'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SelectPurchaseOrderList } from '../../Services/PurchaseOrderAPI'
import { SelectSupplierList } from '../../Services/SuppliersAPI'
import  { set_PO, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader'
function PurchaseOrderList(props) {

    const {  set_PO,ItemCat,Purchase_Order , Comp_Id,p_userid, Group_Id, SHOW_SCREEN } = props;
    const [Supppliers_List, setSupppliers_List] = useState([])
    const [Selected_Sup, setSelected_Sup] = useState(null)
    const [PurchaseOrderList_Data, setPurchaseOrderList_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [IsLoader, setIsLoader] = useState(false)
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Supplier: '',
      Project: ''
    })

    useEffect(()=>{
    document.title = " Pharmacy / Purchase Order"
    SelectGroupScreenAccess(Group_Id,11,99,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })

SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

  setProjectList(ProjectData)
  
  if(Purchase_Order.Purchase_Order)
  {
    Select_SupplierList(Purchase_Order.Project_ID, false)
    Select_PurchaseOrderList(Purchase_Order.Purchase_Order.SupplierID, Purchase_Order.Project_ID)
    setSelected_Sup(Purchase_Order.Purchase_Order)
    setSelectedProject(ProjectData.find(x => x.Project_ID === Purchase_Order.Project_ID))
    set_PO(null,0,false, null)
  }
  else
  {
    setSelectedProject(ProjectData[0])
    Select_SupplierList(ProjectData[0].Project_ID, true)
  }
})


    },[])
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const column_PurchaseOrder = [
        {
         name: "PurchaseOrderID",
         label: "PurchaseOrderID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "PurchaseOrderNo",
            label: "Purchase Order Code",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
         name: "SupplierName",
         label: "Supplier Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        
        {
         name: "OrderDate",
         label: "OrderDate",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var PurchaseOrderID = PurchaseOrderList_Data
             if (PurchaseOrderID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/PurchaseOrderEntry"} onClick={e => edit_PO(PurchaseOrderList_Data[rowIndex.rowIndex].PurchaseOrderID)}> <Edit /></Link>
               );
           }
          }
         }
    ];

    const validation = () =>{
      
      if (!Selected_Sup)
      {
        setError_message({Supplier: 'must be required', Project: ''})
        return false
      }
      else if(!SelectedProject)
      {
        setError_message({Supplier: '', Project: 'must be required'})
        return false
      }

      setError_message({Supplier: '', Project: ''})
      return true
    }
 
    const edit_PO = (PurchaseOrderID) =>{
       set_PO(Selected_Sup,PurchaseOrderID,false, SelectedProject.Project_ID)
     }
     const add_PO = () =>{
       if(validation())
       {
        set_PO(Selected_Sup,0,true, SelectedProject.Project_ID)
        document.getElementById("btn_navigate").click()
       }
     }
   const Select_PurchaseOrderList = (SupplierID, ProjectID) =>{
    showLoader()
    SelectPurchaseOrderList(Comp_Id,ProjectID,SupplierID,(mData)=>{
      hideLoader()
      setPurchaseOrderList_Data(mData)
    })
   }
   const Select_SupplierList = (ProjectID, IsSelected)=>{
    SelectSupplierList(Comp_Id,ProjectID,(mData)=>{

      if(IsSelected)
      {
        setSelected_Sup(mData[0])
        Select_PurchaseOrderList(mData[0].SupplierID, ProjectID)

      }
      setSupppliers_List(mData)
    })
}
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    }
  }
});
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4 > Purchase Order List</h4>
            </Col>
          </Row>
      <Card>
      <Row className="row_margin_top row_margin_left row_margin_right m-4" >

<Col lg={3} xl={3} md={3} >
    <Autocomplete
        name="SupplierName"
        id="SupplierName"
        options={Supppliers_List}
        getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
        value={Selected_Sup}
        onChange={(e, value) => {
            if (value)
            {
                setSelected_Sup(value)
                if(SelectedProject)
                Select_PurchaseOrderList(value.SupplierID, SelectedProject.Project_ID)
            }
            else
            {
                setSelected_Sup(null)
                setPurchaseOrderList_Data([])
            }
        }}
        renderInput={(params) =>
            <TextField {...params} name="SupplierName" label="Supplier"
            />}
    />
    <span style={{ color: 'red' }} > { error_message.Supplier } </span>
</Col>

<Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)

                                    if(Selected_Sup)
                                    Select_PurchaseOrderList(Selected_Sup.SupplierID,value.Project_ID)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                    setPurchaseOrderList_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Project"
                                />}
                        />
                        <span style={{ color: 'red' }} > { error_message.Project } </span>
                    </Col>

<Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
  <Link to={"/PurchaseOrderEntry"} id='btn_navigate' ></Link>
{ btn_add &&(<Link type="button" className="btn btn-primary" to="#" onClick={add_PO} style={{ border: "none" }} > <Add />  Create New</Link>)}
</Col>
</Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
            {/* <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary" to="/OpeningEntry" onClick={add_Opening} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col> */}
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
              <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={PurchaseOrderList_Data}
                        columns={column_PurchaseOrder}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Purchase_Order: state.PO && state.PO,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
  // export default PurchaseOrderList
 export default connect(mapStateToProps,{set_PO, SHOW_SCREEN})(PurchaseOrderList)
