import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Card, Row, Col, Button } from "react-bootstrap"
import { Add } from "@material-ui/icons"
import { connect } from "react-redux"
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import "@syncfusion/ej2-react-grids/styles/material.css";
import { TextField } from "@material-ui/core"
import Swal from "sweetalert2"
import { SelectServices,SaveServices } from "../../Services/ServicesAPI"
import Loader from '../Loader'


function Services({Comp_Id,ProjectID}) {
    const [IsLoader, setIsLoader] = useState(false)
    const [Services, setServices] = useState([])
    const [DeletedServices, setDeletedServices] = useState([])
   
    const [btn_add, setBtn_add] = useState(true)
    const [btn_edit, setBtn_edit] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    var grid = null;

    // var Comp_Id = this.props.Comp_Id
    // var ProjectID = this.props.ProjectID
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    
    useEffect(() => {

        document.title = "IPD / ROOM CATEGORY"
        Select_Services_List()
       

    }, [])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const Select_Services_List = () => {
        showLoader()
        SelectServices(Comp_Id,ProjectID, (data) => {
            
            for (let i = 0; i < data.length; i++) {
                data[i].SrNo = i + 1;
            }
            if(data.length > 0)
            setServices(data)
        })
        hideLoader()
    }

    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.action = rowValue.action
   
                    }
                }
                else {
                    rowValue.data.SrNo = Services.length + 1
                }
            }
        }
    }

    const afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "delete") {
            if (rowValue.data[0].ServicesID) {
                var Deleted_Array = [...DeletedServices];
                Deleted_Array.push(rowValue.data[0]);
                setDeletedServices(Deleted_Array)
            }
        }

        if (rowValue.requestType == "add") {
            document.getElementById('Services').focus()
        }
        else if (rowValue.requestType == "beginEdit") {

            document.getElementById('Services').focus()
            
        }
        else if (rowValue.requestType == "save") {
            for (let i = 0; i < Services.length; i++) {
                if (rowValue.data.SrNo === Services[i].SrNo) {
                    Services[i].ServicesID = rowValue.data.ServicesID;
                    Services[i].Services = rowValue.data.Services;
                    Services[i].Fees = rowValue.data.Fees;
                    Services[i].Comp_Id = rowValue.data.Comp_Id;
                    Services[i].ProjectID = rowValue.data.ProjectID;
                   
                }
            }
        }
    }

    const Service = (rowvalue) => {
        return (
            <TextField
                className="textbox"
                name="Services"
                id="Services"
                defaultValue={rowvalue.Services}
                onInput={toInputUppercase}
                fullWidth
            />
        )
    }
    const Fees = (rowvalue) => {
        return (
            <TextField
                className="textbox"
                name="Fees"
                id="Fees"
                type="number"
                defaultValue={rowvalue.Fees}
                onInput={toInputUppercase}
                fullWidth
            />
        )
    }

    // const ExpenseClassification = (rowValue) => {

    //     return (
    //         <Autocomplete
    //             id="ExpenseClassifications"
    //             name="ExpenseClassifications"
    //             options={ExpanceData}
    //             defaultValue={ExpanceData.find(x => x.ServicesID === rowValue.ServicesID)}
    //             getOptionLabel={(option) => option.ExpenseClassification ? option.ExpenseClassification : "" } 
    //             onChange={(e, value) => {

    //                 if (value) {
    //                     setSelectedExpance(value)
    //                 }
    //                 else {
    //                     setSelectedExpance(null)
    //                 }
    //             }}
    //             renderInput={(params) => <TextField {...params} />}
    //             fullWidth
    //         />
    //     )
    // }


    const sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };


    const save = () => {

        for (let i = 0; i < Services.length; i++) {
            if (!Services[i].Services) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Row No. ${Services[i].SrNo} Enter Name`,
                })
                return;
            }
            else if (Services[i].Services === undefined || Services[i].Services === '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Row No. ${Services[i].SrNo} Enter Services`,
                })
                return;
            }
        }
        
        SaveServices(Services, DeletedServices, Comp_Id,ProjectID, () => {
            Select_Services_List()
        })
    }


    return ReactDOM.createPortal(
        <>
         <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>Services
                        </h2>
                    </div>
                </div>
            </div>
            <Card>
               
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{width:'80%', marginLeft:'10%'}} >
                    <Col>
                        <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%" }}>
                                <GridComponent dataSource={Services} width="100%" height="100%" ref={x => grid = x} actionBegin={beforeRowInsert} allowSorting={true} actionComplete={afterRowInsert} allowFiltering={true} editSettings={{ allowEditing: btn_edit, allowDeleting: btn_delete, allowAdding: btn_add, newRowPosition: 'Bottom' }} filterSettings={{ type: 'Menu' }} sortSettings={sortingOptions} toolbar={toolbarOptions}>
                                    <ColumnsDirective>
                                        <ColumnDirective width='60'  field='SrNo' Format="{0:n}" allowEditing={false} headerText='Sr No.' textAlign='Left' />
                                        <ColumnDirective width='100' field='Services' headerText='Services' editTemplate={Service} textAlign='Left' />
                                        <ColumnDirective width='100' field='Fees' headerText='Fees' editTemplate={Fees} textAlign="Left" />
                                        <ColumnDirective width='100' field='ServicesID' hideAtMedia={true} />
                                        <ColumnDirective field='Comp_Id' headerText='Comp_Id' defaultValue={Comp_Id}  hideAtMedia={true} ></ColumnDirective>
                                        <ColumnDirective field='ProjectID' headerText='ProjectID'  defaultValue={ProjectID} hideAtMedia={true}></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            <button type="button" className="btn btn-primary"  style={{border: "none"}} onClick={e=>{save()}} > <Save /> <u>Save</u></button>
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'#'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row> */}
                     <Row className="row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                <Col lg={7} xl={7}>
                </Col>
                
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                        <Button variant="btn btn-primary"  style={{border: "none"}} onClick={e => {
                            save()
                        }} > <Add />  Save</Button>
                    </Col>
                    <Col lg={2} xl={2}>
                    </Col>
                </Row>
                <Loader
          show={IsLoader}
          hide={hideLoader}
        />
            </Card>
        </>, document.getElementById('mainContent')
    )
}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data.Group_Id,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps)(Services)
