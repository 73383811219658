import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const rptSupplierLedger =(Comp_Id,ProjectID,FromDate,ToDate,FinYear,SupplierID,Callback) =>{
  axios.get(`${domain}/PurcahseReports/rptSupplierLedger/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${FinYear}/${SupplierID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const rptSupplierLedgerSupplierWise =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/PurcahseReports/rptSupplierLedgerSupplierWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptpurchaseSummaryItemWise =(Comp_Id,ProjectID,FromDate,ToDate,FromItem,ToItem,Callback) =>{
    axios.get(`${domain}/PurcahseReports/rptpurchaseSummaryItemWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${FromItem}/${ToItem}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  

  export const rptPayables =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/PurcahseReports/rptPayables/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }