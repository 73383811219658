import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const TodaysSale =(Comp_Id,mDate,Callback) =>{
    
  axios.get(`${domain}/Dashboard/TodaysSale/${Comp_Id}/${mDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const WeekSale = (Comp_Id,mDate, Callback) => {

    axios.get(`${domain}/Dashboard/WeekSale/${Comp_Id}/${mDate}`)
        .then(response => {
            Callback(response.data.Data)
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
            })
        })
}
export const MonthSale = (Comp_Id,mDate, Callback) => {

    axios.get(`${domain}/Dashboard/MonthSale/${Comp_Id}/${mDate}`)
        .then(response => {
            Callback(response.data.Data)
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
            })
        })
}
export const YearSale = (Comp_Id, mDate,Callback) => {

    axios.get(`${domain}/Dashboard/YearSale/${Comp_Id}/${mDate}`)
        .then(response => {
            Callback(response.data.Data)
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
            })
        })
}

export const TodaysExpense = (Comp_Id,Project_ID,mDate, Callback) => {

    axios.get(`${domain}/Dashboard/TodaysExpense/${Comp_Id}/${Project_ID}/${mDate}`)
        .then(response => {
            Callback(response.data.Data)
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
            })
        })
}

export const ExpiredItems = (Comp_Id,Project_ID, Callback) => {

    axios.get(`${domain}/Dashboard/ExpiredItems/${Comp_Id}/${Project_ID}`)
        .then(response => {
            Callback(response.data.Data)
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
            })
        })
}

export const BeforeExpiryItem = (Comp_Id,Project_ID, Callback) => {

    axios.get(`${domain}/Dashboard/BeforeExpiryItem/${Comp_Id}/${Project_ID}`)
        .then(response => {
            Callback(response.data.Data)
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
            })
        })
}