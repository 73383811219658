import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const rptCustomerLedger =(Comp_Id,ProjectID,FromDate,ToDate,FinYear,CustomerID,Callback) =>{
  axios.get(`${domain}/SaleReports/rptCustomerLedger/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${FinYear}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const rptSaleSummaryCustomerWise =(Comp_Id,ProjectID,FromDate,ToDate,CustomerID,Callback) =>{
  axios.get(`${domain}/SaleReports/rptSaleSummaryCustomerWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const rptSaleSummarySalemanWise =(Comp_Id,ProjectID,FromDate,ToDate,UserID,Callback) =>{
  axios.get(`${domain}/SaleReports/rptSaleSummarySalemanWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${UserID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const rptSaleSummary =(Comp_Id,ProjectID,FromDate,ToDate,UserID,Callback) =>{
  axios.get(`${domain}/SaleReports/rptSaleSummary/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${UserID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const rptSaleLedgerItemWise =(Comp_Id,ProjectID,FromDate,ToDate,FromItem,ToItem,Callback) =>{
  axios.get(`${domain}/SaleReports/rptSaleLedgerItemWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${FromItem}/${ToItem}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const rptSaleReturnLedgerCustomerWise =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/SaleReports/rptSaleReturnLedgerCustomerWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptSaleMarginCustomerWise =(Comp_Id,ProjectID,FromDate,ToDate,FromCustomer,ToCustomer,Callback) =>{
  axios.get(`${domain}/SaleReports/rptSaleMarginCustomerWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${FromCustomer}/${ToCustomer}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}



export const rptMostSoldItems =(Comp_Id,ProjectID,FromDate,ToDate,Num,Callback) =>{
  axios.get(`${domain}/SaleReports/rptMostSoldItems/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${Num}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptMinimumSoldItems =(Comp_Id,ProjectID,FromDate,ToDate,Num,Callback) =>{
  axios.get(`${domain}/SaleReports/rptMinimumSoldItems/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${Num}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptDailySaleOfLastMOnth =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/SaleReports/rptDailySaleOfLastMOnth/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const rptReciveables =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/SaleReports/rptReciveables/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const SelectProjectsUser =(ProjectID,Callback) =>{
  axios.get(`${domain}/SaleReports/SelectProjectsUser/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}