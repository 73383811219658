import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import Loader from '../Loader'
import { SaveCustomer , SelectCustomer,GetMaxCustomerCode } from './../../Services/CustomersAPI'
import {GetCurrentDate} from '../../Services/Common'
function CustomerEntry({ isAddNew, CustomerID, show_screen , p_userid,Comp_Id,ProjectID}) {

    const [IsLoader, setIsLoader] = useState(false)
    var UserID = p_userid
    const [Customer_Data,setCustomer_Data] = useState({
        CustomerID:CustomerID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        UserID:UserID,
        CustomerName:'',
        Email:'',
        PhoneNo:'',
        SupplierCode:'',
        CreatedDate:GetCurrentDate(),  
        NTN:'',
        SaleTaxNo:'',
        Address:'',
        btn_Disabled:false,
    })
    const [Item_Err, setItem_Err] = useState({
        Name_Err:'',
        PhoneNo_Err:'',
        NTN_Err:'',
        Cat_Err: '',
        UOM_Err:'',
        SUBUOM_Err:''
    })
  
    const [btn_Edit, setBtn_Edit] = useState(true)

    useEffect(() => {
    document.title = " PHARMACY / Customers"
    if(isAddNew)
    {
        
        GetMaxCustomerCode(Comp_Id,ProjectID,(mCode)=>{
            setCustomer_Data({...Customer_Data,CustomerCode:mCode})
        })
    }
        // if(!isAddNew)
     else   {
            setBtn_Edit(show_screen.edit)
            showLoader()
            SelectCustomer(Comp_Id,ProjectID,CustomerID,(mData)=>{
             
                setCustomer_Data({
                    CustomerID:CustomerID,
                    Comp_Id:Comp_Id,
                    ProjectID:ProjectID,
                    CustomerCode:mData[0].CustomerCode,
                    CustomerName:mData[0].CustomerName,
                    Email:mData[0].Email,
                    PhoneNo:mData[0].PhoneNo,
                    CreatedDate:mData[0].CreatedDate,
                    UserID:mData[0].UserID,
                    // Remarks:mData[0].Remarks,
                    // Company:mData[0].Company,
                    NTN:mData[0].NTN,
                    SaleTaxNo:mData[0].SaleTaxNo,
                    // City:mData[0].City,
                    Address:mData[0].Address

                })
            })
            hideLoader()
        }
    }, [])

    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
   const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
  const validate =() =>{
        var  NameErr=""
        var PhoneNoErr = ""
        
        if(!(Customer_Data.CustomerName).trim())
        {
         NameErr = "Name is required"
        }
        if(!(Customer_Data.PhoneNo).trim())
        {
            PhoneNoErr="required"
        }
      
      
        if(NameErr)
        {
           setItem_Err({...Item_Err,Name_Err:NameErr})
           document.getElementById('CustomerName').focus()
            return false
        }
     if(PhoneNoErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:PhoneNoErr})
        document.getElementById('PhoneNo').focus()
        return false
     }
    
     
     setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:''})

      return true
    
    }
    const Save_Handler = () =>{
        if(validate())
        {
            var CustomerData = {
                CustomerID: Customer_Data.CustomerID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                CustomerCode:Customer_Data.CustomerCode,
                CustomerName:Customer_Data.CustomerName,
                Email:Customer_Data.Email,
                PhoneNo:Customer_Data.PhoneNo,
                NTN:Customer_Data.NTN,
                SaleTaxNo:Customer_Data.SaleTaxNo,
                CreatedDate:Customer_Data.CreatedDate,
                // Remarks:Customer_Data.Remarks, 
                // City:Customer_Data.City,
                Address:Customer_Data.Address,
                btn_Disabled:false
                // Company:Customer_Data.Company,
            }
            setCustomer_Data({...Customer_Data,btn_Disabled:true})
            SaveCustomer(CustomerData, isAddNew,()=>{
                
                setCustomer_Data({...Customer_Data,btn_Disabled:false})
                document.getElementById('btnCancel').click()
            })
        }
   
    }

    return ReactDOM.createPortal (
        <>
          <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title text-white" >
                        <h4>Customers</h4>
                    </Col>
                </Row>
            <Card>
                <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="CustomerCode"
                            id="CustomerCode"
                            label="CustomerCode"
                            value={Customer_Data.CustomerCode}
                            onChange={e => setCustomer_Data({ ...Customer_Data, CustomerCode: e.target.value })}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="CustomerName"
                            id="CustomerName"
                            label="CustomerName"
                            value={Customer_Data.CustomerName}
                            onChange={e=>setCustomer_Data({...Customer_Data,CustomerName:e.target.value})}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                        <span className="text-danger">{Item_Err.Name_Err}</span>
                    </Col>
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="PhoneNo"
                                id="PhoneNo"
                                type="number"
                                label="PhoneNo"
                                value={Customer_Data.PhoneNo}
                                onChange={e=>setCustomer_Data({...Customer_Data,PhoneNo:e.target.value})}
                                fullWidth
                            />
                            <span className="text-danger">{Item_Err.PhoneNo_Err}</span>
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Email"
                                id="Email"
                                label="Email"
                                value={Customer_Data.Email}
                                onChange={e=>setCustomer_Data({...Customer_Data,Email:e.target.value})}
                                fullWidth
                            />
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="NTN"
                            id="NTN"
                            type="number"
                            label="NTN"
                            value={Customer_Data.NTN}
                            onChange={e=>setCustomer_Data({...Customer_Data,NTN:e.target.value})}
                            fullWidth
                        />
                    </Col>
{/*                     
                    <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="City"
                                id="City"
                                label="City"
                                value={Customer_Data.City}
                                onInput={toInputUppercase}
                                onChange={e=>setCustomer_Data({...Customer_Data,City:e.target.value})}
                                fullWidth
                            />
                        </Col> */}
                    <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="SaleTaxNo"
                                id="SaleTaxNo"
                                label="Tax No"
                                type="number"
                                value={Customer_Data.SaleTaxNo}
                                onChange={e=>setCustomer_Data({...Customer_Data,SaleTaxNo:e.target.value})}
                               
                                fullWidth
                            />
                        </Col>
                        {/* <Col lg={8} xl={8} md={8} >
                            <TextField
                                name="Remarks"
                                id="Remarks"
                                label="Remarks"
                                value={Customer_Data.Remarks}
                                onInput={toInputUppercase}
                                onChange={e=>setCustomer_Data({...Customer_Data,Remarks:e.target.value})}
                                fullWidth
                            />
                        </Col>  */}
                        {/* <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Company"
                                id="Company"
                                label="Company"
                                onInput={toInputUppercase}
                                value={Customer_Data.Company}
                                onChange={e=>setCustomer_Data({...Customer_Data,Company:e.target.value})}
                                fullWidth
                            />
                        </Col> */}
                       
                       
                      
                        <Col lg={8} xl={8} md={8} >
                            <TextField
                                name="Address"
                                id="Address"
                                label="Address"
                                onInput={toInputUppercase}
                                value={Customer_Data.Address}
                                onChange={e=>setCustomer_Data({...Customer_Data,Address:e.target.value})}
                                fullWidth
                            />
                        </Col>
                        </Row>
                 
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                        {btn_Edit &&(
                            <button type="button"
                             className="btn btn-primary"
                              style={{ border: "none" }}
                               onClick={Save_Handler}
                               disabled={Customer_Data.btn_Disabled}
                                >
                                {Customer_Data.btn_Disabled?
                                (<><Spinner 
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>): <><Save /></>}  Save</button>)}
                        <Link id="btnCancel" className="btn btn-primary" style={{ border: "none" }} to='/CustomerList' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                <Loader
                show={IsLoader}
                hide={hideLoader}
              />
            </Card>
        </> , document.getElementById('mainContent')
    )
}
 
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    CustomerID: state.Customer && state.Customer.CustomerID,
    isAddNew: state.Customer && state.Customer.isAddNew,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    show_screen: state.SCREEN
  })
export default connect(mapStateToProps)(CustomerEntry)
