import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Loader from '../Loader'
import { GetCurrentDate } from '../../Services/Common'
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse,SelectBatchDetails} from '../../Services/StockTransferAPI'
import { GetMaxStockAdjustmentNo,SelectStockadjustmentMaster,SelectStockAdjustmentDetail,SaveStockAdjustment } from "../../Services/StockAdjustmentAPI";
export class StockAdjustmentEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        AdjustmentNo:'',
        WareHouseList:[],
        SelectedWareHouse:null,
        SelectedType:null,
        AdjustmentDate:GetCurrentDate(),
        CreatedDate:GetCurrentDate(),
        Item_List:[],
        Selected_item:null,
        Batch_List:[],
        Selected_Batch:null,
        TotalQty:0.00,
        IsIncrease:0,
        Remarks:'',
        StockAdjustment_Detail_Data:[],
        WareHouse_Err:'',
        btn_Disabled:false,
        IsLoader:false,
        btn_edit : true
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
      this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
    this.UserID = this.props.p_userid
    this.ProjectID = this.props.ProjectID
    this._Options= [{value:0,title:'Decrease'},{value:1,title:'Increase'}]
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
  }
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }
 
  componentDidMount(){

    document.title = " PHARMACY / Stock Adjustment"
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse})
        if(this.props.AddNew) // save
        {
            this.Max_StockAdjustmentNo() // new reciver NO genrate
        }
        else // update
        {
            this.showLoader()
            SelectStockadjustmentMaster(this.Comp_Id,this.ProjectID,this.props.StockAdjustmentID,(mData)=>{
                this.setState({
                    AdjustmentNo:mData[0].AdjustmentNo,
                    AdjustmentDate:mData[0].AdjustmentDate,
                    Remarks:mData[0].Remarks,
                    btn_edit: this.props.show_screen.edit
                })
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                SelectStockAdjustmentDetail(this.Comp_Id,this.ProjectID,this.props.StockAdjustmentID,(mDetailData)=>{
                    
                    mDetailData.map((value,index)=>{
                        value.SrNo = index + 1 
                    })
                    this.setState({StockAdjustment_Detail_Data:mDetailData})
                })
            })
            this.hideLoader()
        }

       })
  
  }
  showLoader = () => {
   
    this.setState({IsLoader:true})
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }
  Select_Item_Details = (WareHouseID) =>{
    SelectItemsWRTWareHouse(this.Comp_Id,this.ProjectID,WareHouseID,(mItems)=>{ // Api
        this.setState({Item_List:mItems})
    })
    

  }

  Select_BatchDetails = (ItemID) =>{
    SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
        
        this.setState({Batch_List:mItems})
    })
}
//   Show_Min_Stock_Modal = ()=>{
//       this.setState({Stock_Modal:true})
//   }
//   Hide_Min_Stock_Modal = ()=>{
//     this.setState({Stock_Modal:false})
// }
    Max_StockAdjustmentNo = () => {
        this.showLoader()
        GetMaxStockAdjustmentNo(this.Comp_Id,this.ProjectID, (mStockAdjustmentNo) => { // API Function
            this.hideLoader()  
            this.setState({ AdjustmentNo: mStockAdjustmentNo })  // set state here 
        })
    }
    
    BatchNO_Auto = null
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        
                        // var test = document.getElementById('BatchNo')
                        // 
                        this.setState({ Selected_item: value },()=>{
                            document.getElementById('UOM').value = value.uom
                            SelectBatchDetails(this.Comp_Id,this.ProjectID,value.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
                                debugger
                                this.BatchNO_Auto.props.options.splice(0, this.BatchNO_Auto.props.options.length)

                                for (var i = 0; i < mItems.length; i++) {
                                this.BatchNO_Auto.props.options.push(mItems[i])
                                }
                                this.setState({Batch_List:mItems})
                            })
                        })
                       
                    }
                    else {
                        this.setState({ Selected_item: null,Batch_List:[],Selected_Batch:null })
                        document.getElementById('UOM').value = ''

                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
                // this.Calculate_Total()
            }}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    BatchNo = (rowValue) => {
        this.BatchNO_Auto = <Autocomplete
        name="BatchNo"
        id="BatchNo"
        defaultValue={this.state.Batch_List.find(v => v.BatchNo === rowValue.BatchNo)}
        options={this.state.Batch_List}
        getOptionLabel={(option) => option.BatchNo ? option.BatchNo : ""}
        ref={this.bg_Ref}
        freeSolo= {true}
        onChange={(e, value) => {
            if (value) {
                
                this.setState({ Selected_Batch: value })
                document.getElementById('ExpairyDate').value = value.ExpairyDate
                document.getElementById('BQty').value = value.BQty
            }
            else {
                this.setState({ Selected_Batch: null })
                document.getElementById('ExpairyDate').value = ''
                document.getElementById('BQty').value = ''

            }
        }}
        renderInput={(params) =>
            <TextField {...params} name="BatchNo" />}
    />
        return (
            this.BatchNO_Auto
        )
    }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            readOnly={true}

        />)
    }
    BQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BQty"
            defaultValue={rowValue.BQty}
            className="textbox"
            name="BQty"
            readOnly={true}
            
        />)
    }
   
    SaveHandler = () => {
        if(this.state.SelectedWareHouse)
        {
            this.setState({Supplier_Err:''})
            if(this.state.StockAdjustment_Detail_Data.length > 0)
            {
                var AjustmentData = {
                    StockAdjustmentID:this.props.StockAdjustmentID,
                    AdjustmentNo:this.state.AdjustmentNo,
                    AdjustmentDate:this.state.AdjustmentDate,
                    WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                    TotalQty:this.state.TotalQty,
                    IsIncrease:this.state.SelectedType.value,
                    CreatedDate:this.state.CreatedDate,
                    Remarks:this.state.Remarks,
                    Comp_Id:this.Comp_Id,
                    ProjectID:this.ProjectID,
                    UserID:this.UserID

                }

                for(let i = 0; i< this.state.StockAdjustment_Detail_Data.length; i++)
                {
                    if( this.state.StockAdjustment_Detail_Data[i].Qty === 'undefined' || this.state.StockAdjustment_Detail_Data[i].Qty == '' || this.state.StockAdjustment_Detail_Data[i].Qty == null )
                    this.state.StockAdjustment_Detail_Data[i].Qty = 0
                    else if (typeof this.state.StockAdjustment_Detail_Data[i].Qty === 'string')
                    this.state.StockAdjustment_Detail_Data[i].Qty =  parseFloat(this.state.StockAdjustment_Detail_Data[i].Qty)
                    if(!this.state.StockAdjustment_Detail_Data[i].BatchNo ||  this.state.StockAdjustment_Detail_Data[i].BatchNo === 'undefined' || this.state.StockAdjustment_Detail_Data[i].BatchNo.trim() == '' || this.state.StockAdjustment_Detail_Data[i].BatchNo == null ){
                        Swal.fire({
                            icon: 'info',
                            text: `Batch No Must be Entered at ${this.state.StockAdjustment_Detail_Data[i].SrNo}...`,
                        })
                        return
                    }
                   
                }
                
                this.setState({btn_Disabled:true})
                SaveStockAdjustment(AjustmentData,this.state.StockAdjustment_Detail_Data,this.props.AddNew,()=>{
                    this.setState({btn_Disabled:false})
                    document.getElementById('btnCancel').click()
                })
            }
            else
            { 
                Swal.fire({
                    icon: 'info',
                    text: `Stock Adjustment Details Must be Entered...`,
                })
                return
            }
        }
        else
        {
            this.setState({Supplier_Err:'Supplier is required'})
        }
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.Name = this.state.Selected_item.Name
                        rowValue.data.ItemID = this.state.Selected_item.ItemID
                        rowValue.data.UOMID = this.state.UOMID
                        if(this.state.SelectedType.value === 0)
                        {
                            if(parseFloat(rowValue.data.Qty) > parseFloat(rowValue.data.BQty))
                            {
                                Swal.fire({
                                    icon: 'info',
                                    text: `Qty must be less than the Batch Qty : ${rowValue.data.BQty} Given...`,
                                })
                                this.grid.closeEdit()
    
                            }
                        }
                      
                        if (rowValue.data.StockTransferDetailID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.StockAdjustment_Detail_Data.length + 1
                    rowValue.data.Qty = 1
                  

                    this.setState({ Selected_item: null,UOMID:0 })
                }
            }
            // if (rowValue.requestType === 'beginEdit') {
            // }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "Name": rowValue.rowData.Name, "ItemID": rowValue.rowData.ItemID },UOMID: rowValue.rowData.UOMID })
          
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Total()
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Total()
        }
    }

    Calculate_Total = () => {
        var _TotalQty = 0;

        for(let i = 0; i< this.state.StockAdjustment_Detail_Data.length; i++)
        {
           if( typeof this.state.StockAdjustment_Detail_Data[i].Qty === 'string' )
           {
            this.state.StockAdjustment_Detail_Data[i].Qty = parseFloat((this.state.StockAdjustment_Detail_Data[i].Qty).replace(/,/g, ''))
           }
           else if (this.state.StockAdjustment_Detail_Data[i].Qty == '')
           {
            this.state.StockAdjustment_Detail_Data[i].Qty = 0 
           }
           _TotalQty += this.state.StockAdjustment_Detail_Data[i].Qty
        }
        this.setState({TotalQty:_TotalQty})
        // if (document.getElementById('Qty').value)
        //     Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        // else if (document.getElementById('Qty').value == "")
        //     Qty = 0
        // else
        //     Qty = parseFloat(document.getElementById('Qty').value)

        // Rate = document.getElementById('PurchaseRate').value == "" ? 0 : parseFloat(document.getElementById('PurchaseRate').value)
        // DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)
        // TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)

        // DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        // TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        // Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        // document.getElementById('DisAmt').value = DisAmt;
        // document.getElementById('TaxAmt').value = TaxAmt;
        // document.getElementById('value').value = Total_Value;

    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        this.setState({ StockAdjustment_Detail_Data: this.state.StockAdjustment_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.StockAdjustment_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray})
        })
        this.Hide_Min_Stock_Modal()
    }
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
            <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Stock Adjustment</h4>
            </Col>
          </Row>
            <Card>
          
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="AdjustmentNo"
                            label="Adjustment No" 
                            value={this.state.AdjustmentNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="WareHouseID"
                            id="WareHouseID"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                                    this.Select_Item_Details(value.WareHouseID)
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="IsIncrease"
                            id="IsIncrease"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedType:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedType:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="IsIncrease" label="Type"
                                />}
                        />
                        {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.AdjustmentDate}
                            name="AdjustmentDate"
                            label="Adjustment Date" 
                            type= 'date'
                            onChange= {e => this.setState({AdjustmentDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={5} xl={5} md={2} >
                        <TextField
                            name="TotalQty"
                            label="Total Qty" 
                            value={this.state.TotalQty}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalQty:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                    <Col sm={12} lg={5} xl={5} md={2} >
                        <TextField
                            name="Remarks"
                            label="Remarks" 
                            value={this.state.Remarks}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Remarks:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                </Row>
             
                    
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.StockAdjustment_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'dropdownedit'  editTemplate={this.ItemDiscription}   edit={this.cbParams}  validationRules={this.NAmeValidation} textAlign='Left'/> 
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType= 'dropdownedit'  editTemplate={this.BatchNo}   edit={this.cbParams}   textAlign='Left'/> 
                                    <ColumnDirective width='170' field='ExpairyDate' headerText='Expiry Date'  editTemplate={this.ExpairyDate} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='BQty' headerText='Batch Balance'   editTemplate={this.BQty} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right'/>

                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='StockAdjustmentDetailID' headerText='StockAdjustmentDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='StockAdjustmentID' headerText='StockAdjustmentID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                             
                    {this.state.btn_edit &&(
                        <button type="button"
                         className="btn btn-primary"
                           style={{border: "none"}}
                            onClick={this.SaveHandler}
                            disabled={this.state.btn_Disabled}
                            > 
                            {this.state.btn_Disabled?(
                                <><Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> Saving... </> ):<><Save /> <u>Save</u></>}
                            </button>)}
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'/StockAdjustmentList'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                  />
                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    AddNew: state.StockAdjustment && state.StockAdjustment.AddNew,
    StockAdjustmentID:state.StockAdjustment && state.StockAdjustment.StockAdjustmentID,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(StockAdjustmentEntry)