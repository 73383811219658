import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import  { connect } from 'react-redux'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Loader from '../Loader'
import { SelectStockTransferList } from '../../Services/StockTransferAPI'
import  { set_StockTransfer, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'

function StockTransferList(props) {
  const [IsLoader, setIsLoader] = useState(false)
    const {  set_StockTransfer,ItemCat , Comp_Id,ProjectID, Group_Id, SHOW_SCREEN } = props;
    const [StockTransferList_Data, setStockTransferList_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)

    useEffect(()=>{
    document.title = " Pharmacy / Stock Transfer"
    SelectGroupScreenAccess(Group_Id,11,98,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })

// Select_StockTransferList()
    },[])
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const column_StockTransfer = [
        {
         name: "StockTransferID",
         label: "Stock Transfer ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "TransferNo",
            label: "Transfer Code",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
         name: "TransferDate",
         label: "Transfer Date",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "FromWareHouse",
          label: "From WareHouse",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
        {
         name: "ToWareHouse",
         label: "To WareHouse",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }
         }
        }
        //  {
        //   name: "action",
        //   label: "action",
        //   options: {
        //    filter: true,
        //    sort: true,
        //    customHeadLabelRender: (columnMeta)=>{
        //     return(
        //       <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
        //     )
        //    },
        //    customBodyRender: (dataIndex, rowIndex) => {
             
        //      var StockTransferID = StockTransferList_Data
        //      if (StockTransferID[rowIndex.rowIndex] != null)
        //        return (
        //          <Link to={"/StockTransferEntry"} onClick={e => edit_StockTransfer(StockTransferList_Data[rowIndex.rowIndex].StockTransferID)}> <Edit /></Link>
        //        );
        //    }
        //   }
        //  }
    ];
    // const edit_StockTransfer = (StockTransferID) =>{
    //    set_StockTransfer(StockTransferID,false)
    // }
    const add_StockTransfer = () =>{
       set_StockTransfer(0,true)
    }
    const Month_List = [
      {title:"JANUARY",value:1},
      {title:"FEBURARY",value:2},
      {title:"MARCH",value:3},
      {title:"APRIL",value:4},
      {title:"MAY",value:5},
      {title:"JUNE",value:6},
      {title:"JULY",value:7},
      {title:"AUGUST",value:8},
      {title:"SEPTEMBER",value:9},
      {title:"OCTOBER",value:10},
      {title:"NOVEMBER",value:11},
      {title:"DECEMBER",value:12},
  ]
   const Select_StockTransferList = (Month) =>{
    showLoader()
    SelectStockTransferList(Comp_Id,ProjectID,Month,(mData)=>{
      hideLoader()
        setStockTransferList_Data(mData)
    })
   }
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4 > Stock Transfer List</h4>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right p-4" >
          <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Month(value)
                                    Select_StockTransferList(value.value)
                                }
                                else
                                {
                                    setSelected_Month(null)
                                    set_StockTransfer([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Month"
                                />}
                        />
                    </Col>
            <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary" to="/StockTransferEntry" onClick={add_StockTransfer} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                    <MUIDataTable
                        data={StockTransferList_Data}
                        columns={column_StockTransfer}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.StockTransfer && state.StockTransfer.ItemData,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  Group_Id : state.Login_User.User_Data.Group_Id,

})
export default connect(mapStateToProps,{set_StockTransfer, SHOW_SCREEN})(StockTransferList)
