import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import { SelectUserProjects } from '../../../Services/Projects'
import { GetCurrentDate } from '../../../Services/Common'
import { SelectcustomersList } from '../../../Services/CustomersAPI'
import { FinYear } from '../../../Services/FinYear'
import { SelectItemsListWRTProject } from '../../../Services/ItemsAPI'
import { SelectProjectsUser } from '../../../Services/Sale_ReportAPI'

function SaleReport_Modal({show, onHide,Comp_Id,User_Id,toPath,readonlyProps,title }) {
    const [Project_List, setProject_List] = useState([])
    var [SelectedProject, setSelectedProject] = useState(null)
    const [FromDate, setFromDate] = useState(GetCurrentDate())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [Customer_List, setCustomer_List] = useState([])
    const [SelectedFromCustomer, setSelectedFromCustomer] = useState(null)
    const [ SelectedToCustomer, setSelectedToCustomer] = useState(null)
    const [filter, setfilter] = useState(null)
    const [FinYearData, setFinYearData] = useState([])
    const [SelectedFinData, setSelectedFinData] = useState(null)
    const [ReportPath, setReportPath] = useState('#')
    const [ItemList, setItemList] = useState([])
    const [SelectedFromItem, setSelectedFromItem] = useState(null)
    const [SelectedToItem, setSelectedToItem] = useState(null)
    var [NoOFItems, setNoOFItems] = useState(10)
    var [mTarget, setMTarget] = useState('');
    const [UserList, setUserList] = useState([]);
    const [SelectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        if(show && toPath){
            SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
                setProject_List(mProjects)
            })
            FinYear(Comp_Id,User_Id,(mData)=>{
                setFinYearData(mData)
            })
        }
    }, [show])
    const handleFilterChange = event => {
        if (event) {
            setfilter(event.filter);
        }
    };
    const Fin_Columns = [
        {
            field: "fin_year",
            header: "fin_year",
            width: "150px",
        },
        {
            field: "StartYear",
            header: "StartYear",
            width: "150px",
        },
        {
            field: "EndYear",
            header: "EndYear",
            width: "150px",
        },
    ];
    const Select_Item = (Project_ID)=>{
        SelectItemsListWRTProject(Comp_Id,Project_ID,(mItems)=>{
            setItemList(mItems)
        })
    }
    const Select_Customer = (Project_ID)=>{
        SelectcustomersList(Comp_Id,Project_ID,(mCustomer)=>{
            setCustomer_List(mCustomer)
        })
    }
    const Select_ProjectsUser = (Project_ID)=>{
        SelectProjectsUser(Project_ID,(mProjectUser)=>{
            setUserList(mProjectUser)
        })
    }
    const NavigateToReport = ()=>{
        
        if(toPath == '/CustomerLedgerReport'){
            if(SelectedProject && SelectedFinData && SelectedFromCustomer){
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedFinData.fin_year}/${SelectedFromCustomer.CustomerID}`)
                setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if(toPath == '/SaleSummaryCustomerWiseReport'){
            if(SelectedProject){
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedFromCustomer.CustomerID}`)
                setMTarget('_blank')
            }
            else{

            }
        }
        else if(toPath == '/SaleLedgerItemWise')
        {
            if (SelectedProject && SelectedFromItem && SelectedToItem){
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedFromItem.ItemID}/${SelectedToItem.ItemID}`)
              setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/SaleMarginCustomerWise'){
            
            if(SelectedProject && SelectedFromCustomer && SelectedToCustomer){
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedFromCustomer.CustomerID}/${SelectedToCustomer.CustomerID}`)
                setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/SaleReturnLedgerCustWise'){
            if(SelectedProject){
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`) 
                setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/MostSoldItems'){
            if(SelectedProject){
                if(NoOFItems == 0){
                    NoOFItems = 10
                }
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${NoOFItems}`) 
                setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/MinimumSoldItems'){
            if(SelectedProject){
                if(NoOFItems == 0){
                    NoOFItems = 10
                }
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${NoOFItems}`) 
                setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/DailySaleOfLastMOnth'){
            if(SelectedProject){
                setReportPath(`${toPath}/${SelectedProject.Project_ID}`) 
                setMTarget('_blank')
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/Receiveable'){
            if(SelectedProject){
                setMTarget('_blank')
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`) 
            }
            else{
                setMTarget('')
            }
        }
        else if (toPath == '/SaleSummarySalesManWise'){
            if(SelectedProject && SelectedUser){
                setMTarget('_blank')
                setReportPath(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedUser.User_id}`) 
            }
            else{
                setMTarget('')
            }
        }
    }
    const ResetCotrols = () =>{
        setProject_List([])
        setSelectedProject(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
        setCustomer_List([])
        setSelectedFromCustomer(null)
        setSelectedToCustomer(null)
        setfilter(null)
        setFinYearData([])
        setSelectedFinData(null)
        setReportPath('#')
        setItemList([])
        setSelectedFromItem(null)
        setSelectedToItem(null)
        setNoOFItems(10)
        setMTarget('')
        setSelectedUser(null)
        setUserList([])
    }
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Sale_Modal" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Sale Reports / {title} </Modal.Title>
            </Modal.Header>
                <Modal.Body id='mBody'>
                    <Card>
                    <Row className="row_margin_left row_margin_right" >
                             <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="FromDate" 
                                label="From Date"
                                type='date'
                                value={FromDate}
                                onChange={e=>setFromDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.FromDate
                                }
                                }
                             />
                             </Col>
                             <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="ToDate" 
                                label="To Date"
                                type='date'
                                value={ToDate}
                                onChange={e=>setToDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.ToDate
                                }
                                }
                             />
                             </Col>
                            <Col >
                                {/* <Col xl={4} lg={4} md={4} sm={12}>
                                    <MultiColumnComboBox
                                        data={filter ? filterBy(FinYearData, filter) : FinYearData}
                                        value={SelectedFinData}
                                        style={{ fontSize: "12px"}}
                                        disabled={readonlyProps.finYear}
                                        columns={Fin_Columns}
                                        textField={"fin_year"}
                                        filterable={true}
                                        label='Select Fin Year'
                                        onFilterChange={handleFilterChange}
                                        onChange={e => {

                                            if (e.value) {
                                              setSelectedFinData(e.value)
                                            }
                                            else {
                                                setSelectedFinData(null)
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Col> */}
                                <Autocomplete
                                    name="FinYear"
                                    id="FinYear"
                                    options={FinYearData}
                                    getOptionLabel={(option) => option.fin_year ? option.fin_year : ""}
                                    value={SelectedFinData}
                                    disabled={readonlyProps.finYear}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFinData(value)
                                        }
                                        else{
                                            setSelectedFinData(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FinYear" label="Fin Year"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Project"
                                    id="Project"
                                    options={Project_List}
                                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                    value={SelectedProject}
                                    disabled={readonlyProps.Project}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedProject(value)
                                            Select_Item(value.Project_ID)
                                            Select_Customer(value.Project_ID)
                                            Select_ProjectsUser(value.Project_ID)
                                        }
                                        else{
                                            setSelectedProject(null)
                                            setItemList([])
                                            setCustomer_List([])
                                            setSelectedFromCustomer(null)
                                            setSelectedToCustomer(null)
                                            setSelectedFromItem(null)
                                            setSelectedToItem(null)
                                            setUserList([])
                                            setSelectedUser(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Project" label="Project"
                                        />
                                    }
                                />
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="FromCustomer"
                                    id="FromCustomer"
                                    options={Customer_List}
                                    getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                                    value={SelectedFromCustomer}
                                    disabled={readonlyProps.Customer}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFromCustomer(value)
                                        }
                                        else
                                            setSelectedFromCustomer(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FromCustomer" label="FromCustomer"
                                        />
                                    }
                                />
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="ToCustomer"
                                    id="ToCustomer"
                                    options={Customer_List}
                                    getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                                    value={SelectedToCustomer}
                                    disabled={readonlyProps.ToCustomer}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedToCustomer(value)
                                        }
                                        else
                                            setSelectedToCustomer(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ToCustomer" label="ToCustomer"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                        <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="FromItem"
                                    id="FromItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedFromItem}
                                    disabled={readonlyProps.FromItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFromItem(value)
                                        }
                                        else
                                            setSelectedFromItem(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FromItem" label="FromItem"
                                        />
                                    }
                                />
                            </Col> 
                        <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="ToItem"
                                    id="ToItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedToItem}
                                    disabled={readonlyProps.ToItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedToItem(value)
                                        }
                                        else
                                            setSelectedToItem(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ToItem" label="ToItem"
                                        />
                                    }
                                />
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="User"
                                    id="User"
                                    options={UserList}
                                    getOptionLabel={(option) => option.User_name ? option.User_name : ""}
                                    value={SelectedUser}
                                    disabled={readonlyProps.User}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedUser(value)
                                        }
                                        else{
                                            setSelectedUser(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="User" label="User"
                                        />
                                    }
                                />
                            </Col>
                           
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                             name="NoOFItems" 
                             label="No of Items" 
                             type='number'
                             value={NoOFItems}
                             onChange={e=>{setNoOFItems(e.target.value)}}
                             fullWidth
                             inputProps={{
                                 readOnly: readonlyProps.NoOfItems
                             }
                             }
                             />
                            </Col>
                            <Col sm={12} lg={10} xl={10} md={10} style={{ textAlign: "right", marginTop: "1%" }} >
                                <Link to={ReportPath} className="btn btn-primary" target={mTarget} style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></Link>
                                <button type="button" className="btn btn-primary" onClick={e=>{
                                    ResetCotrols()
                                    onHide()
                                }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
})
export default connect(mapStateToProps)(SaleReport_Modal)
