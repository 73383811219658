import {React,useEffect,useState} from "react";
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card,Button } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { Repeat, Print } from '@material-ui/icons'
import Swal from 'sweetalert2'

import { GetCurrentDate } from '../../Services/Common'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectDoctorsList } from "../../Services/OPDRegistrationAPI";
import { SelectDoctorStatisticsList } from "../../Services/PendingPatinetListAPI";

const DoctorStatisticsList = ({Comp_Id,p_userid}) => {
  const [ProjectList, setProjectList] = useState([])
  const [SelectedProject, setSelectedProject] = useState(null)
  const [FromDate , setFromDate] = useState(GetCurrentDate)
  const [ToDate , setToDate] = useState(GetCurrentDate)
  const [IsLoader, setIsLoader] = useState(false)
  const [DoctorList, setDoctorList] =  useState([]);
  const [SelectedDoctor, setSelectedDoctor] = useState(null)
  const [Error, setError] = useState({cmbDoctor: '', cmbProject: ''})
  const [DoctorStatisticData, setDoctorStatisticData] = useState([] )
useEffect(() => {
 document.title="Doctor Statistics"
  SelectUserProjects(Comp_Id,p_userid,(mProjects)=>{
  setProjectList(mProjects)
  setSelectedProject(mProjects[0])
  SelectDoctorsList(Comp_Id,(mDoctors)=>{
  setDoctorList(mDoctors)
    
   
  })
 })

 
}, []);
const validation = () =>{
  let message = '';

  if(!SelectedDoctor && !SelectedProject)
  {
      setError({cmbDoctor : "Must be Required", cmbProject : "Must Be Required"})
      // setReport_URL("#")
      return false
  }
  else if(!SelectedDoctor)
  {
      setError({cmbDoctor : "Must be Required", cmbProject : ""})
      return false
  }
  else if(!SelectedProject)
  {
      setError({cmbDoctor : "", cmbProject : "Must be Required"})
      return false
  }

  setError({cmbDoctor : "", cmbProject : ""})
      return true
}
const Load = () =>{
  if(validation()){
    SelectDoctorStatisticsList(SelectedDoctor.User_id,FromDate,ToDate,Comp_Id,SelectedProject.Project_ID,(mData)=>{  
      if(mData.length > 0)
        setDoctorStatisticData(mData)
        else{
          Swal.fire({
            icon: 'info',
            title: 'Not Found...',
            text: `Data not found in given dates`,
          })
        }
                  })
  }
   
}
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    }
  }
});
const showLoader = () => {
  setIsLoader(true)
}
const hideLoader = () => {
  setIsLoader(false)
}
const column_DoctorStat = [
  {
   name: "PatientName",
   label: "PatientName",
   options: {
    filter: true,
    sort: true,
    customHeadLabelRender: (columnMeta)=>{
      return(
        <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
      )
     },  
    
   }
  },
 
  {
   name: "Guardian",
   label: "Guardian",
   options: {
    filter: true,
    sort: true,
  customHeadLabelRender: (columnMeta)=>{
      return(
        <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
      )
     }, 
    }
  },
  {
      name: "PhoneNo",
      label: "PhoneNo",
      options: {
       filter: true,
       sort: true,
     customHeadLabelRender: (columnMeta)=>{
         return(
           <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
         )
        }, 
       }
     },
     {
      name: "Age",
      label: "Age",
      options: {
       filter: true,
       sort: true,
     customHeadLabelRender: (columnMeta)=>{
         return(
           <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
         )
        }, 
       }
     },
     {
      name: "AppointmentDate",
      label: "AppointmentDate",
      options: {
       filter: true,
       sort: true,
     customHeadLabelRender: (columnMeta)=>{
         return(
           <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
         )
        }, 
       }
     },
     {
      name: "DoctorFee",
      label: "DoctorFee",
      options: {
       filter: true,
       sort: true,
       customHeadLabelRender: (columnMeta)=>{
         return(
           <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
         )
        },
      }
     },
   
  {
   name: "Doctor",
   label: "Doctor",
   options: {
    filter: true,
    sort: true,
    customHeadLabelRender: (columnMeta)=>{
      return(
        <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
      )
     },
   }
  },

     {
      name: "FeeType",
      label: "FeeType",
      options: {
       filter: true,
       sort: true,
       customHeadLabelRender: (columnMeta)=>{
         return(
           <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
         )
        },
      }
     },
  //  {
  //   name: "action",
  //   label: "action",
  //   options: {
  //    filter: true,
  //    sort: true,
  //    customHeadLabelRender: (columnMeta)=>{
  //     return(
  //       <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
  //     )
  //    },
  //    customBodyRender: (dataIndex, rowIndex) => {
       
  //      var RegistrationID =IPDRegistration_Data
  //      if (RegistrationID[rowIndex.rowIndex] != null)
       
  //        return (
  //          <Link to={"/IPDRegistrationEntry"} onClick={  e => {edit_IPDRegistration(RegistrationID[rowIndex.rowIndex].RegistrationID)
          
  //         }}> <Edit /></Link>
       
  //        );
         
  //    }
  //   }
  //  }
];
  return ReactDOM.createPortal(
    <>
      <Row className="row_margin_top row_margin_left row_margin_right">
        <Col lg={6} xl={6} md={6} className="header_title text-white">
          <h4> Doctor Statistics</h4>
        </Col>
      </Row>
      <Card>
        <Row className="row_margin_top row_margin_left row_margin_right m-4">
          <Col lg={3} xl={3} md={3}>
            <Autocomplete
              name="Project"
              id="Project"
              options={ProjectList}
              getOptionLabel={(option) =>
                option.ProjectName ? option.ProjectName : ""
              }
              value={SelectedProject}
              onChange={(e, value) => {
                if (value) {
                  setSelectedProject(value);
                  // SelectDoctorStatisticsList(SelectedDoctor.User_id,FromDate,ToDate,Comp_Id,SelectedProject.Project_ID,(mData)=>{  
                  //   setDoctorStatisticData(mData)
                  // })
                } else {
                  setSelectedProject(null);
                  // setIPDRegistration_Data([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="Project" label="Select Project" />
              )}
            />
            <span style={{color: "red"}} > { Error.cmbProject } </span>
          </Col>
          <Col lg={3} xl={3} md={3}>
            <Autocomplete
              name="Doctor"
              id="Doctor"
              options={DoctorList}
              getOptionLabel={(option) =>
                option.User_name ? option.User_name : ""
              }
              value={SelectedDoctor}
              onChange={(e, value) => {
                if (value) {
                  setSelectedDoctor(value);

                  // SelectDoctorStatisticsList(SelectedDoctor.User_id,FromDate,ToDate,Comp_Id,SelectedProject.Project_ID,(mData)=>{  
                  //   setDoctorStatisticData(mData)
                  // })
                } else {
                  setSelectedDoctor(null);
                  // setIPDRegistration_Data([]);
                }
              }}
              renderInput={(params) => (
               <TextField {...params} name="Doctor" label="Select Doctor" />
              )}
            />
            <span style={{color: "red"}} > { Error.cmbDoctor } </span>
          </Col>
        
          <Col lg={3} xl={3} md={3}>
            <TextField
              name="FromDate"
              id="FromDate"
              label="FromDate"
              type="date"
              value={FromDate}
              onChange={(e) => setFromDate(e.target.value)}
              fullWidth
            />
          </Col>
          <Col lg={3} xl={3} md={3}>
            <TextField
              name="ToDate"
              id="ToDate"
              label="ToDate"
              type="date"
              value={ToDate}
              onChange={(e) => setToDate(e.target.value)}
              fullWidth
            />
          </Col>
        </Row>
        <Row className="row_margin_top row_margin_left row_margin_right" >
        <Col style={{ textAlign: "right" }} >
            <Button className="btn btn-primary waves-effect height-of-button"
             onClick={e => {
                Load()
            }} style={{ border: "none" }}
             > <Repeat />  Load </Button> &nbsp;&nbsp;&nbsp;
            <Link 
            onClick={e =>{
              
                var obj = {
                    Doctor: SelectedDoctor,
                    Project: SelectedProject,
                    DoctorStatisticData: DoctorStatisticData
                }
              
            
                localStorage.setItem('token',sessionStorage.getItem('token'))
                localStorage.setItem("Data",JSON.stringify(obj))
            }} 
            to={"/DoctorStatisticsReport"}
            // to={'/DoctorStatistcReport'}
              target="_blank" 
            className="btn btn-primary waves-effect height-of-button" > <Print />  Print </Link>
        </Col>
    </Row>
        <Row className="row_margin_top row_margin_left row_margin_right">
        <Col>
          <div className="table-responsive">
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                data={DoctorStatisticData}
                columns={column_DoctorStat}
                options={{
                  selectableRows: "none",
                  print: false,
                  search: true,
                  download: false,
                  viewColumns: false,
                  filter: false,
                }}
              />
            </MuiThemeProvider>
          </div>
        </Col>
      </Row>
        <Loader
            show={IsLoader}
            hide={hideLoader}
          />
      </Card>
    </>,
    document.getElementById("mainContent")
  );
};
const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  
  Group_Id: state.Login_User.User_Data.Group_Id,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
});
export default connect(mapStateToProps)(DoctorStatisticsList);
