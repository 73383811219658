import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Delete, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import  { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { SelectAdmitedPatients, SelectPatientMedicainWRTPatient, DeletePatientMedicain } from '../../Services/IPDBillingAPI'
import { SelectUserProjects } from '../../Services/Projects'
import PatientMedicainEntry from './PatientMedicainEntry'

const PatientMedicainList = ({Comp_Id,p_userid}) => {
  const [ProjectList, setProjectList] = useState([])
  const [SelectedProject, setSelectedProject] = useState(null)
  const [PatientList, setPatientList] = useState([])
  const [SelectedPatient, setSelectedPatient] = useState(null)
  const [btn_add, setBtn_add] = useState(true)
  const [PatientMedicainData, setPatientMedicainData] = useState([]) 
  const [IsAddNew, setIsAddNew] = useState(null)
  const [Medicain_Modal, setMedicain_Modal] = useState(false)
  const [PatientMedicainID, setPatientMedicainID] = useState(null)

  useEffect(()=>{
    document.title = 'Patient Medication'
    SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{
        setProjectList(ProjectData)
        setSelectedProject(ProjectData[0])
        Admitted_Patients(ProjectData[0].Project_ID)
        // if(DoctorVisits.Visits_Data)
        // {
          
        //   Select_Visits_List(Comp_Id,DoctorVisits.Visits_Data.SelectedProject.Project_ID,ListDate)
        //   setSelectedProject(ProjectData.find(x => x.Project_ID ===  DoctorVisits.Visits_Data.SelectedProject.Project_ID))
        //   setvisits_Data(null,0,false)
        // }
        // else
        // {
        //   setSelectedProject(ProjectData[0])
        //   Select_Visits_List(Comp_Id,ProjectData[0].Project_ID,ListDate)
        // }
      })
  },[])
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  const Admitted_Patients = (ProjectID) =>{
    SelectAdmitedPatients(Comp_Id, ProjectID, (mData)=>{
      if(mData.length > 0){
        setPatientList(mData)
        setSelectedPatient(mData[0])
        SelectPatient_Medication(mData[0].RegistrationID)
      }
      else{
        setPatientList([])
        setPatientMedicainData([])
        setSelectedPatient(null)
      }
      
    })
  }
  const show_Modal = () =>{
    setMedicain_Modal(true);
}
const hide_Modal = () =>{
    setMedicain_Modal(false)
    setIsAddNew(null)
    Admitted_Patients(SelectedProject.Project_ID)
}  
const SelectPatient_Medication = (PatientID) =>{
  SelectPatientMedicainWRTPatient(PatientID, (mData)=>{
      if(mData.length > 0){
        setPatientMedicainData(mData)
      }
  })
}       
  const column_PatientMedicain = [
    {
     name: "ItemDiscription",
     label: "Items",
     options: {
      filter: true,
      sort: true,
      customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       },  
      
     }
    },
   
    {
     name: "Qty",
     label: "Qty",
     options: {
      filter: true,
      sort: true,
    customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       }, 
      }
    },
    {
        name: "Price",
        label: "Price",
        options: {
         filter: true,
         sort: true,
       customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          }, 
         }
       },
       {
        name: "TotalPrice",
        label: "Total Price",
        options: {
         filter: true,
         sort: true,
       customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          }, 
         }
       },
     {
      name: "action",
      label: "action",
      options: {
       filter: true,
       sort: true,
       customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
        )
       },
       customBodyRender: (dataIndex, rowIndex) => {
         
        var _PatientMedicainID =PatientMedicainData
        if (_PatientMedicainID[rowIndex.rowIndex] != null)
        
          return (
            <Link to={"#"} onClick={e=>Delete_Handler(_PatientMedicainID[rowIndex.rowIndex].PatientMedicainID)} > <Delete /></Link>
        
          );
          
      }
      }
     }
];
const Delete_Handler = (PatientID) =>{

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  
  swalWithBootstrapButtons.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
     DeletePatientMedicain(PatientID, ()=>{
      SelectPatient_Medication(SelectedPatient.RegistrationID)
     })
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })
}
  return ReactDOM.createPortal(
   <>
   <Row className="row_margin_top row_margin_left row_margin_right" >
    <Col lg={6} xl={6} md={6} className="header_title text-white" >
      <h4> Patient Medication</h4>
    </Col>
  </Row>
  <Card>
  <Row className="row_margin_top row_margin_left row_margin_right m-4" >
  <Col lg={3} xl={3} md={3} >
      <Autocomplete
          name="Project"
          id="Project"
          options={ProjectList}
          getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
          value={SelectedProject}
          onChange={(e, value) => {
              if (value)
              {
                  setSelectedProject(value)
                  Admitted_Patients(value.Project_ID)
               
              }
              else
              {
                setSelectedProject(null)
                setPatientMedicainData([])
                setPatientList([])
                setSelectedPatient(null)
             
              }
          }}
          renderInput={(params) =>
              <TextField {...params} name="Project" label="Select Project"
              />}
      />
  </Col>
  <Col lg={3} xl={3} md={3} >
  <Autocomplete
          name="PatientName"
          id="PatientName"
          options={PatientList}
          getOptionLabel={(option) => option.PatientName ? option.PatientName : ""}
          value={SelectedPatient}
          onChange={(e, value) => {
              if (value)
              {
                  setSelectedPatient(value)
                  SelectPatient_Medication(value.RegistrationID)
                
              }
              else
              {
                setSelectedPatient(null)
                setPatientMedicainData([])
              }
          }}
          renderInput={(params) =>
              <TextField {...params} name="PatientName" label="Select Patient"
              />}
      />
  </Col>

  

<Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
<Link to="#" id='btn_navigate' ></Link>
{btn_add && (<Link variant="btn btn-primary"  to="#" onClick={e =>{
  show_Modal()
  setIsAddNew(true)
}} style={{border: "none"}} > <Add />  Create New</Link> )}

</Col>
  </Row>
  <Row className="row_margin_top row_margin_left row_margin_right" >
  <Col>    
            
    <div className="table-responsive" >
  <MuiThemeProvider theme={theme}>
        <MUIDataTable
            data={PatientMedicainData}
            columns={column_PatientMedicain}
            options={{
            selectableRows: 'none',
            print: false,
            search: true,
            download: false,
            viewColumns: false,
            filter: false,
            }}
        />
        
        </MuiThemeProvider>
    </div>
  </Col>
</Row>
  </Card>
  <PatientMedicainEntry
        show = { Medicain_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        PatientMedicainID = {PatientMedicainID}
  />
   </>, document.getElementById('mainContent')
  )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data.Group_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  })
  export default connect(mapStateToProps)(PatientMedicainList)