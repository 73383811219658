import axios from 'axios'
import { domain } from "../Services/Common"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


  export const SelectTotalServices =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    
    axios.get(`${domain}/IPDDashboard/SelectTotalServices/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SelectTotalPateintsBillDetails =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    
    axios.get(`${domain}/IPDDashboard/SelectTotalPateintsBillDetails/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectTopPatients =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    
    axios.get(`${domain}/IPDDashboard/SelectTopPatients/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectTotalValues =(Comp_Id,ProjectID,Callback) =>{
    
    axios.get(`${domain}/IPDDashboard/SelectTotalValues/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectOPDData =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    
    axios.get(`${domain}/IPDDashboard/SelectOPDData/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }