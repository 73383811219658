import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectPendingPatientList =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/PendingPatient/SelectPendingPatientList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          Callback()
     })
}


export const SelectPrescriptionList =(Comp_Id,ProjectID,date,Callback) =>{
    axios.get(`${domain}/PendingPatient/SelectPrescriptionList/${Comp_Id}/${ProjectID}/${date}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
            Callback()
       })
  }
  export const SelectDoctorStatisticsList =(DoctorID,FromDate,ToDate,Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/PendingPatient/SelectDoctorStatisticsList/${DoctorID}/${FromDate}/${ToDate}/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
            Callback()
       })
  }
  export const SelectPendingPatientTests = (Comp_Id,Project_ID,AppointmentID, callback) =>{

    axios.get(`${domain}/PendingPatient/SelectPendingPatientTests/${Comp_Id}/${Project_ID}/${AppointmentID}`)
         .then(response =>{
            callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }
 
 export const PrescriptionReport = (AppointmentID, callback) =>{

  axios.get(`${domain}/PendingPatient/PrescriptionReport/${AppointmentID}`)
       .then(response =>{
          callback(response.data)
       })
       .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}
export const DischargeSlip = (Comp_Id,RegistrationID, callback) =>{

     axios.get(`${domain}/PendingPatient/DischargeSlip/${Comp_Id}/${RegistrationID}`)
          .then(response =>{
             callback(response.data)
          })
          .catch(err =>{
             Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: `${err}`,
               })
          })
   }