import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { SaveSuppliers , SelectSupplier,GetMaxSupplierCode } from './../../Services/SuppliersAPI'
import {GetCurrentDate} from '../../Services/Common'
import Loader from '../Loader'
function SupplierEntry({ isAddNew, SupplierID,Comp_Id,UserID,ProjectID, show_screen }) {
    const [Manufacturer_List, setManufacturer_List] = useState([])
    const [SelectedManufacturerOption, setSelectedManufacturerOption] = useState(null)
    const [IsLoader, setIsLoader] = useState(false)
        const [Supplier_Data,setSupplier_Data] = useState({
        SupplierID:SupplierID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        UserID:UserID,
        SupplierName:'',
        Email:'',
        PhoneNo:'',
        SupplierCode:'',
        CreatedDate:GetCurrentDate(),  
        NTN:'',
        SaleTaxNo:'',
        Address:'',
        btn_Disabled:false,
    })
    const [Item_Err, setItem_Err] = useState({
        Name_Err:'',
        PhoneNo_Err:'',
        NTN_Err:'',
        Cat_Err: '',
        UOM_Err:'',
        SUBUOM_Err:''
    })
  
    const [btn_Edit, setBtn_Edit] = useState(true)

    useEffect(() => {
    document.title = " PHARMACY / Supplier"
    SelectManufacturedByList(Comp_Id,ProjectID,(mManufacturer)=>{
        setManufacturer_List(mManufacturer)
        if(isAddNew)
        {   showLoader()
            GetMaxSupplierCode(Comp_Id,ProjectID,(mCode)=>{
                hideLoader()
                setSupplier_Data({...Supplier_Data,SupplierCode:mCode})
            })
        }
            // if(!isAddNew)
         else   {
                setBtn_Edit(show_screen.edit)
                showLoader()
                SelectSupplier(Comp_Id,ProjectID,SupplierID,(mData)=>{
                    
                    setSupplier_Data({
                        SupplierID:SupplierID,
                        Comp_Id:Comp_Id,
                        ProjectID:ProjectID,
                        SupplierCode:mData[0].SupplierCode,
                        SupplierName:mData[0].SupplierName,
                        Email:mData[0].Email,
                        PhoneNo:mData[0].PhoneNo,
                        CreatedDate:mData[0].CreatedDate,
                        UserID:mData[0].UserID,
                        // Remarks:mData[0].Remarks,
                        // Company:mData[0].Company,
                        NTN:mData[0].NTN,
                        SaleTaxNo:mData[0].SaleTaxNo,
                        // City:mData[0].City,
                        Address:mData[0].Address
    
                    })
                    mManufacturer.map(x=> {
                        if(x.ManufacturedByID === mData[0].ManufacturedByID){
                            setSelectedManufacturerOption(x)
                        }
                    })
                })
              
                hideLoader()
            }
    })
    
    }, [])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
   
   const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
  const validate =() =>{
        var  NameErr=""
        var PhoneNoErr = ""
        
        if(!(Supplier_Data.SupplierName).trim())
        {
         NameErr = "Name is required"
        }
        if(!(Supplier_Data.PhoneNo).trim())
        {
            PhoneNoErr="required"
        }
      
      
        if(NameErr)
        {
           setItem_Err({...Item_Err,Name_Err:NameErr})
           document.getElementById('SupplierName').focus()
            return false
        }
     if(PhoneNoErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:PhoneNoErr})
        document.getElementById('PhoneNo').focus()
        return false
     }
    
     
     setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:''})

      return true
    
    }
    const Save_Handler = () =>{
        if(validate())
        {
            var SupplierData = {
                SupplierID: Supplier_Data.SupplierID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                SupplierCode:Supplier_Data.SupplierCode,
                SupplierName:Supplier_Data.SupplierName,
                Email:Supplier_Data.Email,
                PhoneNo:parseFloat(Supplier_Data.PhoneNo),
                NTN:parseFloat(Supplier_Data.NTN),
                SaleTaxNo:parseFloat(Supplier_Data.SaleTaxNo),
                CreatedDate:Supplier_Data.CreatedDate,
                ManufacturedByID: SelectedManufacturerOption.ManufacturedByID,
                // Remarks:Supplier_Data.Remarks, 
                // City:Supplier_Data.City,
                Address:Supplier_Data.Address,
                btn_Disabled:false
                // Company:Supplier_Data.Company,
            }
          
            setSupplier_Data({...Supplier_Data,btn_Disabled:true})
            
            SaveSuppliers(SupplierData, isAddNew,()=>{
                
                setSupplier_Data({...Supplier_Data,btn_Disabled:false})
                document.getElementById('btnCancel').click()
            })
        }
   
    }

    return ReactDOM.createPortal (
        <>
          <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title text-white" >
                        <h4>Supplier</h4>
                    </Col>
                </Row>
            <Card>
                <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="SupplierCode"
                            id="SupplierCode"
                            label="SupplierCode"
                            value={Supplier_Data.SupplierCode}
                            onChange={e => setSupplier_Data({ ...Supplier_Data, SupplierCode: e.target.value })}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="SupplierName"
                            id="SupplierName"
                            label="SupplierName"
                            value={Supplier_Data.SupplierName}
                            onChange={e=>setSupplier_Data({...Supplier_Data,SupplierName:e.target.value})}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                        <span className="text-danger">{Item_Err.Name_Err}</span>
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                    <Autocomplete
                        name="ManufacturedBy"
                        id="ManufacturedBy"
                        options={Manufacturer_List}
                        getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""} 
                        value={SelectedManufacturerOption}
                        onChange={(e,value) =>{
                        if(value)
                        {
                            setSelectedManufacturerOption(value)
                        
                        }
                        else
                        setSelectedManufacturerOption(null)
                            
                        }}
                        renderInput={(params) => 
                        <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                        />}
                    />
                    <span className="text-danger">{Item_Err.SUBUOM_Err}</span>
                </Col>
                        <Col lg={3} xl={3} md={3} >
                            <TextField
                                name="PhoneNo"
                                id="PhoneNo"
                                type="number"
                                label="PhoneNo"
                                value={Supplier_Data.PhoneNo}
                                onChange={e=>setSupplier_Data({...Supplier_Data,PhoneNo:e.target.value})}
                                fullWidth
                            />
                            <span className="text-danger">{Item_Err.PhoneNo_Err}</span>
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Email"
                                id="Email"
                                label="Email"
                                value={Supplier_Data.Email}
                                onChange={e=>setSupplier_Data({...Supplier_Data,Email:e.target.value})}
                                fullWidth
                            />
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="NTN"
                            id="NTN"
                            type="text"
                            label="NTN"
                            value={Supplier_Data.NTN}
                            onChange={e=>setSupplier_Data({...Supplier_Data,NTN:e.target.value})}
                            fullWidth
                        />
                    </Col>
{/*                     
                    <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="City"
                                id="City"
                                label="City"
                                value={Supplier_Data.City}
                                onInput={toInputUppercase}
                                onChange={e=>setSupplier_Data({...Supplier_Data,City:e.target.value})}
                                fullWidth
                            />
                        </Col> */}
                    <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="SaleTaxNo"
                                id="SaleTaxNo"
                                label="Tax No"
                                type="text"
                                value={Supplier_Data.SaleTaxNo}
                                onChange={e=>setSupplier_Data({...Supplier_Data,SaleTaxNo:e.target.value})}
                               
                                fullWidth
                            />
                        </Col>
                        {/* <Col lg={8} xl={8} md={8} >
                            <TextField
                                name="Remarks"
                                id="Remarks"
                                label="Remarks"
                                value={Supplier_Data.Remarks}
                                onInput={toInputUppercase}
                                onChange={e=>setSupplier_Data({...Supplier_Data,Remarks:e.target.value})}
                                fullWidth
                            />
                        </Col>  */}
                        {/* <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Company"
                                id="Company"
                                label="Company"
                                onInput={toInputUppercase}
                                value={Supplier_Data.Company}
                                onChange={e=>setSupplier_Data({...Supplier_Data,Company:e.target.value})}
                                fullWidth
                            />
                        </Col> */}
                       
                       
                      
                        <Col lg={8} xl={8} md={8} >
                            <TextField
                                name="Address"
                                id="Address"
                                label="Address"
                                onInput={toInputUppercase}
                                value={Supplier_Data.Address}
                                onChange={e=>setSupplier_Data({...Supplier_Data,Address:e.target.value})}
                                fullWidth
                            />
                        </Col>
                        </Row>
                 
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                    {btn_Edit && (
                        <button 
                        type="button" 
                        className="btn btn-primary"  
                        style={{border: "none"}} 
                        onClick={Save_Handler}
                        disabled={Supplier_Data.btn_Disabled}
                        > 
                        {Supplier_Data.btn_Disabled ? (<><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Saving... </>) : <> <Save /> Save </>}</button>)}
                        <Link id="btnCancel" className="btn btn-primary" style={{ border: "none" }} to='/SuppliersList' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                <Loader
                show={IsLoader}
                hide={hideLoader}
              />
            </Card>
        </> , document.getElementById('mainContent')
    )
}
 
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    SupplierID: state.Supplier && state.Supplier.SupplierID,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    isAddNew: state.Supplier && state.Supplier.isAddNew,
    UserID:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN
  })
export default connect (mapStateToProps)(SupplierEntry)
