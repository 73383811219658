import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import  { set_Payment } from '../../React_Redux/Actions'
import { SelectUserProjects } from '../../Services/Projects'
import { SelectPayments } from '../../Services/PaymentsAPI'
function PaymentsList(props) {

    const {  Project,Month,set_Payment,Comp_Id ,p_userid} = props;

    const [Month_Options, setMonth_Options] = useState([
        {value: 1, title: "JANUARY"},
        {value:2 , title: "FEBRUARY"}, 
        {value:3 , title: "MARCH"},
        {value:4 , title: "APRIL"},
        {value: 5, title: "MAY"},
        {value: 6, title: "JUNE"}, 
        {value: 7, title: "JULY"}, 
        {value: 8, title: "AUGUST"},
        {value: 9, title: "SEPTEMBER"},
        {value: 10, title: "OCTOBER"},
        {value: 11, title: "NOVEMBER"}, 
        {value: 12, title: "DECEMBER"}
])
const [SelectedMonth_Option, setSelectedMonth_Option] = useState(null)
const [ProjectData, setProjectData] = useState([])
const [SelectedProject, setSelectedProject] = useState(null)
const [PaymentsData, setPaymentsData] = useState([])
const [IsLoader, setIsLoader] = useState(false)
var [btn_Link, setbtn_Link] = useState('#')


useEffect(()=>{
    document.title = " Pharmacy / Payments"
    if(Month){
        setSelectedMonth_Option(Month)
        setSelectedProject(Project)
        Select_Payment_List(Month.value,Project.Project_ID)
        set_Payment(null,0,false)
    }
    else
    {
        var dateObj = new Date();
        var month = dateObj.getMonth() + 1;
        Month_Options.map(value => {
          if (value.value == month) {
            setSelectedMonth_Option(value)
          }
        })
    }
    Select_Projects()

  },[])
  const showLoader = () => {
    setIsLoader(true)
  }
  const hideLoader = () => {
    setIsLoader(false)
  }
const column_Payments = [
    {
     name: "PaymentNo",
     label: "Payment No",
     options: {
      filter: true,
      sort: true,
      customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       },  
      
     }
    },
    {
     name: "PaymentDate",
     label: "Payment Date",
     options: {
      filter: true,
      sort: true,
    customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       }, 
      }
    },
    {
     name: "PaymentType",
     label: "Payment Type",
     options: {
      filter: true,
      sort: true,
      customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       },
     }
    },
    {
        name: "SupplierName",
        label: "Supplier Name",
        options: {
         filter: true,
         sort: true,
         customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          },
        }
       },
     {
      name: "action",
      label: "action",
      options: {
       filter: true,
       sort: true,
       customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
        )
       },
       customBodyRender: (dataIndex, rowIndex) => {
         
         var PaymentID = PaymentsData
         if (PaymentID[rowIndex.rowIndex] != null)
           return (
             <Link to={"/PaymentEntry"} onClick={e => edit_Payment(PaymentsData[rowIndex.rowIndex].PaymentID)}> <Edit /></Link>
           );
       }
      }
     }
];

const edit_Payment = (PaymentID) =>{
    var P_Data = {SelectedMonth_Option, SelectedProject}
    set_Payment(P_Data,PaymentID,false) 
}

const add_Payment = () =>{
    if(SelectedProject && SelectedMonth_Option){
        var P_Data = {SelectedMonth_Option, SelectedProject}
        set_Payment(P_Data,0,true)
    }
    
}
const Select_Projects = () =>{
    SelectUserProjects(Comp_Id,p_userid,(mData)=>{
        setProjectData(mData)
    })
}

const Select_Payment_List = (Month,ProjectID) =>{
    setbtn_Link('/PaymentEntry')
    showLoader()
    SelectPayments(Comp_Id,ProjectID,Month,(mData)=>{
        hideLoader()
        setPaymentsData(mData)
    })
}
    return ReactDOM.createPortal(
        <>
            <Row className="row_margin_top row_margin_left row_margin_right" >
                <Col className="header_title text-white" >
                    <h4> Payments</h4>
                </Col>
            </Row>
            <Card>
                <Row className="row_margin_top row_margin_left row_margin_right p-4" >

                    <Col sm={12} lg={3} xl={3} md={3}>
                        <Autocomplete
                            id="Months"
                            options={Month_Options}
                            value={SelectedMonth_Option}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            getOptionSelected={(option, value) => option.title === value.title}
                            onChange={(e, option) => {
                                if (option) {
                                    setSelectedMonth_Option(option)
                                    if (SelectedProject)
                                    Select_Payment_List(option.value, SelectedProject.Project_ID)
                                }
                                else {
                                    setSelectedMonth_Option(null)
                                    setPaymentsData([])
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="Select Month" />}
                        />

                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3}>
                        <Autocomplete
                            id="Project"
                            options={ProjectData}
                            value={SelectedProject}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            getOptionSelected={(option, value) => option.Project_ID === value.Project_ID}
                            onChange={(e, option) => {
                                if (option) {
                                    setSelectedProject(option)
                                    if (SelectedMonth_Option)
                                        Select_Payment_List(SelectedMonth_Option.value, option.Project_ID)
                                }
                                else {
                                    setSelectedProject(null)
                                    setPaymentsData([])
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="Select Project" />}
                        />

                    </Col>

                    <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
                        <Link type="button" className="btn btn-primary" to={btn_Link} style={{ border: "none" }} onClick={add_Payment}> <Add />  Create New</Link>
                    </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col>
                        <div className="table-responsive" >
                            <MUIDataTable
                                name="Payments_Datatables"
                                data={PaymentsData}
                                columns={column_Payments}
                                options={{
                                    selectableRows: 'none',
                                    print: false,
                                    search: true,
                                    download: false,
                                    viewColumns: false,
                                    filter: false,
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Loader
                show={IsLoader}
                hide={hideLoader}
              />
            </Card>

        </>, document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Month:state.Payments.Payment_data && state.Payments.Payment_data.SelectedMonth_Option,
  Project:state.Payments.Payment_data && state.Payments.Payment_data.SelectedProject,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
 export default connect (mapStateToProps,{set_Payment})(PaymentsList)
