import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectSaleOrderLists =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/SaleOrder/SelectSaleOrderLists/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemDetails =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/SaleOrder/SelectItemDetails/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxSaleOrderNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/SaleOrder/GetMaxSaleOrderNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectSaleOrderMaster =(Comp_Id,ProjectID,SaleOrderID,Callback) =>{
  axios.get(`${domain}/SaleOrder/SelectSaleOrderMaster/${Comp_Id}/${ProjectID}/${SaleOrderID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectSaleOrderDetail =(Comp_Id,ProjectID,SaleOrderID,Callback) =>{
    axios.get(`${domain}/SaleOrder/SelectSaleOrderDetail/${Comp_Id}/${ProjectID}/${SaleOrderID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveSaleOrder = (SaleOrderMasterData,SaleOrderDetailData,isAddNew,callback) =>{

  axios.post(`${domain}/SaleOrder/SaveSaleOrder`,{
    SaleOrderMasterData,SaleOrderDetailData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

