import * as actionTypes from './../Actions/types'
import { combineReducers } from 'redux'

const initialLoginState ={
    User_Data: null,
    loginToken:null
}

const login_reducer = (state = initialLoginState, action) =>{
    switch(action.type){
        case actionTypes.LOGIN_USER : 
        return{
            ...state,
            User_Data: action.payload.loginData,
            loginToken: action.payload.token
        }
        default :
           return state
    }
}

const initialCompaniesState ={
    Companies: null,
}

const Companies = (state = initialCompaniesState, action) =>{

    switch (action.type) {
        case actionTypes.COMPANIES :
            
            return{
                ...state,
                Companies: action.payload.Companies
            }
    
        default:
           return state
    }
}

const initialGlobalVariableState ={
    Company: null,
    Financial_Year: null
}

const GlobalVariable = (state = initialGlobalVariableState, action) =>{

    switch (action.type) {
        case actionTypes.GLOBAL_VARIABLE :
            
            return{
                ...state,
                Company: action.payload.Company,
                Financial_Year: action.payload.Gl_Year
            }
    
        default:
           return state
    }
}



const initialDonnerState = {
    Donor: null
}
const Donner_reducer = (state = initialDonnerState, action) =>{
    switch(action.type){
        case actionTypes.DONOR : 
        return {
            DonorData:action.payload.DonorData,
            DonnerID:action.payload.d_id,
            IsAddNew:action.payload.isAddNew
        }
        default : 
          return state
    }
}

const initialReceiverState = {
    Receiver: null
}
const Receiver_reducer = (state = initialReceiverState, action) =>{
    switch(action.type){
        case actionTypes.RECEIVER : 
        return {
            ReceiverData:action.payload.ReceiverData,
            ReceiverID:action.payload.p_id,
            IsAddNew:action.payload.isAddNew
        }
        default : 
          return state
    }
}

const initialExchangerState = {
    Exchanger: null
}
const Exchanger_reducer = (state = initialExchangerState, action) =>{
    switch(action.type){
        case actionTypes.EXCHANGER : 
        return {
            ExchangerData:action.payload.ExchangerData,
            ExchangerID:action.payload.e_id,
            IsAddNew:action.payload.isAddNew
        }
        default : 
          return state
    }
}
const initialUserAccessData = { 
    UserAccess:null
}

const UserAccess_reducer = (state = initialUserAccessData, action) =>{
    switch(action.type){
        case actionTypes.USERACCESS :
            return {
                AccessData:action.payload.AccessData,
                Group_Id:action.payload.Group_Id,
                isAddNew:action.payload.FrmAdd
            }
    default : 
    return state
    }
}

const initialUsersState = { 
    Users:null
}

const Users_reducer = (state = initialUsersState, action)=>{
    switch(action.type){
        case actionTypes.USERS : 
        return {
            UsersData:action.payload.UsersData,
            UserID:action.payload.UserID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state 
    }
}



// ################################  --- OPD ---- ################################ //   

const initialOPD_RegState = { 
    OPD_Registration:null
}

const OPD_Reg_reducer = (state = initialOPD_RegState, action)=>{
    switch(action.type){
        case actionTypes.OPD_REGISTRATION : 
        return {
            RegData:action.payload.RegData,
            pt_id:action.payload.pt_id,
            isAddNew:action.payload.Frm_Add,
            AppointmentID:action.payload.AppointmentID
        }
        default : 
        return state 
    }
}
// ################################  --- IPD ---- ################################ //   

const initialIPD_RegState = { 
    IPD_Registration:null
}

const IPD_Reg_reducer = (state = initialIPD_RegState, action)=>{
    switch(action.type){
        case actionTypes.IPD_REGISTRATION : 
        return {
            RegData:action.payload.RegData,
            pt_id:action.payload.pt_id,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state 
    }
}
const initialDoctorVisits = { 
    Doctor_Visits:null
}

const Doctor_Visits_Reducer = (state = initialDoctorVisits, action)=>{
    switch(action.type){
        case actionTypes.DOCTOR_VISITS : 
        return {
            Visits_Data:action.payload.Visits_Data,
            VisitID:action.payload.VisitID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state 
    }
}
// ################################  --- Pharmacy ---- ################################ // 

const initial_Items = { 
    ItemsData : null
}

const Items_reducer = (state = initial_Items, action) =>{
    switch(action.type){
        case actionTypes.MEDICINE : 
        return { 
            ItemData: action.payload.ItemData,
            ItemID:action.payload.ItemID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}
// USED FOR NEW PROJECT 
const initial_Suppliers = { 
    SupplierData : null
}

const Supplier_reducer = (state = initial_Suppliers, action) =>{
    switch(action.type){
        case actionTypes.SUPPLIER : 
        return { 
            SupplierID:action.payload.SupplierID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}

const initial_Customers = { 
    CustomerData : null
}

const initial_CustomerReducer = {
    CustomerID: null,
    Frm_Add: null
}

const Customer_reducer = (state = initial_Customers, action) =>{
    switch(action.type){
        case actionTypes.CUSTOMER : 
        return { 
            CustomerID:action.payload.CustomerID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}
const Configuration_reducer = (state = initial_Customers, action) =>{
    switch(action.type){
        case actionTypes.CONFIGURATION : 
        return { 
            ConfigurationID:action.payload.ConfigurationID,
        }
        default : 
        return state
    }
}
const StockTransfer_reducer = (state = initial_Customers, action) =>{
    switch(action.type){
        case actionTypes.STOCKTRANSFER : 
        return { 
            StockTransferID:action.payload.StockTransferID,
            AddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}
const StockAdjustment_reducer = (state = initial_Customers, action) =>{
    switch(action.type){
        case actionTypes.STOCKADJUSTMENT : 
        return { 
            StockAdjustmentID:action.payload.StockAdjustmentID,
            AddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}
const initial_PO = { 
    POData : null
}
const initial_SO = { 
    Sale_Order: null,
    SaleOrderID: null,
    isAddNew:null,
    ProjectID:null
}

const PO_reducer = (state = initial_PO, action) =>{
    switch(action.type){
        case actionTypes.PURCHASE_ORDER : 
        return { 
            Purchase_Order : action.payload.PO_data,
            PurchaseOrderID:action.payload.PurchaseOrderID,
            isAddNew:action.payload.Frm_Add,
            Project_ID: action.payload.Project_ID
        }
        default : 
        return state
    }
}
const SO_reducer = (state = initial_SO, action) =>{
    switch(action.type){
        case actionTypes.SALE_ORDER : 
        return { 
            Sale_Order : action.payload.SO_data,
            SaleOrderID:action.payload.SaleOrderID,
            isAddNew:action.payload.Frm_Add,
            ProjectID: action.payload.ProjectID
        }
        default : 
        return state
    }
}

const initial_PI = { 
    PIData : null
}

const PI_reducer = (state = initial_PI, action) =>{
    switch(action.type){
        case actionTypes.PURCHASE_INVOICE : 
        return { 
            PI_data : action.payload.PI_data,
            PurchaseInvoiceID:action.payload.PurchaseInvoiceID,
            isAddNew:action.payload.Frm_Add,
            Project_ID: action.payload.Project_ID
        }
        default : 
        return state
    }
}

const initial_OPening = { 
    OPeningData : null
}

const OPening_reducer = (state = initial_OPening, action) =>{
    switch(action.type){
        case actionTypes.OPENING : 
        return { 
            OPening_data : action.payload.OPening_data,
            OPening_ID:action.payload.OPening_ID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}

const initial_SI = { 
    SIData : null
}

const SI_reducer = (state = initial_SI, action) =>{
    switch(action.type){
        case actionTypes.SALE_INVOICE : 
        return { 
            SI_data : action.payload.SI_data,
            SaleInvoiceID:action.payload.SaleInvoiceID,
            isAddNew:action.payload.Frm_Add,
            Project_ID: action.payload.Project_ID
        }
        default : 
        return state
    }
}

const initialTurnNo ={
    turn_no: null,
    Financial_Year: null
}

const Turn_No = (state = initialTurnNo, action) =>{

    switch (action.type) {
        case actionTypes.TURN_NO :
            
            return{
                ...state,
                turn_no: action.payload.turn_no
            }
    
        default:
           return state
    }
}


const initialShow_Screen ={
    add: null,
    edit: null,
    del: null,
    other: null,
}

const SHOW_SCREEN = (state = initialShow_Screen, action) =>{

    switch (action.type) {
        case actionTypes.SHOW_SCREEN :
            
            return{
                ...state,
                add: action.payload.add,
                edit: action.payload.edit,
                del: action.payload.del,
                other: action.payload.other,
            }
    
        default:
           return state
    }
}

const initial_Payments = { 
    PaymentsData : null
}

const Payment_reducer = (state = initial_Payments, action) =>{
    switch(action.type){
        case actionTypes.PAYMENTS : 
        return { 
            Payment_data : action.payload.Payment_data,
            PaymentID:action.payload.PaymentID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}

const initial_Receipts = { 
    ReceiptsData : null
}

const Receipt_reducer = (state = initial_Receipts, action) =>{
    switch(action.type){
        case actionTypes.RECEIPTS : 
        return { 
            Receipt_data : action.payload.Receipt_data,
            ReceiptID:action.payload.ReceiptID,
            isAddNew:action.payload.Frm_Add
        }
        default : 
        return state
    }
}

const initialGlobalProjectsState ={
    GlobalProjects: null,
}

const GlobalProjects_reducer = (state = initialGlobalProjectsState, action) =>{

    switch (action.type) {
        case actionTypes.GLOBAL_PROJECT :
            
            return{
                ...state,
                GlobalProjects: action.payload.GlobalProjects
            }
    
        default:
           return state
    }
}
const initialGlobalConfigState ={
    GlobalConfig: null,
}

const GlobalConfig_reducer = (state = initialGlobalConfigState, action) =>{

    switch (action.type) {
        case actionTypes.GLOBAL_CONFIG :
            
            return{
                ...state,
                GlobalConfig: action.payload.Global_Config
            }
    
        default:
           return state
    }
}

const initialGlobalProjectsListState ={
    GlobalProjectsList: null,
}

const GlobalProjectsList_reducer = (state = initialGlobalProjectsListState, action) =>{

    switch (action.type) {
        case actionTypes.GLOBAL_PROJECTS_LIST :
            
            return{
                ...state,
                GlobalProjectsList: action.payload.GlobalProjectsList
            }
    
        default:
           return state
    }
}
const initialPendingPatient ={
    PatientID: null
}

const Pending_Patient = (state = initialPendingPatient, action) =>{

    switch (action.type) {
        case actionTypes.PENDING_PATIENT:
            
            return{
                ...state,
                PatientID: action.payload.PatientID,
                Date: action.payload.Date
            }
    
        default:
           return state
    }
}
const initialGrid_Data ={
    Grid_Data: null
}

const Grid_Data = (state = initialGrid_Data, action) =>{
    switch (action.type) {
        case actionTypes.Grid_Data:
            return{
                ...state,
                Grid_Data: action.payload.Grid_Data,
            }
        default:
           return state
    }
}
const rootReducers = combineReducers({
    Login_User: login_reducer,
    Companies: Companies,
    GlobalVariables: GlobalVariable,
    Donner : Donner_reducer,
    Receiver : Receiver_reducer,
    Exchanger: Exchanger_reducer,
    UserAccess: UserAccess_reducer,
    Users:Users_reducer,
    OPD_Reg:OPD_Reg_reducer,
    IPD_Reg:IPD_Reg_reducer,
    DoctorVisits:Doctor_Visits_Reducer,
    Items : Items_reducer,
    Supplier:Supplier_reducer, // USED FOR NEW PROJECT
    Customer:Customer_reducer,
    Configuration:Configuration_reducer,
    StockTransfer:StockTransfer_reducer,
    StockAdjustment:StockAdjustment_reducer,
    PO:PO_reducer,
    SO:SO_reducer,
    PI:PI_reducer,
    Opening:OPening_reducer,
    SI:SI_reducer,
    Turn_No: Turn_No,
    SCREEN: SHOW_SCREEN,
    Payments:Payment_reducer,
    Receipts:Receipt_reducer,
    Global_Projects:GlobalProjects_reducer,
    Global_Configs:GlobalConfig_reducer,
    GlobalProjectsList:GlobalProjectsList_reducer,
    Pending_Patient: Pending_Patient,
    Grid_Data: Grid_Data,
});

export default rootReducers
