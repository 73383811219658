import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, ProgressBar } from "react-bootstrap"
import _Loader from '../Images/logo.jpg';

function Loader(props) {


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.hide}
                backdrop="static"
                dialogClassName="modal-90w"
                size="sm"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body className=" header_Color" style={{ background: 'trasparent', backgroundColor: props.isProgressBar ? "white" : "none" }} >
                    {
                        !props.isProgressBar ? (
                            <div style={{ marginLeft: '38%', marginTop: '-10%' }}>
                            <div className="content">
                                <div className="m-t-30"><img className="zmdi-hc-spin" src={_Loader} width={48} height={48} alt="bs" /></div>
                                <p style={{ marginLeft: '-10%' }} >Please wait...</p>
                            </div>
                        </div> 
                        ): (
                            <ProgressBar animated now={props.value} />
                        )
                    }
  
                </Modal.Body>

            </Modal>
        </>
    )
    }



export default Loader
