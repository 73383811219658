import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Save, Cancel } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';

//  import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
 import { connect} from 'react-redux'
// import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SelectTests , SaveTests } from '../../Services/TestAPI'


export class Tests extends React.Component {
  constructor(props){
      super(props)
      this.state = {
        Tests:[],
        DeletedTests:[],
       btn_Add: true,
       btn_Edit: true,
       btn_Delete: true,
       Others: null
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.grid = null
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.BGValidation = {
        required: [this.customBGValidation, 'Tests must be Entered'],
      };
  }
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
 
  customBGValidation(args) {
  return getValue('value', args) != ''   
  }

 
  componentDidMount()
  {
    document.title = " PHARMACY / Tests"
    // SelectGroupScreenAccess(this.props.Group_Id,10,87,(data)=>{
    //     this.setState({ btn_Add: parseInt(data.AddNew) === 0 ? false : true, 
    //                     btn_Edit: parseInt(data.Edit) === 0 ? false : true,
    //                     btn_Delete: parseInt(data.Del) === 0 ? false : true,
    //                     Others:data.OtherAccess})
    // })
 
 this.Select_Tests();
  }
  Select_Tests =() =>{

    SelectTests(this.Comp_Id,this.ProjectID,(mTests)=>{
        
        mTests.map((value,index)=>{
            mTests[index].SrNo = index + 1
        })
        if(mTests.length > 0 )
        this.setState({Tests:mTests})
    })
  }


  Tests = (rowValue) =>{
    return(<input
        style={{width:"80%"}}
        type="text"
        id="Tests"
        className="textbox"
        name='Tests'
        onInput={this.toInputUppercase}
        defaultValue={rowValue.Tests}
    />)
}




SaveHandler =()=>{
        if(this.state.Tests.length > 0 || this.state.DeletedTests.length > 0)
        {
            for (let i = 0; i < this.state.Tests.length; i++) {
                
                if(this.state.Tests[i].Tests === undefined || this.state.Tests[i].Tests === '')
                {
                    Swal.fire({
                        icon:'error',
                        text:`Tests at Sr. No. ${this.state.Tests[i].SrNo} is required`
                    })
                    return
                }
                
            }
            
            SaveTests(this.state.Tests,this.state.DeletedTests,()=>{
                this.Select_Tests();
            })
        }
        else
        { 
            Swal.fire({
                icon: 'info',
                text: `Tests Must be Entered...`,
            })
            return
        }
}


beforeRowInsert = (rowValue) =>{
    
    if(rowValue.rowData)
    {
        if(rowValue.data)
        {
            if(rowValue.data.SrNo)
            {
                if(rowValue.requestType != "cancel")
                {
                     rowValue.data.Comp_Id = this.Comp_Id
                     rowValue.data.ProjectID = this.ProjectID
                   rowValue.data.action = rowValue.action
                }
            }    
            else
            {
                rowValue.data.SrNo = this.state.Tests.length + 1
               
            }
        }
    }
  
}
afterRowInsert = (rowValue) =>{ 
 
    if(rowValue.requestType === 'delete')
    {
        if(rowValue.data[0].TestID)
        {
            this.setState({DeletedTests:this.state.DeletedTests.concat(rowValue.data)})
            
        }
    }
}

toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
} 

render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','Tests']);
         enableRipple(true);
    return ReactDOM.createPortal (
        <>
            <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>Tests 
                        </h2>
                    </div>
                </div>
            </div>
    <Card>     
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{width:'80%', marginLeft:'10%'}} >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}> 
                            <GridComponent dataSource={this.state.Tests} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: this.state.btn_Edit, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='Tests' headerText='Tests Name' editTemplate={this.Tests} textAlign='Left'/> 
                                    {/* <ColumnDirective width='200' field='b_no' headerText='Bed No'  editTemplate={this.b_no} textAlign='Left' /> */}
                                    
                                    
                                    <ColumnDirective field='TestID' headerText='TestID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='Comp_Id' headerText='Comp_Id'  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='ProjectID' headerText='ProjectID'  hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            <button type="button" className="btn btn-primary"  style={{border: "none"}} onClick={this.SaveHandler} > <Save /> <u>Save</u></button>
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'#'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})

export default connect(mapStateToProps)(Tests)