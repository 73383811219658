import React, {useEffect,useState } from 'react'
import reactDom from 'react-dom'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { TodaysSale,WeekSale,MonthSale,YearSale,TodaysExpense, ExpiredItems,BeforeExpiryItem} from '../../Services/DashboardAPI'
import { rptPayables } from '../../Services/Purchase_ReportAPI'
import { rptReciveables  } from '../../Services/Sale_ReportAPI';
import { SelectUserProjects } from '../../Services/Projects'
import { GetCurrentDate , numFormatter} from '../../Services/Common'
import { rptMostSoldItems,rptMinimumSoldItems } from '../../Services/Sale_ReportAPI'
import { rptPL,DirectExpenseForPL,OperativeExpenseForPL,OtherExpenseForPL,OtherIncomeForPL } from '../../Services/PL_ReportsAPI';
import { SelectItemsWRTMinStockForDashboard,SelectItemsWRTMaxStock } from '../../Services/PurchaseOrderAPI';
import { rptCashInBank, rptCashInHand } from '../../Services/FinanceReport_API';
import D1 from '../../Images/D1.PNG'
import D2 from '../../Images/D2.PNG'
function Index({Comp_Id,Project_ID,User_Id}) {

  const [_TodaysSale, set_TodaysSale] = useState(0);
  const [thisWeekSale, setThisWeekSale] = useState(0);
  const [thisMonthSale, setThisMonthSale] = useState(0);
  const [thisYearSale, setThisYearSale] = useState(0);
  const [Project_List, setProject_List] = useState([])
  var [SelectedProject, setSelectedProject] = useState(null)
  const [FromDate, setFromDate] = useState(GetCurrentDate())
  const [ToDate, setToDate] = useState(GetCurrentDate())
const [_Payables, set_Payables] = useState(0);
const [_Receivables, set_Receivables] = useState(0);
const [MostSoldItemData, setMostSoldItemData] = useState([]);
const [MinimunSoldItemData, setMinimunSoldItemData] = useState([]);
const [TotalMinQty, setTotalMinQty] = useState(0);
const [TotalMaxQty, setTotalMaxQty] = useState(0);
const [PL_Narration, setPL_Narration] = useState('Profit');
const [PL_Value, setPL_Value] = useState(0);
const [Min_Stock_Data, setMin_Stock_Data] = useState([])
const [Max_Stock_Data, setMax_Stock_Data] = useState([]);
const [Total_Cash_In_Hand, setTotal_Cash_In_Hand] = useState(0);
const [Total_Cash_In_Banks, setTotal_Cash_In_Banks] = useState(0);
const [Total_Todays_Expense, setTotal_Todays_Expense] = useState(0);
const [ExpiredItemsData, setExpiredItemsData] = useState([]);
const [BeforeExpiredItemsData, setBeforeExpiredItemsData] = useState([]);
// PL
const [Opening, setOpening] = useState(0.00)
  const [Purchase, setPurchase] = useState(0.00)
  const [PurchaseReturn, setPurchaseReturn] = useState(0.00)
  const [NetPurchase, setNetPurchase] = useState(0.00)
  const [Sale, setSale] = useState(0.00)
  const [SaleReturn, setSaleReturn] = useState(0.00)
  const [NetSale, setNetSale] = useState(0.00)
  const [ADJI, setADJI] = useState(0.00)
  const [ADJD, setADJD] = useState(0.00)
  const [Closing, setClosing] = useState(0.00)
  const [DirectExpenseData, setDirectExpenseData] = useState([])
  const [TotalDirectExpense, setTotalDirectExpense] = useState(0.00)
  const [OperativeExpenseData, setOperativeExpenseData] = useState([])
  const [TotalOperativeExpense, setTotalOperativeExpense] = useState(0.00)
  const [CGAS , setCGAS] = useState(0.00)
  const [CGS, setCGS] = useState(0.00)
  const [GP, setGP] = useState(0.00)
  const [NetProfit, setNetProfit] = useState(0.00)
  const [OtherIncomeData, setOtherIncomeData] = useState([])
  const [TotalOtherIncome, setTotalOtherIncome] = useState(0.00)
  const [TotalIncome, setTotalIncome] = useState(0.00)
  const [OtherExpenseData, setOtherExpenseData] = useState([])
  const [TotalOtherExpense, setTotalOtherExpense] = useState(0.00)
  const [NetIncome, setNetIncome] = useState(0.00)
// End PL

  useEffect(() => {
   document.title = "Pharmacy / Dashboard"
   TodaysSale(Comp_Id,GetCurrentDate(),(mTodaySale)=>{
     var _mtodaySale = numFormatter(mTodaySale[0].todaysale)
     set_TodaysSale(_mtodaySale)
   })
   WeekSale(Comp_Id,GetCurrentDate(),(mWeekSale)=>{
    var _mweekSale = numFormatter(mWeekSale[0].thisweaksale)
     setThisWeekSale(_mweekSale)
   })
   MonthSale(Comp_Id,GetCurrentDate(),(mMonthSale)=>{
    var _mMonthSale = numFormatter(mMonthSale[0].thismonthsale)
     setThisMonthSale(_mMonthSale)
   })
   YearSale(Comp_Id,GetCurrentDate(),(mYearSale)=>{
    var _mYearSale = numFormatter(mYearSale[0].thisyearsale)
     setThisYearSale(_mYearSale)
   })
   SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
    setProject_List(mProjects)
    setSelectedProject(mProjects[0])
    Payables(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    Receivables(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    MostSoldItems(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    MinimunSoldItems(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    ProfitAndLoss(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    MinimumStock(mProjects[0].Project_ID)
    MaxStock(mProjects[0].Project_ID)
    Cash_In_Hand(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    Cash_In_Banks(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
    Todays_Expense(mProjects[0].Project_ID)
    Expired_Items(mProjects[0].Project_ID)
    Items_to_be_Expired(mProjects[0].Project_ID)
    })
  }, []);
  
  const Payables = (Project_ID,FromDate,ToDate)=>{
    rptPayables(Comp_Id,Project_ID,FromDate,ToDate,(mPayables)=>{
      var Totals = mPayables.splice(-1);
        set_Payables(Totals[0].Balance.split('~')[1])
    })
  }
  const Receivables = (Project_ID,FromDate,ToDate) =>{
    
    rptReciveables(Comp_Id,Project_ID,FromDate,ToDate,(mReceivables)=>{
      
      if(mReceivables.length > 0){
        var Totals = mReceivables.splice(-1);
        var _Recivable = numFormatter(parseFloat(Totals[0].Balance.split('-')[1]))
        set_Receivables(_Recivable)
      }
    })
  }
  const MostSoldItems = (ProjectID,FromDate,ToDate)=>{
    rptMostSoldItems(Comp_Id,ProjectID,FromDate,ToDate,5,(mData)=>{
     if(mData.length > 0){
      var Totals = mData.splice(-1);
      setTotalMaxQty(Totals[0].Qty.split('-')[1])
      setMostSoldItemData(mData)
     }
  })
  }
  const MinimunSoldItems = (ProjectID,FromDate,ToDate)=>{
    rptMinimumSoldItems(Comp_Id,ProjectID,FromDate,ToDate,5,(mData)=>{
     if(mData.length > 0){
      var Totals = mData.splice(-1);
      setTotalMinQty(Totals[0].Qty.split('-')[1])
      setMinimunSoldItemData(mData)
     }
  })
  }
  const ProfitAndLoss  = (ProjectID,FromDate,ToDate)=>{
    
    rptPL(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
      
      if(mData.length > 0)
      {
        setOpening(mData[0].GrandTotal)
        setPurchase(mData[1].GrandTotal)
        setPurchaseReturn(mData[2].GrandTotal)
        setNetPurchase(mData[3].GrandTotal)
        setSale(mData[4].GrandTotal)
        setSaleReturn(mData[5].GrandTotal)
        setNetSale(mData[6].GrandTotal)
        setADJD(mData[7].GrandTotal)
        setADJI(mData[8].GrandTotal)
        setClosing(mData[9].GrandTotal)
        OperativeExpenseForPL(Comp_Id,ProjectID,FromDate,ToDate,(mOperative)=>{

          if(mOperative.length > 0){
            var OperativeTotal = mOperative.splice(-1)
            setTotalOperativeExpense(OperativeTotal[0].GrandTotal)
            setOperativeExpenseData(mOperative)
  
          }
          DirectExpenseForPL(Comp_Id,ProjectID,FromDate,ToDate,(MDirectExpense)=>{
            if(MDirectExpense.length > 0){
             var DirectTotal = MDirectExpense.splice(-1)
              setTotalDirectExpense(DirectTotal[0].GrandTotal)
              setCGAS(parseFloat(mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal).toFixed(2))
              setCGS(parseFloat((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)- mData[9].GrandTotal).toFixed(2) )
              setGP(parseFloat(((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal)).toFixed(2))
              setNetProfit(parseFloat((((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal))-(OperativeTotal[0].GrandTotal)).toFixed(2))
              setDirectExpenseData(MDirectExpense)
              
            }
            OtherIncomeForPL(Comp_Id,ProjectID,FromDate,ToDate,(mOtherIncome)=>{
             
              if(mOtherIncome.length > 0){
                var TotalOtherIncome = mOtherIncome.splice(-1)
                setTotalOtherIncome(TotalOtherIncome[0].GrandTotal)
                setTotalIncome(parseFloat(((((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal))-(OperativeTotal[0].GrandTotal))+(TotalOtherIncome[0].GrandTotal)).toFixed(2))
                setOtherIncomeData(mOtherIncome)
              }
              OtherExpenseForPL(Comp_Id,ProjectID,FromDate,ToDate,(mOtherExpenses)=>{
                if(mOtherExpenses.length > 0){
                  var TotalOtherExpenses = mOtherExpenses.splice(-1)
                  setTotalOtherExpense(TotalOtherExpenses[0].GrandTotal)
                  setOtherExpenseData(mOtherExpenses)
                  var netincome = parseFloat((((((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal))-(OperativeTotal[0].GrandTotal))+(TotalOtherIncome[0].GrandTotal))-(TotalOtherExpenses[0].GrandTotal)).toFixed(2)
                  setNetIncome(netincome)
                }
              })
            })
          })
   
  
        })
        // setPL_Narration(mData[0][8].Naration)
        // setPL_Value(mData[0][8].Total)
      }
   })
  }

  const MinimumStock = (ProjectID)=>{
    SelectItemsWRTMinStockForDashboard(Comp_Id,ProjectID,(mStockData)=>{
      var items =[]
      if(mStockData.length > 0){
       items =  mStockData.slice(0,5)
      }
      setMin_Stock_Data(items)
  })
  }
  const MaxStock = (ProjectID)=>{
    SelectItemsWRTMaxStock(Comp_Id,ProjectID,(mStockData)=>{
      var items =[]
      if(mStockData.length > 0){
       items =  mStockData.slice(0,5)
      }
      setMax_Stock_Data(items)
  })
  }
  const Cash_In_Hand = (ProjectID,FromDate,ToDate) =>{
    
    rptCashInHand(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
      if(mData.length > 0){
          var Totals = mData.splice(-1)
          if(Totals[0].PaidAmt != null)
          {
            var mCashinHand = numFormatter(Totals[0].PaidAmt)
            setTotal_Cash_In_Hand(mCashinHand)
          }
      }
   
   })
  }
  const Cash_In_Banks = (ProjectID,FromDate,ToDate) =>{
    
    rptCashInBank(Comp_Id,ProjectID,FromDate,ToDate,0,(mData)=>{
      if(mData.length > 0){
          var Totals = mData.splice(-1)
          if(Totals[0].PaidAmt != null){
            var mCashInBank = numFormatter(Totals[0].PaidAmt)
            setTotal_Cash_In_Banks(mCashInBank)
          }
      }
   
   })
  }
  const Todays_Expense = (ProjectID)=>{
    TodaysExpense(Comp_Id,ProjectID,GetCurrentDate(),(mData)=>{
      if(mData.length > 0){
        var mExpense = numFormatter(mData[0].TodayExpences)
        setTotal_Todays_Expense(mExpense)
      }
     
    })
  }
  const Expired_Items = (ProjectID) =>{
    ExpiredItems(Comp_Id,ProjectID,(mData)=>{
      setExpiredItemsData(mData)
    })
  }
  const Items_to_be_Expired = (ProjectID) =>{
    BeforeExpiryItem(Comp_Id,ProjectID,(mData)=>{
      setBeforeExpiredItemsData(mData)
    })
  }
    return reactDom.createPortal(
        <>
  <div className="block-header">
    <div className="row">
      <div className="col-lg-5 col-md-5 col-sm-12">
        <h2>Dashboard
          <small>Welcome to Lord's Tech Pharmacy Management System</small>
        </h2>
      </div>            
      <div className="col-lg-7 col-md-7 col-sm-12 text-right">
        {/* <div className="inlineblock text-center m-r-15 m-l-15 hidden-md-down">
          <div className="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-width={2} data-bar-spacing={5} data-bar-color="#fff">3,2,6,5,9,8,7,9,5,1,3,5,7,4,6</div>
          <small className="col-white">Visitors</small>
        </div>
        <div className="inlineblock text-center m-r-15 m-l-15 hidden-md-down">
          <div className="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-width={2} data-bar-spacing={5} data-bar-color="#fff">1,3,5,7,4,6,3,2,6,5,9,8,7,9,5</div>
          <small className="col-white">Bounce Rate</small>
        </div>
        <button className="btn btn-white btn-icon btn-round hidden-sm-down float-right m-l-10" type="button">
          <i className="zmdi zmdi-plus" />
        </button> */}
        <ul className="breadcrumb float-md-right">
          <li className="breadcrumb-item"><Link to="/Index"><i className="zmdi zmdi-home" /> Lord Tech</Link></li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="container-fluid">
    <div className="row clearfix">
      <div className="col-sm-12">
        <div className="card">
          <div className="body" style={{border:'none', outline:'none'}}>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="body" style={{border:'none', outline:'none'}}>
                  <TextField
                    name="FromDate"
                    label="From Date"
                    type='date'
                    value={FromDate}
                    onChange={e => {
                      
                      setFromDate(e.target.value)
                      Payables(SelectedProject.Project_ID,e.target.value,ToDate)
                      Receivables(SelectedProject.Project_ID,e.target.value,ToDate)
                      MostSoldItems(SelectedProject.Project_ID,e.target.value,ToDate)
                      MinimunSoldItems(SelectedProject.Project_ID,e.target.value,ToDate)
                      ProfitAndLoss(SelectedProject.Project_ID,e.target.value,ToDate)
                      Cash_In_Hand(SelectedProject.Project_ID,e.target.value,ToDate)
                      Cash_In_Banks(SelectedProject.Project_ID,e.target.value,ToDate)
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="body" style={{border:'none', outline:'none'}} >
                  <TextField
                    name="ToDate"
                    label="To Date"
                    type='date'
                    value={ToDate}
                    onChange={e => {
                      setToDate(e.target.value)
                      Payables(SelectedProject.Project_ID,FromDate,e.target.value)
                      Receivables(SelectedProject.Project_ID,FromDate,e.target.value)
                      MostSoldItems(SelectedProject.Project_ID,FromDate,e.target.value)
                      MinimunSoldItems(SelectedProject.Project_ID,FromDate,e.target.value)
                      ProfitAndLoss(SelectedProject.Project_ID,FromDate,e.target.value)
                      Cash_In_Hand(SelectedProject.Project_ID,FromDate,e.target.value)
                      Cash_In_Banks(SelectedProject.Project_ID,FromDate,e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="body" style={{border:'none', outline:'none'}}>
                  <Autocomplete
                    name="Project"
                    id="Project"
                    options={Project_List}
                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                    value={SelectedProject}
                    onChange={(e, value) => {
                        if (value) {
                            setSelectedProject(value)
                            Payables(value.Project_ID,FromDate,ToDate)
                            Receivables(value.Project_ID,FromDate,ToDate)
                            MostSoldItems(value.Project_ID,FromDate,ToDate)
                            MinimunSoldItems(value.Project_ID,FromDate,ToDate)
                            ProfitAndLoss(value.Project_ID,FromDate,ToDate)
                            MinimumStock(value.Project_ID)
                            MaxStock(value.Project_ID)
                            Cash_In_Hand(value.Project_ID,FromDate,ToDate)
                            Cash_In_Banks(value.Project_ID,FromDate,ToDate)
                            Todays_Expense(value.Project_ID)
                            Expired_Items(value.Project_ID)
                            Items_to_be_Expired(value.Project_ID)
                       
                        }
                        else{
                            setSelectedProject(null)
                        }
                    }}
                    renderInput={(params) =>
                      <TextField {...params} name="Project" label="Project"
                      />
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="body" style={{border:'none', outline:'none'}}>
                <Link target={"_blank"} to={`/Payables/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}><h2 className="number count-to m-t-0 m-b-5" data-from={0} data-to={_Payables} data-speed={1000} data-fresh-interval={700}><u>{_Payables}</u></h2></Link>
                  <p className="text-muted">Total Payables</p>
                  <img  src={D1} alt="D1" />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="body" style={{border:'none', outline:'none'}}>
                <Link target={"_blank"} to={`/Receiveable/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}><h2 className="number count-to m-t-0 m-b-5" data-from={0} data-to={_Receivables} data-speed={2000} data-fresh-interval={700}><u>{_Receivables}</u></h2></Link>
                  <p className="text-muted ">Total Receivables</p>
                  <img  src={D2} alt="D2" />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="body" style={{border:'none', outline:'none'}}>
                <Link target={"_blank"} to={`/PLReport/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}> <h2 className="number count-to m-t-0 m-b-5" data-from={0} data-to={NetIncome} data-speed={2000} data-fresh-interval={700}><u>{NetIncome}</u></h2></Link>
                  <p className="text-muted">Total {PL_Narration}</p>
                  <img  src={D1} alt="D1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row clearfix">
      <div className="col-lg-12">
        <div className="card">
          <div className="header">
            <h2><strong>Sales</strong> Report</h2>
            <ul className="header-dropdown">
              {/* <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more" /> </a>
                <ul className="dropdown-menu slideUp float-right">
                  <li><a href="javascript:void(0);">Edit</a></li>
                  <li><a href="javascript:void(0);">Delete</a></li>
                  <li><a href="javascript:void(0);">Report</a></li>
                </ul>
              </li> */}
              <li className="remove">
                <a role="button" className="boxs-close"><i className="zmdi zmdi-close" /></a>
              </li>
            </ul>
          </div>
          <div className="body" style={{border:'none', outline:'none'}}>
            <div className="row text-center">
              <div className="col-sm-3 col-6">
                <h4 className="margin-0">PKR {_TodaysSale} <i className="zmdi zmdi-trending-up col-green" /></h4>
                <p className="text-muted"> Today's Sales</p>
              </div>
              <div className="col-sm-3 col-6">
                <h4 className="margin-0">PKR {thisWeekSale} <i className="zmdi zmdi-trending-down col-red" /></h4>
                <p className="text-muted">This Week's Sales</p>
              </div>
              <div className="col-sm-3 col-6">
                <h4 className="margin-0">PKR {thisMonthSale} <i className="zmdi zmdi-trending-up col-green" /></h4>
                <p className="text-muted">This Month's Sales</p>
              </div>
              <div className="col-sm-3 col-6">
                <h4 className="margin-0">PKR {thisYearSale}<i className="zmdi zmdi-trending-up col-green" /></h4>
                <p className="text-muted">This Year's Sales</p>
              </div>
            </div>
            {/* <div id="area_chart" className="graph" /> */}
          </div>
        </div>
      </div>
    </div>
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        {/* <div className="card visitors-map">
          <div className="header">
            <h2><strong>Visitors</strong> Statistics</h2>
            <ul className="header-dropdown">
              <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more" /> </a>
                <ul className="dropdown-menu slideUp">
                  <li><a href="javascript:void(0);">Action</a></li>
                  <li><a href="javascript:void(0);">Another action</a></li>
                  <li><a href="javascript:void(0);">Something else</a></li>
                </ul>
              </li>
              <li className="remove">
                <a role="button" className="boxs-close"><i className="zmdi zmdi-close" /></a>
              </li>
            </ul>                        
          </div>
          <div className="body">
            <div id="world-map-markers" style={{height: 245}} className="m-b-10" />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="progress-container">
                  <span className="progress-badge">visitor from america</span>
                  <div className="progress">
                    <div className="progress-bar l-turquoise" role="progressbar" aria-valuenow={86} aria-valuemin={0} aria-valuemax={100} style={{width: '86%'}}>
                      <span className="progress-value">86%</span>
                    </div>
                  </div>
                </div>
                <div className="progress-container m-t-20">
                  <span className="progress-badge">visitor from Canada</span>
                  <div className="progress">
                    <div className="progress-bar l-coral" role="progressbar" aria-valuenow={86} aria-valuemin={0} aria-valuemax={100} style={{width: '86%'}}>
                      <span className="progress-value">86%</span>
                    </div>
                  </div>
                </div>
                <div className="progress-container m-t-20">
                  <span className="progress-badge">visitor from Germany</span>
                  <div className="progress">
                    <div className="progress-bar l-blue" role="progressbar" aria-valuenow={38} aria-valuemin={0} aria-valuemax={100} style={{width: '38%'}}>
                      <span className="progress-value">86%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="progress-container">
                  <span className="progress-badge">visitor from UK</span>
                  <div className="progress">
                    <div className="progress-bar l-salmon" role="progressbar" aria-valuenow={48} aria-valuemin={0} aria-valuemax={100} style={{width: '48%'}}>
                      <span className="progress-value">86%</span>
                    </div>
                  </div>
                </div>
                <div className="progress-container m-t-20">
                  <span className="progress-badge">visitor from India</span>
                  <div className="progress">
                    <div className="progress-bar l-parpl" role="progressbar" aria-valuenow={86} aria-valuemin={0} aria-valuemax={100} style={{width: '86%'}}>
                      <span className="progress-value">86%</span>
                    </div>
                  </div>
                </div>
                <div className="progress-container m-t-20">
                  <span className="progress-badge">visitor from Australia</span>
                  <div className="progress">
                    <div className="progress-bar l-amber" role="progressbar" aria-valuenow={55} aria-valuemin={0} aria-valuemax={100} style={{width: '55%'}}>
                      <span className="progress-value">86%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row clearfix social-widget">                   
          <div className="col-lg-4 col-md-4">
            <div className="card info-box-2 hover-zoom-effect instagram-widget">
              <div className="icon"><i className="zmdi zmdi-money-box" /></div>
              <div className="content">
                <div className="text">Cash in Hand</div>
                <Link target={"_blank"} to={`/CashInHandReport/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}><div className="number" ><u>{Total_Cash_In_Hand}</u></div></Link>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-4">
            <div className="card info-box-2 hover-zoom-effect behance-widget">
              <div className="icon"><i className="zmdi zmdi-balance" /></div>
              <div className="content">
                <div className="text">Cash in Banks</div>
                <Link target={"_blank"} to={`/CashInBankReport/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}/0`}><div className="number"><u>{Total_Cash_In_Banks}</u></div></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="card info-box-2 hover-zoom-effect linkedin-widget">
              <div className="icon"><i className="zmdi zmdi-trending-down" /></div>
              <div className="content">
                <div className="text">Today's Expense</div>
                <div className="number">{Total_Todays_Expense}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div className="row clearfix">
      {/* <div className="col-lg-8 col-md-12">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <ul className="row profile_state list-unstyled">
                <li className="col-lg-3 col-md-3 col-6">
                  <div className="body">
                    <i className="zmdi zmdi-eye col-amber" />
                    <h4>15,453</h4>
                    <span>Post View</span>
                  </div>
                </li>
                <li className="col-lg-3 col-md-3 col-6">
                  <div className="body">
                    <i className="zmdi zmdi-thumb-up col-blue" />
                    <h4>921</h4>
                    <span>Likes</span>
                  </div>
                </li>
                <li className="col-lg-3 col-md-3 col-6">
                  <div className="body">
                    <i className="zmdi zmdi-comment-text col-red" />
                    <h4>215</h4>
                    <span>Comments</span>
                  </div>
                </li>
                <li className="col-lg-3 col-md-3 col-6">
                  <div className="body">
                    <i className="zmdi zmdi-account text-success" />
                    <h4>22,055</h4>
                    <span>Profile Views</span>
                  </div>
                </li>                      
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="card tasks_report">
              <div className="header">
                <h2><strong>Total</strong> Revenue</h2>                        
                <ul className="header-dropdown">
                  <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more" /> </a>
                    <ul className="dropdown-menu slideUp">
                      <li><a href="javascript:void(0);">2017 Year</a></li>
                      <li><a href="javascript:void(0);">2016 Year</a></li>
                      <li><a href="javascript:void(0);">2015 Year</a></li>
                    </ul>
                  </li>
                  <li className="remove">
                    <a role="button" className="boxs-close"><i className="zmdi zmdi-close" /></a>
                  </li>
                </ul>
              </div>
              <div className="body text-center">
                <h4 className="margin-0">Total Sale</h4>
                <h6 className="m-b-20">2,45,124</h6>
                <input type="text" className="knob dial1" defaultValue={99} data-width={100} data-height={100} data-thickness="0.1" data-fgcolor="#212121" readOnly />
                <h6 className="m-t-20">Satisfaction Rate</h6>
                <small className="displayblock">47% Average <i className="zmdi zmdi-trending-up" /></small>
                <div className="sparkline m-t-20" data-type="bar" data-width="97%" data-height="35px" data-bar-width={2} data-bar-spacing={8} data-bar-color="#212121">3,2,6,5,9,8,7,8,4,5,1,2,9,5,1,3,5,7,4,6</div>
              </div>
            </div>                    
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="card top-report">
              <div className="body">
                <h3 className="m-t-0">1,600 <i className="zmdi zmdi-trending-up float-right" /></h3>
                <p className="text-muted">New Feedbacks</p>
                <div className="progress">
                  <div className="progress-bar l-blush" role="progressbar" aria-valuenow={68} aria-valuemin={0} aria-valuemax={100} style={{width: '68%'}} />
                </div>
                <small>Change 15%</small>
              </div>
            </div>
            <div className="card top-report">
              <div className="body">
                <h3 className="m-t-0 m-b-25">50.5 Gb <i className="zmdi zmdi-trending-up float-right" /></h3>
                <p className="text-muted">Traffic this month</p>
                <div className="progress">
                  <div className="progress-bar l-turquoise" role="progressbar" aria-valuenow={68} aria-valuemin={0} aria-valuemax={100} style={{width: '68%'}} />
                </div>
                <small>Change 5%</small>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="col-lg-6 col-md-12">
        <div className="card weather2">
          <div className="city-selected body" style={{backgroundColor:'#8C99E0',border:'none', outline:'none'}}>
            <div className="row" >
              <div className="col-12">
                <div className="city text-white"> Most Sold Items</div>
                <div className="night text-white">Top 5 QtyWise</div>
              </div>
              <div className="info col-7">		
                <Link to={`/MostSoldItems/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}/5`} target={"_blank"}> <div className="temp text-white"><h4><u>{TotalMaxQty}</u></h4></div></Link> 						
              </div>
              {/* <div className="icon col-5">
                <img src="../assets/images/weather/summer.svg" alt />
              </div> */}
            </div>
          </div>
          <table className="table table-striped m-b-0">
            <tbody>
              {
                MostSoldItemData.length > 0 ?
                MostSoldItemData.map((value,index)=>(
                 <>
                  <tr key={index}>
                  <td>{value.ItemDiscription}</td>
                  <td className="font-medium">{value.Qty}</td>
                </tr>
                </>
                  ))
                  :
                  <>
                  <tr>
                  <td style={{backgroundColor:'white'}}>No Items Found</td>
                </tr>
                  </>
              }
          
            </tbody>
          </table>
          {/* <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item text-center active">
                <div className="col-12">
                  <ul className="row days-list list-unstyled">
                    <li className="day col-4">
                      <p>Monday</p>
                      <img src="../assets/images/weather/rain.svg" alt />
                    </li>
                    <li className="day col-4">
                      <p>Tuesday</p>
                      <img src="../assets/images/weather/cloudy.svg" alt />
                    </li>
                    <li className="day col-4">
                      <p>Wednesday</p>
                      <img src="../assets/images/weather/wind.svg" alt />
                    </li>
                  </ul>
                </div>                                
              </div>
              <div className="carousel-item text-center">
                <div className="col-12">
                  <ul className="row days-list list-unstyled">
                    <li className="day col-4">
                      <p>Thursday</p>
                      <img src="../assets/images/weather/sky.svg" alt />
                    </li>
                    <li className="day col-4">
                      <p>Friday</p>
                      <img src="../assets/images/weather/cloudy.svg" alt />
                    </li>
                    <li className="day col-4">
                      <p>Saturday</p>
                      <img src="../assets/images/weather/summer.svg" alt />
                    </li>
                  </ul>
                </div>
              </div>							
            </div>
          </div>                     */}
        </div>
      </div>
      <div className="col-lg-6 col-md-12">
        <div className="card weather2">
          <div className="city-selected body" style={{backgroundColor:'#8C99E0',border:'none', outline:'none'}}>
            <div className="row">
              <div className="col-12">
                <div className="city text-white"> Minimum Sold Items</div>
                <div className="night text-white">Top 5 QtyWise</div>
              </div>
              <div className="info col-7">
              <Link to={`/MinimumSoldItems/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}/5`} target={"_blank"}> <div className="temp text-white"><h4><u>{TotalMinQty}</u></h4></div></Link> 							
              </div>
            </div>
          </div>
          <table className="table table-striped m-b-0">
            <tbody>
            {
                MinimunSoldItemData.length > 0 ?
                MinimunSoldItemData.map((value,index)=>(
                 <>
                  <tr key={index}>
                  <td>{value.ItemDiscription}</td>
                  <td className="font-medium">{value.Qty}</td>
                </tr>
                </>
                  ))
                  :
                  <>
                  <tr>
                  <td style={{backgroundColor:'white'}}>No Items Found</td>
                </tr>
                  </>
              }
            </tbody>
          </table>
                 </div>
      </div>
      <div className="col-lg-6 col-md-12">
        <div className="card">
          <div className="header">
            <h2><strong>Minimum Stock</strong></h2>
            {/* <ul className="header-dropdown">
              <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more" /> </a>
                <ul className="dropdown-menu">
                  <li><a href="javascript:void(0);">Action</a></li>
                  <li><a href="javascript:void(0);">Another action</a></li>
                  <li><a href="javascript:void(0);">Something else</a></li>
                </ul>
              </li>
              <li className="remove">
                <a role="button" className="boxs-close"><i className="zmdi zmdi-close" /></a>
              </li>
            </ul> */}
          </div>
          <div className="body" style={{border:'none', outline:'none'}}>
            <table className="table m-t-5 m-b-0">
              <thead>
                <tr style={{fontWeight:'bold'}}>
                  <td> Item </td>
                  <td> UOM </td>
                  <td> Qty</td>
                </tr>
              </thead>
              <tbody>
                {
                  Min_Stock_Data.length > 0 ?
                  Min_Stock_Data.map((value,index)=>(
                    <>
                      <tr key={index}>
                        <td>{value.ItemDiscription}</td>
                        <td>{value.UOM}</td>
                        <td>{value.StockQty}</td>
                      </tr>
                    </>
                  ))
                  :
                      <tr >
                        <td style={{backgroundColor:'white'}}>No Data Found</td>
                      </tr>
                }
               
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12">
        <div className="card">
          <div className="header">
            <h2><strong>Maximum Stock</strong></h2>
          </div>
          <div className="body" style={{border:'none', outline:'none'}}>
            <table className="table m-t-5 m-b-0">
              <thead>
                <tr style={{fontWeight:'bold'}}>
                  <td> Item </td>
                  <td> UOM </td>
                  <td> Qty</td>
                </tr>
              </thead>
              <tbody>
                {
                  Max_Stock_Data.length > 0 ?
                  Max_Stock_Data.map((value,index)=>(
                    <>
                      <tr key={index}>
                        <td>{value.ItemDiscription}</td>
                        <td>{value.UOM}</td>
                        <td>{value.StockQty}</td>
                      </tr>
                    </>
                  ))
                  :
                      <tr >
                        <td style={{backgroundColor:'white'}}>No Data Found</td>
                      </tr>
                }
               
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>        
  
    <div className="row clearfix">
      <div className="col-sm-6 col-md-6 col-lg-6">
        <div className="card">
          <div className="header">
            <h2><strong>Expired Items</strong><small></small> </h2>
            {/* <ul className="header-dropdown">
              <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more" /> </a>
                <ul className="dropdown-menu slideUp">
                  <li><a href="javascript:void(0);">Action</a></li>
                  <li><a href="javascript:void(0);">Another action</a></li>
                  <li><a href="javascript:void(0);">Something else</a></li>
                </ul>
              </li>
              <li className="remove">
                <a role="button" className="boxs-close"><i className="zmdi zmdi-close" /></a>
              </li>
            </ul> */}
          </div>
          <div className="body table-responsive members_profiles" style={{border:'none', outline:'none'}}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Code</th>
                  <th >Item</th>
                  <th>StockQty</th>
                  <th>Expiry Date </th>  
                </tr>
              </thead>
              <tbody>
                {
                  ExpiredItemsData.length > 0 ?
                  ExpiredItemsData.map((value,index)=>(
                  <>
                   <tr key={index}>
                    <td>{value.ItemCode}</td>
                    <td>{value.ItemDiscription}</td>
                    <td>{value.StockQty}</td>                                   
                    <td>{value.Expairy_Date} </td>
                </tr>
                </>
                  ))
                  :
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                }
               
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6">
        <div className="card">
          <div className="header">
            <h2><strong>Near Expiry Items</strong><small>Items to be expired Soon</small> </h2>
          </div>
          <div className="body table-responsive members_profiles" style={{border:'none', outline:'none'}}> 
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Code</th>
                  <th >Item</th>
                  <th>StockQty</th>
                  <th>Expiry Date </th>  
                </tr>
              </thead>
              <tbody>
                {
                  BeforeExpiredItemsData.length > 0 ?
                  BeforeExpiredItemsData.map((value,index)=>(
                  <>
                   <tr key={index}>
                    <td>{value.ItemCode}</td>
                    <td>{value.ItemDiscription}</td>
                    <td>{value.StockQty}</td>                                   
                    <td>{value.Expairy_Date} </td>
                </tr>
                </>
                  ))
                  :
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                }
               
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

        </>, document.getElementById("mainContent")
    )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id

})
export default connect(mapStateToProps)(Index)
