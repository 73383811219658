import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { DischargeSlip_Report, SelectIPDBillServices,SelectDoctorandVisistsDetails, SelectIPDBillMedicains } from "../../Services/IPDBillingAPI"
// import { SelectDoctorandVisistsDetails } from '../../Services/IPDBillingAPI'
import { ThousandSeparater } from "../../Services/Common"
import './ReportArchitect.css' 
import { connect } from "react-redux"
import { BorderColor } from '@material-ui/icons'

function IPDBilling_Report({Comp_Id, Comp_Name, Comp_Address}) {

    const { Id } = useParams()
    const [CompanyName, setCompanyName] = useState(Comp_Name)
    const [Address, setAddress] = useState(Comp_Address)
    const [PhoneNo, setPhoneNo] = useState("")
    const [Patient_Name, setPatient_Name] = useState("")
    const [Patient_Age, setPatient_Age] = useState("")
    const [Patient_Gardien, setPatient_Gardien] = useState("")
    const [Patient_Gender, setPatient_Gender] = useState("")
    const [Patient_Address, setPatient_Address] = useState("")
    const [date, setDate] = useState("")
    const [Doctor, setDoctor] = useState("")
    const [Ward, setWard] = useState("")
    const [RoomNo, setRoomNo] = useState("")
    const [BedNo, setBedNo] = useState("")
    const [Doctor_Fees_Per_Visit, setDoctor_Fees_Per_Visit] = useState("")
    const [Total_Visit, setTotal_Visit] = useState("")
    const [Total_Doctor_Fees, setTotal_Doctor_Fees] = useState("")
    const [Room_Rent_Per_Day, setRoom_Rent_Per_Day] = useState("")
    const [Total_Days, setTotal_Days] = useState("")
    const [Total_Room_Rent, setTotal_Room_Rent] = useState("")
    const [FirstTime_Registration, setFirstTime_Registration] = useState("")
    const [Total_Discount, setTotal_Discount] = useState("")
    const [TotalAmount, setTotalAmount] = useState("")
    
    useEffect(() => {
        document.getElementById('TopBar').hidden = true
        document.getElementById('leftsidebar').hidden = true
        document.getElementById('rightsidebar').hidden = true
        document.getElementById('chat-wrapper').hidden = true
        document.getElementById('mainContent').hidden = true

        DischargeSlip_Report( Id, (data) => {
            setPatient_Name(data[0].Patient)
            setPatient_Age(data[0].Age)
            setPatient_Gardien(data[0].Guardient)
            setPatient_Gender(data[0].Gender)
            setPatient_Address(data[0].Address)
            setDate(data[0].DateTime)
            setDoctor(data[0].Doctor)
            setWard(data[0].Ward)
            setRoomNo(data[0].Room)
            setBedNo(data[0].BedNo)
            setTotal_Doctor_Fees(ThousandSeparater((data[0].TotalDoctorFee).toFixed(2)))
            setRoom_Rent_Per_Day(ThousandSeparater((data[0].RoomRentPerDay).toFixed(2)))
            setTotal_Days(data[0].TotalDays)
            setTotal_Room_Rent(ThousandSeparater((data[0].TotalRoomRent).toFixed(2)))
            setFirstTime_Registration(ThousandSeparater((data[0].RegistrationFee).toFixed(2)))
            setTotal_Discount(ThousandSeparater((data[0].Discount).toFixed(2)))
            setTotalAmount(ThousandSeparater((data[0].GrossTotal).toFixed(2)))
            
            SelectDoctorandVisistsDetails(Comp_Id,data[0].ProjectID,data[0].RegistrationNo,(mData1)=>{
      
                loadTableData_DocVisits(mData1)
                // let sum_Visits = 0;
                // let doc_fee = 0;
                // mData1.map(value=>{
                //     sum_Visits += parseInt(value.v_Visits);
                //     doc_fee += parseFloat(value.u_fees) ; 
                // })
                // setTotal_Visit(sum_Visits)
                // setDoctor_Fees_Per_Visit(ThousandSeparater((doc_fee).toFixed(2)))
            })

            SelectIPDBillServices(data[0].RegistrationID,(data)=>{
                
                
                loadTableData_Patient(data)
            })
            SelectIPDBillMedicains(data[0].RegistrationID, (mData)=>{
                
                loadTableData_Medicain(mData)
            })
        })

        // setTimeout(() => {
        //     window.print()
        // }, 1500);
    }, [])

    function loadTableData_Patient(items) {
         
        const table = document.getElementById("Patient_body");
      
       
        var sum = 0;
        items.forEach( (item, index) => {
          
            sum += item.TotalPrice
          let row = table.insertRow();
         
          let ServiceName = row.insertCell(0);
          ServiceName.innerHTML = item.Services;
          let QTy = row.insertCell(1);
          QTy.innerHTML = item.Qty;
          let Price = row.insertCell(2);
          Price.innerHTML = item.Price;
          let TotalPrice = row.insertCell(3);
          TotalPrice.innerHTML = item.TotalPrice;
          
        });

        const footer = document.getElementById("Patient_Foot")
        let row = footer.insertRow();
        let _Total = row.insertCell(0);
        _Total.innerHTML = `Total`
        _Total.colSpan = "3"
        _Total.style.textAlign = 'right'

        let _TotalDr_Fees = row.insertCell(1);
        _TotalDr_Fees.innerHTML = `${sum}`
      }

      function loadTableData_Medicain(items) {
         
        const table = document.getElementById("Medicain_body");
      
        var sum = 0;
        items.forEach( (item, index) => {
          
            sum += item.TotalPrice
          let row = table.insertRow();
          let ItemName = row.insertCell(0);
          ItemName.innerHTML = item.ItemDiscription;
          let QTy = row.insertCell(1);
          QTy.innerHTML = item.Qty;
          let Price = row.insertCell(2);
          Price.innerHTML = item.Price;
          let TotalPrice = row.insertCell(3);
          TotalPrice.innerHTML = item.TotalPrice;
          
        });

        const footer = document.getElementById("Medicain_Foot")
        let row = footer.insertRow();
        let _Total = row.insertCell(0);
        _Total.innerHTML = `Total`
        _Total.colSpan = "3"
        _Total.style.textAlign = 'right'
        let _TotalDr_Fees = row.insertCell(1);
        _TotalDr_Fees.innerHTML = `${sum}`
      }
      function loadTableData_DocVisits(items) {
         
        const table = document.getElementById("visits_body");
      
        var sum = 0,sumVisits=0;
        items.forEach( (item, index) => {
          
            sum += item.DoctorFee
            sumVisits += item.Visits

          let row = table.insertRow();
          let DocName = row.insertCell(0);
          DocName.innerHTML = item.Doctor;
          let VisitDate = row.insertCell(1);
          VisitDate.innerHTML = item.VisitDate;
          let v_Visits = row.insertCell(2);
          v_Visits.innerHTML = item.Visits;
          let u_fees = row.insertCell(3);
          u_fees.innerHTML = item.DoctorFee;
          
        });

        const footer = document.getElementById("visit_Foot")
        let row = footer.insertRow();
        let _Total = row.insertCell(0);
        _Total.innerHTML = `Total`
        _Total.colSpan = "2"
        _Total.style.textAlign = 'right'

        let _Total_visit = row.insertCell(1);
        _Total_visit.innerHTML = `${sumVisits}`
        let _Total_Fees = row.insertCell(2);
        _Total_Fees.innerHTML = `${sum}`

      }
    return (
        <div style={{backgroundColor:'white'}}>
            <form>
                <Row>
                    <Col className="form_title">
                        <div> {CompanyName} </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={4} ></Col>
                    <Col  xl={4} lg={4} md={4} sm={4} style={{textAlign:'center'}}>
                        <div>Address: &nbsp; &nbsp; {Address} </div>
                        <div> Phone No: &nbsp; &nbsp; {PhoneNo} </div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} ></Col>
                </Row>
                <Row>
                    <Col className="background-color" >
                        <h6> Patient Bill Recipt </h6>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="Patient-Detail">
                        <div className="title" >
                            &nbsp;&nbsp;  Patient Details &nbsp;&nbsp; (مریض کی تفصیلات)
                        </div>
                        <div className="body" >
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Patient Name: </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6}  > {Patient_Name} </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }}>Age:</Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} > {Patient_Age} </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Gardian:</Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} > {Patient_Gardien} </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Gender:</Col>
                                <Col xl={8} lg={8} md={8} sm={8} xs={8} > {Patient_Gender} </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Address:</Col>
                                <Col xl={8} lg={8} md={8} sm={8} xs={8} > {Patient_Address} </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="Patient-Detail">
                        <div className="title" >
                            &nbsp;&nbsp;  Hospital Details &nbsp;&nbsp; (ہسپتال کی تفصیلات)
                        </div>
                        <div className="body" >
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }}>Date:</Col>
                                <Col xl={3} lg={3} md={3} sm={3} xs={3} > {date} </Col>
                            </Row>
                          
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Ward:</Col>
                                <Col xl={8} lg={8} md={8} sm={8} xs={8} > {Ward} </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Room No:</Col>
                                <Col xl={8} lg={8} md={8} sm={8} xs={8} > {RoomNo} </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }} >Bed No:</Col>
                                <Col xl={8} lg={8} md={8} sm={8} xs={8}> {BedNo} </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="background-color mt-4" >
                        <h6> Services </h6>
                    </Col>
                </Row>
                <Row className="margin-top" >
                    <Col lg={12} xl={12} md={12} sm={12} style={{  textAlign: "center" }} >
                        <Table  bordered  size="sm" >
                            <thead>
                                <tr>
                                    <th style={{ fontSize: "16px" }} > Services </th>
                                    <th style={{ fontSize: "16px" }} > Quantity </th>
                                    <th style={{ fontSize: "16px" }} > Price </th>
                                    <th style={{ fontSize: "16px" }} > Total Price </th>
                                </tr>
                            </thead>
                            <tbody id="Patient_body">
                               
                            </tbody>
                            <tfoot id="Patient_Foot" style={{fontWeight:'bold'}}>

                            </tfoot>
                        </Table>
                    </Col>
                </Row>


                <Row>
                <Col className="background-color mt-4" >
                    <h6> MEDICAINS </h6>
                </Col>
            </Row>
            <Row className="margin-top" >
                <Col lg={12} xl={12} md={12} sm={12} style={{  textAlign: "center" }} >
                    <Table bordered  size="sm"  >
                        <thead  >
                            <tr  >
                                <th style={{ fontSize: "16px" }} > Items </th>
                                <th style={{ fontSize: "16px" }} > Quantity </th>
                                <th style={{ fontSize: "16px" }} > Price </th>
                                <th style={{ fontSize: "16px" }} > Total Price </th>
                            </tr>
                        </thead>
                        <tbody id="Medicain_body"  >
                           
                        </tbody>
                        <tfoot id="Medicain_Foot" style={{fontWeight:'bold'}}>

                        </tfoot>
                    </Table>
                </Col>
            </Row>
                <Row>
                    <Col className="background-color" >
                        <h6>  &nbsp;&nbsp;  Doctor's Charges &nbsp;&nbsp; (ڈاکٹر کی فیس) </h6>
                    </Col>
                </Row>
                <Row className="margin-top" >
                    <Col lg={12} xl={12} md={12} sm={12} style={{  textAlign: "center" }} >
                        <Table  bordered  size="sm" >
                            <thead>
                                <tr>
                                    <th style={{ fontSize: "16px" }} > Doctor Name </th>
                                    <th style={{ fontSize: "16px" }} > Visit Date </th>
                                    <th style={{ fontSize: "16px" }} > Visits </th>
                                    <th style={{ fontSize: "16px" }} > Doc Fee </th>
                                </tr>
                            </thead>
                            <tbody id="visits_body">

                            </tbody>
                            <tfoot id="visit_Foot" style={{ fontWeight: 'bold' }}>

                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <Row className="margin-top" >
                    {/* <Col xl={4} lg={4} md={4} >
                        <div className="title" >
                            &nbsp;&nbsp;  Doctor's Charges &nbsp;&nbsp; (ڈاکٹر کی فیس)
                        </div>
                        <div className="body font-size" >
                            <Row>
                                <Col xl={7} lg={7} md={7} style={{ textAlign: "right" }} ><p> Doctor Fees per Visit: </p></Col>
                                <Col xl={5} lg={5} md={5} style={{ textAlign: "right" }} > { Doctor_Fees_Per_Visit } </Col>
                            </Row>
                            <Row>
                                <Col xl={7} lg={7} md={7} style={{ textAlign: "right" }}>Total Visit:</Col>
                                <Col xl={5} lg={5} md={5} style={{ textAlign: "right" }}> { Total_Visit } </Col>
                            </Row>

                        </div>

                        <Row className="total" >
                            <Col xl={7} lg={7} md={7} className="border-top" style={{ textAlign: "right" }} >Total Doctor Fees:</Col>
                            <Col xl={5} lg={5} md={5} className="border-top" style={{ textAlign: "right" }}> { Total_Doctor_Fees } </Col>
                        </Row>
                    </Col> */}
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} >
                        <div className="title" >
                            &nbsp;&nbsp;  Room's Rent &nbsp;&nbsp; (کمرے کا کرایہ)
                        </div>
                        <div className="body font-size" >
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={6}  xs={6} style={{ textAlign: "right" }}>Room Rent Per Day:</Col>
                                <Col xl={6} lg={6} md={6} sm={6}  xs={6} style={{ textAlign: "left" }}> { Room_Rent_Per_Day } </Col>
                            </Row>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={6}  xs={6} style={{ textAlign: "right" }} ><p> Total Days: </p></Col>
                                <Col xl={6} lg={6} md={6} sm={6}  xs={6} style={{ textAlign: "left" }}> { Total_Days } </Col>
                            </Row> 
                        </div>
                        <Row className="total" >
                            <Col xl={6} lg={6} md={6} sm={6}  xs={6} style={{ textAlign: "right" }} >Total Room Rent:</Col>
                            <Col xl={6} lg={6} md={6} sm={6}  xs={6} style={{ textAlign: "left" }}> { Total_Room_Rent } </Col>
                        </Row>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6}  xs={6} >
                        <div className="title" >
                            &nbsp;&nbsp;  Registration's Fees &nbsp;&nbsp; (رجسٹریشن کی فیس)
                        </div>
                        <div className="body font-size" >
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={5}  xs={5} style={{ textAlign: "right" }}>First Time:</Col>
                                <Col xl={7} lg={7} md={7} sm={7}  xs={7} style={{ textAlign: "left" }}> { FirstTime_Registration } </Col>
                            </Row>

                        </div>

                        <Row className="total" >
                            <Col xl={5} lg={5} md={5} sm={5} xs={5}  style={{ textAlign: "right" }} ><p> Total Discount: </p></Col>
                            <Col xl={7} lg={7} md={7} sm={7} xs={7}  style={{ textAlign: "left" }}> { Total_Discount } </Col>
                            <Col xl={5} lg={5} md={5} sm={5} xs={5}  style={{ textAlign: "right" }} >Total Amount:</Col>
                            <Col xl={7} lg={7} md={7} sm={7} xs={7} style={{ textAlign: "left" }}> { TotalAmount } </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    Comp_Name: state.GlobalVariables.Company.Name,
    Comp_Id : state.GlobalVariables.Company.Branch_Id,
    Comp_Address: state.GlobalVariables.Company.Address
})
export default connect(mapStateToProps)(IPDBilling_Report)
