import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { SelectSaleInvoiceMaster,SelectSaleInvoiceDetail } from '../../Services/SaleInvoiceAPI'
import {SelectcustomersList  } from "../../Services/CustomersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { Selectbank } from '../../Services/bankAPI'
import './InvoicePrint.css'
// import './style.css'
import {ThousandSeparater} from '../../Services/Common'

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function SaleInvoiceReport({ CompName,ConfigObj, CompPhoneNo, CompMobileNo, Comp_Id, Project_ID}) {
    const { SaleInvoiceID } = useParams()
    const [InvoiceTableData, setInvoiceTableData] = useState([])
    const [Inv, setInv] = useState('')
    const [InvDate, setInvDate] = useState('')
    const [Supplier, setSupplier] = useState('')
    const [Godown, setGodown] = useState('')
    const [PaymentType, setPaymentType] = useState('')
    const [BankName, setBankName] = useState('')
    const [Dis, setDis] = useState('')
    var [TotalQty, setTotalQty] = useState(0.00)
    var [TotalNetAmt, setTotalNetAmt] = useState(0.00)
    var [Discount, setDiscount] = useState(0.00);
    const [GrandTotal, setGrandTotal] = useState(0.00)
    const [ServiceCharges, setServiceCharges] = useState(0.00)


    useEffect(() => {

       document.getElementById('TopBar').hidden = true
       document.getElementById('leftsidebar').hidden = true
       document.getElementById('rightsidebar').hidden = true
       document.getElementById('chat-wrapper').hidden = true
       document.getElementById('mainContent').hidden = true


        // Selectbank(Comp_Id, Project_ID , (mBank)=>{
        //     SelectWareHouseList(Comp_Id, Project_ID, (mWareHouse)=>{
        //         SelectcustomersList(Comp_Id, Project_ID,(mCustomer)=>{
        //             SelectSaleInvoiceMaster(Comp_Id, Project_ID, SaleInvoiceID, (mMaster)=>{
        //                 SelectSaleInvoiceDetail(Comp_Id, Project_ID, SaleInvoiceID, (mDetails)=>{
        //                     debugger
        //                     setInv(mMaster[0].SaleInvoiceNo)
        //                 setInvDate(mMaster[0].InvoiceDate)
        //                 setDis(mMaster[0].Discription)
        //                 mCustomer.map(val=>{
        //                     if(val.CustomerID === mMaster[0].CustomerID)
        //                     {
        //                         setSupplier(val.CustomerName)
        //                     }
        //                 })
        //                 mWareHouse.map(val=>{
        //                     if(val.WareHouseID === mMaster[0].WareHouseID)
        //                     {
        //                         setGodown(val.WareHouse)
        //                     }
        //                 })
        //              //   mBank.map(val=>{
        //                //     if(val.BankID === mMaster[0].BankID)
        //                  //   {
        //                   //      setBankName(val.BankName)
        //                    // }
        //                 //})
                       
        //                 setPaymentType(mMaster[0].PaymentType)
                       
        //                 setInvoiceTableData(mDetails)
        //                  setDiscount(mMaster[0].TotalDiscount ? mMaster[0].TotalDiscount : 0)   
        //                 })
                
        //                })
        //            })
        //     })
        // })
      
     
    

       var _Data = JSON.parse(localStorage.getItem("SaleInvoice"))
       debugger
       localStorage.removeItem("SaleInvoice")
       if(_Data){
        setInv(_Data.InvoiceNo)
        setGrandTotal(_Data.GrandTotal)
        setServiceCharges(_Data.ServiceCharges)
        setInvDate(_Data.InvoiceDate)
        setSupplier(_Data.Supplier.CustomerName)
        setGodown(_Data.Warehouse.WareHouse)
        setPaymentType(_Data.PaymentType.value)
        setBankName(_Data.Bank && _Data.Bank.BankName)
        setInvoiceTableData(_Data.Detail)
         setDiscount(_Data.TotalDiscount ? _Data.TotalDiscount : 0)   
         setDis(_Data.Discription)
       }
    }, [])

    return (   
        <div id = 'main_div_invprntthrml' style={{fontSize: '14px', marginLeft: '14%'}} >
        <div id="invoice-POS" style={{ width: '4in'}} >

            <center id="top" style={{border:'none', width: '4in'}}>
                <div className="info">
                   {Comp_Id === 3 ? <h2 style={{fontSize: '28px',fontWeight:'bold'}}><b>{CompName}</b></h2> :
                   <h2 style={{fontSize: '18px',fontWeight:'bold'}} className='pt-2'><b>{CompName}</b></h2>
                  
                }
                <hr className='mt-1'/>
                </div>
            </center>

            <div id="mid" style={{marginTop: '-10%', width: '4in'}}>
                <div className="info">
                    <span>
                        <Row>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '18px', color: 'black'}}><b>Inv NO : {Inv}</b> </Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '18px' , color: 'black'}}><b>Inv Date : {InvDate}</b> </Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '18px' , color: 'black'}}><b>Customer : {Supplier}</b></Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '18px' , color: 'black'}}><b>Godown : {Godown}</b></Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '18px' , color: 'black'}}><b>Payment Type : {PaymentType}</b></Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '18px', color: 'black'}}><b>Discription : {Dis ? Dis : ''}</b></Col>
                        </Row>
                    </span>
                </div>
            </div>

            <div id="bot">

                <div id="table_invtherprnt">
                    <table >
                        <thead>
                            <tr style={{border: '1px solid black', textAlign:'center'}}>
                              
                                <th style={{ fontSize: '14px', borderRight: '1px solid black', padding:'5px'}}>Item Name</th>
                                <th style={{ textAlign: 'center', borderRight: '1px solid black', padding:'5px', fontSize: '14px' }} >Qty</th>
                                <th style={{ textAlign: 'center', borderRight: '1px solid black', padding:'5px', fontSize: '14px' }} >Rate</th>
                                <th style={{ textAlign: 'center' , fontSize: '14px'}} >Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                InvoiceTableData.map((invoicetabledata, index) => {
                                    invoicetabledata.Qty = parseFloat(invoicetabledata.Qty)
                                    invoicetabledata.Value = parseFloat(invoicetabledata.Value)
                                    invoicetabledata.DisAmt = parseFloat(invoicetabledata.DisAmt)
                                    invoicetabledata.SaleRate = parseFloat(invoicetabledata.SaleRate)
                                    
                                    TotalQty += parseFloat(invoicetabledata.Qty)
                                    TotalNetAmt += parseFloat(invoicetabledata.Value)
                                    
                                    // Discount += parseFloat(invoicetabledata.DisAmt)
                                    return(
                                    <>
                                        <tr id='data_values_invprntathrml' key={index} style={{border: '1px solid black', textAlign:'center'}} >
                                          
                                            <td style={{ textAlign: 'left', fontWeight:'bold', fontFamily:'sans-serif', fontSize: '14px', borderRight: '1px solid black', padding:'5px' }}> {invoicetabledata.ItemDiscription} </td>
                                            <td style={{ textAlign: 'right', borderRight: '1px solid black', padding:'5px', fontWeight:'bold', fontFamily:'sans-serif', fontSize: '14px' }} > {invoicetabledata.Qty != null ? ThousandSeparater((invoicetabledata.Qty).toFixed()) : invoicetabledata.Qty} </td>
                                            <td style={{ textAlign: 'right', borderRight: '1px solid black', padding:'5px' , fontWeight:'bold', fontFamily:'sans-serif', fontSize: '14px'}}> {((invoicetabledata.SaleRate).toFixed(2))} </td>
                                            <td style={{ textAlign: 'right' , padding:'5px', fontWeight:'bold', fontSize: '14px'}}> {ThousandSeparater((invoicetabledata.Value).toFixed(2))} </td>
                                        </tr>

                                    </>
                                )
                            })
                            }
                            <tr id='ttl_dis_fr_invprntthrml'>
                                <td colSpan="4" style={{ textAlign: 'right', fontSize: '14px' }}>
                                    <b>Total Qty:</b></td>
                                <td style={{ textAlign: 'right', fontSize: '14px' }}><b>{ThousandSeparater(parseFloat(-TotalQty).toFixed(2))}</b></td>
                                {/* <td colSpan="2" style={{ textAlign: 'right' }}><b>{TotalNetAmt}</b></td> */}
                            </tr>
                            <tr id='dis_invprntthrml' >
                                <td colSpan="4" style={{ textAlign: 'right', fontSize: '14px' }}><b>Total Discount:</b></td>
                                <td colSpan="1" style={{ textAlign: 'right' , fontSize: '14px'}}><b>{ThousandSeparater(parseFloat(Discount).toFixed(2))}</b></td>
                            </tr>


                        </tbody>
                        <tfoot>                
                     {
                        Comp_Id != 3 && <tr id='netblnc_invprntthrml' >
                        <td colSpan='4' style={{ textAlign: 'right', fontSize: '14px' }}><b>Service Charges:</b></td>
                        <td colSpan='1' style={{ textAlign: 'right', fontSize: '14px' }}><b>{ServiceCharges}</b></td>
                    </tr>
                     } 
                            <tr id='netblnc_invprntthrml' >
                                <td colSpan='4' style={{ textAlign: 'right', fontSize: '18px', marginTop:"10px" }}><b>Grand Total:</b></td>
                                <td colSpan='1' style={{ textAlign: 'right', fontSize: '18px' }}><b>{GrandTotal}</b></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div id="legalcopy">
                    <br/>
                    <p className="legal"><strong><b style={{fontSize: '14px', color: 'black'}}>Thank you!</b></strong></p>
                    <br/>
                    <p style={{fontSize: '14px', color: 'black'}}><b>Ph #:</b> { CompPhoneNo && CompPhoneNo} - {CompMobileNo && CompMobileNo}</p>
                </div>

            </div>
        </div>

    </div>

    )
}

{/* <div className='size' >
            <Row className='between-rows-margin between-left-margin between-right-margin'>
                <Col sm={3} md={3} xl={3}></Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'center' }}>
                    <h3 style={{ textAlign: 'center' }}> {CompName} </h3>
                </Col>
            </Row>

            <Row className='between-rows-margin between-left-margin between-right-margin' >

                <Col lg={8} md={8} lg={8}>
                    <Row lg={12} md={12} xl={12} >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv.#
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Inv }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv Date:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { InvDate }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Customer:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Supplier }
                        </Col>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Godown:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Godown }
                        </Col>
                    </Row>
                </Col>

                <Col lg={4} md={4} lg={4}>

                    <Row sm={12} md={12} xl={12}>
                        
                        <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
                            Payment Type:
                        </Col>
                        <Col sm={8} md={8} xl={8}>
                            { PaymentType }
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
                            Bank Name:
                        </Col>
                        <Col sm={8} md={8} xl={8}>
                            { BankName }
                        </Col>


                    </Row>
                </Col>
            </Row>


            <Row className='between-rows-margin between-left-margin between-right-margin' >
                <Col>
                    <div id='table_sec_invprt'>
                        <table id='main_table_invoicepr' className='table table-bordered"' style={{ marginBottom: "20px", border: '1px solid black' }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}> Sr # </th>
                            <th style={{ textAlign: 'left' }}> Item Description </th>
                            <th style={{ textAlign: 'center' }}> Qty </th>
                            <th style={{ textAlign: 'right' }}> Rate </th>
                            <th style={{ textAlign: 'right' }}> Amount </th>
                        </tr>
                    </thead>

                    <tbody >
                        {
                            InvoiceTableData.map((invoicetabledata, index) => {
                                TotalQty += invoicetabledata.Qty
                                TotalNetAmt += invoicetabledata.Value
                                return(                               
                                    <tr key={index} style={{border:'1px solid black'}}>
                                        <td style={{ textAlign: 'center' }}> {index + 1} </td>
                                        <td style={{ textAlign: 'left' }}> {invoicetabledata.ItemDiscription} </td>
                                        <td style={{ textAlign: 'center' }} > {isNaN(invoicetabledata.Qty) ? '' : parseFloat(invoicetabledata.Qty).toFixed()}</td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.SaleRate} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.Value} </td>
                                    </tr>                                
                            )
                        })
                        }

                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ textAlign: 'right' }} colSpan='2'  > Total:</td>
                            <td style={{ textAlign: 'center' }}>{TotalQty}</td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}>{TotalNetAmt}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
                </Col>
            </Row>

            <Row className="divFooter" style={{ marginTop: "3%" }} >
               <p>{ConfigObj && ConfigObj.SaleInvoiceFooterLine1 && ConfigObj.SaleInvoiceFooterLine1 !== '' && ConfigObj.SaleInvoiceFooterLine1}
               <br />
               {ConfigObj && ConfigObj.SaleInvoiceFooterLine2 && ConfigObj.SaleInvoiceFooterLine2 !== '' && ConfigObj.SaleInvoiceFooterLine2 }
            </p>
            </Row>
        </div> */}

const mapStateToProps = state => ({
    CompName: state.GlobalVariables.Company.Name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
    CompPhoneNo: state.GlobalVariables && state.GlobalVariables.Company.PhoneNo,
    CompMobileNo: state.GlobalVariables && state.GlobalVariables.Company.MobileNo,
    Project_ID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
})

export default connect(mapStateToProps)(SaleInvoiceReport)

// export default InvoicePrint