
import React, { useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {Link} from "react-router-dom"
import Loader from '../Loader'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit, Report } from '@material-ui/icons'
import { connect } from "react-redux"
 import AddExpense from './AddExpense';
import { SelectExpencesList } from "../../Services/ExpenseAPI"
import { ThousandSeparater } from "../../Services/Common"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SHOW_SCREEN } from "../../React_Redux/Actions"

function ExpenseList(props) {
    const { Comp_Id,ProjectID } = props;
    const [Expense_Modal, setExpense_Modal] = useState(false)
    const [IsAddNew, setIsAddNew] = useState(null)
    const [Expense_Data, setExpense_Data] = useState([])
    const [Expense_Id, setExpense_Id] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [IsLoader, setIsLoader] = useState(false)
    useEffect(() => {
     document.title = "Pharmacy/ Expense List"
     SelectGroupScreenAccess(props.Group_Id,10,90,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)

      props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })    
     Select_Expense_List()
    }, [])
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const Select_Expense_List = () =>{
      showLoader()
        SelectExpencesList(Comp_Id,ProjectID, (data) =>{
            
            for(let i = 0; i < data.length; i++)
            {
                data[i].SrNo = i + 1
            }

            setExpense_Data(data)
        })
        hideLoader()
    }
    const theme = createTheme({
      overrides: {
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }
      }
    });
    const show_Modal = () =>{

        setExpense_Modal(true);
    }

    const hide_Modal = () =>{

        setExpense_Modal(false)
        setIsAddNew(null)
        Select_Expense_List()
    }
    const column = [
      {
        name: "SrNo",
        label: "Sr No.",
        options: {
         filter: true,
         sort: true,
         customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          },
       //  } 
        }
       },
       {
         name: "ExpenseClassification",
         label: "Expense Classification",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }
         }
        },
        {
         name: "ExpenseType",
         label: "Expense Type",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "Expenses",
         label: "Expense",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
           customBodyRenderLite: (dataIndex, rowIndex) => {
              
            const _Expense_Data = Expense_Data
            if(_Expense_Data[dataIndex] != null)
              return (
               <div style={{textAlign: "right"}} > { _Expense_Data[dataIndex].Expenses ? ThousandSeparater((_Expense_Data[dataIndex].Expenses).toFixed(2)) : '-' } </div>
              );
          }
         }
        },
        {
         name: "Edate",
         label: "Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
              
            const _Expense_Data = Expense_Data
            if(_Expense_Data[rowIndex.rowIndex] != null)
              return (
                  
                    <div > {(_Expense_Data[rowIndex.rowIndex].Edate).split('-').reverse().join('-')} </div> 
                
              );
          }
         }
        },
        {
          name: "Discription",
          label: "Discription",
          
          options: {
           filter: true,
           sort: true,
          
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
           
          }
         },
        {
            name: "actions",
            label: "Actions",
            options: {
             customHeadLabelRender: (columnMeta)=>{
              
              return(
                  <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
             customBodyRender: (dataIndex, rowIndex) => {
              
                const _Expense_Data = Expense_Data
                if(_Expense_Data[rowIndex.rowIndex] != null)
                  return (
                      <>
                        <Link to={"#"} onClick={e =>{
                           setIsAddNew(false)
                           setExpense_Id(_Expense_Data[rowIndex.rowIndex].ExpenseID)
                              show_Modal()
                         
                          }} > <Edit/> </Link> 
                      </>
                  );
              }
            }
        }
    ];
    

    return ReactDOM.createPortal(
      <>
      <Row>
      <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Expense List </h4>
            </Col>
      </Row>
      <Card>
          <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{width:'80%', marginLeft:'17%'}} >
         
            <Col style={{textAlign: "right"}} >
             {btn_add && (<Button variant="btn btn-primary" onClick={e =>{
                show_Modal()
                setIsAddNew(true)
              }} style={{border: "none"}} > <Add />  Create New</Button> )}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        name = "OPD_Datatables"
                         data={Expense_Data}
                        columns={column}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
      </Card>

      <AddExpense 
        show = { Expense_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        Expense_Id = {Expense_Id}
      />
      <Loader
      show={IsLoader}
      hide={hideLoader}
    />              
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state =>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps, {SHOW_SCREEN})(ExpenseList)

