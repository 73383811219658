import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectOPTAssignList =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/OPTAssign/SelectOPTAssignList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectOPTAssign =(Comp_Id,ProjectID,OPTAssignID,Callback) =>{
  axios.get(`${domain}/OPTAssign/SelectOPTAssign/${Comp_Id}/${ProjectID}/${OPTAssignID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const SaveOPTAssign = (OPTAssign,IsAddNew,callback) =>{

  axios.post(`${domain}/OPTAssign/SaveOPTAssign`,{
    OPTAssign,
    IsAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

export const DeleteOPTAssign = (Comp_Id,ProjectID,OPTAssignID,cb) =>{

    axios.delete(`${domain}/OPTAssign/DeleteOPTAssign/${Comp_Id}/${ProjectID}/${OPTAssignID}`
    )
         .then(response =>{
          
         
            cb(response.data.message)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
  }