import * as actiontypes from "./types"

export const setLoginData = (loginData,token)=>{
    return {
        type: actiontypes.LOGIN_USER,
        payload:{
            loginData,
            token
        }
    }
}


export const setCompanies = (Companies) =>{

    return {
        type: actiontypes.COMPANIES,
        payload: {
            Companies: Companies
        }
    }
}

export const setSelectedGlobalVariable = (Gl, Company) =>{

    return{
        type: actiontypes.GLOBAL_VARIABLE,
        payload: {
            Gl_Year: Gl,
            Company: Company
        }
    }
}


export const setDonorData = (DonorData,d_id,isAddNew) =>{
    return {
        type: actiontypes.DONOR,
        payload:{
            DonorData,
            d_id,
            isAddNew
        }
    }
}

export const setReceiverData = (ReceiverData,p_id,isAddNew) =>{
    return {
        type: actiontypes.RECEIVER,
        payload:{
            ReceiverData,
            p_id,
            isAddNew
        }
    }
}


export const setExchangerData = (ExchangerData,e_id,isAddNew) =>{
    return {
        type: actiontypes.EXCHANGER,
        payload:{
            ExchangerData,
            e_id,
            isAddNew
        }
    }
}
export const setUserAccessData = (AccessData,Group_Id,Frm_Add) =>{
    return { 
        type: actiontypes.USERACCESS,
        payload:{
            AccessData:AccessData,
            Group_Id:Group_Id,
            FrmAdd:Frm_Add
        }
    }
}

export const setUsers = (UsersData,UserID,Frm_Add)=>{
    return {
        type: actiontypes.USERS ,
        payload:{
            UsersData,
            UserID,
            Frm_Add
        }
    }
}

// ################################  --- OPD ---- ################################ //   
export const setOPD_Regstration = (RegData,pt_id,Frm_Add,AppointmentID)=>{
    return {
        type: actiontypes.OPD_REGISTRATION ,
        payload:{
            RegData,
            pt_id,
            Frm_Add,
            AppointmentID,
        }
    }
}
//################################-----IPD---###############################
export const setIPD_Regstration = (RegData,pt_id,Frm_Add)=>{
    return {
        type: actiontypes.IPD_REGISTRATION ,
        payload:{
            RegData,
            pt_id,
            Frm_Add
        }
    }
}
export const setDoctor_Visits = (Visits_Data,VisitID,Frm_Add)=>{
    return {
        type: actiontypes.DOCTOR_VISITS ,
        payload:{
            Visits_Data,
            VisitID,
            Frm_Add
        }
    }
}
// ################################  --- Pharmacy ---- ################################ // 

export const set_Medicine = (ItemData,ItemID,Frm_Add) =>{
    return { 
        type : actiontypes.MEDICINE , 
        payload:{
            ItemData,
            ItemID,
            Frm_Add
        }
    }
}

export const set_Supplier = (SupplierID,Frm_Add) =>{
    return { 
        type : actiontypes.SUPPLIER , 
        payload:{
            SupplierID,
            Frm_Add
        }
    }
}
export const set_Customer = (CustomerID,Frm_Add) =>{
    return { 
        type : actiontypes.CUSTOMER, 
        payload:{
            CustomerID,
            Frm_Add
        }
    }
}
export const set_Configuration = (ConfigurationID,Frm_Add) =>{
    return { 
        type : actiontypes.CONFIGURATION, 
        payload:{
            ConfigurationID,
            Frm_Add
        }
    }
}
export const set_StockTransfer = (StockTransferID,Frm_Add) =>{
    return { 
        type : actiontypes.STOCKTRANSFER, 
        payload:{
            StockTransferID,
            Frm_Add
        }
    }
}
export const set_StockAdjustment = (StockAdjustmentID,Frm_Add) =>{
    return { 
        type : actiontypes.STOCKADJUSTMENT, 
        payload:{
            StockAdjustmentID,
            Frm_Add
        }
    }
}

export const set_PO = (PO_data,PurchaseOrderID,Frm_Add, Project_ID) =>{
    return { 
        type : actiontypes.PURCHASE_ORDER , 
        payload:{
            PO_data,
            PurchaseOrderID,
            Frm_Add,
            Project_ID
        }
    }
}
export const set_SO = (SO_data,SaleOrderID,Frm_Add, ProjectID) =>{
    return { 
        type : actiontypes.SALE_ORDER , 
        payload:{
            SO_data,
            SaleOrderID,
            Frm_Add,
            ProjectID: ProjectID
        }
    }
}

export const set_PI = (PI_data,PurchaseInvoiceID,Frm_Add, Project_ID) =>{
    return { 
        type : actiontypes.PURCHASE_INVOICE , 
        payload:{
            PI_data,
            PurchaseInvoiceID,
            Frm_Add,
            Project_ID
        }
    }
}

export const setTurnNo = (turn_no) =>{

    return{
        type: actiontypes.TURN_NO,
        payload: {
            turn_no
        }
    }
}

export const set_OPening = (OPening_data,OPening_ID,Frm_Add) =>{
    return { 
        type : actiontypes.OPENING , 
        payload:{
            OPening_data,
            OPening_ID,
            Frm_Add
        }
    }
}
export const set_SI = (SI_data,SaleInvoiceID,Frm_Add, Project_ID) =>{
    return { 
        type : actiontypes.SALE_INVOICE , 
        payload:{
            SI_data,
            SaleInvoiceID,
            Frm_Add,
            Project_ID
        }
    }
}

export const SHOW_SCREEN = (add, edit, del, others) =>{
    return { 
        type : actiontypes.SHOW_SCREEN , 
        payload:{
            add,
            edit,
            del,
            others
        }
    }
}

export const set_Payment = (Payment_data,PaymentID,Frm_Add) =>{
    return { 
        type : actiontypes.PAYMENTS , 
        payload:{
            Payment_data,
            PaymentID,
            Frm_Add
        }
    }
}

export const set_Receipt = (Receipt_data,ReceiptID,Frm_Add) =>{
    return { 
        type : actiontypes.RECEIPTS , 
        payload:{
            Receipt_data,
            ReceiptID,
            Frm_Add
        }
    }
}

export const setGlobal_Project = Projects =>{
    return {
        type: actiontypes.GLOBAL_PROJECT,
        payload: {
            GlobalProjects: Projects
        }
    }
}
export const setGlobal_Config = Config =>{
    return {
        type: actiontypes.GLOBAL_CONFIG,
        payload: {
            Global_Config:Config
        }
    }
}
export const setGlobal_Project_List = Projects =>{
    return {
        type: actiontypes.GLOBAL_PROJECTS_LIST,
        payload: {
            GlobalProjectsList: Projects
        }
    }
}
export const setPENDING_PATIENT = (PatientID, p_Date) =>{
    return { 
        type : actiontypes.PENDING_PATIENT , 
        payload:{
            PatientID,
            Date:p_Date
        }
    }
}
export const setGrid_Data = (Grid_Data) =>{
    return { 
        type : actiontypes.Grid_Data , 
        payload:{
            Grid_Data,
        }
    }
}