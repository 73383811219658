import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectIPDRegistrationList =(Comp_Id,ProjectID,dte,Callback) =>{
    
  axios.get(`${domain}/IPDRegistration/SelectIPDRegistrationList/${Comp_Id}/${ProjectID}/${dte}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          Callback()
     })
}
export const GetMaxRegistrationCode =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/IPDRegistration/GetMaxRegistrationCode/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectBedNoWRTRoom =(Comp_Id,ProjectID,RoomID,WardID,Callback) =>{
    
  axios.get(`${domain}/IPDRegistration/SelectBedNoWRTRoom/${Comp_Id}/${ProjectID}/${RoomID}/${WardID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectAllBedNoWRTRoom =(Comp_Id,ProjectID,RoomID,WardID,Callback) =>{
    
  axios.get(`${domain}/IPDRegistration/SelectAllBedNoWRTRoom/${Comp_Id}/${ProjectID}/${RoomID}/${WardID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveIPDRegistration = (RegistrationData,isAddNew,callback) =>{

  axios.post(`${domain}/IPDRegistration/SaveIPDRegistration`,{
    RegistrationData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback(response.data.ID)
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}
export const SelectIPDRegistrationData =(RegistrationID,Callback) =>{
    
  axios.get(`${domain}/IPDRegistration/SelectIPDRegistrationData/${RegistrationID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          
          Callback()
     })
}

export const SelectIPDRegistrationDataForReport =(RegistrationID,Callback) =>{
    debugger
  axios.get(`${domain}/IPDRegistration/SelectIPDRegistrationDataForReport/${RegistrationID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          
          Callback()
     })
}