import axios from 'axios'
import { domain } from "../Services/Common"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const _ConfirmPassword = (LoginID, Password, cb) => {

    axios.get(`${domain}/ChangePassword/ConfirmPassword/${LoginID}/${Password}`)
        .then(response => {
            cb(response.data)
        })
        .catch(err => console.log(err))
}

export const UpdatePassword = (LoginID, Password, cb) => {

    axios.put(`${domain}/ChangePassword/UpdatePassword/${LoginID}/${Password}`)
        .then(response => {

            toast.configure();
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            cb()
        })
        .catch(err => console.log(err))
}