import React, {useEffect, useState} from 'react'
import { Business, LocalPhone } from '@material-ui/icons'
import  './IPDRegistration.css'
import { useParams } from "react-router-dom"
import PatientInformation from './PatientInformation'
import { connect } from 'react-redux'
import { SelectIPDRegistrationDataForReport } from '../../Services/IPDRegistrationAPI'

const IPDRegistration_Report = ({Comp_Name, Comp_Address, PhoneNo}) => {
    const {Id} = useParams()
    const [RegistrationNo, setRegistrationNo] = useState('')
    const [RegistrationDate, setRegistrationDate] = useState('')
    const [PatientName, setPatientName] = useState('')
    const [Guardian, setGuardian] = useState('')
    const [Phone_No, setPhone_No] = useState('')
    const [Gender, setGender] = useState('')
    const [CNIC, setCNIC] = useState('')
    const [Address, setAddress] = useState('')
    const [Ward, setWard] = useState('')
    const [Room, setRoom] = useState('')
    const [BedNo, setBedNo] = useState('')
    const [RoomCategory, setRoomCategory] = useState('')

   
   
    const containerStyle = {
        backgroundColor: 'white',
       
      };
    useEffect(()=>{
        document.title = 'IPD Registration Report'
        document.getElementById('TopBar').hidden = true
        document.getElementById('leftsidebar').hidden = true
        document.getElementById('rightsidebar').hidden = true
        document.getElementById('chat-wrapper').hidden = true
        document.getElementById('mainContent').hidden = true

        SelectIPDRegistrationDataForReport(Id, (mData)=>{
            setRegistrationNo(mData[0].RegistrationNo)
            setRegistrationDate(mData[0].RegistrationDate)
            setPatientName(mData[0].PatientName)
            setGuardian(mData[0].Guardian)
            setPhone_No(mData[0].PhoneNo)
            setGender(mData[0].Gender)
            setCNIC(mData[0].CNIC)
            setAddress(mData[0].Address)
            setWard(mData[0].Ward)
            setRoom(mData[0].Room)
            setBedNo(mData[0].BedNo)
            setRoomCategory(mData[0].RoomCategory)
        })

    }, [])
  return (

    <div style={containerStyle} className="container printPreview">
     
        <div className="row">
          <div className="col-xl-3 col-lg-3 co-l-md-3 col-sm-3 col-xs-3"></div>
          <div className="col-xl-6 col-lg-6 co-l-md-6 col-sm-6 col-xs-6">
            <h2 className="comptitle">{Comp_Name}</h2>
            <h6 className="compsubtitles mt-2">
              <Business className="mr-2" />
              Address: {Comp_Address}
            </h6>
            <h6 className="compsubtitles mt-2">
              <LocalPhone className="mr-2" />
              <span>Phone No:{PhoneNo}</span>
            </h6>
          </div>
          <div className="col-xl-3 col-lg-3 co-l-md-3 col-sm-3 col-xs-3"> </div>
        </div>
        <div className="row">
        <div className='col-xl-12 col-lg-12 co-l-md-12 col-sm-12 col-xs-12'>
                    <h5 className='rptTitle mt-3'>IPD Registration Slip</h5>
        </div>
      
      </div>
      <div className='row'>
      <div className='custome_border' style={{width:'100%', padding:'10px'}}>
      <PatientInformation label="Registration No" value={RegistrationNo} />
      <PatientInformation label="Registration Date" value={RegistrationDate} />
      <PatientInformation label="Patient Name" value={PatientName} />
      <PatientInformation label="Father Name" value={Guardian} />
      <PatientInformation label="Phone No" value={Phone_No} />
      <PatientInformation label="Gender" value={Gender} />
      <PatientInformation label="CNIC" value={CNIC} />
      <PatientInformation label="Address" value={Address} />
      <PatientInformation label="Ward No" value={Ward} />
      <PatientInformation label="Room" value={Room} />
      <PatientInformation label="Bed No" value={BedNo} />
      <PatientInformation label="Room Type" value={RoomCategory} />
      
      </div>
      </div>
      <div className="row">
      <div className='col-xl-12 col-lg-12 co-l-md-12 col-sm-12 col-xs-12'>
                  <h5 className='rptTitle mt-3'>Phone No # {PhoneNo}</h5>
      </div>
    
    </div>
    
      </div>
   
  );
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
     PhoneNo:state.GlobalVariables &&  state.GlobalVariables.Company.PhoneNo,
    isAddNew:state.IPD_Reg && state.IPD_Reg.isAddNew,
    RegistrationID: state.IPD_Reg && state.IPD_Reg.pt_id,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(IPDRegistration_Report)

