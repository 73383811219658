import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectPurchaseInvoiceList =(Comp_Id,ProjectID,Tr_Type,MonthID,Callback) =>{
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceList/${Comp_Id}/${ProjectID}/${Tr_Type}/${MonthID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const GetMaxPurchaseeInvoiceNo =(Comp_Id,ProjectID,Tr_Type,Callback) =>{
  axios.get(`${domain}/PurchaseInvoice/GetMaxPurchaseeInvoiceNo/${Comp_Id}/${ProjectID}/${Tr_Type}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SavePurchaseInvoice = (PurchaseInvoiceMasterData,PurchaseInvoiceDetailData,isAddNew,callback)=>{
 
  axios.post(`${domain}/PurchaseInvoice/SavePurchaseInvoice`,{
    PurchaseInvoiceMasterData,
    PurchaseInvoiceDetailData,
    isAddNew
  })
  .then(response=>{
    toast.configure()
    toast.info(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    callback()
  })
  .catch(err=>console.error(err))
} 
export const SelectPurchaseInvoiceMasterWRTCode = (Comp_Id,ProjectID, PurchaseInvoiceNo, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceMasterWRTCode/${Comp_Id}/${ProjectID}/${PurchaseInvoiceNo}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}
export const SelectPurchaseInvoiceDetailWRTCode = (Comp_Id,ProjectID, PurchaseInvoiceNo, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceDetailWRTCode/${Comp_Id}/${ProjectID}/${PurchaseInvoiceNo}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}

export const SelectPurchaseInvoiceMaster = (Comp_Id,ProjectID, PurchaseInvoiceID, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceMaster/${Comp_Id}/${ProjectID}/${PurchaseInvoiceID}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}
export const SelectPurchaseInvoiceDetail = (Comp_Id,ProjectID, PurchaseInvoiceID, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceDetail/${Comp_Id}/${ProjectID}/${PurchaseInvoiceID}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}

export const SelectPurchaseInvoiceMasterForReturn = (Comp_Id,ProjectID, PurchaseInvoiceNo, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceMasterForReturn/${Comp_Id}/${ProjectID}/${PurchaseInvoiceNo}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}
export const SelectPurchaseInvoiceDetailForReturn = (Comp_Id,ProjectID, PurchaseInvoiceNo, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectPurchaseInvoiceDetailForReturn/${Comp_Id}/${ProjectID}/${PurchaseInvoiceNo}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}
export const SelectItemsWRTPO = (Comp_Id,ProjectID, PurchaseOrderNo,SupplierID, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectItemsWRTPO/${Comp_Id}/${ProjectID}/${PurchaseOrderNo}/${SupplierID}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}

export const SelectItemsForPUR = (Comp_Id,ProjectID, PurchaseInvoiceNo,Tr_Type,SupplierID, Callback) => {
  axios.get(`${domain}/PurchaseInvoice/SelectItemsForPUR/${Comp_Id}/${ProjectID}/${PurchaseInvoiceNo}/${Tr_Type}/${SupplierID}`)
    .then(response => {
      Callback(response.data.Data)
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err}`,
      })
    })
}