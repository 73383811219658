import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectPrescriptionList } from "../../Services/PendingPatinetListAPI";
import { SelectUserProjects } from "../../Services/Projects"

import { GetCurrentDate } from '../../Services/Common'

 const OPDBilling = ({Comp_Id,p_userid}) => {
    const [IsLoader, setIsLoader] = useState(false)
    const [OPDBilling_Data, setOPDBilling_Data] = useState([])
    const [ListDate , setListDate] = useState(GetCurrentDate)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Project: ''
    })
    useEffect(()=>{
        document.title = "OPD Billing and Prescription"
        SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{
            setProjectList(ProjectData)
            setSelectedProject(ProjectData[0])
            if(ProjectData.length > 0)
            Select_Prescription_List(Comp_Id,ProjectData[0].Project_ID,ListDate)
            // if(IPD_Reg.RegData)
            // {
              
            //   Select_IPDRegistration_List(Comp_Id,IPD_Reg.RegData.SelectedProject.Project_ID,ListDate)
            //   setSelectedProject(ProjectData.find(x => x.Project_ID ===  IPD_Reg.RegData.SelectedProject.Project_ID))
            //   setIPD_Regstration(null,0,false)
            // }
            // else
            // {
            //   setSelectedProject(ProjectData[0])
            //   Select_IPDRegistration_List(Comp_Id,ProjectData[0].Project_ID,ListDate)
            // }
          })
    },[])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const column_OPDBilling = [
        {
         name: "TokenNo",
         label: "Turn No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
       
        {
         name: "PatientName",
         label: "Patient Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
            name: "Guardian",
            label: "Guardian",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "AppointmentDate",
            label: "Appointment Date",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "Doctor",
            label: "Doctor",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
       
     
           {
            name: "Status",
            label: "Status",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "actions",
            label: "Actions",
            options: {
                customHeadLabelRender: (columnMeta) => {

                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {
                    const _OPD_BAndP_Data = OPDBilling_Data
                    if (_OPD_BAndP_Data[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <Link to={`/Prescription/${_OPD_BAndP_Data[rowIndex.rowIndex].AppointmentID}`} target="_blank" onClick={e => {
                                    localStorage.setItem('token',sessionStorage.getItem('token'))
                                }} > <Print /> </Link>

                            </>
                        );
                }
            }
        }
    ];
    const validation = () =>{

       if(!SelectedProject)
        {
          setError_message({Project: 'must be selected'})
          return false
        }
  
        setError_message({ Project: ''})
        return true
      }
  
      const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
      const Select_Prescription_List = (Comp_Id,ProjectID,ListDate) =>{
        showLoader()
        SelectPrescriptionList(Comp_Id,ProjectID,ListDate,(mData)=>{
          hideLoader()
          if(mData)
            setOPDBilling_Data(mData)
        })
       }
       return ReactDOM.createPortal(
        <>
         <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col lg={6} xl={6} md={6} className="header_title text-white" >
                <h4> OPD Billing and Prescription</h4>
              </Col>
            </Row>
        <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right m-4" >
  
                      <Col lg={3} xl={3} md={3} >
                      <TextField
                      name="AppointmentDate"
                      id="AppointmentDate"
                      label="AppointmentDate"
                      type="date"
                       value={ListDate}
                      onChange={e=>{
                        setListDate(e.target.value)
                        if(e.target.value){
                          Select_Prescription_List(Comp_Id,SelectedProject.Project_ID,e.target.value)
                        }
                      
                      }}
                      fullWidth
                  />
                      </Col>
  
                      <Col lg={3} xl={3} md={3} >
                          <Autocomplete
                              name="Project"
                              id="Project"
                              options={ProjectList}
                              getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                              value={SelectedProject}
                              onChange={(e, value) => {
                                  if (value)
                                  {
                                      setSelectedProject(value)
  
                                     
                                      Select_Prescription_List(Comp_Id,value.Project_ID,ListDate)
                                  }
                                  else
                                  {
                                    setSelectedProject(null)
                                      setOPDBilling_Data([])
                                  }
                              }}
                              renderInput={(params) =>
                                  <TextField {...params} name="Project" label="Select Project"
                                  />}
                          />
                           <span style={{color: 'red'}} > {error_message.Project} </span>
                      </Col>
  
            
            </Row>
            <Row className="row_margin_top row_margin_left row_margin_right" >
                <Col>    
                          
                  <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                      <MUIDataTable
                          data={OPDBilling_Data}
                          columns={column_OPDBilling}
                          options={{
                          selectableRows: 'none',
                          print: false,
                          search: true,
                          download: false,
                          viewColumns: false,
                          filter: false,
                          }}
                      />
                      
                      </MuiThemeProvider>
                  </div>
                </Col>
            </Row>
            <Loader
            show={IsLoader}
            hide={hideLoader}
          />
        </Card>
                          
        </>  , document.getElementById('mainContent')
      )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    IPD_Reg: state.IPD_Reg && state.IPD_Reg,
    Group_Id : state.Login_User.User_Data.Group_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  })
  export default connect(mapStateToProps)(OPDBilling)
  
