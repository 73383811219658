import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptSaleMarginCustomerWise } from '../../../Services/Sale_ReportAPI'
import  {SelectcustomersList } from '../../../Services/CustomersAPI'

function SaleMarginCustomerWise({Comp_Name,Comp_Id,User_Name}) {
    const { ProjectID,FromDate,ToDate,FromCustomer,ToCustomer } = useParams();

    const [CustomerData, setCustomerData] = useState([])
    const [TotalMargin, setTotalMargin] = useState(0)
    const [SelectedFromCustomer, setSelectedFromCustomer] = useState(null)
    const [SelectedToCustomer, setSelectedToCustomer] = useState(null)

    useEffect(() => {
        document.title = "Pharmacy / Sales Margin Customer Wise" 

        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;
debugger
        rptSaleMarginCustomerWise(Comp_Id,ProjectID,FromDate,ToDate,FromCustomer,ToCustomer,(mData)=>{
            debugger
            if(mData.length > 0){
            var Totals = mData.splice(-1);
            if(Totals[0].Margin !== null){
                setTotalMargin(Totals[0].Margin.split('-')[1])
            }
            setCustomerData(mData)
            }
        
        })
        SelectcustomersList(Comp_Id,ProjectID,(mCustomer)=>{
            mCustomer.map( value =>{
                if(value.CustomerID == FromCustomer){
                    setSelectedFromCustomer(value)
                }
                if(value.CustomerID == ToCustomer){
                    setSelectedToCustomer(value)
                }
            })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
          
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                 <Col sm={12} md={12} xl={12}>
                    <h3> {Comp_Name} </h3> <br /> <h3>Sales Margin Customer Wise</h3>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                        <b>From Customer:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6}  style={{textAlign:'left'}}>
                        {SelectedFromCustomer && SelectedFromCustomer.CustomerName}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                        <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                        <b>To Customer:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} style={{textAlign:'left'}}>
                        {SelectedToCustomer && SelectedToCustomer.CustomerName}
                        </Col>
                    </Row>
                </Col>

            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >ItemCode</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >Item</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >Narration</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >PurchaseRate</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >SaleRate</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold",borderTop:"2px solid #000" }} >Margin</th>
                        </tr>
                    </thead>

                     <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                            
                         {
                            CustomerData.map((Customerdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Customerdata.ItemCode}</td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Customerdata.ItemDiscription}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Customerdata.TransactionType}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Customerdata.Qty} </td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Customerdata.Naration}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Customerdata.PurchaseRate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Customerdata.SaleRate} </td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Customerdata.Margin}</td>
                                    </tr>
                                  
                                </>
                            ))
                        } 
                           
                        <tr >
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px'}}  > Total Margin:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalMargin}</td>
                        </tr>  
                    </tbody> 
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div> 
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})

export default connect(mapStateToProps)(SaleMarginCustomerWise)
