import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Checkbox, TextField, FormControlLabel,TextareaAutosize } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import { SelectDays } from '../../Services/DaysAPI'
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Edit, EditSettingsModel, Inject, Toolbar, ToolbarItems, Filter, Sort, ForeignKey, Page } from '@syncfusion/ej2-react-grids';
import { Card, Row, Col, Button } from "react-bootstrap"
import { Call, Camera, Delete, Save, Refresh } from "@material-ui/icons"
import { connect } from "react-redux"

import Swal from 'sweetalert2'

import { GetCurrentDate } from '../../Services/Common'
import {  SelectDiseases } from '../../Services/DiseasesAPI'
import { SelectItemsListWRTProject } from '../../Services/ItemsAPI'
import { SelectDosages } from '../../Services/DosageAPI'
import { SelectTests} from '../../Services/TestAPI'
import { SelectSymptoms } from '../../Services/SymptomAPI'
import { SelectToDayPatient , SelectToDayPatientWRTDoctor, CallPatient, SaveCheckup} from '../../Services/CheckupAPI'
import { setPENDING_PATIENT , setGrid_Data} from '../../React_Redux/Actions'
const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    // return ['Patient History', 'Chekup', 'Sign & Symptoms', 'Provisional Diagnosis',  'Scan/Lab Test/Ultrasound', " Test Result ", "Final Diagnosis", 'Treatment', 'Remarks'];
    return ['Patient History', 'Chekup', 'Sign & Symptoms', 'Provisional Diagnosis', 'Scan/Lab Test/Ultrasound', "Final Diagnosis", 'Treatment', 'Remarks'];
}

const OPDCheckup = ({Comp_Id,Project_ID,UserType,Doctor_ID,PendingPatient,setPENDING_PATIENT, setGrid_Data}) => {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [AppointmentID, setAppointmentID] = useState('')
    const [Father_Gardien_Name, setFather_Gardien_Name] = useState("")
    const [Age, setAge] = useState("")
    var [PatientHistory_Date, setPatientHistory_Date] = useState('')
    const [CmbPatient, setCmbPatient] = useState([])
    const [SelectedPatient, setSelectedPatient] = useState(null)
    const [LastAppointmentDate, setLastAppointmentDate] = useState('')
    const [ConsultedTo, setConsultedTo] = useState('')
    const [Temp, setTemp] = useState('')
    const [BloodGroup, setBloodGroup] = useState('')
    const [CmbDisease, setCmbDisease] = useState([])
    const [SelectedDisease, setSelectedDisease] = useState(null)
    const [CmbMedince, setCmbMedince] = useState([])
    const [SelectedMedince, setSelectedMedince] = useState(null)
    const [CmbDosage, setCmbDosage] = useState([])
    const [SelectedDosage, setSelectedDosage] = useState(null)

     const [CmbDays, setCmbDays] = useState([])
    const [SelectedDays, setSelectedDays] = useState(null)
    const [CmbScan_LabTest_Ultrasound, setCmbScan_LabTest_Ultrasound] = useState([])
    const [SelectedScan_LabTest_Ultrasound, setSelectedScan_LabTest_Ultrasound] = useState(null)
    const [CmbSymptoms, setCmbSymptoms] = useState([])
    const [SelectedSymptoms, setSelectedSymptoms] = useState(null)
    const [Is_Provisional_Diagnosis, setIs_Provisional_Diagnosis] = useState(false)

    const [isShowScanTest, setIsShowScanTest] = useState(false)

    //History Grid States
    const [Medicine_Grid, setMedicine_Grid] = useState([])
    const [Symptoms_Grid, setSymptoms_Grid] = useState([])
    const [Disease_Grid, setDisease_Grid] = useState([])
    const [Test_Grid, setTest_Grid] = useState([])
    const [Remarks_Grid, setRemarks_Grid] = useState([])
    //Grid states
    const [main_Medicine_Grid, setmain_Medicine_Grid] = useState([])
    const [main_Symptoms_Grid, setmain_Symptoms_Grid] = useState([])
    const [main_Disease_Grid, setmain_Disease_Grid] = useState([])
    const [main_Test_Grid, setmain_Test_Grid] = useState([])
    const [final_disease, setFinal_disease] = useState([])

    const [Pluse, setPluse] = useState('')
    const [Pulse, setPulse] = useState('')
    const [Temperature, setTemperature] = useState('')
    const [BloodGolocouse, setBloodGolocouse] = useState('')
    const [Fees, setFees] = useState(0.00)
    const [Remarks, setRemarks] = useState('')
    const [btn_add, setBtn_add] = useState(true)
    const [chk_disabled, setChk_disabled] = useState(false)
    const [BG, setBG] = useState('')
    const [Test_Result_Grid, setTest_Result_Grid] = useState([])

    const [DeletedObj, setDeletedObj] = useState(null)
    const [imgSrc, setimgSrc] = useState('')
    const [SubDirectory, setSubDirectory] = useState('')
    const [file, setFile] = useState({ img: null })
    const [BloodPresure, setBloodPresure] = useState('')
    const [Weight, setWeight] = useState('')
    const [Height, setHeight] = useState('')
    const [History_Wieght, setHistory_Wieght] = useState("")
    const [History_Height, setHistory_Height] = useState("")
    const [History_BP, setHistory_BP] = useState("")
    const [History_FD, setHistory_FD] = useState([])
    const [History_Treatment, setHistory_Treatment] = useState([])
    const [DeletedSymptoms_Data, setDeletedSymptoms_Data] = useState([])
    const [DeletedDisease_Data, setDeletedDisease_Data] = useState([])
    const [DeletedTest_Data, setDeletedTest_Data] = useState([])
    const [btnDisabled, setbtnDisabled] = useState(false)

    var Disease_grid;
    var Test_grid;
    const steps = getSteps();
    const EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true };
    const ToolbarItems = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    const EditSettingsModel1 = { allowEditing: true, allowAdding: true, allowDeleting: true };
    const ToolbarItems1 = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    
    useEffect(() => {
        LoadHandler()
        
      }, [])

      const LoadHandler = () =>{
        Select_Disease_List()
        Select_Medicine_List()
        Select_Dosage_List()
        Select_Test_List()
        Select_Symptoms_List()
        Select_Days_List()
       
        if(PendingPatient)
        {
            setActiveStep(5)
        setSelectedPatient({PatientName:PendingPatient.PatientName,RegistrationID:PendingPatient.RegistrationID})
          setPatientHistory_Date(PendingPatient.AppointmentDate)
          Select_ToDay_Patient(PendingPatient.AppointmentDate)
          Call_Patient(PendingPatient.RegistrationID)
          setPENDING_PATIENT(null)
        }
        else
        {
          setPatientHistory_Date(GetCurrentDate())
          Select_ToDay_Patient(GetCurrentDate())
        }
      }
    const handleNext = () => {

        setActiveStep((prevActiveStep) => {
            if(prevActiveStep + 1 === 4)
            {
                setIsShowScanTest(true)
            }
            else
            {
                if(isShowScanTest)
                {
                    setIsShowScanTest(false)
                }
            }
            
            return prevActiveStep + 1
        
        });
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            if(prevActiveStep - 1 === 4)
            {
                setIsShowScanTest(true)
            }
            else
            {
                if(isShowScanTest)
                {
                    setIsShowScanTest(false)
                }
            }
            
            return prevActiveStep - 1});
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    function getStepContent(step) {
        switch (step) {
            //Patient History
            case 0:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_top row_margin_left row_margin_right pl-4" >
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="PatientHistory_Date"
                                        id="PatientHistory_Date"
                                        type="date"
                                        label="Date"
                                        value={PatientHistory_Date}
                                        onChange={e => {

                                            setPatientHistory_Date(e.target.value)
                                            Select_ToDay_Patient(e.target.value)

                                        }}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3} >
                                    <Autocomplete
                                        id="PatientHistory_cmbPatient"
                                        name="PatientHistory_cmbPatient"
                                        options={CmbPatient}
                                        value={SelectedPatient}
                                        getOptionLabel={(option) => option.PatientName}
                                        onChange={(e, value) => {
                                            if (value) {
                                                setSelectedPatient(value)
                                                Call_Patient(value.RegistrationID)
                                                setAge(value.Age)
                                                setFather_Gardien_Name(value.Guardian)
                                            }
                                            else {
                                                setSelectedPatient(null)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select Patient" />}
                                        fullWidth
                                    />
                                </Col>
                              <Col xl={6} lg={6} md={6} style={{ textAlign: "left" }} >
                                   <button onClick={e=>LoadHandler()} disabled = {btnDisabled}>  <Refresh className='mt-4 text-primary' style={{cursor:'pointer'}}  /> </button>
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col style={{ textAlign: "center" }} >
                                    ---------- Patient History ----------
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top pl-4 pr-4" >
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="PatientHistory_Father"
                                        id="PatientHistory_Father"
                                        label="Father/Gardien Name"
                                        value={Father_Gardien_Name}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="PatientHistory_Age"
                                        id="PatientHistory_Age"
                                        label="Age"
                                        value={Age}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={2} lg={2} md={2}>
                                    <TextField
                                        name="Height"
                                        id="Height"
                                        label="Height"
                                        value={History_Height}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={2} lg={2} md={2}>
                                    <TextField
                                        name="Weight"
                                        id="Weight"
                                        label="Weight"
                                        value={History_Wieght}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={2} lg={2} md={2}>
                                    <TextField
                                        name="BloodPressure"
                                        id="BloodPressure"
                                        label="Blood Pressure"
                                        value={History_BP}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top pl-4 pr-4" >
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="PatientHistory_LastAppointmentDate"
                                        id="PatientHistory_LastAppointmentDate"
                                        type="date"
                                        label="Last Appointment Date"
                                        value={LastAppointmentDate}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="PatientHistory_ConsultedTo"
                                        id="PatientHistory_ConsultedTo"
                                        label="Consulted To"
                                        value={ConsultedTo}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={2} lg={2} md={2}>
                                    <TextField
                                        name="PatientHistory_Pluse"
                                        id="PatientHistory_Pluse"
                                        label="Pluse"
                                        value={Pulse}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={2} lg={2} md={2}>
                                    <TextField
                                        name="PatientHistory_Temp"
                                        id="PatientHistory_Temp"
                                        label="Temp"
                                        value={Temp}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>

                                <Col xl={2} lg={2} md={2}>
                                    <TextField
                                        name="PatientHistory_BloodGroup"
                                        id="PatientHistory_BloodGroup"
                                        label="Blood Group"
                                        value={BloodGroup}
                                        onChange={e => { }}
                                        inputProps={
                                            { readOnly: true }
                                        }
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top pt-4" >
                                <Col xl={4} lg={4} md={4}>
                                    <label> Preception List </label>
                                    <GridComponent dataSource={Medicine_Grid} toolbar={ToolbarItems}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='ItemDiscription' headerText='Name' width='120' />
                                            <ColumnDirective field='ItemID' visible={false} />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>

                                <Col xl={4} lg={4} md={4}>
                                    <label> Disease's List</label>
                                    <GridComponent dataSource={Disease_Grid} toolbar={ToolbarItems}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Diseases' headerText='D Name' width='120' />
                                            <ColumnDirective field='DiseaseID' visible={false} width='120' />
                                            <ColumnDirective field='PatientDiseaseID' visible={false} width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>

                                <Col xl={4} lg={4} md={4}>
                                    <label> Symptoms List</label>
                                    <GridComponent dataSource={Symptoms_Grid} toolbar={ToolbarItems}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Symptoms' headerText='Name' width='120' />
                                            <ColumnDirective field='SymptomID' visible={false} />
                                            <ColumnDirective field='PatientSymptomsID' visible={false} />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top pt-4" >
                                <Col xl={4} lg={4} md={4}>
                                    <label> Scan/Labortary Test/X-Rays List </label>
                                    <GridComponent dataSource={Test_Grid} toolbar={ToolbarItems}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Tests' headerText='Name' width='120' />
                                            <ColumnDirective field='TestID' visible={false} width='120' />
                                            <ColumnDirective field='PatientTestID' visible={false} width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>

                                <Col xl={4} lg={4} md={4}>
                                    <label> Previous Remarks List</label>
                                    <GridComponent dataSource={Remarks_Grid} toolbar={ToolbarItems}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Remarks' headerText='Remarks' width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                                <Col xl={4} lg={4} md={4}>
                                    <label> Final Diagnosis</label>
                                    <GridComponent dataSource={History_FD} toolbar={ToolbarItems}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Diseases' headerText='Name' width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                                {/* <Col xl={4} lg={4} md={4}>
                      <label> Treatment</label>
                      <GridComponent dataSource={History_Treatment} toolbar={ToolbarItems}>
                        <ColumnsDirective>
                          <ColumnDirective field='Name' headerText='Name' width='120' />
                        </ColumnsDirective>
                        <Inject services={[Edit, Toolbar]} />
                      </GridComponent>
                    </Col> */}
                            </Row>
                        </Card>
                    </>
                );
            //Diseases
            case 3:
                return (
                    <>
                        <Card>
                           {!isShowScanTest && (<> <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <Autocomplete
                                        id="Disease_cmbDisease"
                                        name="Disease_cmbDisease"
                                        options={CmbDisease}
                                        value={SelectedDisease}
                                        getOptionLabel={(option) => option.Diseases}
                                        onChange={(e, value) => {

                                            if (value) {
                                                let array = [...main_Disease_Grid]

                                                var count = 0
                                                for (let i = 0; i < main_Disease_Grid.length; i++) {
                                                    if (main_Disease_Grid[i].DiseaseID === value.DiseaseID) {
                                                        count++
                                                    }
                                                }
                                                if (count > 0) {
                                                    Swal.fire({
                                                        icon: 'info',
                                                        title: 'Oops...',
                                                        text: `Already Exist...`,
                                                    })
                                                    return;
                                                }
                                                array.push({ DiseaseID: value.DiseaseID, Diseases: value.Diseases})
                                                setmain_Disease_Grid(array)

                                                //   let newModuleAccessData = [...ModuleAccessData]
                                                //   newModuleAccessData[dataIndex].Allow_Access = e.target.checked 
                                                //  setModuleAccessData(newModuleAccessData)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select" />}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top row_margin_bottom" >
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <label> Diseases List </label>
                                    <GridComponent dataSource={main_Disease_Grid} toolbar={ToolbarItems} actionComplete={afterRowInsertDisease} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Diseases' headerText='Name' width='120' />
                                            <ColumnDirective field='DiseaseID' visible={false} width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                            </Row></> )}
                        </Card>
                    </>
                );
            //Medince
            case 6:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={2} lg={2} md={2}></Col>
                                <Col xl={3} lg={3} md={3}>
                                    <Autocomplete
                                        id="Medince_cmbMedince"
                                        name="Medince_cmbMedince"
                                        options={CmbMedince}
                                        value={SelectedMedince}
                                        getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : "" }
                                        onChange={(e, value) => {
                                            debugger
                                            if (value) {
                                                setSelectedMedince(value)
                                                if (SelectedDosage) {
                                                    let array = [...main_Medicine_Grid]
                                                    array.push({ ItemID: value.ItemID, ItemDiscription: value.ItemDiscription, Dosage: SelectedDosage.Dosage, DosageID: SelectedDosage.DosageID, Days: SelectedDays.Days, DaysID: SelectedDays.DaysID})
                                                    setmain_Medicine_Grid(array)
                                                }
                                            }
                                            else {
                                                setSelectedMedince(null)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select" />}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3}>
                                    <Autocomplete
                                        id="Dosage_cmbDosage"
                                        name="Dosage_cmbDosage"
                                        options={CmbDosage}
                                        value={SelectedDosage}
                                        getOptionLabel={(option) => option.Dosage ? option.Dosage : ""}
                                        onChange={(e, value) => {
                                            debugger
                                            if (value) {
                                                setSelectedDosage(value)
                                                if (SelectedDays) {
                                                    let array = [...main_Medicine_Grid]
                                                    array.push({ ItemID: SelectedMedince.ItemID, ItemDiscription: SelectedMedince.ItemDiscription, Dosage: value.Dosage, DosageID: value.DosageID, Days: SelectedDays.Days, DaysID: SelectedDays.DaysID })
                                                    setmain_Medicine_Grid(array)
                                                }
                                            }
                                            else {
                                                setSelectedDosage(null)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select" />}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={2} lg={2} md={2}>
                                <Autocomplete
                                    id="Days"
                                    name="Days"
                                    options={CmbDays}
                                    value={SelectedDays}
                                    getOptionLabel={(option) => option.Days ? option.Days : ""}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelectedDays(value)
                                            if (SelectedDosage && SelectedMedince) {
                                                let array = [...main_Medicine_Grid]
                                                array.push({ ItemID: SelectedMedince.ItemID, ItemDiscription: SelectedMedince.ItemDiscription, Dosage: SelectedDosage.Dosage, DosageID: SelectedDosage.DosageID, DaysID: value.DaysID, Days: value.Days})
                                                setmain_Medicine_Grid(array)
                                            }
                                        }
                                        else {
                                            setSelectedDays(null)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select" />}
                                    fullWidth
                                />
                            </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top" >
                                <Col xl={2} lg={2} md={2}></Col>
                                <Col xl={8} lg={8} md={8}>
                                    <label> Medicine List </label>
                                    <GridComponent dataSource={main_Medicine_Grid} toolbar={ToolbarItems} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: false, newRowPosition:'Bottom'  }}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='ItemDiscription' headerText='Name' width='120' />
                                            <ColumnDirective field='Dosage' headerText='Dosage Time' width='100' />
                                            <ColumnDirective field='Days' headerText='Days' width='100' />

                                            <ColumnDirective field='DosageID' visible={false} width='120' />
                                            <ColumnDirective field='ItemID' visible={false} width='120' />
                                            <ColumnDirective field='DaysID' visible={false} width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                            </Row>
                        </Card>
                    </>
                );
            //Check up
            case 1:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="Checkup_Pluse"
                                        id="Checkup_Pluse"
                                        label="Pulse"
                                        value={Pluse}
                                        onChange={e => setPluse(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="BloodPresure"
                                        id="BloodPresure"
                                        label=" Blood Pressure "
                                        value={BloodPresure}
                                        onChange={e => setBloodPresure(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="Weight"
                                        id="Weight"
                                        label="Weight"
                                        value={Weight}
                                        onChange={e => setWeight(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="Height"
                                        id="Height"
                                        label=" Height "
                                        value={Height}
                                        onChange={e => setHeight(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top" >
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="Checkup_Temperature"
                                        id="Checkup_Temperature"
                                        label="Temperature"
                                        value={Temperature}
                                        onChange={e => setTemperature(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="Checkup_BloodGroup"
                                        id="Checkup_BloodGroup"
                                        label="Blood Golocouse / Diabities"
                                        value={BloodGolocouse}
                                        onChange={e => setBloodGolocouse(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={3} lg={3} md={3}>
                                    <TextField
                                        name="BG"
                                        id="BG"
                                        label="Blood Group"
                                        value={BG}
                                        onChange={e => setBG(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </>
                );
            //Scan / Lab Test / Ultrasound (Tests)
            case 4:
                return (
                    <>
                        <Card>
                        {isShowScanTest && (<><Row className="row_margin_right row_margin_left row_margin_top">
                            <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <Autocomplete
                                        id="Scan_cmbScan"
                                        name="Scan_cmbScan"
                                        options={CmbScan_LabTest_Ultrasound}
                                        value={SelectedScan_LabTest_Ultrasound}
                                        getOptionLabel={(option) => option.Tests ? option.Tests : ""}
                                        onChange={(e, value) => {

                                            if (value) {
                                                let array = [...main_Test_Grid]

                                                var count = 0
                                                for (let i = 0; i < main_Test_Grid.length; i++) {

                                                    if (main_Test_Grid[i].TestID === value.TestID) {
                                                        count++
                                                    }
                                                }
                                                if (count > 0) {
                                                    Swal.fire({
                                                        icon: 'info',
                                                        title: 'Oops...',
                                                        text: `Already Exist...`,
                                                    })
                                                    return;
                                                }
                                                array.push({ TestID: value.TestID, Tests: value.Tests })
                                                setmain_Test_Grid(array)
                                            }

                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select" />}
                                        fullWidth
                                    />
                                </Col>
                                <Col xl={4} lg={4} md={4} style={{ textAlign: "right" }} >
                                    <Button className="btn btn-primary waves-effect height-of-button" onClick={e => Save_Patient("PendingTest")} > <Save /> Save </Button>
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top" >
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <label> Scan / Lab Test / Ultrasound List </label>
                                    <GridComponent dataSource={main_Test_Grid}  toolbar={ToolbarItems1} actionComplete={afterRowInsertTests} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Tests' headerText='T Name' width='120' />
                                            <ColumnDirective field='TestID'  headerText='ID' visible={false}  width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                            </Row></>)}
                        </Card>
                    </>
                );
            //Symptoms
            case 2:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_right row_margin_left">
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <Autocomplete
                                        id="Symptoms_cmbSymptoms"
                                        name="Symptoms_cmbSymptoms"
                                        options={CmbSymptoms}
                                        value={SelectedSymptoms}
                                        getOptionLabel={(option) => option.Symptoms}
                                        onChange={(e, value) => {
                                            if (value) {
                                                let array = [...main_Symptoms_Grid]

                                                var count = 0
                                                for (let i = 0; i < main_Symptoms_Grid.length; i++) {
                                                    if (main_Symptoms_Grid[i].SymptomID === value.SymptomID) {
                                                        count++
                                                    }
                                                }
                                                if (count > 0) {
                                                    Swal.fire({
                                                        icon: 'info',
                                                        title: 'Oops...',
                                                        text: `Already Exist...`,
                                                    })
                                                    return;
                                                }

                                                array.push({ SymptomID: value.SymptomID, Symptoms: value.Symptoms })

                                                setmain_Symptoms_Grid(array)
                                                setSelectedSymptoms(value)
                                            }
                                            else {
                                                setSelectedSymptoms(null)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select" />}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top" >
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <label> Symptoms List </label>
                                    <GridComponent dataSource={main_Symptoms_Grid}  actionComplete={afterRowInsertSymptoms} toolbar={ToolbarItems} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Symptoms' headerText='S Name' width='120' />
                                            <ColumnDirective field='SymptomID' visible={false} width='120'/>
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                            </Row>
                        </Card>
                    </>
                );
            //Remarks
            case 7:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <TextareaAutosize aria-label="minimum height" value={Remarks} onChange={e => setRemarks(e.target.value)} minrows={5} cols={50} placeholder="Enter Remarks" />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top" >
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <TextField
                                        name="Remarks_Fees"
                                        id="Remarks_Fees"
                                        label="Fees"
                                        value={Fees}
                                        onChange={e => setFees(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </>
                );
            //Test Result
            // case 5:
            //   return(
            //     <>
            //     <Card>
            //       <form>
            //         <Row>
            //             <Col style={{ textAlign: "right" }} >
            //             <Button className="btn btn-primary waves-effect height-of-button" style={{ marginTop: "-1px" }} onClick={Upload_Img} > <Camera /> Image</Button>
            //             </Col>
            //         </Row>
            //         <Row className="row-margin-left row-margin-right row-margin-top" >
            //             <Col sm={4} lg={3} xl={3} >
            //                 <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
            //                     <div style={{ flex: "1", width: "100%" }}>
            //                     <GridComponent dataSource={(Test_Result_Grid)} id='mGrid' rowSelected={Row_Selected_Grid1} width="100%" height="500px" allowSorting={true} allowFiltering={true}  filterSettings={{ type: 'Menu' }}>
            //                             <ColumnsDirective>
            //                                 <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} hideAtMedia={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
            //                                 <ColumnDirective width='100' field='image' headerText='Document Title' textAlign='Left' />
            //                             </ColumnsDirective>
            //                             <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
            //                         </GridComponent>
            //                     </div>
            //                 </div>
            //             </Col>
            //             <Col sm={8} lg={9} xl={9} >
            //             <img width={700} height={550} src={imgSrc} id="img" name="SO" />
            //             &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            //             &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            //             <input type="file" name="img_file" id="img_file" hidden={true} onChange={change_Image} className="between-rows-margin" />
            //             </Col>
            //         </Row>
            //         </form>
            //     </Card>
            //     </>
            //   )
            //Final Diagnosis
            case 5:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={3} lg={3} md={3}>
                                    <Checkbox checked={Is_Provisional_Diagnosis} disabled={chk_disabled} onChange={e => {
                                        var _Checked = e.target.checked
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: "Provisional Diagnosis is Final Diagnosis",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Yes !'
                                        }).then((result) => {
                                            if (result.isConfirmed) {

                                                let array = [...final_disease]
                                                main_Disease_Grid.map((value, index) => {
                                                    array.push({ Diseases: value.Diseases, DiseaseID: value.DiseaseID,PatientDiseaseID: value.PatientDiseaseID  })
                                                })
                                                setFinal_disease(array)
                                                setIs_Provisional_Diagnosis(_Checked)
                                                setChk_disabled(true)
                                            }
                                            else {
                                                setIs_Provisional_Diagnosis(false)
                                            }
                                        })
                                    }} />
                                    {/* tststs */}
                                    <Typography variant="span"> Provisional Diagnosis </Typography>
                                </Col>
                                <Col xl={5} lg={5} md={5}>
                                    <Autocomplete
                                        id="Disease_cmbDisease"
                                        name="Disease_cmbDisease"
                                        options={CmbDisease}
                                        value={SelectedDisease}
                                        getOptionLabel={(option) => option.Diseases}
                                        onChange={(e, value) => {

                                            if (value) {
                                                let array = [...final_disease]

                                                var count = 0
                                                for (let i = 0; i < final_disease.length; i++) {
                                                    if (final_disease[i].DiseaseID === value.DiseaseID) {
                                                        count++
                                                    }
                                                }
                                                if (count > 0) {
                                                    Swal.fire({
                                                        icon: 'info',
                                                        title: 'Oops...',
                                                        text: `Already Exist...`,
                                                    })
                                                    return;
                                                }
                                                array.push({ DiseaseID: value.DiseaseID, Diseases: value.Diseases })
                                                setFinal_disease(array)

                                                //   let newModuleAccessData = [...ModuleAccessData]
                                                //   newModuleAccessData[dataIndex].Allow_Access = e.target.checked 
                                                //  setModuleAccessData(newModuleAccessData)
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select" />}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top">
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>

                                </Col>
                            </Row>
                            <Row className="row_margin_right row_margin_left row_margin_top row_margin_bottom" >
                                <Col xl={3} lg={3} md={3}></Col>
                                <Col xl={5} lg={5} md={5}>
                                    <label> Final Diseases List </label>
                                    <GridComponent dataSource={final_disease} toolbar={ToolbarItems} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Diseases' headerText='Name' width='120' />
                                            <ColumnDirective field='DiseaseID' visible={false} width='120' />
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent>
                                </Col>
                            </Row>
                        </Card>
                    </>
                )
            default:
                return 'Unknown step';
        }
    }
    const afterRowInsertSymptoms = rowValue =>{
        if (rowValue.requestType == "delete") {
            if(rowValue.data[0].PatientSymptomsID){
                setDeletedSymptoms_Data(DeletedSymptoms_Data.concat(rowValue.data))
            }
        }
    }
    const afterRowInsertDisease = rowValue =>{
        if (rowValue.requestType == "delete") {
            if(rowValue.data[0].PatientDiseaseID){
                setDeletedDisease_Data(DeletedDisease_Data.concat(rowValue.data))
            }
        }
    }
    const afterRowInsertTests = rowValue =>{
        if (rowValue.requestType == "delete") {
            if(rowValue.data[0].PatientTestID){
                setDeletedTest_Data(DeletedTest_Data.concat(rowValue.data))
            }
        }
    }
    const Save_Patient = (S_Status) => {

        var Checkup_Data = { Weight,Height,Pulse:Pluse,BP:BloodPresure,Goloco:BloodGolocouse,BG,Temperature:Temperature }
        var Remarks_Data = {  Remarks,  Fees }

        if (S_Status === "PendingTest") {
            var obj = {
                Patient_Name: SelectedPatient.PatientName
            }

            setGrid_Data(main_Test_Grid)
            localStorage.setItem('token', sessionStorage.getItem('token'))
            window.localStorage.setItem("test", JSON.stringify(obj))

            document.getElementById("Test_Report").click()
        }
        SaveCheckup(main_Disease_Grid,
          main_Medicine_Grid,
          Checkup_Data,
          main_Test_Grid,
          main_Symptoms_Grid,
          Remarks_Data, Comp_Id,Project_ID, AppointmentID, S_Status, final_disease,
          DeletedSymptoms_Data,DeletedDisease_Data,DeletedTest_Data,
          (data) => {
            ClearFields()
            Select_ToDay_Patient(PatientHistory_Date)
          })  
    }
    const ClearFields = () => {

        setAppointmentID(0)
        setFather_Gardien_Name("")
        setAge("")
        // setCmbPatient([])
        setSelectedPatient(null)
        setLastAppointmentDate('')
        setConsultedTo('')
        setTemp('')
        setBloodGroup('')
        // setCmbDisease([])
        setSelectedDisease(null)
        // setCmbMedince([])
        setSelectedMedince(null)
        // setCmbDosage([])
        setSelectedDosage(null)
        setCmbScan_LabTest_Ultrasound([])
        setSelectedScan_LabTest_Ultrasound(null)
        // setCmbSymptoms([])
        setSelectedSymptoms(null)

        //History Grid States
        setMedicine_Grid([])
        setSymptoms_Grid([])
        setDisease_Grid([])
        setTest_Grid([])
        setRemarks_Grid([])
        //Grid states
        setmain_Medicine_Grid([])
        setmain_Symptoms_Grid([])
        setmain_Disease_Grid([])
        setmain_Test_Grid([])

        setPluse('')
        setTemperature('')
        setBloodPresure('')
        setBloodGolocouse('')
        setFees(0.00)
        setRemarks('')
        setBG('')
        setWeight('')
        setHeight('')
        setHistory_Wieght("")
        setHistory_Height("")
        setHistory_BP("")
        setHistory_FD([])
        setHistory_Treatment([])
    }
    const Select_Patient_Test_Report = (PatientID, date) => {

        // SelectPatientTestReport(Comp_Id, PatientID ? PatientID : SelectedPatient.PatientID, date ? date : PatientHistory_Date, (data) => {

        //     if (data.length > 0)
        //         setTest_Result_Grid(data)
        // })
    }
    const Select_Disease_List = () => {
        SelectDiseases(Comp_Id,Project_ID, (data) => {
            setCmbDisease(data)
        })
    }
    const Select_Medicine_List = () => {
     
        SelectItemsListWRTProject(Comp_Id, Project_ID,(data) => {
            debugger
            setCmbMedince(data)
        })
    }
    const Select_Dosage_List = () => {
        SelectDosages(Comp_Id,Project_ID, (data) => {
            setCmbDosage(data)
        })
    }
    const Select_Days_List = () =>{
        SelectDays(Comp_Id, Project_ID, (data)=>{
                setCmbDays(data)
        })
    }
    const Select_Test_List = () => {
        SelectTests(Comp_Id,Project_ID, (data) => {

            setCmbScan_LabTest_Ultrasound(data)
        })
    }
    const Select_Symptoms_List = () => {
        SelectSymptoms(Comp_Id,Project_ID, (data) => {
            setCmbSymptoms(data)
        })
    }
    const Select_ToDay_Patient = (date) => {
        setbtnDisabled(true)
        if(UserType == 'Doctor'){
            SelectToDayPatientWRTDoctor(Comp_Id,Project_ID,Doctor_ID,date,(data)=>{
                setbtnDisabled(false)
                setCmbPatient(data)
            })
        }
        else {
            // admin
            SelectToDayPatient(Comp_Id,Project_ID,date,(data)=>{
                setbtnDisabled(false)
                setCmbPatient(data)
            })
        }
        setbtnDisabled(false)
        // SelectToDayPatient(Comp_Id, date, (data) => {

        //     if (PendingPatient && PendingPatient.PatientID) {
        //         data.map(value => {
        //             if (value.PatientID == PendingPatient.PatientID) {

        //                 setSelectedPatient(value)
        //                 Call_Patient(PendingPatient.PatientID, PendingPatient.Date, value.pa_status)
        //                 setPENDING_PATIENT(null, null)
        //             }
        //         })
        //     }

        //     if (UserType == 'Doctor') {
        //         var mArr = []
        //         data.map(value => {
        //             if (value.pa_doctorID == Doctor_ID) {
        //                 mArr.push(value)
        //             }
        //         })
        //         setCmbPatient(mArr)
        //     }
        //     else {
        //         setCmbPatient(data)
        //     }
        // })
    }
    const Call_Patient = (RegistrationID)=>{
        
        CallPatient(Comp_Id,Project_ID,RegistrationID,(mData)=>{
            
            if(mData.dataFound){
                if(mData.LastAppointment_Data.length > 0){
                    setAppointmentID(mData.LastAppointment_Data[0].AppointmentID)
                    setLastAppointmentDate(mData.LastAppointment_Data[0].AppointmentDate)
                }
                if(mData.LastBasic_Data.length > 0){
                    // setTemp()  // missing 
                    setPulse(mData.LastBasic_Data[0].Pulse)
                    setHistory_Wieght(mData.LastBasic_Data[0].Weight)
                    setHistory_BP(mData.LastBasic_Data[0].BP)
                    setTemp(mData.LastBasic_Data[0].Temperature) //missing 
                    setBloodGroup(mData.LastBasic_Data[0].BG)
                    setHistory_Height(mData.LastBasic_Data[0].Height)
                    
                    setPluse(mData.LastBasic_Data[0].Pulse)
                    setWeight(mData.LastBasic_Data[0].Weight)
                    setBloodPresure(mData.LastBasic_Data[0].BP)
                    setTemperature(mData.LastBasic_Data[0].Temperature) //missing 
                    setBG(mData.LastBasic_Data[0].BG)
                    setHeight(mData.LastBasic_Data[0].Height)
                    setBloodGolocouse(mData.LastBasic_Data[0].Goloco)
                }
                if(mData.LastMedicain_Data.length > 0){
                    setMedicine_Grid(mData.LastMedicain_Data)
                }
                if(mData.LastDisease_Data.length > 0){
                    setDisease_Grid(mData.LastDisease_Data)
                    setmain_Disease_Grid(mData.LastDisease_Data)
                }
                if(mData.LastSymptoms_Data.length > 0){
                    setSymptoms_Grid(mData.LastSymptoms_Data)
                    setmain_Symptoms_Grid(mData.LastSymptoms_Data)
                }
                if(mData.LastTest_Data.length > 0){
                    setTest_Grid(mData.LastTest_Data)
                    setmain_Test_Grid(mData.LastTest_Data)
                }
                if(mData.LastRemarks_Data.length > 0){
                    setRemarks_Grid(mData.LastRemarks_Data)
                }
                if(mData.LastFinalDiagnosis_Data.length > 0){
                    setHistory_FD(mData.LastFinalDiagnosis_Data)
                    // setfinal_disease(mData.LastFinalDiagnosis_Data)
                }
            }
            else{
                setAppointmentID(0)
                setLastAppointmentDate('')
                 // setTemp()  // missing 
                 setPulse('')
                 setHistory_Height('')
                 setHistory_BP('')
                 // setBloodGolocouse(mData.LastBasic_Data[0].Goloco) //missing 
                 setBloodGroup('')
                 setHistory_Height('')
                 setMedicine_Grid([])
                 setDisease_Grid([])
                 setSymptoms_Grid([])
                 setTest_Grid([])
                 setRemarks_Grid([])
                 setHistory_FD([])
            }
        })
    }
    // const Call_Patient = (PatientID, date, status) => {

    //     if (!PatientID) {
    //         if (!SelectedPatient) {
    //             Swal.fire({
    //                 icon: 'info',
    //                 title: 'Oops...',
    //                 text: `Select Patient`,
    //             })
    //             return
    //         }
    //     }

        // Select_Patient_Test_Report(PatientID ? PatientID : SelectedPatient.PatientID, date ? date : PatientHistory_Date)
        // var _status = status !== null ? status : SelectedPatient.pa_status

        // CallPatient(PatientID ? PatientID : SelectedPatient.PatientID, date ? date : PatientHistory_Date, _status, (PatientData) => {

        //     if (PendingPatient.PatientID) {
        //         setPluse(PatientData.LastCheckup[0].Pulse)
        //         setTemperature(PatientData.LastCheckup[0].Temprature)
        //         setBloodGolocouse(PatientData.LastCheckup[0].Temprature)
        //         setWeight(PatientData.LastCheckup[0].Weight)
        //         setHeight(PatientData.LastCheckup[0].Height)
        //         setBloodPresure(PatientData.LastCheckup[0].BloodPressure)

        //         PatientData.DiseasesHistory.map(value => {
        //             value.Diseases = value.Disease
        //             value.DiseaseID = value.ID
        //         })
        //         PatientData.SymtomsHistory.map(value => {
        //             value.Symptoms = value.Symptoms
        //             value.SymptomID = value.ID
        //         })
        //         PatientData.TestHistory.map(value => {
        //             value.Tests = value.test
        //             value.TestID = value.ID
        //         })

        //         setmain_Symptoms_Grid(PatientData.SymtomsHistory)
        //         setmain_Disease_Grid(PatientData.DiseasesHistory)
        //         // main_Test_Grid
        //         setmain_Test_Grid(PatientData.TestHistory)
        //     }

        //     window.localStorage.setItem("TurnNo", PatientData.AppointmentData[0].pa_turnNumber)
        //     setTurnNo(PatientData.AppointmentData[0].pa_turnNumber)
        //     setAppointmentID(PatientData.AppointmentData[0].AppointmentID)
        //     setDisease_Grid(PatientData.DiseasesHistory)
        //     setAge(PatientData.PatientGardien_Age[0].Age)
        //     setFather_Gardien_Name(PatientData.PatientGardien_Age[0].Guardien)
        //     setLastAppointmentDate(PatientData.AppointmentData[0].PatientDate)
        //     setMedicine_Grid(PatientData.LastMedicine)
        //     setHistory_FD(PatientData.FinalDisease)
        //     setSelectedScan_LabTest_Ultrasound(PatientData.TestHistory)
        //     setSymptoms_Grid(PatientData.SymtomsHistory)
        //     setTemp(PatientData.LastCheckup[0].Temprature)
        //     setPulse(PatientData.LastCheckup[0].Pulse)
        //     setHistory_Height(PatientData.LastCheckup[0].Height)
        //     setHistory_Wieght(PatientData.LastCheckup[0].Weight)
        //     setHistory_BP(PatientData.LastCheckup[0].BloodPressure)
        //     setBloodGroup(PatientData.LastCheckup[0].Golucose)
        //     setConsultedTo(PatientData.LastCheckup[0].Doctor)
        //     setRemarks_Grid(PatientData.LastRemarks)
        //     setTest_Grid(PatientData.TestHistory)
        // })
    // }
    return ReactDOM.createPortal(
        <>
            <Row className="row_margin_top row_margin_left row_margin_right" >
                <Col lg={6} xl={6} md={6} className="header_title text-white" >
                    <h4>OPD CHECKUP</h4>
                </Col>
            </Row>
            <Card>
            <Row className="row_margin_top row_margin_left row_margin_right pl-4" >
                <Col>                {activeStep === steps.length ? (
                    <>
                    <Row  className="row_margin_top row_margin_left row_margin_right pb-4 pt-4 ">
                        <Col xl={6} lg={6} md={6} sm={6}>
                        <Typography component={'span'} className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={6}>
                        <Button onClick={e => {
                            handleReset()
                            if (e.target.innerText === "Reset") {
                                ClearFields()
                            }
                        }} className={classes.button}>
                            Reset
                        </Button>
                        </Col>
                    </Row>
                        
                        
                    </>
                ) : (
                    <>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <>
                            <div style={{ textAlign: "right" }} >
                                <Button disabled={activeStep === 0} className="btn btn-primary"  onClick={handleBack} >
                                    Back
                                </Button>
                                <Button
                                    // className="btn btn-primary" s
                                    color="primary"
                                    onClick={e => {
                                        handleNext()
                                        if (btn_add) {
                                            var tsts = e.target.innerText;
                                            tsts = tsts.toLowerCase()

                                            if (tsts == "save") {
                                                Save_Patient('Completed')
                                            }
                                        }

                                    }}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? "Save" : "Next"}
                                </Button>
                            </div>
                        </>
                        <Typography component={'span'} className={classes.instructions}>{getStepContent(activeStep)}</Typography>

                        <Link to="/Test-Report" target="_blank" id="Test_Report" ></Link>
                    </>
                )}
                </Col>

            </Row>
            </Card>
        </>, document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    UserType : state.Login_User && state.Login_User.User_Data.User_Type,
    Doctor_ID : state.Login_User && state.Login_User.User_Data.User_id,
    PendingPatient : state.Pending_Patient && state.Pending_Patient.PatientID
})
export default connect(mapStateToProps,{setPENDING_PATIENT, setGrid_Data})(OPDCheckup)