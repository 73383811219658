import { useEffect, useState } from "react";
import { Col } from "react-bootstrap"
const TextAreas = ({placeholder,value,id, onChange})=>{
        const [count, setCount] = useState(0)
        const [readOnly, setReadOnly] = useState(false)
        // debugger
            useEffect(()=>{
                const total = value.length;
                if(total <= 50){
                    setCount(total)
                   
                }
               
            },[value])
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    return (
        <>
            <Col lg={3} xl={3} md={3} ></Col>
            <Col lg={5} xl={5} md={5} >
            <textarea 
           
            id={id}
            rows={4} cols={5}
            placeholder={placeholder} 
            className="mt-3 w-100" style={{border: "1px solid black", outline: "1px solid black"}}
            value={value}
            onChange={onChange}
            onInput={toInputUppercase}
            maxLength={1000}
           
            ></textarea>
            </Col>
            <Col lg={4} xl={4} md={4} ></Col>

            <Col lg={3} xl={3} md={3} ></Col>
            <Col lg={5} xl={5} md={5}  className="text-right" >
            <label>{count}/1000</label>
            </Col>
            <Col lg={4} xl={4} md={4} ></Col>
           
        </>
    )
}

export default TextAreas