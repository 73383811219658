import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptExpense } from '../../../Services/Expense_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'

function ExpenseReport({Comp_Name,Comp_Id,User_Name,User_Id}) {
    const { ProjectID, FromDate, ToDate } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [TotalExpense, setTotalExpense] = useState(0.00)
    useEffect(() => {
        document.title = "Pharmacy / Expense Report" 

        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;

        rptExpense(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
           
           if(mData.length > 0){
               var Totals = mData.splice(-1)
                setTotalExpense(Totals[0].Expenses.split('~')[1])
                setItemData(mData)

           }
        
        })
     
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        mProjects.map(value=>{
            if(value.Project_ID == ProjectID){
                setSelectedProject(value)
            }
        })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={12} md={12} xl={12}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Expense Report</h3>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         {SelectedProject && SelectedProject.ProjectName}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'5%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'5%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
              
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Classification</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Discription</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Expense</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                           ItemData.map((Itemdata, index) => {

                            return(
                                                               
                                <>
                                {
                                    
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.ExpenseDate || Itemdata.ExpenseDate != '' && ReverseDate(Itemdata.ExpenseDate)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ExpenseClassification}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ExpenseType}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Discription}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.Expenses} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                            <tr id='footer_balance'>
                                <td id='footer_td_cb' colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                                <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > Total Expenses</td>
                                <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalExpense}</td>
                            </tr>
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})
export default connect(mapStateToProps)(ExpenseReport)
