import {domain} from "./Common";
import axios from "axios"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2"

export const FinYear = (CompID,UserID,cb) =>{

    axios.get(`${domain}/FinYears/SelectFinYears/${CompID}/${UserID}`)
         .then(response =>{
            cb(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}