import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectPurchaseInvoiceList } from "../../Services/PurchaseInvoiceAPI";
import { set_PI, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'

function PurchaseInvoiceList(props) {
  const [IsLoader, setIsLoader] = useState(false)
    const {  set_PI,Purchase_Invoice, Comp_Id ,p_userid,Group_Id, SHOW_SCREEN} = props;
    const [PI_Data, setPI_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Month: '',
      Project: ''
    })
    useEffect(()=>{
    document.title = " PHARMACY / Purchase Invoice"
    SelectGroupScreenAccess(Group_Id,11,100,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })
        

      SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

          setProjectList(ProjectData)
          if(Purchase_Invoice.PI_data)
          {
            Select_PurchaseInvoice_List(Purchase_Invoice.PI_data.value, Purchase_Invoice.Project_ID)
            setSelected_Month(Purchase_Invoice.PI_data)
            setSelectedProject(ProjectData.find(x => x.Project_ID === Purchase_Invoice.Project_ID))
            set_PI(null,0,false)
          }
          else
          {
            setSelectedProject(ProjectData[0])
            var _date = new Date()
            setSelected_Month(Month_List.find(x => x.value === (_date.getMonth())+1))
            Select_PurchaseInvoice_List((_date.getMonth())+1, ProjectData[0].Project_ID)
          }
        })
    },[])
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const column_PI = [
        {
         name: "PurchaseInvoiceNo",
         label: "Invoice No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "SupplierName",
         label: "Supplier Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "PurchaseDate",
         label: "Purchase Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var PurchaseInvoiceID = PI_Data
             if (PurchaseInvoiceID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/PurchaseInvoiceEntry"} onClick={e => edit_PI(PI_Data[rowIndex.rowIndex].PurchaseInvoiceID)}> <Edit /></Link>
               );
           }
          }
         }
    ];

    const validation = () =>{

      if(!Selected_Month)
      {
        setError_message({Month: 'must be selected', Project: ''})
        return false
      }
      else if(!SelectedProject)
      {
        setError_message({Month: '', Project: 'must be selected'})
        return false
      }

      setError_message({Month: '', Project: ''})
      return true
    }
    const edit_PI = (PurchaseInvoiceID) =>{
       set_PI(Selected_Month,PurchaseInvoiceID,false, SelectedProject.Project_ID)
    }
    const add_PI = () =>{
      if(validation())
      {
        set_PI(Selected_Month,0,true, SelectedProject.Project_ID)
        document.getElementById("btn_navigate").click()
      }
      
    }
  const Month_List = [
      {title:"JANUARY",value:1},
      {title:"FEBURARY",value:2},
      {title:"MARCH",value:3},
      {title:"APRIL",value:4},
      {title:"MAY",value:5},
      {title:"JUNE",value:6},
      {title:"JULY",value:7},
      {title:"AUGUST",value:8},
      {title:"SEPTEMBER",value:9},
      {title:"OCTOBER",value:10},
      {title:"NOVEMBER",value:11},
      {title:"DECEMBER",value:12},
  ]
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
   const Select_PurchaseInvoice_List = (Month, ProjectID) =>{
    showLoader()
    SelectPurchaseInvoiceList(Comp_Id,ProjectID,'PUR',Month,(mData)=>{
      hideLoader() 
      setPI_Data(mData)
    })
   }
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Purchase Invoice</h4>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right m-4" >

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Month(value)

                                    if(SelectedProject)
                                    Select_PurchaseInvoice_List(value.value, SelectedProject.Project_ID)
                                }
                                else
                                {
                                    setSelected_Month(null)
                                    setPI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Month"
                                />}
                        />
                        <span style={{color: 'red'}} > {error_message.Month} </span>
                    </Col>

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)

                                    if(Selected_Month)
                                    Select_PurchaseInvoice_List(Selected_Month.value,value.Project_ID)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                    setPI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Project"
                                />}
                        />
                         <span style={{color: 'red'}} > {error_message.Project} </span>
                    </Col>

            <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
              <Link to="/PurchaseInvoiceEntry" id='btn_navigate' ></Link>
              { btn_add &&(<Link type="button" className="btn btn-primary" to="#" onClick={add_PI} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>    
                        
                <div className="table-responsive" >
              <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={PI_Data}
                        columns={column_PI}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Purchase_Invoice: state.PI && state.PI,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps,{set_PI, SHOW_SCREEN})(PurchaseInvoiceList)
