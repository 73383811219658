import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit , Delete} from '@material-ui/icons'
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { SelectOPTAssignList , SaveOPTAssign,SelectOPTAssign, DeleteOPTAssign } from '../../Services/OPTAssignAPI'
import OPTAssign_Modal from './OPTAssign_Modal';
// import { SelectGroupScreenAccess } from '../../../Services/SecurityAPI'
// import { SHOW_SCREEN } from "../../../React_Redux/Actions"

function OPTAssignList(props) {

    const [OPTAssign_Data, setOPTAssign_Data] = useState([])
    const [Modal_OPTAssign, setModal_OPTAssign] = useState(false)
    const [is_AddNew, setIs_AddNew ] = useState(false)
    const [SelectOPTAssignData,setSelectOPTAssignData] = useState([])
    const { Comp_Id,Project_ID } = props;
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)


    useEffect(()=>{
      document.title= "H M S / OPT Assign "
    //   SelectGroupScreenAccess(props.Group_Id,5,37,(data)=>{
            
    //     setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
    //     setBtn_delete(parseInt(data.Del) === 0 ? false : true)
  
    //     props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
    //     parseInt(data.Edit) === 0 ? false : true,
    //     parseInt(data.Del) === 0 ? false : true,
    //     data.OtherAccess
    //     )
        
    // })    
      SelectOPTAssign_List()
    },[])
    
  const SelectOPTAssign_List = ()=>{
    
    SelectOPTAssignList(Comp_Id,Project_ID,(mData)=>{
        setOPTAssign_Data(mData)
    })
  }
  const show_Modal_OPTAssign = () =>{
    setModal_OPTAssign(true);
}

const hide_Modal_OPTAssign = () =>{

    setModal_OPTAssign(false)
}
  const column_OPTAssign = [
    {
      name: "dopt_id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "u_name",
      label: "Doctor Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "w_name",
      label: "Ward Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "op_name",
      label: "OPT Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "dopt_rent",
      label: "OPT Rent",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "dopt_date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "From_Time",
      label: "From Time",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "To_Time",
      label: "To Time",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {
          var dopt_id = OPTAssign_Data
          if (dopt_id[rowIndex.rowIndex] != null)
            return (
              <>
                <Link to={"#"} onClick={e => {
                  setIs_AddNew(false)
                  Select_OPTAssign(OPTAssign_Data[rowIndex.rowIndex].OPTAssignID)
                 show_Modal_OPTAssign()

                }}> <Edit /></Link> 

                {btn_delete &&(<>|<Link to={"#"} onClick={e => {
                  Delete_OPTAssign(OPTAssign_Data[rowIndex.rowIndex].OPTAssignID)
                }}> <Delete /></Link></>)}
              </>
            );
        }
      }
    },
  ];
  const Select_OPTAssign = (dopt_id) => {
    SelectOPTAssign(Comp_Id,Project_ID,dopt_id,(mdata)=>{
      setSelectOPTAssignData(mdata)
  })
  }
  const add_OPTAssign = () => {
    setIs_AddNew(true)
    setSelectOPTAssignData([])
    show_Modal_OPTAssign()
  }

  

 const Delete_OPTAssign = (dopt_id) =>{
   
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: `Yes, Delete it`,
    cancelButtonText: 'No, cancel!',
    reverseButtons: true
  }).then(async (result) => {
    if (result.isConfirmed) {
      
      await DeleteOPTAssign(Comp_Id,Project_ID,dopt_id, () => {
        SelectOPTAssign_List()
        swalWithBootstrapButtons.fire(
          `Deleted`,
          `Your file has been Deleted.`,
          'success'
        )
      })
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        `Your record is safe :)`,
        'error'
      )
    }
  })
 }
const Save_OPT_Assign = (mOPTAssign,AddNew,callback) =>{
  
  SaveOPTAssign(mOPTAssign,AddNew,()=>{
    hide_Modal_OPTAssign()
    SelectOPTAssign_List();
  })
  callback()
  }
    return ReactDOM.createPortal(
      <>
      <Card>
          <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} sm={6} className="header_title" >
                <h4 className='mt-2 pl-2'> OPT Assign </h4>
            </Col>
            <Col xl={4} lg={4} md={3} sm={2} ></Col>
            <Col xl={2} lg={2} md={3} sm={4} style={{textAlign: "right"}} >
              { btn_add && (<button  className="btn btn-primary mt-2 mr-2"   style={{border: "none"}} onClick={add_OPTAssign}  > <Add />  Create New</button>)}
            </Col>
          </Row>

          <Row className="row_margin_top row_margin_left row_margin_right" >
          <Col style={{zIndex:1}}>         
                <div className="table-responsive" >
                    <MUIDataTable
                        name = "OPTAssign_Datatables"
                        data={OPTAssign_Data}
                        columns={column_OPTAssign}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                </div>
              </Col>
          </Row>
         <OPTAssign_Modal 
            show = { Modal_OPTAssign }
            onHide = { hide_Modal_OPTAssign }
            mSaveOPTAssign = {Save_OPT_Assign}
            addNew = {is_AddNew}
            mdopt = {SelectOPTAssignData}
          />
      </Card>                   
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state =>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
  Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})
export default connect(mapStateToProps)(OPTAssignList)
