import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectStockTransferList =(Comp_Id,ProjectID,Month,Callback) =>{
  axios.get(`${domain}/StockTransfer/SelectStockTransferList/${Comp_Id}/${ProjectID}/${Month}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsWRTWareHouse =(Comp_Id,ProjectID,WareHouseID,Callback) =>{
  axios.get(`${domain}/StockTransfer/SelectItemsWRTWareHouse/${Comp_Id}/${ProjectID}/${WareHouseID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsForInv =(Comp_Id,ProjectID,WareHouseID,Callback) =>{
  axios.get(`${domain}/StockTransfer/SelectItemsForInv/${Comp_Id}/${ProjectID}/${WareHouseID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectBatchDetails =(Comp_Id,ProjectID,ItemID,WareHouseID,Callback) =>{
  axios.get(`${domain}/StockTransfer/SelectBatchDetails/${Comp_Id}/${ProjectID}/${ItemID}/${WareHouseID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectBatchDetailsForSR =(Comp_Id,ProjectID,ItemID,WareHouseID,Callback) =>{
  axios.get(`${domain}/StockTransfer/SelectBatchDetailsForSR/${Comp_Id}/${ProjectID}/${ItemID}/${WareHouseID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxStockTransferNo =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/StockTransfer/GetMaxStockTransferNo/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SelectStockTransferMaster =(Comp_Id,ProjectID,StockTransferID,Callback) =>{
  axios.get(`${domain}/StockTransfer/SelectStockTransferMaster/${Comp_Id}/${ProjectID}/${StockTransferID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectStockTransferDetail =(Comp_Id,ProjectID,StockTransferID,Callback) =>{
    axios.get(`${domain}/StockTransfer/SelectStockTransferDetail/${Comp_Id}/${ProjectID}/${StockTransferID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveStockTransfer = (StockTransferMasterData,StockTransferDetailData,isAddNew,callback) =>{
  axios.post(`${domain}/StockTransfer/SaveStockTransfer`,{
    StockTransferMasterData,
    StockTransferDetailData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

