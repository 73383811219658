import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"
import { GetCurrentDate } from "./../../Services/Common"


import { SelectAdmitedPatients, GetItemsForIPD, SavePatinetMedicain } from '../../Services/IPDBillingAPI'


const PatientMedicainEntry = ({show, onHide,PatientMedicainID, is_add_new,Comp_Id,ProjectID}) => {
    const [Price, setPrice] = useState(0.00)
    const [TotalPrice, setTotalPrice] = useState(0.00)
    const [Qty, setQty] = useState(1)
    const [PatientList, setPatientList] = useState([])
    const [SelectedPatient, setSelectedPatient] = useState(null)
    const [MedicationDate, setMedicationDate] = useState(GetCurrentDate())
    

    const [MedicainList, setMedicainList] = useState([])
    const [SelectedMedicain, setSelectedMedicain] = useState(null)
    const [btn_edit, setBtn_edit] = useState(true)
    const [Err_Msgs , setErr_Msgs]= useState({
        NameErr:'',
        MedicainErr : '',
        PriceErr:'',
        QtyErr:'',
        TotalPriceErr:''

    })
    useEffect(()=>{
        document.title = "Add Patient Services"
        Admitted_Patients(ProjectID)
        GetItems_ForIPD()
    },[])
    const Admitted_Patients = (ProjectID) =>{
        SelectAdmitedPatients(Comp_Id, ProjectID, (mData)=>{
          if(mData.length > 0){
            setPatientList(mData)
          }else{
            setPatientList([])
            setSelectedPatient(null)
          }
        })
      }
      const GetItems_ForIPD = () =>{
        GetItemsForIPD(Comp_Id, ProjectID, (mData)=>{
            setMedicainList(mData)
        })
      }
    const clearFields = () => {
        onHide()
        setSelectedPatient(null)
        setSelectedMedicain(null)
        setPrice(0)
        setQty(1)
        setTotalPrice(0)
    }
    const validation_Handler = () =>{
        if(!SelectedPatient){
            setErr_Msgs({NameErr:'Name is Required', MedicainErr:'', PriceErr:'', QtyErr:'',TotalPriceErr:''})
            return false
        }else if(!SelectedMedicain){
            setErr_Msgs({NameErr:'', MedicainErr:'Medicain is Required', PriceErr:'', QtyErr:'',TotalPriceErr:''})
            return false 
        }else if(Price <= 0){
            setErr_Msgs({NameErr:'', MedicainErr:'', PriceErr:'Price is Required', QtyErr:'',TotalPriceErr:''})
            return false 
        }else if(Qty <= 0){
            setErr_Msgs({NameErr:'', MedicainErr:'', PriceErr:'', QtyErr:'Qty is Required',TotalPriceErr:''})
           return false
        }else if(TotalPrice <= 0){
            setErr_Msgs({NameErr:'', MedicainErr:'', PriceErr:'', QtyErr:'',TotalPriceErr:'Total Price is Required'})
           return false
        }else{
            setErr_Msgs({NameErr:'', MedicainErr:'', PriceErr:'', QtyErr:'',TotalPriceErr:''})
            return true
        }
      }
      const Save_Handler = () =>{
        if(validation_Handler()){
            var MeicainObj = {
                PatientMedicainID: 0,
                PatientID:SelectedPatient.RegistrationID,
                PatientName:SelectedPatient.PatientName,
                ItemID:SelectedMedicain.ItemID,
                Qty:Qty,
                Price:Price,
                TotalPrice:TotalPrice,
                MedicainDate:MedicationDate,
            }
            SavePatinetMedicain(MeicainObj, ()=>{
                clearFields()
            })

        }
      }
  return (
    <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
    <Modal.Header closeButton className="header_title " >
        <Modal.Title > Add Patient Medication </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Card>

            <Row className=" row_margin_top row_margin_left row_margin_right" >
            <Col lg={4} xl={4} md={4} >
            <Autocomplete
                name="PatientName"
                id="PatientName"
                options={PatientList}
                getOptionLabel={(option) => option.PatientName ? option.PatientName : ""}
                value={SelectedPatient}
                onChange={(e, value) => {

                    if (value) {
                        setSelectedPatient(value)
                       
                    }
                    else {
                        setSelectedPatient(null)
                        
                    }

                }}
                renderInput={(params) =>
                    <TextField {...params} name="PatientName" id="PatientName" label="PatientName"
                    />}

            />
                    <span className='text-danger'>{Err_Msgs.NameErr}</span>
        </Col>
        <Col lg={4} xl={4} md={4} >
        <Autocomplete
        name="ItemDiscription"
        id="ItemDiscription"
        options={MedicainList}
        getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
        value={SelectedMedicain}
        onChange={(e, value) => {
            if (value) {
                setSelectedMedicain(value)
                setPrice(value.SaleRate)
                setTotalPrice(value.SaleRate)
               
            }
            else {
                setSelectedMedicain(null)
                setPrice(0)
                setTotalPrice(0)
                setQty(1)
            }

        }}
        renderInput={(params) =>
            <TextField {...params} name="ItemDiscription" id="ItemDiscription" label="ItemDiscription"
            />}

    />
    <span className='text-danger'>{Err_Msgs.MedicainErr}</span>
    </Col>
    <Col lg={4} xl={4} md={4} >
    <TextField
        name="Price"
        id="Price"
        label="Price"
        value={ Price }
        onChange = {e => { setPrice(e.target.value) }}
        type="number"
        fullWidth
    />
    <span className='text-danger'>{Err_Msgs.PriceErr}</span>
</Col> 
    
            </Row>
            <Row className=" row_margin_top row_margin_left row_margin_right" style={{marginTop: "4%", marginBottom: "4%"}} >
            <Col lg={4} xl={4} md={4} >
            <TextField
                name="Qty"
                id="Qty"
                label="Qty"
                value={ Qty }
                onChange = {e => {

                     setQty(e.target.value)
                     setTotalPrice(e.target.value * Price)
                    
                    }}
                type="number"
                fullWidth
            />
            <span className='text-danger'>{Err_Msgs.QtyErr}</span>
        </Col>
        <Col lg={4} xl={4} md={4} >
        <TextField
            name="TotalPrice"
            id="TotalPrice"
            label="TotalPrice"
            value={ TotalPrice }
            onChange = {e => { setTotalPrice(e.target.value) }}
            type="number"
            fullWidth
        />
        <span className='text-danger'>{Err_Msgs.TotalPriceErr}</span>
    </Col>
            <Col lg={4} xl={4} md={4} >
            <TextField
                name="MedicationDate"
                id="MedicationDate"
                label="MedicationDate"
                value={ MedicationDate }
                onChange = {e => { setMedicationDate(e.target.value) }}
                type="date"
                fullWidth
            />
        </Col>
            </Row>
           
            <Row className=" row_margin_top row_margin_left row_margin_right" >
                <Col style={{ textAlign: "right", marginTop: "1%" }} >
                    {btn_edit && (<Button variant="btn btn-primary" style={{ border: "none" }} onClick={Save_Handler} > <Save /> <u>Save</u></Button>)}
                    <Button variant="btn btn-primary" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                </Col>
            </Row>
        </Card>
    </Modal.Body>
</Modal>
  )
}
const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id, 
    ProjectID : state.Global_Projects && state.Global_Projects.GlobalProjects.Project_ID,

})
export default connect(mapStateToProps)(PatientMedicainEntry)
