import React, { useState, useEffect } from 'react';
import './Test_Report.css';
import {connect} from "react-redux"
import { GetCurrentDate } from "../../Services/Common"
import { Col, Row, Table } from "react-bootstrap"

function Test_Report({Comp_Name, UserName, Grid}) {
    const [PatientName, setPatientName] = useState("")
    var [Test_Data, setTest_Data] = useState()
    useEffect(() => {
        
        document.getElementById('TopBar').hidden = true
       document.getElementById('leftsidebar').hidden = true
       document.getElementById('rightsidebar').hidden = true
       document.getElementById('chat-wrapper').hidden = true
       document.getElementById('mainContent').hidden = true

        var test = JSON.parse(localStorage.getItem("test"))
debugger
        setPatientName(test.Patient_Name)
        // setTest_Data(Grid.Grid_Data)
        loadTableData(Grid.Grid_Data)

    }, [])

    function loadTableData(items) {
        const table = document.getElementById("testBody");
      
        items.forEach( (item, index) => {
          
          let row = table.insertRow();
          let SrNo = row.insertCell(0);
          SrNo.innerHTML = index + 1;
          let t_name = row.insertCell(1);
          t_name.innerHTML = item.Tests;
          
        });
      }
    return (
        <div id = 'main_div_invprntthrml'>
            <div id="invoice-POS">

                <center id="top">
                    <div className="info">
                        <h2><b>{Comp_Name}</b></h2>
                    </div>
                </center>

                <div id="mid">
                    <div className="info">
                        <p>
                            <b> Date</b> : {GetCurrentDate()}<br />
                            <b>Patient Name</b> : {PatientName}
                        </p>
                    </div>
                </div>

                <div id="bot">

                <Table striped bordered hover size="sm" >
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: "16px", textAlign: 'center' }}> # </th>
                                        <th style={{ fontSize: "16px", textAlign: 'center' }}> Test Name </th>
                                    </tr>
                                </thead>
                                <tbody id="testBody" >
                                    
                                </tbody>
                            </Table>

                    <div id="legalcopy">
                        <br/>
                        <p className="legal"><strong>Thank you!</strong></p>
                        <br/>
                        <p><b>User:</b> {UserName}</p>
                    </div>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    Comp_Name: state.GlobalVariables.Company.Name,
    UserName: state.Login_User.User_Data.User_name,
    Grid: state.Grid_Data
})

export default connect(mapStateToProps)(Test_Report)
