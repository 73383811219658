import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptSaleSummarySalemanWise,SelectProjectsUser, rptSaleSummary } from '../../../Services/Sale_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'


function SaleSummarySalesManWise({Comp_Name,Comp_Id,User_Name}) {

    const { ProjectID,FromDate,ToDate,User_Id } = useParams();

    const [SalesManData, setSalesManData] = useState([])
    const [TotalSale, setTotalSale] = useState(0)
    const [TotalReturn, setTotalReturn] = useState(0);
    const [Total, setTotal] = useState(0);
    const [SelectedUser, setSelectedUser] = useState(null)
    const [SelectedProject, setSelectedProject] = useState(null)
    const [ItemData, setItemData] = useState([]);
    useEffect(() => {
        document.title = "Pharmacy / Sales Summary Sales Man Wise" 

        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;

        // rptSaleSummarySalemanWise(Comp_Id,ProjectID,FromDate,ToDate,User_Id,(mData)=>{
        //     if(mData.length > 0){
        //     var Totals = mData.splice(-1);
        //     if(Totals[0].SaleInvoiceID !== null){
        //         setTotalSale(Totals[0].SaleInvoiceID.split('-')[1])
        //         setTotalReturn(Totals[0].SaleInvoiceNo.split('-')[1])
        //         setTotal(Totals[0].WareHouse.split('-')[1])
        //     }
        //     setSalesManData(mData)
        //     }
        
        // })
      
        rptSaleSummary(Comp_Id,ProjectID,FromDate,ToDate, User_Id,(mData)=>{
           
                setItemData(mData)  
        
        })
        SelectProjectsUser(ProjectID,(mUser)=>{
            setSelectedUser(mUser[0])
        })
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
          setSelectedProject(mProjects.find(x=> x.Project_ID === parseInt(ProjectID)))
        })
   
    }, [])
    return (
        <>
        
       {/* <div id="main_div_report" >
          
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                 <Col sm={12} md={12} xl={12}>
                    <h3> {Comp_Name} </h3> <br /> <h3>Sales Summary Sales Man Wise</h3>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                        <b>Project:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} style={{textAlign:'left'}}>
                            {SelectedProject && SelectedProject.ProjectName}
                        </Col>
                       
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                        <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                        <b>Sales Man:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6}  style={{textAlign:'left'}}>
                        {SelectedUser && SelectedUser.User_name}
                        </Col>
                    </Row>
                </Col>

            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Date</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >Item</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Payment Type</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >WareHouse</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold",borderTop:"2px solid #000" }} >Discription</th>
                        </tr>
                    </thead>

                     <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                            
                         {
                            SalesManData.map((SalesMandata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{SalesMandata.SaleInvoiceNo}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{SalesMandata.InvoiceDate} </td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{SalesMandata.ItemDiscription}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{SalesMandata.TransactionType}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {SalesMandata.PaymentType} </td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{SalesMandata.WareHouse}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{SalesMandata.Qty}</td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{SalesMandata.Discription}</td>
                                    </tr>
                                  
                                </>
                            ))
                        } 
                           
                        <tr id='footer_balance' >
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total Sale:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalSale}</td>
                        </tr>  
                        <tr >
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total Return:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalReturn}</td>
                        </tr> 
                        <tr >
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {Total}</td>
                        </tr> 
                    </tbody> 
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div> 
        </div>*/}
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Sale Summary</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row>
                </Col>
               
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{FromDate}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{ToDate}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Cust No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Sale Inv No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Sale Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Name</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} ></th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Gross Val.</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Discount</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} ></th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} ></th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Net Value + Tax</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    Itemdata.CustomerID === "Totals" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                     <td colSpan={2} style={{ width: '10%', fontWeight:'bold',textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Invoices</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleInvoiceID}</td>
                                        <td colSpan={2}  style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Totals :</td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.InvoiceAmount}</td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.TotalDiscount} </td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > </td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >  </td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.GrandTotal} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CustomerCode}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleInvoiceNo} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}    >{Itemdata.InvoiceDate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CustomerName}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.InvoiceAmount}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.TotalDiscount} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >  </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.GrandTotal} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        {/* <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Grand Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal}</td>
                        </tr> */}
                    </tbody>
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})

export default connect(mapStateToProps)(SaleSummarySalesManWise)
