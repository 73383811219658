import axios from 'axios'
import { domain } from "../Services/Common"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export const SelectIPDPatientBillList = (Comp_Id,ProjectID,Dated,cb) => {
    axios.get(`${domain}/IPDBilling/SelectIPDPatientBillList/${Comp_Id}/${ProjectID}/${Dated}`)
        .then(response => {
            cb(response.data.Data)
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'opps...',
                text: `${err}`,
              })
        })
}


export const SelectPatinetDetailsWRTRegNo = (Comp_Id,ProjectID,RegistrationNo,cb) => {
    axios.get(`${domain}/IPDBilling/SelectPatinetDetailsWRTRegNo/${Comp_Id}/${ProjectID}/${RegistrationNo}`)
        .then(response => {
            cb(response.data.Data)
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'opps...',
                text: `${err}`,
              })
        })
}

export const SelectDoctorandVisistsDetails = (Comp_Id,ProjectID,RegistrationNo,cb) => {
    axios.get(`${domain}/IPDBilling/SelectDoctorandVisistsDetails/${Comp_Id}/${ProjectID}/${RegistrationNo}`)
        .then(response => {
            cb(response.data.Data)
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'opps...',
                text: `${err}`,
              })
        })
}
export const SaveIPDBill = (IPDMasterData,IPDDetailData,CareTakerData,isAddNew,callback) =>{
  
    axios.post(`${domain}/IPDBilling/SaveIPDBill`,{IPDMasterData,IPDDetailData,CareTakerData,isAddNew })
        .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
        })
        .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const CancleBill =(BillID,IsCancleBill,Callback) =>{
    axios.get(`${domain}/IPDBilling/CancleBill/${BillID}/${IsCancleBill}`)
         .then(response=>{
             Callback(response.data.message)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const DischargeSlip_Report = (Billing_Id, callback) =>{

    axios.get(`${domain}/IPDBilling/SelectIPDBilling/${Billing_Id}`)
         .then(response =>{

            callback(response.data.Data)

         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}

export const SelectIPDBillServices = (Billing_Id, callback) =>{

   axios.get(`${domain}/IPDBilling/SelectIPDBillServices/${Billing_Id}`)
        .then(response =>{

           callback(response.data.Data)

        })
        .catch(err =>{
           Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: `${err}`,
             })
        })
}

export const SelectAdmitedPatients = (Comp_Id, ProjectID, callback) =>{
    axios.get(`${domain}/IPDBilling/SelectAdmitedPatients/${Comp_Id}/${ProjectID}`)
         .then(response =>{
            callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }

 export const SelectPatientServicesWRTPatient = (PatientID, callback) =>{
    axios.get(`${domain}/IPDBilling/SelectPatientServicesWRTPatient/${PatientID}`)
         .then(response =>{
            callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }
 export const SavePatinetServices = (ServicesData,callback) =>{
  
    axios.post(`${domain}/IPDBilling/SavePatinetServices`,{ServicesData })
        .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
        })
        .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const DeletePatientServices = (PatientServicesID, callback) =>{
    axios.get(`${domain}/IPDBilling/DeletePatientServices/${PatientServicesID}`)
         .then(response =>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }
 export const GetItemsForIPD = (Comp_Id, ProjectID, callback) =>{
    axios.get(`${domain}/IPDBilling/GetItemsForIPD/${Comp_Id}/${ProjectID}`)
         .then(response =>{
            callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }
 export const SavePatinetMedicain = (MeicainObj,callback) =>{
  
    axios.post(`${domain}/IPDBilling/SavePatinetMedicain`,{MeicainObj})
        .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
        })
        .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectPatientMedicainWRTPatient = (PatientID, callback) =>{
    axios.get(`${domain}/IPDBilling/SelectPatientMedicainWRTPatient/${PatientID}`)
         .then(response =>{
            callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }

 export const DeletePatientMedicain = (PatientMedicainID, callback) =>{
    axios.get(`${domain}/IPDBilling/DeletePatientMedicain/${PatientMedicainID}`)
         .then(response =>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }

 export const SelectServicesDetails = (Comp_Id,ProjectID,RegistrationNo,cb) => {
    axios.get(`${domain}/IPDBilling/SelectServicesDetails/${Comp_Id}/${ProjectID}/${RegistrationNo}`)
        .then(response => {
            cb(response.data.Data)
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'opps...',
                text: `${err}`,
              })
        })
}

export const SelectMedicainDetails = (Comp_Id,ProjectID,RegistrationNo,cb) => {
    axios.get(`${domain}/IPDBilling/SelectMedicainDetails/${Comp_Id}/${ProjectID}/${RegistrationNo}`)
        .then(response => {
            cb(response.data.Data)
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'opps...',
                text: `${err}`,
              })
        })
}

export const SelectIPDBillMedicains = (Billing_Id, callback) =>{

    axios.get(`${domain}/IPDBilling/SelectIPDBillMedicains/${Billing_Id}`)
         .then(response =>{
 
            callback(response.data.Data)
 
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }