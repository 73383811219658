import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { DischargeSlip } from '../../Services/PendingPatinetListAPI'
import "./ReportArchitect.css"
import PatientInformation from '../IPDRegistration/PatientInformation'
import { connect } from "react-redux"

function Report_DischargeSlip({CompName,Comp_Id,Comp_Address,Comp_Phone,Comp_Mob}) {

    const { Id } = useParams()
    const [CompanyName, setCompanyName] = useState(CompName)
    // const [Address, setAddress] = useState(Comp_Address)
    const [PhoneNo, setPhoneNo] = useState("")
    const [Patient_Name, setPatient_Name] = useState("")
    const [Patient_Age, setPatient_Age] = useState("")
    const [Patient_Gardien, setPatient_Gardien] = useState("")
    const [Patient_Gender, setPatient_Gender] = useState("")
    const [Patient_Address, setPatient_Address] = useState("")
    const [Discharge_Date, setDischarge_Date] = useState("")
    const [RegistrationDate, setRegistrationDate] = useState("")
    const [Doctor_Name, setDoctor_Name] = useState("")
    const [Ward, setWard] = useState("")
    const [Room_No, setRoom_No] = useState("")
    const [Bed_No, setBed_No] = useState("")
    const [CareTaker, setCareTaker] = useState("")
    const [CNIC, setCNIC] = useState("")


    const [HistoryIllness, setHistoryIllness] = useState('')
    const [ClinicalFinding, setClinicalFinding] = useState('')
    const [ProcedurePerformed, setProcedurePerformed] = useState('')
    const [Complications, setComplications] = useState('')
    const [Investigations, setInvestigations] = useState('')

    const [TreatmentGivenAtHospital, setTreatmentGivenAtHospital] = useState('')
    const [MedicalForHome, setMedicalForHome] = useState('')
    const [PrecautionObservedAtHome, setPrecautionObservedAtHome] = useState('')
    const [FollowUp, setFollowUp] = useState('')


    useEffect(() => {

        document.getElementById('TopBar').hidden = true
        document.getElementById('leftsidebar').hidden = true
        document.getElementById('rightsidebar').hidden = true
        document.getElementById('chat-wrapper').hidden = true
        document.getElementById('mainContent').hidden = true

        DischargeSlip(Comp_Id, Id, (data) => {
                                  
            setPatient_Name(data.Data[0].pt_name)
            setPatient_Age(data.Data[0].pt_age)
            setPatient_Gender(data.Data[0].pt_gender)
            setPatient_Gardien(data.Data[0].pt_guardient)
            setPatient_Address(data.Data[0].pt_address)
            setDischarge_Date(data.Data[0].Discharge_Date + " " + data.Data[0].d_DischargeTime)
            setDoctor_Name(data.Data[0].Doctor_Name)
            setWard(data.Data[0].w_name)
            setRoom_No(data.Data[0].Room_Name)
            setBed_No(data.Data[0].Bed_No)
            setCareTaker(data.Data[0].d_CareTaker)
            setCNIC(data.Data[0].d_CareTakerCNIC)
            setRegistrationDate(data.Data[0].RegistrationDate)

            setHistoryIllness(data.Data[0].HistoryIllness)
            setClinicalFinding(data.Data[0].ClinicalFinding)
            setProcedurePerformed(data.Data[0].ProcedurePerformed)
            setComplications(data.Data[0].Complications)
            setInvestigations(data.Data[0].Investigations)
            setTreatmentGivenAtHospital(data.Data[0].TreatmentGivenAtHospital)
            setMedicalForHome(data.Data[0].MedicalForHome)
            setPrecautionObservedAtHome(data.Data[0].PrecautionObservedAtHome)
            setFollowUp(data.Data[0].FollowUp)
        })

        // setTimeout(() => {
        //     window.print()
        // }, 2000);
    }, [])


    return (
        <div className="form-container">
        <div style={{backgroundColor:'white'}}>
        <div   style={{backgroundColor:'white'}}  className='custome_border p-5 MarginWhilePrint page1'>
            <form>
                <Row>
                    <Col className="form_title mt-5">
                        <div> {CompanyName} </div>
                    </Col>
                </Row>
            <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4} ></Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                        <div className='text-center mt-3 mb-3'>Address: &nbsp; &nbsp; {Comp_Address} </div>
                    
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4} ></Col>
                </Row> 
            
                <Row>
                    <Col className="background-color" >
                        <h6> Discharge Slip </h6>
                    </Col>
                </Row>
                <div className='custome_border' style={{width:'95%', padding:'10px'}}>
                            <PatientInformation label="Patient Name:" value={Patient_Name} />
                            <PatientInformation label="Age:" value={Patient_Age} />
                            <PatientInformation label="Gender:" value={Patient_Gender} />
                            <PatientInformation label="Address:" value={Patient_Address} />
                            <PatientInformation label="Care Taker:" value={CareTaker} />
                            <PatientInformation label="Care Taker CNIC:" value={CNIC} />
                            <PatientInformation label="Admission Date:" value={RegistrationDate} />
                            <PatientInformation label="Discharge Date:" value={Discharge_Date} />
                </div>

              
            </form>

            {/* <footer style={{bottom:'0'}}> */}
            <Row>
            <Col className="background-color mt-4" style={{bottom:'0'}} >
                <h6> Phone No {Comp_Phone}  {'   '} Mobile No: {Comp_Mob} </h6>
            </Col>
        </Row>
               

            {/* </footer> */}
        </div>
        </div>
      
        <div   style={{backgroundColor:'white'}}  className='custome_border p-5 page2'>
                        <DischargeDetails title="History of Present illness:"  paragraph={HistoryIllness}/>
                        <DischargeDetails title="Clinical Finding:"  paragraph={ClinicalFinding}/>
                        <DischargeDetails title="Procedure Performed:"  paragraph={ProcedurePerformed}/>
                        <DischargeDetails title="Complications:"  paragraph={Complications}/>
                        <DischargeDetails title="Investigations:"  paragraph={Investigations}/>
                        <DischargeDetails title="Treatment Given at hospital:"  paragraph={TreatmentGivenAtHospital}/>
                        <DischargeDetails title="Medical to be taken at home:"  paragraph={MedicalForHome}/>
                        <DischargeDetails title="Precaution to be observed home:"  paragraph={PrecautionObservedAtHome}/>
                        <DischargeDetails title="Follow up:"  paragraph={FollowUp}/>
                     
                        <p style={{textAlign:'right'}}>------------------------------------------------------</p>
                        <h6 style={{textAlign:'right'}}> Medical Officer Signature </h6>
                        
       
           
        </div>
      
        </div>
    )
}


const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    CompName: state.GlobalVariables.Company.Name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
    Comp_Address: state.GlobalVariables.Company.Address,
    Comp_Phone: state.GlobalVariables.Company.PhoneNo,
    Comp_Mob: state.GlobalVariables.Company.MobileNo
})

export default connect(mapStateToProps)(Report_DischargeSlip)


const DischargeDetails = ({title, paragraph})=>{
   
        return (
            <>
            <Row>
            <Col Col xl={12} lg={12} md={12} sm={12} xs={12}>
             <h4 style={{fontWeight:'bold'}}>{title}</h4> 
            </Col>
            </Row>
            <Row>
            <Col Col xl={12} lg={12} md={12} sm={12} xs={12}>
             <p className='lead text-justify mt-5 ' style={{height:'350px', marginBottom:0}}>{paragraph}</p> 
            </Col>
            </Row>
            </>
        )
    }


