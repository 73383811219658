import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Accordion  } from "react-bootstrap"

import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'

import { setGlobal_Config } from "./../../React_Redux/Actions/index"
import { SelectWareHouseListWRTCompID } from "../../Services/WareHouseAPI";
import {SelectConfigurationData,updateconfiguration } from './../../Services/ControlPanelAPI'

import {GetCurrentDate} from '../../Services/Common'
function ControlPanel({ ConfigurationID,Comp_Id ,ProjectID,setGlobal_Config}) {

    const [Configuration_Data,setConfiguration_Data] = useState({
        ConfigurationID:ConfigurationID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        CompName:'',
        CompAddress:'',
        PhoneNo:'',
        STaxNo:'',
        NTNNo:'',
        Logo:'',
        SaleTaxPer:0.00,
        SaleTaxAmt:0.00,
        DisPer:0.00,
        DisAmt:0.00,
        IsSaleTaxPer:0,
        IsSaleTaxAmt:0,
        IsDisPer:0,
        IsDisAmt:0,
        IsFarmulation:0,
        SaleInvoiceFooterLine1:'',
        SaleInvoiceFooterLine2:'',
        PurchaseInvoiceFooterLine1:'',
        PurchaseInvoiceFooterLine2:'',
        PaymentFooterLine1:'',
        PaymentFooterLine2:'',
        ReciptFooterLine1:'',
        ReciptFooterLine2:'',
        BeforeExpMonth:GetCurrentDate(),
        ApplyTax_on_SaleInvoice:0,
        ApplyTax_on_PurInvoice:0,
        PurRatePer:0.00,
        WareHouseList: [],
          SelectedWareHouse: null,
          SelectedPaymentType:null,
     
      
    })
    const [WareHouseList, setWareHouseList] = useState([])
    const [SelectedWareHouse, setSelectedWareHouse] = useState(null)
    const [SelectedPaymentTypeINV, setSelectedPaymentTypeINV] = useState(null)
    const [SelectedPaymentTypePUR, setSelectedPaymentTypePUR] = useState(null)

    // const [Item_Err, setItem_Err] = useState({
    //     Name_Err:'',
    //     PhoneNo_Err:'',
    //     NTN_Err:'',
    //     Cat_Err: '',
    //     UOM_Err:'',
    //     SUBUOM_Err:''
    // })
  
   const [btn_Edit, setBtn_Edit] = useState(true)

  const  _Options= [
    {value:'Cash Sale',title:'Cash Sale'},
    {value:'Credit Sale',title:'Credit Sale'},
    {value:'Account Sale',title:'Account Sale'},
    {value:'Cash Credit Sale',title:'Cash Credit Sale'},
    {value:'Account Credit Sale',title:'Account Credit Sale'}
  ]

  const  _PUROptions= [
    {value:'Cash Purchase',title:'Cash Purchase'},
    {value:'Credit Purchase',title:'Credit Purchase'},
    {value:'Account Purchase',title:'Account Purchase'},
    {value:'Cash Credit Purchase',title:'Cash Credit Purchase'},
    {value:'Account Credit Purchase',title:'Account Credit Purchase'}
  ]
    useEffect(() => {
    document.title = " PHARMACY / Configuration"

    SelectWareHouseListWRTCompID(Comp_Id,(mWareHouse)=>{
    setWareHouseList(mWareHouse)
    SelectConfigurationData(Comp_Id,ProjectID,(mData)=>{
        setConfiguration_Data({
            ConfigurationID: mData[0].ConfigurationID,
            Comp_Id: Comp_Id,
            ProjectID: ProjectID,
            CompName: mData[0].CompName,
            CompAddress: mData[0].CompAddress,
            PhoneNo: mData[0].PhoneNo,
            STaxNo: mData[0].STaxNo,
            NTNNo: mData[0].NTNNo,
            Logo: mData[0].Logo,
            SaleTaxPer: mData[0].SaleTaxPer,
            DisPer: mData[0].DisPer,
            IsSaleTaxPer: mData[0].IsSaleTaxPer === 1 ? true : false,
            IsSaleTaxAmt: mData[0].IsSaleTaxAmt === 1 ? true : false ,
            IsDisPer: mData[0].IsDisPer === 1 ? true : false,
            IsDisAmt: mData[0].IsDisAmt === 1 ? true : false,
            IsFarmulation: mData[0].IsFarmulation === 1 ? true : false,
            SaleInvoiceFooterLine1: mData[0].SaleInvoiceFooterLine1,
            SaleInvoiceFooterLine2: mData[0].SaleInvoiceFooterLine2,
            PurchaseInvoiceFooterLine1: mData[0].PurchaseInvoiceFooterLine1,
            PurchaseInvoiceFooterLine2: mData[0].PurchaseInvoiceFooterLine2,
            PaymentFooterLine1: mData[0].PaymentFooterLine1,
            PaymentFooterLine2: mData[0].PaymentFooterLine2,
            ReciptFooterLine1: mData[0].ReciptFooterLine1,
            ReciptFooterLine2: mData[0].ReciptFooterLine2,
            BeforeExpMonth: mData[0].BeforeExpMonth,
            ApplyTax_on_PurInvoice:mData[0].ApplyTax_on_PurInvoice === 1 ? true : false,
            ApplyTax_on_SaleInvoice:mData[0].ApplyTax_on_SaleInvoice === 1 ? true : false,
            PurRatePer:mData[0].PurRatePer

        })
        
       mWareHouse.map(val =>{
        if(val.WareHouseID == mData[0].DefaultWareHouseID)
        {
            setSelectedWareHouse(val)
        }
       })

      _Options.map(val =>{
        if(val.value == mData[0].PaymentTypeForINV)
        {
            setSelectedPaymentTypeINV(val)
        }
      })
      _PUROptions.map(val =>{
        if(val.value == mData[0].PaymentTypeForPUR)
        {
            setSelectedPaymentTypePUR(val)
        }
      })
    })
    })

   
       
    }, [])

   
   const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
//   const validate =() =>{
//         var  NameErr=""
//         var PhoneNoErr = ""
        
//         if(!(Customer_Data.CustomerName).trim())
//         {
//          NameErr = "Name is required"
//         }
//         if(!(Customer_Data.PhoneNo).trim())
//         {
//             PhoneNoErr="required"
//         }
      
      
//         if(NameErr)
//         {
//            setItem_Err({...Item_Err,Name_Err:NameErr})
//            document.getElementById('CustomerName').focus()
//             return false
//         }
//      if(PhoneNoErr)
//      {
//         setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:PhoneNoErr})
//         document.getElementById('PhoneNo').focus()
//         return false
//      }
    
     
//      setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:''})

//       return true
    
//     }
    const Save_Handler = () =>{
        // if(validate())
        // {

            var ConfigurationData = {
                ConfigurationID: Configuration_Data.ConfigurationID,
                Comp_Id: Comp_Id,
                ProjectID: ProjectID,
                CompName: Configuration_Data.CompName,
                CompAddress: Configuration_Data.CompAddress,
                PhoneNo: Configuration_Data.PhoneNo,
                STaxNo: parseFloat(Configuration_Data.STaxNo),
                NTNNo: parseFloat(Configuration_Data.NTNNo),
                Logo: Configuration_Data.Logo,
                SaleTaxPer: Configuration_Data.SaleTaxPer,
                DisPer: Configuration_Data.DisPer,
                IsSaleTaxPer: Configuration_Data.IsSaleTaxPer ? 1 : 0,
                IsSaleTaxAmt: Configuration_Data.IsSaleTaxAmt ? 1 : 0,
                IsDisPer: Configuration_Data.IsDisPer ? 1 : 0,
                IsDisAmt: Configuration_Data.IsDisAmt ? 1 : 0,
                IsFarmulation: Configuration_Data.IsFarmulation ? 1 : 0,
                SaleInvoiceFooterLine1: Configuration_Data.SaleInvoiceFooterLine1,
                SaleInvoiceFooterLine2: Configuration_Data.SaleInvoiceFooterLine2,
                PurchaseInvoiceFooterLine1: Configuration_Data.PurchaseInvoiceFooterLine1,
                PurchaseInvoiceFooterLine2: Configuration_Data.PurchaseInvoiceFooterLine2,
                PaymentFooterLine1: Configuration_Data.PaymentFooterLine1,
                PaymentFooterLine2: Configuration_Data.PaymentFooterLine2,
                ReciptFooterLine1: Configuration_Data.ReciptFooterLine1,
                ReciptFooterLine2: Configuration_Data.ReciptFooterLine2,
                BeforeExpMonth: Configuration_Data.BeforeExpMonth,
                ApplyTax_on_PurInvoice: Configuration_Data.ApplyTax_on_PurInvoice ? 1 : 0,
                ApplyTax_on_SaleInvoice: Configuration_Data.ApplyTax_on_SaleInvoice ? 1 : 0,
                PurRatePer: Configuration_Data.PurRatePer,
                DefaultWareHouseID: SelectedWareHouse.WareHouseID == null ? 0 : SelectedWareHouse.WareHouseID,
                PaymentTypeForINV: SelectedPaymentTypeINV.value == null ? '' : SelectedPaymentTypeINV.value,
                PaymentTypeForPUR: SelectedPaymentTypePUR.value == null ? '' : SelectedPaymentTypePUR.value

            }
            debugger
            updateconfiguration(ConfigurationData,()=>{
                setGlobal_Config(ConfigurationData)
                // document.getElementById('btnCancel').click()
            })
        // }
   
    }

    return ReactDOM.createPortal (
        <>
          <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title text-white" >
                        <h4>Control Panel</h4>
                    </Col>
                </Row>
            <Card>
                <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="CompName"
                            id="CompName"
                            label="Company Name"
                            value={Configuration_Data.CompName}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, CompName: e.target.value })}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="PhoneNo"
                            id="PhoneNo"
                            type="number"
                            label="Phone No"
                            value={Configuration_Data.PhoneNo}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, PhoneNo: e.target.value })}
                            fullWidth
                        />
                        {/* <span className="text-danger">{Item_Err.PhoneNo_Err}</span> */}
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="STaxNo"
                            id="STaxNo"
                            label="Sale Tax No"
                            value={Configuration_Data.STaxNo}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, STaxNo: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="NTNNo"
                            id="NTNNo"
                            type="number"
                            label="NTN No"
                            value={Configuration_Data.NTNNo}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, NTNNo: e.target.value })}
                            fullWidth
                        />
                    </Col>

                   
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="BeforeExpMonth"
                            id="BeforeExpMonth"
                            label="Expairy Alert Month"
                            type="number"
                            value={Configuration_Data.BeforeExpMonth}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, BeforeExpMonth: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="DisPer"
                            id="DisPer"
                            label="Dis Per"
                            value={Configuration_Data.DisPer}
                            onInput={toInputUppercase}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, DisPer: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="SaleInvoiceFooterLine1"
                            id="SaleInvoiceFooterLine1"
                            label="Sale Invoice Footer Line 1"
                            onInput={toInputUppercase}
                            value={Configuration_Data.SaleInvoiceFooterLine1}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, SaleInvoiceFooterLine1: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="SaleInvoiceFooterLine2"
                            id="SaleInvoiceFooterLine2"
                            label="Sale Invoice Footer Line 2"
                            onInput={toInputUppercase}
                            value={Configuration_Data.SaleInvoiceFooterLine2}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, SaleInvoiceFooterLine2: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="PurchaseInvoiceFooterLine1"
                            id="PurchaseInvoiceFooterLine1"
                            label="Purchase Invoice Footer Line 1"
                            onInput={toInputUppercase}
                            value={Configuration_Data.PurchaseInvoiceFooterLine1}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, PurchaseInvoiceFooterLine1: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="PurchaseInvoiceFooterLine2"
                            id="PurchaseInvoiceFooterLine2"
                            label="Purchase Invoice Footer Line 2"
                            onInput={toInputUppercase}
                            value={Configuration_Data.PurchaseInvoiceFooterLine2}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, PurchaseInvoiceFooterLine2: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="PaymentFooterLine1"
                            id="PaymentFooterLine1"
                            label="Payment Footer Line 1"
                            onInput={toInputUppercase}
                            value={Configuration_Data.PaymentFooterLine1}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, PaymentFooterLine1: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="PaymentFooterLine2"
                            id="PaymentFooterLine2"
                            label="Payment Footer Line 2"
                            onInput={toInputUppercase}
                            value={Configuration_Data.PaymentFooterLine2}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, PaymentFooterLine2: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="ReciptFooterLine1"
                            id="ReciptFooterLine1"
                            label="Recipt Footer Line 1"
                            onInput={toInputUppercase}
                            value={Configuration_Data.ReciptFooterLine1}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, ReciptFooterLine1: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="ReciptFooterLine2"
                            id="ReciptFooterLine2"
                            label="Recipt Footer Line 2"
                            onInput={toInputUppercase}
                            value={Configuration_Data.ReciptFooterLine2}
                            onChange={e => setConfiguration_Data({ ...Configuration_Data, ReciptFooterLine2: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="PurRatePer"
                                id="PurRatePer"
                                label="Pur Rate %"
                                type="number"
                                value={Configuration_Data.PurRatePer}
                                onChange={e => setConfiguration_Data({ ...Configuration_Data, PurRatePer: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                   
                                   setSelectedWareHouse(value)
                                }
                                else
                                {
                                    setSelectedWareHouse(null)
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="Default WareHouse"
                                />}
                        />
                        
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                    <Autocomplete
                        name="PaymentType"
                        id="PaymentType"
                        options={_Options}
                        getOptionLabel={(option) => option.title ? option.title : ""}
                        value={SelectedPaymentTypeINV}
                        onChange={(e, value) => {
                            if (value)
                            {
                                setSelectedPaymentTypeINV(value)
                          
                            }
                            else
                            {
                                setSelectedPaymentTypeINV(null)
                        
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="PaymentType" label="Payment Type for INV"
                            />}
                    />
                    {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                </Col>
                <Col lg={3} xl={3} md={3} >
                <Autocomplete
                    name="PaymentType"
                    id="PaymentType"
                    options={_PUROptions}
                    getOptionLabel={(option) => option.title ? option.title : ""}
                    value={SelectedPaymentTypePUR}
                    onChange={(e, value) => {
                        if (value)
                        {
                            setSelectedPaymentTypePUR(value)
                      
                        }
                        else
                        {
                            setSelectedPaymentTypePUR(null)
                    
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="PaymentType" label="Payment Type for PUR"
                        />}
                />
                {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
            </Col>
                    </Row>
                   
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={4} xl={4} md={4}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Configuration_Data.ApplyTax_on_SaleInvoice}
                                    id="ApplyTax_on_SaleInvoice"
                                    onChange={e => {
                                        setConfiguration_Data({ ...Configuration_Data, ApplyTax_on_SaleInvoice: e.target.checked })

                                        // setisExpire(!e.target.checked)
                                    }}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Apply on SaleInv"}
                           
                        />

                    </Col>
                    <Col lg={4} xl={4} md={4}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Configuration_Data.ApplyTax_on_PurInvoice}
                                    id="ApplyTax_on_PurInvoice"
                                    onChange={e => {
                                        setConfiguration_Data({ ...Configuration_Data, ApplyTax_on_PurInvoice: e.target.checked })

                                        // setisExpire(!e.target.checked)
                                    }}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Apply on PurInv"}
                           
                        />

                    </Col>
                    <Col lg={4} xl={4} md={4}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Configuration_Data.IsSaleTaxPer}
                                    id="IsSaleTaxPer"
                                    label="Show Sale Tax Per Column"
                                    onChange={e => {
                                        setConfiguration_Data({ ...Configuration_Data, IsSaleTaxPer: e.target.checked })

                                        // setisExpire(!e.target.checked)
                                    }}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Show Sale Tax % Column"}
                           
                        />

                    </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col lg={4} xl={4} md={4}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Configuration_Data.IsSaleTaxAmt}
                                    id="IsSaleTaxAmt"
                                    label="Show Sale Tax Amt Column"
                                    onChange={e => {
                                        setConfiguration_Data({ ...Configuration_Data, IsSaleTaxAmt: e.target.checked })

                                        // setisExpire(!e.target.checked)
                                    }}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Show Sale Tax Amt Column"}
                           
                        />

                    </Col>
                    <Col lg={4} xl={4} md={4}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Configuration_Data.IsDisPer}
                                    id="IsDisPer"
                                    label="Show Dis Per Column"
                                    onChange={e => {
                                        setConfiguration_Data({ ...Configuration_Data, IsDisPer: e.target.checked })

                                        // setisExpire(!e.target.checked)
                                    }}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Show Dis % Column"}
                           
                        />

                    </Col>
                    <Col lg={2} xl={2} md={2}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Configuration_Data.IsDisAmt}
                                    id="IsDisAmt"
                                    label="Show Dis Amt Column"
                                    onChange={e => {
                                        setConfiguration_Data({ ...Configuration_Data, IsDisAmt: e.target.checked })

                                        // setisExpire(!e.target.checked)
                                    }}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Show Dis Amt Column"}
                           
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2}  >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Configuration_Data.IsFarmulation}
                                id="IsFarmulation"
                                label="Show Farmulation on Sale"
                                onChange={e => {
                                    setConfiguration_Data({ ...Configuration_Data, IsFarmulation: e.target.checked })

                                   
                                }}

                                name="Access"
                                color="primary"
                            />
                        }
                        label={"Show Farmulation on Sale"}
                       
                    />
                </Col>
                </Row>
                
                  
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                        {btn_Edit &&(<button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={Save_Handler} > <Save />  Save</button>)}
                        <Link id="btnCancel" className="btn btn-primary" style={{ border: "none" }}  > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
            </Card>
        </> , document.getElementById('mainContent')
    )
}
 
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    CustomerID: state.Configuration && state.Configuration.CustomerID,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps,{setGlobal_Config})(ControlPanel)
