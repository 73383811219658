import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Delete, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import  { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { SelectUserProjects } from '../../Services/Projects'
import { DeletePatientServices, SelectAdmitedPatients, SelectPatientServicesWRTPatient } from '../../Services/IPDBillingAPI'
import PatientServicesEntry from './PatientServicesEntry'


const PatientServicesList = ({Comp_Id,p_userid}) => {
  const [ProjectList, setProjectList] = useState([])
  const [SelectedProject, setSelectedProject] = useState(null)
  const [PatientList, setPatientList] = useState([])
  const [SelectedPatient, setSelectedPatient] = useState(null)
  const [btn_add, setBtn_add] = useState(true)
  const [PatientServicesData, setPatientServicesData] = useState([]) 
  const [IsAddNew, setIsAddNew] = useState(null)
  const [Service_Modal, setService_Modal] = useState(false)
  const [PatientServiceID, setPatientServiceID] = useState(null)

  useEffect(()=>{
    document.title = `Patient Services`
    SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{
      setProjectList(ProjectData)
      setSelectedProject(ProjectData[0])
      Admitted_Patients(ProjectData[0].Project_ID)
      // if(DoctorVisits.Visits_Data)
      // {
        
      //   Select_Visits_List(Comp_Id,DoctorVisits.Visits_Data.SelectedProject.Project_ID,ListDate)
      //   setSelectedProject(ProjectData.find(x => x.Project_ID ===  DoctorVisits.Visits_Data.SelectedProject.Project_ID))
      //   setvisits_Data(null,0,false)
      // }
      // else
      // {
      //   setSelectedProject(ProjectData[0])
      //   Select_Visits_List(Comp_Id,ProjectData[0].Project_ID,ListDate)
      // }
    })
  },[])
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  const show_Modal = () =>{
    setService_Modal(true);
}
const hide_Modal = () =>{
  setService_Modal(false)
    setIsAddNew(null)
    Admitted_Patients(SelectedProject.Project_ID)
}
  const Admitted_Patients = (ProjectID) =>{
    SelectAdmitedPatients(Comp_Id, ProjectID, (mData)=>{
      if(mData.length > 0){
        setPatientList(mData)
        setSelectedPatient(mData[0])
        Select_PatientServices(mData[0].RegistrationID)
      }else{
        setPatientList([])
        setPatientServicesData([])
        setSelectedPatient(null)
      }
      
    })
  }
  const Delete_Handler = (PatientID) =>{
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
       DeletePatientServices(PatientID, ()=>{
        Select_PatientServices(SelectedPatient.RegistrationID)
       })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  const Select_PatientServices = (PatientID) =>{
    
    SelectPatientServicesWRTPatient(PatientID,(mData)=>{
      
      if(mData.length > 0){
        setPatientServicesData(mData)
      }
    })
  }
  const column_PatientServices = [
    {
     name: "Services",
     label: "Service",
     options: {
      filter: true,
      sort: true,
      customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       },  
      
     }
    },
   
    {
     name: "Qty",
     label: "Qty",
     options: {
      filter: true,
      sort: true,
    customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       }, 
      }
    },
    {
        name: "Price",
        label: "Price",
        options: {
         filter: true,
         sort: true,
       customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          }, 
         }
       },
       {
        name: "TotalPrice",
        label: "Total Price",
        options: {
         filter: true,
         sort: true,
       customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          }, 
         }
       },
     {
      name: "action",
      label: "action",
      options: {
       filter: true,
       sort: true,
       customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
        )
       },
       customBodyRender: (dataIndex, rowIndex) => {
         
        var _PatientServiceID =PatientServicesData
        if (_PatientServiceID[rowIndex.rowIndex] != null)
        
          return (
            <Link to={"#"} onClick={e=>Delete_Handler(_PatientServiceID[rowIndex.rowIndex].PatientServicesID)} > <Delete /></Link>
        
          );
          
      }
      }
     }
];
  return ReactDOM.createPortal (
    <>
   <Row className="row_margin_top row_margin_left row_margin_right" >
    <Col lg={6} xl={6} md={6} className="header_title text-white" >
      <h4> Patient Services</h4>
    </Col>
  </Row>
  <Card>
  <Row className="row_margin_top row_margin_left row_margin_right m-4" >
  <Col lg={3} xl={3} md={3} >
      <Autocomplete
          name="Project"
          id="Project"
          options={ProjectList}
          getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
          value={SelectedProject}
          onChange={(e, value) => {
              if (value)
              {
                  setSelectedProject(value)
                  Admitted_Patients(value.Project_ID)
              }
              else
              {
                setSelectedProject(null)
                setPatientList([])
                setSelectedPatient(null)
              }
          }}
          renderInput={(params) =>
              <TextField {...params} name="Project" label="Select Project"
              />}
      />
  </Col>
  <Col lg={3} xl={3} md={3} >
  <Autocomplete
          name="PatientName"
          id="PatientName"
          options={PatientList}
          getOptionLabel={(option) => option.PatientName ? option.PatientName : ""}
          value={SelectedPatient}
          onChange={(e, value) => {
              if (value)
              {
                  setSelectedPatient(value)
                  Select_PatientServices(value.RegistrationID)
                  //  Select_Visits_List(Comp_Id,value.Project_ID,ListDate)
              }
              else
              {
                setSelectedPatient(null)
                setPatientServicesData([])
                //   setIPDRegistration_Data([])
              }
          }}
          renderInput={(params) =>
              <TextField {...params} name="PatientName" label="Select Patient"
              />}
      />
  </Col>

  

<Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
<Link to="#" id='btn_navigate' ></Link>
{btn_add && (<Link variant="btn btn-primary"  to="#" onClick={e =>{
  show_Modal()
  setIsAddNew(true)
}} style={{border: "none"}} > <Add />  Create New</Link> )}

</Col>
  </Row>
  <Row className="row_margin_top row_margin_left row_margin_right" >
  <Col>    
            
    <div className="table-responsive" >
  <MuiThemeProvider theme={theme}>
        <MUIDataTable
            data={PatientServicesData}
            columns={column_PatientServices}
            options={{
            selectableRows: 'none',
            print: false,
            search: true,
            download: false,
            viewColumns: false,
            filter: false,
            }}
        />
        
        </MuiThemeProvider>
    </div>
  </Col>
</Row>
  </Card>
  <PatientServicesEntry
        show = { Service_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        PatientServiceID = {PatientServiceID}
  />
    </>, document.getElementById('mainContent')
  )
}

// export default PatientServicesList

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps)(PatientServicesList)