import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "../DoctorStatistics/ReportArchitect.css";
import { connect } from "react-redux";
import { GetCurrentDate, ThousandSeparater } from "../../Services/Common";

function DoctorStatisticsReport({ CompName }) {
  const [Grid_Data, setGrid_Data] = useState([]);
  const [Doctor_Name, setDoctor_Name] = useState("");
  const [Project, setProject] = useState("");
  const [TotalAdvanceFee, setTotalAdvanceFee] = useState(0.0);
  const [TotalAfterFee, setTotalAfterFee] = useState(0.0);
  const [TotalGross, setTotalGross] = useState(0.0);
  const [TotalValue, setTotalValue] = useState(0.0);

  useEffect(() => {
    document.title = "Doctor Statistics Report";
    document.getElementById("TopBar").hidden = true;
    document.getElementById("leftsidebar").hidden = true;
    document.getElementById("rightsidebar").hidden = true;
    document.getElementById("chat-wrapper").hidden = true;
    document.getElementById("mainContent").hidden = true;

    var data = JSON.parse(localStorage.getItem("Data"));

    setGrid_Data(data && data.DoctorStatisticData);
    loadTableData(data && data.DoctorStatisticData);
    setDoctor_Name(data && data.Doctor.User_name);
    setProject(data && data.Project.ProjectName);

    var Total_AF = 0,
      Total_AfterFee = 0;
    data &&
      data.DoctorStatisticData.map((value) => {
        Total_AfterFee +=
          typeof value.DoctorFee === "string"
            ? value.DoctorFee.replace(/,/g, "")
            : value.DoctorFee;
      });

    setTotalGross(Total_AfterFee);
    //    alert(TotalGross)

    //    localStorage.removeItem("Data")

    //    setTimeout(() => {
    //        window.print()
    //    }, 200);
  }, []);

  function loadTableData(items) {
    const table = document.getElementById("m_body");

    var sum = 0;
    var altrasum = 0;
    items.forEach((item, index) => {
      sum += item.DoctorFee;
      altrasum += item.AltrasoundFee
      let row = table.insertRow();
      let SrNO = row.insertCell(0);
      let Name = row.insertCell(1);
      let Guardient = row.insertCell(2);
      let Age = row.insertCell(3);
      let Phone = row.insertCell(4);
      let Date = row.insertCell(5);
      let AdvanceFee = row.insertCell(6);
      let CheackupAfterFee = row.insertCell(7);
      let AltrasoundFee = row.insertCell(8);

      SrNO.innerHTML = index + 1;
      Name.innerHTML = item.PatientName;
      Guardient.innerHTML = item.Guardian;
      Age.innerHTML = item.Age;
      Phone.innerHTML = item.PhoneNo;
      Date.innerHTML =
        item.AppointmentDate &&
        item.AppointmentDate.split("-").reverse().join("-");
      AdvanceFee.innerHTML = item.FeeType;
      CheackupAfterFee.innerHTML = item.DoctorFee
        ? ThousandSeparater(item.DoctorFee.toFixed(2))
        : "-";
        AltrasoundFee.innerHTML = item.AltrasoundFee
        ? ThousandSeparater(item.AltrasoundFee.toFixed(2))
        : "-";
       
      SrNO.style.textAlign = "center";
      Name.style.textAlign = "center";
      Guardient.style.textAlign = "center";
      Age.style.textAlign = "center";
      Phone.style.textAlign = "center";
      Date.style.textAlign = "center";
      AdvanceFee.style.textAlign = "right";
      CheackupAfterFee.style.textAlign = "right";
      AltrasoundFee.style.textAlign = "right";
    });
    setTotalValue(sum+altrasum)
    const footer = document.getElementById("Expense_Foot");
   
    let row1 = footer.insertRow();
   
    let _TotalGross = row1.insertCell(0);
    let _TotalAltra = row1.insertCell(1)

  
    _TotalGross.innerHTML = `Fee Total`;
    _TotalGross.colSpan = 7;
    _TotalGross.style.fontWeight = "bold";
    _TotalGross.style.textAlign = "right";

    _TotalAltra.innerHTML =ThousandSeparater(altrasum.toFixed(2)); ;
    _TotalAltra.colSpan = 8;
    _TotalAltra.style.fontWeight = "bold";
    _TotalAltra.style.textAlign = "right";

  

    let _TotalGrossvalue = row1.insertCell(1);
    _TotalGrossvalue.innerHTML = ThousandSeparater(sum.toFixed(2));
    _TotalGrossvalue.style.textAlign = "right";
    _TotalGrossvalue.style.fontWeight = "bold";

  
   
  }
  return (
    <>
      <form>
        <Row>
          <Col className="form_title">
            <div> {CompName} </div>
          </Col>
        </Row>
        {/* <Row>
                    <Col xl={4} lg={4} md={4} ></Col>
                    <Col>
                        <div>Address: &nbsp; &nbsp; {Address} </div>
                        <div> Phone No: &nbsp; &nbsp; {PhoneNo} </div>
                    </Col>
                </Row> */}
        <Row>
          <Col className="background-color">
            <h6>
              {" "}
              Doctor Statistics (
              {GetCurrentDate().split("-").reverse().join("-")})
            </h6>
          </Col>
        </Row>
        <Row className="margin-top">
          <Col lg={4} xl={4} md={4}>
            <h5> Doctor Name: {Doctor_Name}</h5>
          </Col>
          <Col lg={4} xl={4} md={4}></Col>
          <Col lg={4} xl={4} md={4} style={{ textAlign: "right" }}>
            <h5> Project: {Project}</h5>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xl={12} md={12} style={{ textAlign: "center" }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th style={{ fontSize: "16px" }}> # </th>
                  <th style={{ fontSize: "16px" }}> Name </th>
                  <th style={{ fontSize: "16px" }}> Gardient </th>
                  <th style={{ fontSize: "16px" }}> Age </th>

                  <th style={{ fontSize: "16px" }}> Phone No. </th>
                  <th style={{ fontSize: "16px" }}> Date </th>
                  <th style={{ fontSize: "16px", textAlign: "right" }}>
                    {" "}
                    Fee Type{" "}
                  </th>
                  <th style={{ fontSize: "16px", textAlign: "right" }}>
                    {" "}
                    Doctor Fee{" "}
                  </th>
                  <th style={{ fontSize: "16px", textAlign: "center" }}>
                 
                  AltrasoundFee Fee
                </th>
                </tr>
              </thead>
              <tbody id="m_body">
                {/* { Grid_Data.map((value, index) =>{
                                     return(<>
                                        <tr>
                                            <td>{value.SrNo}</td>
                                            <td>{value.Name}</td>
                                            <td>{value.Guardient}</td>
                                            <td>{value.Age}</td>
                                            <td>{value.Phone}</td>
                                            <td>{value.Date && (((value.Date).split("-")).reverse()).join("-") }</td>
                                            <td style={{ textAlign: "right" }} >{value.AdvanceFee ? ThousandSeparater((value.AdvanceFee).toFixed(2)) : '-' }</td>
                                            <td style={{ textAlign: "right" }} >{value.CheackupAfterFee ? ThousandSeparater((value.CheackupAfterFee).toFixed(2)) : '-'}</td>
                                        </tr>
                                        </>)
                                    
                                }) } */}
                {/* <tr style={{textAlign: "right"}} >
                                    <td colSpan="6"></td>
                                    <td ><b> Total : {ThousandSeparater((TotalAdvanceFee).toFixed(2))} </b></td>
                                    <td><b>Total :{ThousandSeparater((TotalAfterFee).toFixed(2))} </b></td>
                                </tr>
                                <tr style={{textAlign: "right"}} >
                                    <td colSpan="7"><b>Gross Total</b></td>
                                    <td  ><b>{ThousandSeparater((TotalGross).toFixed(2))} </b></td>
                                </tr> */}
              </tbody>
              <tfoot id="Expense_Foot"></tfoot>
              
            </Table>
                       
            <h4 style={{textAlign:'right'}}>Total Value : {TotalValue}</h4>
                                 
          </Col>
        </Row>
      </form>
    </>
  );
}

const mapStateToProps = (state) => ({
  User_Name: state.Login_User && state.Login_User.User_Data.User_name,
  CompName: state.GlobalVariables.Company.Name,
  ConfigObj: state.Global_Configs && state.Global_Configs.GlobalConfig,
});

export default connect(mapStateToProps)(DoctorStatisticsReport);
