import React, { useState, useEffect } from 'react';
import './InvoicePrintThermal.css';
import {connect} from "react-redux"
import { Row, Col } from "react-bootstrap"
import { ThousandSeparater } from "../../Services/Common"

function TurnReport({Comp_Name, UserName , Comp_Id, CompMobileNo, CompPhoneNo}) {
    const [Turn_Data, setTurn_Data] = useState({})
    useEffect(() => {
        debugger
        document.title = "Turn No Report"
        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;
        var _turn_data = JSON.parse(localStorage.getItem("TokenData"))
         
        if(_turn_data)
        {
            setTurn_Data(_turn_data)
        }
        console.log(_turn_data)
    }, [])
    return (
        <div id = 'main_div_invprntthrml' style={{width: '4in'}}>
            <div id="invoice-POS"  style={{width: '4in'}}>

                <center id="top">
                    <div className="info" style={{color: 'black'}}>
                        <h2><b style={{fontSize:'28px', color: 'black'}}>{ Comp_Name }</b></h2>
                    </div>
                    <Row>
                        <Col lg={12} md={12} sm={12} xm={12} style={{textAlign: "center", marginTop: "7%",fontSize:'28px', color: 'black'}} >
                        <b style={{color: 'black'}}> Token No# {Comp_Id != 3 && Turn_Data.TokenNo} </b>
                        </Col>
                       {/* <Col lg={6} md={6} sm={6}  xm={6} style={{textAlign: "left", fontSize: "50px"}}>

                        </Col>*/}
                        </Row>
                        <Row>
                        <Col lg={12} md={12} sm={12} xm={12}  style={{textAlign: "left",marginBottom: "3%", color: 'black'}}>
                       
                        <b style={{color: 'black', fontSize: '24px'}}>Registration No: </b><span style={{color: 'black', fontSize: '24px'}} >{Turn_Data.RegNo}</span>
                    </Col>
                    </Row>
                        <Row>
                        <Col lg={12} md={12} sm={12} xm={12}  style={{textAlign: "left",marginBottom: "3%", color: 'black'}}>
                        <b style={{color: 'black', fontSize: '24px'}}>Appointment Date: </b><span style={{color: 'black', fontSize: '24px'}}>{Turn_Data.AppointmentDate  }</span>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12} md={12} sm={12} xm={12}  style={{textAlign: "left",marginBottom: "3%", color: 'black'}}>
                        <b style={{color: 'black', fontSize: '24px'}}>Doctor Name: </b><span style={{color: 'black', fontSize: '24px'}}>{ Turn_Data.Doctor }</span>
                    </Col>
                </Row>
                    
                 </center>

                <div>
                    <div className="info">

                        <Row style={{fontSize:'14px', marginTop: '15px', color: 'black'}}>
                       
                    {/*  <Col lg={6} md={6} sm={6} xm={6}>
                        {Turn_Data.AppointmentDate  }
                    </Col>*/}
                       
                     {/*<Col lg={6} md={6} sm={6} xm={6}>
                       { Turn_Data.Doctor }
                    </Col>*/}
                            <Col lg={12} md={12} sm={12} xm={12}>
                            <b style={{color: 'black', fontSize: '24px'}}>Patient Name:</b> <span style={{color: 'black', fontSize: '24px'}}>{Turn_Data.PatientName  }</span> 
                            </Col>
                           {/* <Col lg={6} md={6} sm={6} xm={6}>
                                {Turn_Data.PatientName  }
                            </Col>*/}
                            <hr style={{color:'black'}}></hr>
                            <Col lg={12} md={12} sm={12} xm={12} >
                            <b style={{color: 'black', fontSize: '24px'}}> Guardian Name:</b><span style={{color: 'black', fontSize: '24px'}}> { Turn_Data.Guardian }</span> 
                            </Col>
                            <Col lg={12} md={12} sm={12} xm={12} className= 'mt-2'>
                            <b style={{color: 'black', fontSize: '24px'}}> Age:</b><span style={{color: 'black', fontSize: '24px'}}> { Turn_Data.Age + ' '+Turn_Data.AgeType }</span> 
                            </Col>
                           {/*  <Col lg={6} md={6} sm={6} xm={6}>
                                { Turn_Data.Guardian }
                            </Col>*/}
                           
                            <hr style={{color:'black'}}></hr>
                            <Col lg={12} md={12} sm={12} xm={12} className= 'mt-2'>
                            <b style={{color: 'black', fontSize: '24px'}}>{Turn_Data.IsFirstCheckup? `First Checkup Fee :` : `Doctor Fee :`} </b><span style={{color: 'black', fontSize: '24px'}}>  {Turn_Data.DoctorFee !== null ?  ThousandSeparater((parseFloat(Turn_Data.DoctorFee)).toFixed(2)) : 0 }</span> 
                            </Col>
                           {
                            Turn_Data.IsFirstUltraSound || Turn_Data.IsSecondUltraSound  &&  <Col lg={12} md={12} sm={12} xm={12} className= 'mt-2'>
                            <b style={{color: 'black', fontSize: '24px'}}>{Turn_Data.IsFirstUltraSound? `First Ultrasound Fee :` : `Ultrasound Fee :`} </b><span style={{color: 'black', fontSize: '24px'}}>  {Turn_Data.AltrasoundFee !== null ?  ThousandSeparater((parseFloat(Turn_Data.AltrasoundFee)).toFixed(2)) : 0 }</span> 
                            </Col>
                           } 
                           {/* <Col lg={6} md={6} sm={6} xm={6}>
                               {Turn_Data.DoctorFee !== null ?  ThousandSeparater((parseFloat(Turn_Data.DoctorFee)).toFixed(2)) : 0 }
                            </Col>*/}
                          
                        </Row>
                        <hr style={{color:'black'}}></hr>
                        <Col lg={12} md={12} sm={12} xm={12} className= 'mt-2'>
                        <b style={{color: 'black', fontSize: '24px'}}>Total Fee: </b><span style={{color: 'black', fontSize: '24px'}}>  { parseFloat(Turn_Data.DoctorFee) + parseFloat(Turn_Data.AltrasoundFee) }</span> 
                        </Col>

                       <hr color='black' className='mb-5'/>
                       <p  style={{textAlign: "center", marginTop: "7%",fontSize:'24px',textTransform:'uppercase', color: 'black'}}>Moaziz patient Please wait for your turn</p>
                    </div>
                </div>

                <div id="bot">

                    <div id="legalcopy">
                        <br/>
                        <p className="legal" style={{color: 'black', fontSize: '24px'}}><strong>Thank you!</strong></p>
                        <br/>
                        <p style={{color: 'black'}}><b>User:</b> { UserName }</p>
                        <p style={{fontSize: '14px', color: 'black'}}><b>Ph #:</b> { CompPhoneNo && CompPhoneNo} - {CompMobileNo && CompMobileNo}</p>

                    </div>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    Comp_Name: state.GlobalVariables.Company.Name,
    UserName: state.Login_User.User_Data.User_name,
    Comp_Id : state.GlobalVariables.Company.Branch_Id,
    CompPhoneNo: state.GlobalVariables && state.GlobalVariables.Company.PhoneNo,
    CompMobileNo: state.GlobalVariables && state.GlobalVariables.Company.MobileNo,
})

export default connect(mapStateToProps)(TurnReport)
