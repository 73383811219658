import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";

import { SelectItemsForPUR } from '../../Services/PurchaseInvoiceAPI' 

function PurchaseReturnModal({show, onHide, mSaveStock,SupplierID,Comp_Id,ProjectID}) {

    const [PO_Data, setPO_Data] = useState([])
    
    const [LoadAlll, setLoadAlll] = useState(false)
    useEffect(()=>{
        // SelectItemsWRTMinStock(Comp_Id,ProjectID,(mStockData)=>{
        //     mStockData.map(value=>{
        //         value.chkForSelect = false
        //     })
        //     setPO_Data(mStockData)
        // })

    },[show])

    const column_PO = [
        {
            name: "Select",
            label: "Select",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {
                    var dopt_id = PO_Data
                    if (dopt_id[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={PO_Data[rowIndex.rowIndex].chkForSelect}
                                            id={`chk${rowIndex.rowIndex}`}
                                            onChange={e => {
                                                let newPO_Data = [...PO_Data]
                                                newPO_Data[rowIndex.rowIndex].chkForSelect = e.target.checked 
                                               setPO_Data(newPO_Data)
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </>
                        );
                }
            }
        },
        {
          name: "ItemID",
          label: "ItemID",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "ItemDiscription",
          label: "ItemDiscription",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "UOM",
            label: "UOM",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
      
        {
          name: "Rate",
          label: "Rate",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "BatchNo",
            label: "BatchNo",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "ExpairyDate",
            label: "ExpairyDate",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "TaxPer",
            label: "TaxPer",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "TaxAmt",
            label: "TaxAmt",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "DisPer",
            label: "DisPer",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "DisAmt",
            label: "DisAmt",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
          {
            name: "Amount",
            label: "Amount",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
    ];

    const save_Handler = ()=>{
      var  mArray = []
        for(let i=0; i<PO_Data.length; i++ )
        {
            if(PO_Data[i].chkForSelect)
            {
                PO_Data[i].Qty = PO_Data[i].Qty
                PO_Data[i].PurchaseRate =  PO_Data[i].Rate
                PO_Data[i].DisPer =  PO_Data[i].DisPer 
                PO_Data[i].DisAmt = PO_Data[i].DisAmt 
                PO_Data[i].TaxPer =  PO_Data[i].TaxPer 
                PO_Data[i].TaxAmt = PO_Data[i].TaxAmt 
                PO_Data[i].Value = PO_Data[i].Amount
                // PO_Data[i].Value = PO_Data[i].Qty
                // PO_Data[i].Value = PO_Data[i].DisAmt
                // PO_Data[i].Value = PO_Data[i].TaxAmt
                // PO_Data[i].Value =  PO_Data[i].TaxPer 
                // PO_Data[i].Value =  PO_Data[i].DisPer 
                PO_Data[i].action = 'add'
                mArray.push(PO_Data[i])
            }
            
        }
        if(mArray.length > 0)
        mSaveStock(mArray)
        else
        onHide()

    }


    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Purchase Invoice Items </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                    <Row className=" row_margin_left row_margin_right" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="PurchaseInvoiceNo"
                            label="Purchase Invoice No" 
                            // value={this.state.PurchaseOrderNo}
                            inputProps={
                                {readOnly: false}
                            }
                            onChange={e=>{
                                if(e.target.value.length === 6)
                                {
                                   SelectItemsForPUR(Comp_Id, ProjectID,e.target.value,'PUR',SupplierID, (mStockData) => {
                                    mStockData.map(value => {
                                            value.chkForSelect = false
                                        })
                                        setPO_Data(mStockData)
                                    })
                                   
                                }
                                else
                                {
                                  
                                   
                                }
                               
                               }}
                            fullWidth
                            />
                    </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={LoadAlll}
                                            id="LoadAlll"
                                            label="LoadAlll"
                                            onChange={e => {
                                                setLoadAlll(e.target.checked)
                                                PO_Data.map(value=>{
                                                    if(e.target.checked)
                                                    value.chkForSelect = true
                                                    else
                                                    value.chkForSelect = false
                                                })
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label="Select All"
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <div className="table-responsive" >
                                    <MUIDataTable
                                        name="PO_Datatables"
                                        data={PO_Data}
                                        columns={column_PO}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={save_Handler} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    ProjectID: state.PI && state.PI.Project_ID,
  })
export default connect(mapStateToProps)(PurchaseReturnModal)
