import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectDoctorVisitsList =(Comp_Id,ProjectID,VisitDate,Callback) =>{    
  axios.get(`${domain}/DoctorVisits/SelectDoctorVisitsList/${Comp_Id}/${ProjectID}/${VisitDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          Callback()
     })
}
export const SelectPatientWRTWard =(Comp_Id,ProjectID,WardID,Callback) =>{    
    axios.get(`${domain}/DoctorVisits/SelectPatientWRTWard/${Comp_Id}/${ProjectID}/${WardID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
            Callback()
       })
  }
  export const SaveDoctorVisits = (DoctorVisits,  IsAddNew,cb) =>{

    axios.post(`${domain}/DoctorVisits/SaveDoctorVisits`, 
    {DoctorVisits, IsAddNew}
    )
         .then(response =>{
            
          toast.info(response.data.message, {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
         });
            cb()
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
 }
 export const DeleteDocVisit = (Comp_Id,  VisitID,cb) =>{

  axios.delete(`${domain}/DoctorVisits/DeleteDocVisit/${Comp_Id}/${VisitID}`, 
  {Comp_Id, VisitID}
  )
       .then(response =>{
        
       
          cb(response.data.message  )
       })
       .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}