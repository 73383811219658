import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { makeStyles } from "@material-ui/core/styles";
import { enableRipple , MediaQueryList } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';

import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar, Freeze } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Loader from '../Loader'

import { GetCurrentDate } from '../../Services/Common'
import { SelectSupplierList } from "../../Services/SuppliersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemDetails } from '../../Services/PurchaseOrderAPI'
import { GetMaxPurchaseeInvoiceNo,SavePurchaseInvoice,SelectPurchaseInvoiceMaster,SelectPurchaseInvoiceDetail } from '../../Services/PurchaseInvoiceAPI'
import { SelectConfigData } from '../../Services/ControlPanelAPI'
import { Selectbank } from '../../Services/bankAPI'
import PurchaseOrderModal from './PurchaseOrderModal'
export class PurchaseInvoiceEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        PurchaseInvoiceNo: '',
          SupplierList: [],
          SelectedSupplier: null,
          BankList:[],
          SelectedBank:null,
          SelectedPaymentType:null,
          PurchaseDate: GetCurrentDate(),
          CreateDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Remarks: '',
          PI_Detail_Data: [],
          Supplier_Err: '',
            UOMID:0,
            Bank_Disable_Prop:false,
            Total_Disable_Prop:false,
            Balance_Disable_Prop:false,
            TotalQty:0,
            TotalDisAmt:0,
            TotalTaxAmt:0,
            PurchaseValue:0,
            GrandTotal:0,
            Balance:0,
            TotalPaid:0,
            Discription:'',
            _IsPack:false,
            Pack_Qty:0,
            ItemPackPurRate:0,
            ItemLoosePurRate:0,
            btn_Disabled:false,
        // Modal 
        Stock_Modal : false,
        btn_edit : true,
        IsLoader:false,
        Bank_Err:'',
        //Hide or Show Columns
        DisAmt_Visible: true,
        DisPer_Visible: true,
        SaleTaxAmt_Visible:true,
        SaleTaxPer_Visible:true,
        btn_AddNew:false,
        // frozenColumns: 4
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
    //   this.mediaQuery = window.matchMedia('(max-width: 768px)');
    //   this.mediaQuery.addListener(this.handleMediaQueryChange);
      this.Comp_Id = this.props.Comp_Id 
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
      this._Options= [
          {value:'Cash Purchase',title:'Cash Purchase'},
          {value:'Credit Purchase',title:'Credit Purchase'},
          {value:'Account Purchase',title:'Account Purchase'},
          {value:'Cash Credit Purchase',title:'Cash Credit Purchase'},
          {value:'Account Credit Purchase',title:'Account Credit Purchase'}]
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }

  componentDidMount(){

    document.title = " Pharmacy / Purchase Invoice"

    // this.handleMediaQueryChange(this.mediaQuery);
    Selectbank(this.Comp_Id,this.ProjectID,(mBank)=>{
        this.setState({BankList:mBank})

    })
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse})
       
    SelectSupplierList(this.Comp_Id,this.ProjectID,(mSupplier)=>{
        this.setState({SupplierList:mSupplier})
      
        if(this.props.isAddNew) // save
        {
            this.GetMax_PurchaseeInvoiceNo() 
            // this.setState({SelectedPaymentType: {value:'Cash Purchase',title:'Cash Purchase'},Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
            SelectConfigData(this.Comp_Id, (mData)=>{
                mWareHouse.map(val => {
                    if(val.WareHouseID == mData[0].DefaultWareHouseID)
                    {
                        this.setState({SelectedWareHouse:val,btn_AddNew:true})
                    }
                })
                this._Options.map(val =>{
                    
                    if(val.value == mData[0].PaymentTypeForPUR)
                    {
    
                        this.setState({SelectedPaymentType:val},()=>{
                            if(val.value === 'Account Purchase'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true,
                                     Balance_Disable_Prop:true})
                            }
                            else if (val.value === 'Account Credit Purchase'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false,
                                     Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Credit Purchase'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false,
                                    Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Purchase'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true,
                                     Balance_Disable_Prop:true})
                            }
                        })
                    }
                })
              
            })
        }


        else // update
        {
            this.showLoader()
            SelectPurchaseInvoiceMaster(this.Comp_Id,this.ProjectID,this.props.PurchaseInvoiceID,(mData)=>{
                this.setState({
                    PurchaseInvoiceID:mData[0].PurchaseInvoiceID,
                    PurchaseDate:mData[0].PurchaseDate,
                    Discription:mData[0].Discription,
                    TotalDisAmt:mData[0].TotalDisAmt,
                    TotalTaxAmt:mData[0].TotalTaxAmt,
                    TotalQty:mData[0].TotalQty,
                    GrandTotal:mData[0].GrandTotal,
                    PurchaseValue:mData[0].PurchaseValue,
                    TotalPaid:mData[0].TotalPaid,
                    btn_edit: this.props.show_screen.edit,
                    PurchaseInvoiceNo:mData[0].PurchaseInvoiceNo,
                    btn_Disabled:false,
                })
                this._Options.map(val=>{
                    if(val.value === mData[0].PaymentType )
                    {
                        this.setState({SelectedPaymentType:val},()=>{
                            if(val.value === 'Account Purchase'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                            }
                            else if (val.value === 'Account Credit Purchase'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Credit Purchase'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Purchase'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                            }
                        })  
                    }
                })
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val,btn_AddNew:true})
                    }
                })
                mSupplier.map(val=>{
                    if(val.SupplierID === mData[0].SupplierID)
                    {
                        this.setState({SelectedSupplier:val})
                    }
                })
               
                SelectPurchaseInvoiceDetail(this.Comp_Id,this.ProjectID,this.props.PurchaseInvoiceID,(mDetailData)=>{
                    
                    mDetailData.map((value,index)=>{
                        value.SrNo = index + 1 
                        // value.Qty = value.Qty - value.BonusQty
                    })
                    this.setState({PI_Detail_Data:mDetailData})
                })
            })
            this.hideLoader()
        }
        this.Select_Item_Details()
       })
    })
    
    // Sale Tax Column hide
    SelectConfigData(this.Comp_Id,(mConfigData)=>{


        if (mConfigData[0].ApplyTax_on_PurInvoice === 1)
        {
            this.setState({
             DisAmt_Visible: mConfigData[0].IsDisAmt === 1 ? true : false ,
             DisPer_Visible:mConfigData[0].IsDisPer === 1 ? true : false ,
             SaleTaxAmt_Visible:mConfigData[0].IsSaleTaxAmt === 1 ? true : false ,
             SaleTaxPer_Visible:mConfigData[0].IsSaleTaxPer === 1 ? true : false ,
            },()=>{
                this.grid.refresh()
            })
        }
    })
  }
//   componentWillUnmount() {
//     this.mediaQuery.removeListener(this.handleMediaQueryChange);
//   }
//   handleMediaQueryChange = (mediaQuery) => {
//     const frozenColumns = mediaQuery.matches ? 3 : 6; // Update the number of frozen columns based on the screen size
//     this.setState({ frozenColumns });
//   }
  showLoader = () => {
   
    this.setState({IsLoader:true})
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }
  Select_Item_Details = () =>{
    SelectItemDetails(this.Comp_Id,this.ProjectID,(mItems)=>{ // Api
        this.setState({Item_List:mItems})
    })
  }
  Show_Min_Stock_Modal = ()=>{
      this.setState({Stock_Modal:true})
  }
  Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
}
GetMax_PurchaseeInvoiceNo = () => {
        
        GetMaxPurchaseeInvoiceNo(this.Comp_Id,this.ProjectID,'PUR', (mPurchaseOrderNo) => { // API Function
            this.setState({ PurchaseInvoiceNo: mPurchaseOrderNo })  // set state here 
        })
    }
    useStyles = makeStyles({
        option: {
          "&:hover": {
            backgroundColor: "#8C99E0 !important",
            color:"#fff"
          },
          '&.MuiAutocomplete-option[data-focus="true"]':{  //arrow above or below key
            backgroundColor: "#8C99E0 !important",
            color:"#fff"
          }
        }
      });
      IsPack = (rowValue) =>{
        var disabledProp ;
        if(typeof rowValue.index === 'string')
        {
          disabledProp = true;
        }
        else
        {
          disabledProp=false
        }
          return(<input
          type="checkbox"
          id="IsPack"
          defaultChecked = { rowValue.IsPack }
          name="IsPack"   
          disabled={disabledProp}  
          onChange={(e,value)=>{{
         
            if(e.target.checked){
                // this.setState({ItemLoosePurRate: document.getElementById('PurchaseRate').value})
                document.getElementById('PurchaseRate').value = this.state.ItemPackPurRate
             
            }else{
            //   this.setState({ItemPackPurRate: document.getElementById('PurchaseRate').value})
                document.getElementById('PurchaseRate').value = this.state.ItemLoosePurRate
                
            }
              var chkd = e.target.checked ===true ? 1 : 0
              this.setState({_IsPack:chkd})
              this.Calculate_Total()
          }}}
      />)
      }
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                classes={{
                    option: this.useStyles().option,
                  }}
                defaultValue={this.state.Item_List.find(v => v.ItemID === rowValue.ItemID)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        
                        this.setState({ Selected_item: value,UOMID:value.UOMID,Pack_Qty: value.PackQty, ItemPackPurRate:value.PackPurRate, ItemLoosePurRate:value.PurchaseRate })
                        document.getElementById('UOM').value = value.UOM
                        if(rowValue.IsPack === 1){
                            document.getElementById('PurchaseRate').value = value.PackPurRate
                        }else{
                            document.getElementById('PurchaseRate').value = value.PurchaseRate
                        }
                       
                        document.getElementById('value').value = value.PurchaseRate
                        this.Calculate_Total()

                    }
                    else {
                        this.setState({ Selected_item: null,Pack_Qty:0,ItemPackPurRate:0,ItemLoosePurRate:0})
                        document.getElementById('UOM').value = ''
                        document.getElementById('PurchaseRate').value = ''
                        document.getElementById('value').value = ''

                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
               this.Calculate_Total()
            }}
        />)
    }
    BonusQty= (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            defaultValue={rowValue.BonusQty}
            // onChange = { e => {
            //    this.Calculate_Total()
            // }}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    PurchaseRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="PurchaseRate"
            defaultValue={rowValue.PurchaseRate}
            className="textbox"
            name="PurchaseRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    BatchNo = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BatchNo"
            defaultValue={rowValue.BatchNo}
            className="textbox"
            name="BatchNo"
            onChange = { e => {
                //  this.Calculate_Total()
             }}
        />)
    }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="date"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            onChange = { e => {
                //  this.Calculate_Total()
             }}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    Dis2Per = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Dis2Per"
            defaultValue={rowValue.Dis2Per}
            className="textbox"
            name="Dis2Per"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    Dis2Amt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Dis2Amt"
            defaultValue={rowValue.Dis2Amt}
            className="textbox"
            name="Dis2Amt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
               this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    validate = ()=>{
        var bank_err =''
        if (this.state.SelectedPaymentType) {
            if (this.state.SelectedPaymentType.value === 'Account Purchase' || this.state.SelectedPaymentType.value === 'Account Credit Purchase') {
                if (!this.state.SelectedBank)
                    bank_err = 'required'
            }
        }
      
        
        if (bank_err){
            this.setState({Bank_Err:bank_err})
            return false
        }
       
        return true
    }
    SaveHandler = () => {
        if(this.validate()){
            if(this.state.SelectedSupplier)
            {
                this.setState({Supplier_Err:''})
                if(this.state.PI_Detail_Data.length > 0)
                {
                    
                    var PurchaseData = {
                        PurchaseInvoiceID:this.props.PurchaseInvoiceID,
                        Comp_Id:this.Comp_Id,
                        ProjectID : this.ProjectID,
                        UserID : this.UserID,
                        PurchaseInvoiceNo:this.state.PurchaseInvoiceNo,
                        WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                        SupplierID:this.state.SelectedSupplier.SupplierID,
                        CreateDate:this.state.CreateDate,
                        PurchaseDate:this.state.PurchaseDate, 
                        Discription:this.state.Discription,
                        TotalDisAmt:this.state.TotalDisAmt,
                        TotalTaxAmt:this.state.TotalTaxAmt,
                        TotalQty:this.state.TotalQty,
                        GrandTotal:this.state.GrandTotal,
                        PurchaseValue:this.state.PurchaseValue,
                        IsPost:1,
                        TransactionType:'PUR',
                        PaymentType:this.state.SelectedPaymentType.value,
                        BankID:this.state.SelectedBank ? this.state.SelectedBank.BankID:0,
                        TotalPaid: this.state.SelectedPaymentType.value === 'Cash Credit Purchase' || 
                        this.state.SelectedPaymentType.value === 'Account Credit Purchase' 
                        ? this.state.TotalPaid : this.state.SelectedPaymentType.value === 'Cash Purchase' || this.state.SelectedPaymentType.value === 'Account Purchase' ? this.state.GrandTotal :  this.state.TotalPaid,
                        // TotalPaid:this.state.TotalPaid,
                        Balance:this.state.Balance
                    }
    
                    for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
                    {
                        if( typeof this.state.PI_Detail_Data[i].BatchNo === undefined || this.state.PI_Detail_Data[i].BatchNo === 'undefined' || this.state.PI_Detail_Data[i].BatchNo == '' || this.state.PI_Detail_Data[i].BatchNo == null )
                        {
                            Swal.fire({
                                icon: 'info',
                                text: `Batch No at Sr No ${this.state.PI_Detail_Data[i].SrNo} Must be Entered...`,
                            })
                            return
                        }
                        if(typeof this.state.PI_Detail_Data[i].Qty === 'undefined' || this.state.PI_Detail_Data[i].Qty == '' || this.state.PI_Detail_Data[i].Qty == null )
                        this.state.PI_Detail_Data[i].Qty = 0
                        else if (typeof this.state.PI_Detail_Data[i].Qty === 'string')
                        this.state.PI_Detail_Data[i].Qty =  parseFloat(this.state.PI_Detail_Data[i].Qty)
    
                        if(typeof this.state.PI_Detail_Data[i].BonusQty === 'undefined' || this.state.PI_Detail_Data[i].BonusQty == '' || this.state.PI_Detail_Data[i].BonusQty == null )
                        this.state.PI_Detail_Data[i].BonusQty = 0
                        else if (typeof this.state.PI_Detail_Data[i].BonusQty === 'string')
                        this.state.PI_Detail_Data[i].BonusQty =  parseFloat(this.state.PI_Detail_Data[i].BonusQty)
    
                        if(typeof this.state.PI_Detail_Data[i].TaxPer === 'undefined' || this.state.PI_Detail_Data[i].TaxPer == '' || this.state.PI_Detail_Data[i].TaxPer == null )
                        this.state.PI_Detail_Data[i].TaxPer = 0
                        else if (typeof this.state.PI_Detail_Data[i].TaxPer === 'string')
                        this.state.PI_Detail_Data[i].TaxPer =  parseFloat(this.state.PI_Detail_Data[i].TaxPer)
    
                        if(typeof this.state.PI_Detail_Data[i].DisPer === 'undefined' || this.state.PI_Detail_Data[i].DisPer == '' || this.state.PI_Detail_Data[i].DisPer == null )
                        this.state.PI_Detail_Data[i].DisPer = 0
                        else if (typeof this.state.PI_Detail_Data[i].DisPer === 'string')
                        this.state.PI_Detail_Data[i].DisPer =  parseFloat(this.state.PI_Detail_Data[i].DisPer)

                        if(typeof this.state.PI_Detail_Data[i].Dis2Per === 'undefined' || this.state.PI_Detail_Data[i].Dis2Per == '' || this.state.PI_Detail_Data[i].Dis2Per == null )
                        this.state.PI_Detail_Data[i].Dis2Per = 0
                        else if (typeof this.state.PI_Detail_Data[i].Dis2Per === 'string')
                        this.state.PI_Detail_Data[i].Dis2Per =  parseFloat(this.state.PI_Detail_Data[i].Dis2Per)
    
                        if(typeof this.state.PI_Detail_Data[i].TaxAmt === 'undefined' || this.state.PI_Detail_Data[i].TaxAmt == '' || this.state.PI_Detail_Data[i].TaxAmt == null )
                        this.state.PI_Detail_Data[i].TaxAmt = 0
                        else if (typeof this.state.PI_Detail_Data[i].TaxAmt === 'string')
                        this.state.PI_Detail_Data[i].TaxAmt =  parseFloat(this.state.PI_Detail_Data[i].TaxAmt)
    
                        if(typeof this.state.PI_Detail_Data[i].DisAmt === 'undefined' || this.state.PI_Detail_Data[i].DisAmt == '' || this.state.PI_Detail_Data[i].DisAmt == null )
                        this.state.PI_Detail_Data[i].DisAmt = 0
                        else if (typeof this.state.PI_Detail_Data[i].DisAmt === 'string')
                        this.state.PI_Detail_Data[i].DisAmt =  parseFloat(this.state.PI_Detail_Data[i].DisAmt)

                        if(typeof this.state.PI_Detail_Data[i].Dis2Amt === 'undefined' || this.state.PI_Detail_Data[i].Dis2Amt == '' || this.state.PI_Detail_Data[i].Dis2Amt == null )
                        this.state.PI_Detail_Data[i].Dis2Amt = 0
                        else if (typeof this.state.PI_Detail_Data[i].Dis2Amt === 'string')
                        this.state.PI_Detail_Data[i].Dis2Amt =  parseFloat(this.state.PI_Detail_Data[i].Dis2Amt)
    
                        if(typeof this.state.PI_Detail_Data[i].PurchaseRate === 'undefined' || this.state.PI_Detail_Data[i].PurchaseRate == '' || this.state.PI_Detail_Data[i].PurchaseRate == null )
                        this.state.PI_Detail_Data[i].PurchaseRate = 0
                        else if (typeof this.state.PI_Detail_Data[i].PurchaseRate === 'string')
                        this.state.PI_Detail_Data[i].PurchaseRate =  parseFloat(this.state.PI_Detail_Data[i].PurchaseRate)
                        
                        if(typeof this.state.PI_Detail_Data[i].Value === 'undefined' || this.state.PI_Detail_Data[i].Value == '' || this.state.PI_Detail_Data[i].Value == null )
                        this.state.PI_Detail_Data[i].Value = 0
                        else if (typeof this.state.PI_Detail_Data[i].Value === 'string')
                        this.state.PI_Detail_Data[i].Value =  parseFloat(this.state.PI_Detail_Data[i].Value)
                    }
                    this.setState({btn_Disabled:true})
                    
                    SavePurchaseInvoice(PurchaseData,this.state.PI_Detail_Data,this.props.isAddNew,()=>{
                        
                        this.setState({btn_Disabled:false})
                        document.getElementById('btnCancel').click()
                    })
                }
                else
                { 
                    Swal.fire({
                        icon: 'info',
                        text: `Purchase Details Must be Entered...`,
                    })
                    return
                }
            }
            else
            {
                this.setState({Supplier_Err:'Supplier is required'})
            }
        }
      
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
                        rowValue.data.ItemID = this.state.Selected_item.ItemID
                        rowValue.data.UOMID = this.state.UOMID
                        rowValue.data.IsPack = this.state._IsPack ? 1 : 0
                        rowValue.data.ItemLoosePurRate = this.state.ItemLoosePurRate
                        rowValue.data.ItemPackPurRate  = this.state.ItemPackPurRate
                 
                        if( rowValue.data.IsPack === 1){
                            if(rowValue.data.PurchaseRate == 0 || rowValue.data.PurchaseRate == null
                            || rowValue.data.PurchaseRate == '' || rowValue.data.PurchaseRate == undefined)
                           
                            {
                                Swal.fire({
                                    icon: 'info',
                                    text: `Please Enter Pack Qty in Items Form for Item Sr.No ${rowValue.data.SrNo}`,
                                })
                                this.grid.closeEdit()
                
                            }
                        }
                       
                        if (rowValue.data.PurchaseInvoiceDetailID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action
           

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.PI_Detail_Data.length + 1
                    rowValue.data.BatchNo = '0001'
                    rowValue.data.Qty = 1
                    rowValue.data.BonusQty = 0
                    rowValue.data.DisPer = 0.00
                    rowValue.data.DisAmt = 0.00
                    rowValue.data.Dis2Per = 0.00
                    rowValue.data.Dis2Amt = 0.00
                    rowValue.data.TaxPer = 0.00
                    rowValue.data.TaxAmt = 0.00

                    this.setState({ Selected_item: null,UOMID:0,_IsPack:false,Pack_Qty:0,ItemLoosePurRate:0,ItemPackPurRate:0 })
                }
            }
            // if (rowValue.requestType === 'beginEdit') {
            // }
        }

    }
    afterRowInsert = (rowValue) => {
        
        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID },UOMID: rowValue.rowData.UOMID,
            ItemLoosePurRate: rowValue.rowData.ItemLoosePurRate, ItemPackPurRate: rowValue.rowData.ItemPackPurRate, _IsPack: rowValue.rowData.IsPack === 1 ? true : false })
          
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values()
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Values()
        }
        
        if(rowValue.requestType === 'add')
        {
            document.getElementById("ExpairyDate").value = GetCurrentDate()
        }
    }
    Calculate_Total = () => {
        var Qty = 0, Rate = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0, Dis2Per = 0, Dis2Amt = 0,valueForDis2=0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

        Rate = document.getElementById('PurchaseRate').value == "" ? 0 : parseFloat(document.getElementById('PurchaseRate').value)
        if(this.state.DisPer_Visible === true)
        {
            DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)

        }
        Dis2Per = document.getElementById('Dis2Per').value == "" ? 0 : parseFloat(document.getElementById('Dis2Per').value)
        if(this.state.SaleTaxPer_Visible === true ){
            TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)
        }

        DisAmt = parseFloat((( (Qty * Rate)/ 100) * DisPer).toFixed(2))
        valueForDis2 = parseFloat(((Qty * Rate)-DisAmt).toFixed(2))
        Dis2Amt = parseFloat((valueForDis2/100)*Dis2Per).toFixed(2)
        TaxAmt = parseFloat((((Qty * Rate) / 100) * TaxPer).toFixed(2))
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt - Dis2Amt) + TaxAmt)).toFixed(2))
        if(this.state.DisAmt_Visible){
            document.getElementById('DisAmt').value = DisAmt;

        }
        document.getElementById('Dis2Amt').value = Dis2Amt;
        if(this.state.SaleTaxAmt_Visible){
            document.getElementById('TaxAmt').value = TaxAmt;

        }
        document.getElementById('value').value = Total_Value;

    }
    Calculate_Values = () =>{
        var _TotalQty = 0, _TotalDis = 0, _TotalTax = 0, _TotalPur = 0, _GrandTotal = 0, Rate=0, Qty=0
        for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
        {
           if( typeof this.state.PI_Detail_Data[i].Qty === 'string' && typeof this.state.PI_Detail_Data[i].BonusQty === 'string'  && typeof this.state.PI_Detail_Data[i].DisAmt === 'string' && typeof this.state.PI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.PI_Detail_Data[i].PurchaseRate === 'string' && typeof this.state.PI_Detail_Data[i].value === 'string'  )
           {
            this.state.PI_Detail_Data[i].Qty = parseFloat((this.state.PI_Detail_Data[i].Qty).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].BonusQty = parseFloat((this.state.PI_Detail_Data[i].BonusQty).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].DisAmt = parseFloat((this.state.PI_Detail_Data[i].DisAmt).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].TaxAmt = parseFloat((this.state.PI_Detail_Data[i].TaxAmt).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].PurchaseRate = parseFloat((this.state.PI_Detail_Data[i].PurchaseRate).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].Value = parseFloat((this.state.PI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (this.state.PI_Detail_Data[i].Qty == '' && this.state.PI_Detail_Data[i].BonusQty == '' && typeof this.state.PI_Detail_Data[i].DisAmt === '' && typeof this.state.PI_Detail_Data[i].TaxAmt === '' && typeof this.state.PI_Detail_Data[i].PurchaseRate === '' && typeof this.state.PI_Detail_Data[i].value === '' )
           {
            this.state.PI_Detail_Data[i].Qty = 0 
            this.state.PI_Detail_Data[i].BonusQty = 0 
            this.state.PI_Detail_Data[i].DisAmt = 0
            this.state.PI_Detail_Data[i].TaxAmt = 0
            this.state.PI_Detail_Data[i].PurchaseRate = 0
           this.state.PI_Detail_Data[i].Value = 0
           }
           _TotalQty += parseFloat(this.state.PI_Detail_Data[i].Qty) + parseFloat(this.state.PI_Detail_Data[i].BonusQty)
           _TotalDis = parseFloat(this.state.PI_Detail_Data[i].DisAmt) +  parseFloat(this.state.PI_Detail_Data[i].Dis2Amt)
           _TotalTax += parseFloat(this.state.PI_Detail_Data[i].TaxAmt)
           _TotalPur += parseFloat(this.state.PI_Detail_Data[i].Qty * this.state.PI_Detail_Data[i].PurchaseRate)
            _GrandTotal += parseFloat(this.state.PI_Detail_Data[i].Value)
        }
        this.setState({TotalQty:_TotalQty,TotalDisAmt:_TotalDis,TotalTaxAmt:_TotalTax,PurchaseValue:_TotalPur.toFixed(2),GrandTotal:_GrandTotal.toFixed(2)})
      
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        this.setState({ PI_Detail_Data: this.state.PI_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.PI_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray}, ()=>{
                this.Calculate_Values()
            })
        })
        this.Hide_Min_Stock_Modal()
        
    }
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
           <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Purchase Invoice</h4>
            </Col>
          </Row>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="PurchaseInvoiceNo"
                            label="Purchase Invoice No" 
                            value={this.state.PurchaseInvoiceNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="SupplierName"
                            id="SupplierName"
                            options={this.state.SupplierList}
                            getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                            value={this.state.SelectedSupplier}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedSupplier:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedSupplier:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="SupplierName" label="Supplier"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value,btn_AddNew:true})
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null,btn_AddNew:false})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.PurchaseDate}
                            name="PurchaseDate"
                            label="Purchase Date" 
                            type= 'date'
                            onChange= {e => this.setState({PurchaseDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PaymentType"
                            id="PaymentType"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedPaymentType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedPaymentType:value},()=>{
                                        if(value.value === 'Account Purchase'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                        else if (value.value === 'Account Credit Purchase'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Credit Purchase'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Purchase'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                    })
                              
                                }
                            
                                else
                                {
                                    this.setState({SelectedPaymentType:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PaymentType" label="Payment Type"
                                />}
                        />
                        {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                
                    
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="BankName"
                            id="BankName"
                            options={this.state.BankList}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            value={this.state.SelectedBank}
                            disabled={this.state.Bank_Disable_Prop}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedBank:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedBank:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="BankName" label="BankName"
                                />}
                        />
                        <span className="text-danger">{this.state.Bank_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalPaid"
                            label="TotalPaid"
                            type="number"
                            value={this.state.TotalPaid}
                            // onInput={this.toInputUppercase}
                            disabled={this.state.Total_Disable_Prop}
                            onChange={e=>this.setState({TotalPaid:e.target.value}
                                ,()=>{
                                  let bal =   this.state.TotalPaid - this.state.GrandTotal
                                  this.setState({Balance:bal})
                                }
    )}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Balance"
                            label="Balance" 
                            type="number"
                            value={this.state.Balance}
                            // onInput={this.toInputUppercase}
                            disabled={this.state.Balance_Disable_Prop}
                            onChange={e=>this.setState({Balance:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField
                            name="Discription"
                            label="Reference Invoice No" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                </Row>
             
                    
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent gridLines='Both' enableStickyHeader={true} frozenColumns={4} dataSource={this.state.PI_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: this.state.btn_AddNew, newRowPosition:'Top'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='120' field='IsPack' headerText='Is Pack' editTemplate={this.IsPack} template= {this.IsPack}  ></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'text'  editTemplate={this.ItemDiscription} edit={this.cbParams} validationRules={this.NAmeValidation} textAlign='center'/> {/*validationRules={true} */}
                                   
                                    <ColumnDirective width='100' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='center'/>
                                    <ColumnDirective width='140' field='BonusQty' headerText='BonusQty' editType="number" editTemplate={this.BonusQty} textAlign='center'/>
                                    <ColumnDirective width='150' field='PurchaseRate' headerText='PurchaseRate'   editTemplate={this.PurchaseRate} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    {/* <ColumnDirective width='170' field='Expairy' headerText='Expiry Date'  editTemplate={this.Expairy} textAlign='right'></ColumnDirective> */}
                                   
                                    <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType= 'text'  editTemplate={this.BatchNo}   edit={this.cbParams}  validationRules={this.NAmeValidation} textAlign='Left'/>
                                    <ColumnDirective width='250' field='ExpairyDate' headerText='ExpairyDate' editType= 'date'  editTemplate={this.ExpairyDate}   edit={this.cbParams}  validationRules={this.NAmeValidation} textAlign='Left'/>
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis 1%'   editTemplate={this.DisPer} visible={this.state.DisPer_Visible} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='Dis1 Amt'   editTemplate={this.DisAmt} visible={this.state.DisAmt_Visible} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Dis2Per' headerText='Dis 2%'   editTemplate={this.Dis2Per}  textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Dis2Amt' headerText='Dis2 Amt'   editTemplate={this.Dis2Amt}  textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText='Tax%'   editTemplate={this.TaxPer} visible={this.state.SaleTaxPer_Visible} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' headerText='TaxAmt'   editTemplate={this.TaxAmt} visible={this.state.SaleTaxAmt_Visible} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='right'></ColumnDirective>
                                    
                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='PurchaseInvoiceDetailID' headerText='PurchaseInvoiceDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseOrderDetailID' defaultValue="0"  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseInvoiceID' headerText='PurchaseInvoiceID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='UOMID' headerText='UOMID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='ItemLoosePurRate' defaultValue={0}  visible={false}></ColumnDirective>
                                    <ColumnDirective field='ItemPackPurRate' defaultValue={0}  visible={false}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar, Freeze]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalQty"
                            label="TotalQty" 
                            value={this.state.TotalQty}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalQty:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDisAmt"
                            label="TotalDisAmt" 
                            value={this.state.TotalDisAmt}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalDisAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt"
                            label="TotalTaxAmt" 
                            value={this.state.TotalTaxAmt}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalTaxAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={2} >
                        <TextField
                            name="PurchaseValue"
                            label="PurchaseValue" 
                            value={this.state.PurchaseValue}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({PurchaseValue:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={2} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            value={this.state.GrandTotal}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            
                            fullWidth
                        />
                    </Col>
                    </Row>
                  
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                             <Link id="btnGetItems" className="btn btn-primary" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Items from Purchase Order</u></Link>
                        
                            {this.state.btn_edit && (
                            <button 
                            type="button" 
                            className="btn btn-primary"  
                            style={{border: "none"}} 
                            onClick={this.SaveHandler}
                            disabled={this.state.btn_Disabled}
                            > 
                            {this.state.btn_Disabled ? (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Saving... </>) : <> <Save /> Save </>}</button>)}
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'/PurchaseInvoiceList'} > <Cancel /> <u>Close</u></Link>
                            <Link target={"_blank"}
                             className="btn btn-primary" 
                             style={{border: "none"}} 
                             to={'/PurchaseReport'} 
                             onClick={e =>{

                                var obj = {
                                    PurchaseInvoiceID:this.state.PurchaseInvoiceID,
                                    InvoiceNo: this.state.InvoiceNo ,
                                    PurchaseDate:this.state.PurchaseDate,
                                    Discription:this.state.Discription,
                                    TotalDisAmt:this.state.TotalDisAmt,
                                    TotalTaxAmt:this.state.TotalTaxAmt,
                                    TotalQty:this.state.TotalQty,
                                    GrandTotal:this.state.GrandTotal,
                                    PurchaseValue:this.state.PurchaseValue,
                                    Supplier: this.state.SelectedSupplier,
                                    Warehouse: this.state.SelectedWareHouse,
                                    PaymentType: this.state.SelectedPaymentType,
                                    Bank: this.state.SelectedBank,
                                    Detail: this.state.PI_Detail_Data
                                }

                                localStorage.setItem("PurchaseInvoice", JSON.stringify(obj))
                             }}
                             > <Print /> <u>Print</u></Link>
                        </Col>
                    </Row>
                    <PurchaseOrderModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        SupplierID={this.state.SelectedSupplier && this.state.SelectedSupplier.SupplierID}
                        projectID = { this.ProjectID }
                    />
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                  />
                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.PI && state.PI.isAddNew,
    PurchaseInvoiceID:state.PI && state.PI.PurchaseInvoiceID,
    Project_ID:state.PI && state.PI.Project_ID,
    show_screen: state.SCREEN,
   ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
})
export default connect(mapStateToProps)(PurchaseInvoiceEntry)