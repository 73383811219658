import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectPurchaseOrderList =(Comp_Id,ProjectID,SupplierID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderList/${Comp_Id}/${ProjectID}/${SupplierID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemDetails =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectItemDetails/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxPurchaseNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/GetMaxPurchaseNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectPurchaseOrderMaster =(Comp_Id,ProjectID,PurchaseOrderID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderMaster/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectPurchaseOrderDetail =(Comp_Id,ProjectID,PurchaseOrderID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderDetail/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SavePurchaseOrder = (PurchaseOrderMasterData,PurchaseOrderDetailData,isAddNew,callback) =>{

  axios.post(`${domain}/PurchaseOrder/SavePurchaseOrder`,{
    PurchaseOrderMasterData,PurchaseOrderDetailData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

export const SelectItemsWRTMinStock =(Comp_Id,ProjectID,SupplierID,Callback) =>{

    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTMinStock/${Comp_Id}/${ProjectID}/${SupplierID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  
  export const SelectItemsWRTMaxStock =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTMaxStock/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectItemsWRTReOrderStock =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTReOrderStock/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SelectPOWRTINV =(Comp_Id,ProjectID, PurchaseOrderID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectPOWRTINV/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectItemsWRTMinStockForDashboard =(Comp_Id,ProjectID,Callback) =>{
    
    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTMinStockForDashboard/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  