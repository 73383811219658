import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit,Report,Print } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectOPDRegistrationList } from "../../Services/OPDRegistrationAPI";
import { setOPD_Regstration, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
// import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'

function OPDRegistrationList(props) {
  const [IsLoader, setIsLoader] = useState(false)
    const {  setOPD_Regstration,OPD_Reg, Comp_Id ,p_userid,Group_Id, SHOW_SCREEN} = props;
    const [OPDRegistration_Data, setOPDRegistration_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Month: '',
      Project: ''
    })
    
    useEffect(()=>{
    document.title = "OPD Registration"
//     SelectGroupScreenAccess(Group_Id,11,100,(data)=>{
            
//       setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
//       setBtn_delete(parseInt(data.Del) === 0 ? false : true)

//       SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
//       parseInt(data.Edit) === 0 ? false : true,
//       parseInt(data.Del) === 0 ? false : true,
//       data.OtherAccess
//       )
      
//   })
        

      SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

          setProjectList(ProjectData)
          if(OPD_Reg.RegData)
          {
            
            Select_OPDRegistration_List(OPD_Reg.RegData.Selected_Month.value, OPD_Reg.RegData.SelectedProject.Project_ID)
            setSelected_Month(OPD_Reg.RegData.Selected_Month)
            setSelectedProject(ProjectData.find(x => x.Project_ID ===  OPD_Reg.RegData.SelectedProject.Project_ID))
            setOPD_Regstration(null,0,false,0)
          }
          else
          {
            setSelectedProject(ProjectData[0])
            var _date = new Date()
            setSelected_Month(Month_List.find(x => x.value === (_date.getMonth())+1))
            Select_OPDRegistration_List((_date.getMonth())+1, ProjectData[0].Project_ID)
          }
        })
    },[])
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const column_OPDReg = [
        {
         name: "RegistrationNo",
         label: "Registration No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "TokenNo",
            label: "Token No",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },  
             
            }
           },
        {
         name: "PatientName",
         label: "Patient Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
            name: "Guardian",
            label: "Guardian",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "Age",
            label: "Age",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "AgeType",
            label: "AgeType",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "PhoneNo",
            label: "PhoneNo",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
        {
         name: "AppointmentDate",
         label: "Appointment Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        {
            name: "Doctor",
            label: "Doctor",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "Status",
            label: "Status",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "DoctorFee",
            label: "DoctorFee",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var RegistrationID =OPDRegistration_Data
             var AppointmentID  =OPDRegistration_Data
             var TokenNo =OPDRegistration_Data
             var PatientName =OPDRegistration_Data
             var Guardian =OPDRegistration_Data
             var AppointmentDate =OPDRegistration_Data
             var Doctor =OPDRegistration_Data
             var DoctorFee =OPDRegistration_Data
             var Age =OPDRegistration_Data
             var AgeType =OPDRegistration_Data
             var IsFirstCheckup = OPDRegistration_Data
             var IsSecondFee = OPDRegistration_Data
             var IsFirstUltraSound = OPDRegistration_Data
             var IsSecondUltraSound = OPDRegistration_Data
             var AltrasoundFee = OPDRegistration_Data
       
             if (RegistrationID[rowIndex.rowIndex] != null)
             
               return (
                <>
                 <Link to={"/OPDRegistrationEntry"} onClick={  e => {
                
                  edit_OPDRegistration(RegistrationID[rowIndex.rowIndex].RegistrationID
                  ,AppointmentID[rowIndex.rowIndex].AppointmentID )
                
                }}> <Edit /></Link>| 
                <Link to="/InvoicePrintThermal" target="_blank"
                //  ref={ReportButton_Ref} 
                 onClick={e=>{
               var mobj = {
                RegNo: TokenNo[rowIndex.rowIndex].RegistrationNo,
                TokenNo: TokenNo[rowIndex.rowIndex].TokenNo,
                PatientName: PatientName[rowIndex.rowIndex].PatientName,
                Guardian: Guardian[rowIndex.rowIndex].Guardian,
                AppointmentDate: AppointmentDate[rowIndex.rowIndex].AppointmentDate,
                Doctor: Doctor[rowIndex.rowIndex].Doctor,
                DoctorFee: DoctorFee[rowIndex.rowIndex].DoctorFee,
                Age: Age[rowIndex.rowIndex].Age,
                AgeType: AgeType[rowIndex.rowIndex].AgeType,
                IsFirstCheckup :  IsFirstCheckup[rowIndex.rowIndex].IsFirstCheckup,
                IsSecondFee :  IsSecondFee[rowIndex.rowIndex].IsSecondFee,
                IsFirstUltraSound :  IsFirstUltraSound[rowIndex.rowIndex].IsFirstUltraSound,
                IsSecondUltraSound :  IsSecondUltraSound[rowIndex.rowIndex].IsSecondUltraSound,
                AltrasoundFee :  AltrasoundFee[rowIndex.rowIndex].AltrasoundFee,
               }
                  localStorage.setItem('TokenData',JSON.stringify(mobj))
                  //  localStorage.setItem('token',sessionStorage.getItem('token'))
                  // TurnReport(ItemCode[rowIndex.rowIndex])
                }} > <Print /> </Link>
                </>
               );
               
           }
          }
         }
    ];
    
    const validation = () =>{

      if(!Selected_Month)
      {
        setError_message({Month: 'must be selected', Project: ''})
        return false
      }
      else if(!SelectedProject)
      {
        setError_message({Month: '', Project: 'must be selected'})
        return false
      }

      setError_message({Month: '', Project: ''})
      return true
    }
    const edit_OPDRegistration = (RegistrationID,AppointmentID) =>{
        var mobj = {
            Selected_Month,
            SelectedProject
        }
       setOPD_Regstration(mobj,RegistrationID,false,AppointmentID)
    }
    const add_OPDRegistration = () =>{
      if(validation())
      {
        var mobj = {
            Selected_Month,
            SelectedProject
        }
        setOPD_Regstration(mobj,0,true,0)
        document.getElementById("btn_navigate").click()
      }
      
    }
  const Month_List = [
      {title:"JANUARY",value:1},
      {title:"FEBURARY",value:2},
      {title:"MARCH",value:3},
      {title:"APRIL",value:4},
      {title:"MAY",value:5},
      {title:"JUNE",value:6},
      {title:"JULY",value:7},
      {title:"AUGUST",value:8},
      {title:"SEPTEMBER",value:9},
      {title:"OCTOBER",value:10},
      {title:"NOVEMBER",value:11},
      {title:"DECEMBER",value:12},
  ]
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
   const Select_OPDRegistration_List = (Month, ProjectID) =>{
    showLoader()
    SelectOPDRegistrationList(Comp_Id,ProjectID,Month,(mData)=>{
      hideLoader()
        setOPDRegistration_Data(mData)
    })
   }
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> OPD Patient Registration</h4>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right m-4" >

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Month(value)

                                    if(SelectedProject)
                                    Select_OPDRegistration_List(value.value, SelectedProject.Project_ID)
                                }
                                else
                                {
                                    setSelected_Month(null)
                                    setOPDRegistration_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Month"
                                />}
                        />
                        <span style={{color: 'red'}} > {error_message.Month} </span>
                    </Col>

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)

                                    if(Selected_Month)
                                    Select_OPDRegistration_List(Selected_Month.value,value.Project_ID)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                    setOPDRegistration_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Project"
                                />}
                        />
                         <span style={{color: 'red'}} > {error_message.Project} </span>
                    </Col>

            <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
              <Link to="/OPDRegistrationEntry" id='btn_navigate' ></Link>
              { btn_add &&(<Link type="button" className="btn btn-primary" to="/OPDRegistrationEntry" onClick={add_OPDRegistration} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>    
                        
                <div className="table-responsive" >
              <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={OPDRegistration_Data}
                        columns={column_OPDReg}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  OPD_Reg: state.OPD_Reg && state.OPD_Reg,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps,{setOPD_Regstration, SHOW_SCREEN})(OPDRegistrationList)
