import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import Loader from '../Loader'
import {GetMaxRegistrationCode,SelectBedNoWRTRoom,SaveIPDRegistration,SelectIPDRegistrationData,SelectAllBedNoWRTRoom} from '../../Services/IPDRegistrationAPI'
import { SelectRoomCategory} from '../../Services/RoomCategoryAPI'
import {SelectWards} from '../../Services/WardsAPI'
import { SelectRoomsWRTWard } from '../../Services/BedsAPI'
import { GetCurrentDate } from '../../Services/Common'
const IPDRegistrationEntry = ({Comp_Id,ProjectID,isAddNew,UserID,RegistrationID}) => {

   const [RoomList, setRoomList] = useState([])
   const [SelectedRoom, setSelectedRoom] = useState(null)
   const [BedNoList, setBedNoList] = useState([])
   const [SelectedBedNo, setSelectedBedNo] = useState(null)
   const [RoomCategoryList, setRoomCategoryList] = useState([])
   const [SelectedRoomCategory, setSelectedRoomCategory] = useState(null)
    const [SelectedGender, setSelectedGender] = useState([])
    const [WardsList, setWardsList] = useState(null)
    const [SelectedWards, setSelectedWards] = useState(null)
    const [IsLoader, setIsLoader] = useState(false)
    const [Registration_Data, setRegistration_Data] = useState({
        RegistrationID:RegistrationID,
        UserID:UserID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        RegistrationNo:'',
        PatientName:'',
        Guardian:'',
        Age:1,
        Gender:'',
        PhoneNo:'',
        CNIC:'',
        Address:'',
        RegistrationDate:GetCurrentDate(),
        RegistrationFee:1,
        Status:'ADMITTED',
        CreatedDate:GetCurrentDate(),
        CancleRegistration:0,
        btn_Disabled:false,
    }) 
    const [Registration_Err, setRegistration_Err] = useState({
        Name_Err: '',
        Phone_Err: '',
        CNIC_Err: '',
        Ward_Err: '',
        Room_Err:'',
        Bedno_Err:'',
        RoomCategory_Err:''
    })
    const validate =() =>{
        var  NameErr=""
        var PhoneErr = ""
        var CNICErr = ""
        var WardErr = ""
        var RoomErr = ""
        var BednoErr = ""
        var RoomcategoryErr = ""

        if(!Registration_Data.PatientName)
        {
         NameErr = "Patient Name is required"
        }
        if(!Registration_Data.PhoneNo)
        {
            PhoneErr = "Phone No is required"
        }
     
     
        if(!Registration_Data.CNIC)
        {
            CNICErr="required"
        }
        if(!SelectedWards)
        {
            WardErr="required"
        }
        if(!SelectedRoom)
        {
            RoomErr="required"
        }
        if(!SelectedBedNo)
        {
            BednoErr="required"
        }
        if(!SelectedRoomCategory)
        {
            RoomcategoryErr="required"
        }
        if(NameErr)
        {
           setRegistration_Err({...Registration_Err,Name_Err:NameErr})
           document.getElementById('PatientName').focus()
            return false
        }
        
        if(PhoneErr)
        {
            setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:PhoneErr})
            document.getElementById('PhoneNo').focus()
             return false
        }
     if(CNICErr)
     {
        setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:CNICErr})
        document.getElementById('CNIC').focus()
         return false
     }
     if(WardErr)
     {
        setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:'',Ward_Err:WardErr})
        document.getElementById('Ward').focus()
        return false
     }
     if(RoomErr)
     {
        setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:'',Ward_Err:'',Room_Err:RoomErr})
        document.getElementById('Room').focus()
        return false
     }
     if(BednoErr)
     {
        setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:'',Ward_Err:'',Room_Err:'',Bedno_Err:BednoErr})
        document.getElementById('BedNo').focus()
        return false
     }
     if(RoomcategoryErr)
     {
        setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:'',Ward_Err:'',Room_Err:'',Bedno_Err:'',RoomCategory_Err:RoomcategoryErr})
        document.getElementById('RoomCategory').focus()
        return false
     }
     setRegistration_Err({...Registration_Err,Name_Err:'',Phone_Err:'',CNIC_Err:'',Ward_Err:''})
      return true
    }
    useEffect(()=>{
        document.title = "IPD Registration"
        showLoader()
        SelectRoomCategory(Comp_Id, ProjectID,(mRoomCategory)=>{
            hideLoader()
            setRoomCategoryList(mRoomCategory)
            showLoader()
        SelectWards(Comp_Id,ProjectID,(mWards)=>{
            hideLoader()
            setWardsList(mWards)
      
            if (isAddNew) {
                showLoader()
                GetMaxRegistrationCode(Comp_Id,ProjectID,(mData)=>{
                    hideLoader()
                    setRegistration_Data({...Registration_Data,RegistrationNo:mData})
                })
            }else{
                showLoader()
                SelectIPDRegistrationData(RegistrationID,(mData)=>{
                    
                    setRegistration_Data({
                                    RegistrationID:RegistrationID,
                                    Comp_Id:mData[0].Comp_Id,
                                    ProjectID:mData[0].ProjectID,
                                    UserID:mData[0].UserID,
                                    RegistrationNo:mData[0].RegistrationNo,
                                    PatientName: mData[0].PatientName,
                                    Guardian: mData[0].Guardian,
                                    Age: mData[0].Age,
                                    PhoneNo: mData[0].PhoneNo,
                                    CNIC: mData[0].CNIC,
                                    Address: mData[0].Address,
                                    RegistrationDate:mData[0].RegistrationDate,
                                    RegistrationFee:mData[0].RegistrationFee,
                                    CreatedDate: mData[0].CreatedDate,
                                    Status: mData[0].Status,
                                    CancleRegistration:mData[0].CancleRegistration,
                                
                    })
                
                    Gender_List.map(val=>{
                       
                        if(val.value == mData[0].Gender)
                        {
                            setSelectedGender(val)
                        }
                    })
                    mWards.map(x=>{
                        
                        if(x.WardID === mData[0].WardID){
                            setSelectedWards(x)
                            SelectRoomsWRTWard(mData[0].WardID,Comp_Id,ProjectID,(mRooms)=>{
                                setRoomList(mRooms)
                                mRooms.map(y=>{
                                    if(y.RoomID === mData[0].RoomID){
                                        setSelectedRoom(y)
                                        SelectAllBedNoWRTRoom(Comp_Id,ProjectID,mData[0].RoomID,mData[0].WardID,(mBedNo)=>{
                                            setBedNoList(mBedNo)
                                            mBedNo.map(z=>{
                                                if(z.BedNoID == mData[0].BedNoID){
                                                    
                                                    setSelectedBedNo(z)
                                                }
                                            })
                                        })
                                    }
                                })
                            })
                        }
                    })
                 
                    mRoomCategory.map(x=>{
                        if(x.RoomCategoryID === mData[0].RoomCategoryID){
                            setSelectedRoomCategory(x)
                        }
                    })
                })
              

               
                hideLoader()
            }
        })
    })
    },[])
   
   
    const Gender_List = [
        {title:"Male",value:"Male"},
        {title:"Female",value:"Female"},
        {title:"Other",value:"Other"},
    ]
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const Save_Handler =() =>{
        if(validate()){
            var RegistrationData = {
                RegistrationID:RegistrationID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                RegistrationNo:Registration_Data.RegistrationNo,
                PatientName: Registration_Data.PatientName,
                Guardian: Registration_Data.Guardian,
                Age: Registration_Data.Age,
                Gender: SelectedGender.title,
                PhoneNo: Registration_Data.PhoneNo,
                Address: Registration_Data.Address,
                CNIC: Registration_Data.CNIC,
                WardID: SelectedWards.WardID,
                RoomID: SelectedRoom.RoomID,
                RegistrationDate: Registration_Data.RegistrationDate,
                BedNoID: SelectedBedNo.BedNoID,
                RegistrationFee: Registration_Data.RegistrationFee,
                RoomCategoryID: SelectedRoomCategory.RoomCategoryID,
                Status: Registration_Data.Status,
                CreatedDate: Registration_Data.CreatedDate,
                CancleRegistration: Registration_Data.CancleRegistration ? 1 : 0
  
            }
            
            SaveIPDRegistration(RegistrationData,isAddNew,()=>{
               
                    document.getElementById('btnCancel').click()
              
               
            })
           
        }
    }
    const Print_Handler = () =>{
        if(validate()){
            var RegistrationData = {
                RegistrationID:RegistrationID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                RegistrationNo:Registration_Data.RegistrationNo,
                PatientName: Registration_Data.PatientName,
                Guardian: Registration_Data.Guardian,
                Age: Registration_Data.Age,
                Gender: SelectedGender.title,
                PhoneNo: Registration_Data.PhoneNo,
                Address: Registration_Data.Address,
                CNIC: Registration_Data.CNIC,
                WardID: SelectedWards.WardID,
                RoomID: SelectedRoom.RoomID,
                RegistrationDate: Registration_Data.RegistrationDate,
                BedNoID: SelectedBedNo.BedNoID,
                RegistrationFee: Registration_Data.RegistrationFee,
                RoomCategoryID: SelectedRoomCategory.RoomCategoryID,
                Status: Registration_Data.Status,
                CreatedDate: Registration_Data.CreatedDate,
                CancleRegistration: Registration_Data.CancleRegistration ? 1 : 0
  
            }
            
            SaveIPDRegistration(RegistrationData,isAddNew,(id)=>{
                var url = "/IPDRegistrationReport"
               window.location.href = `${url}/${id}`;
               
            })
           
        }
    }
    
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    return ReactDOM.createPortal (
        <>
        <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title text-white" >
                        <h4>IPD Registration</h4>
                    </Col>
                </Row>
            <Card> 
                <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="RegistrationNo"
                            id="RegistrationNo"
                            label="RegistrationNo"
                             value={Registration_Data.RegistrationNo}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,RegistrationNo:e.target.value})
                                if(e.target.value.length > 6){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid Registration No',
                                        text: `Please Use 6 digit Registration No`,
                                      })
                                      setRegistration_Data({...Registration_Data,RegistrationNo:''}) 
                                }
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                    <TextField
                        name="PatientName"
                        id="PatientName"
                        label="PatientName"
                        value={Registration_Data.PatientName}
                         onChange={e=>{
                            setRegistration_Data({...Registration_Data,PatientName:e.target.value})
                            if(e.target.value.length > 50){
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Invalid length',
                                    text: `Please Use 50 Characters letter Name`,
                                  })
                                  setRegistration_Data({...Registration_Data,PatientName:''})
                            }
                        }}
                         onInput={toInputUppercase}
                        fullWidth
                    />
                    <span className="text-danger">{Registration_Err.Name_Err}</span>
                </Col>
                <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="Guardian"
                        id="Guardian"
                        label="Father/Mother/Guardian"
                        value={Registration_Data.Guardian}
                        onChange={e=>{
                           setRegistration_Data({...Registration_Data,Guardian:e.target.value})
                           if(e.target.value.length > 50){
                               Swal.fire({
                                   icon: 'info',
                                   title: 'Invalid length',
                                   text: `Please Use 50 Characters letter Name`,
                                 })
                                 setRegistration_Data({...Registration_Data,Guardian:''})
                           }
                       }}
                        onInput={toInputUppercase}
                        fullWidth
                    />
                   {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
                </Col>
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="PhoneNo"
                        id="PhoneNo"
                        label="PhoneNo"
                        value= {Registration_Data.PhoneNo}
                        onChange={e=>{
                            setRegistration_Data({...Registration_Data,PhoneNo:e.target.value})
                            // if(e.target.value.length > 11){
                            //     Swal.fire({
                            //         icon: 'info',
                            //         title: 'Invalid length',
                            //         text: `Please Use 11 digits Number`,
                            //       })
                            //       setRegistration_Data({...Registration_Data,PhoneNo:''})
                            // }
                            // if(e.target.value.length == 11){
                            //     SelectPatientDetailsWRTPhoneNo(Comp_Id,ProjectID,e.target.value,(mData)=>{
                            //         if(mData.length > 0){
                            //             setRegistration_Data({
                            //                 RegistrationID:mData[0].RegistrationID,
                            //                 Comp_Id:mData[0].Comp_Id,
                            //                 ProjectID:mData[0].ProjectID,
                            //                 UserID:mData[0].UserID,
                            //                 // AppointmentID:AppointmentID,
                            //                 RegistrationNo:mData[0].RegistrationNo,
                            //                 PatientName: mData[0].PatientName,
                            //                 Guardian: mData[0].Guardian,
                            //                 PhoneNo: mData[0].PhoneNo,
                            //                 Address: mData[0].Address,
                            //                 Age: mData[0].Age,
                            //                 CNIC: mData[0].CNIC,
                            //                 CreatedDate: mData[0].CreatedDate,
                            //                 AppointmentDate:mData[0].AppointmentDate,
                            //                 Status: mData[0].Status,
                            //                 IsCheackupAfterFee: mData[0].IsCheakupAfterFee ? 1 : 0,
                            //                 ConsultedTo:mData[0].Doctor,
                            //                 Fees:mData[0].Fees,
                            //                 CancleRegistration:mData[0].CancleRegistration
                                           
                                            
                            //             })
                                        
                                         
                                       
                            //         }
                                   
                                    
                            //     })
                            // }
                          
                           
                        }}
                        fullWidth
                    />
                    <span className="text-danger">{Registration_Err.Phone_Err}</span>
                </Col>
                 
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="Age"
                            id="Age"
                            label="Age"
                            type="number"
                            value={Registration_Data.Age}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,Age:e.target.value})
                                if(parseFloat(e.target.value) == 0){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid Age',
                                        text: `Age Must be Greater then 0`,
                                      })
                                      setRegistration_Data({...Registration_Data,Age:1})
                                }
                            }
                                
                            }
                            fullWidth
                        />
                       {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
                    </Col>
                   
                        
                        </Row>
                       
                        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                        <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="CNIC"
                            id="CNIC"
                            label="CNIC"
                            value={Registration_Data.CNIC}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,CNIC:e.target.value})
                                if(e.target.value.length > 13){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid length',
                                        text: `Please Use 13 character CNIC || CNIC must be without dashes`,
                                      })
                                      setRegistration_Data({...Registration_Data,CNIC:''})
                                }
                            }}
                            onInput={toInputUppercase}
                            fullWidth
                        />

                        <span className="text-danger">{Registration_Err.CNIC_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                    <TextField
                        name="RegistrationDate"
                        id="RegistrationDate"
                        label="Registration Date"
                        type="date"
                         value={Registration_Data.RegistrationDate}
                        onChange={e=>setRegistration_Data({...Registration_Data,RegistrationDate:e.target.value})}
                        fullWidth
                    />
                </Col>
                        <Col lg={2} xl={2} md={2} >
                            <Autocomplete
                                name="Gender"
                                id="Gender"
                                options={Gender_List}
                                getOptionLabel={(option) => option.title ? option.title : ""} 
                                value={SelectedGender}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedGender(value)
                                  
                                
                                }
                                else{
                                    setSelectedGender(null)
                                  
                                }
                               
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Gender" label="Gender"
                                />}
                            />
                           
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="RegistrationFee"
                                id="RegistrationFee"
                                type="number"
                                label="Registrtation Fee"     
                            value={Registration_Data.RegistrationFee}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,RegistrationFee:e.target.value})
                                if(parseFloat(e.target.value) == 0){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid Fees',
                                        text: `Fees Must be Greater then 0`,
                                      })
                                      setRegistration_Data({...Registration_Data,RegistrationFee:1})
                                }
                            }
                                
                            }
                                fullWidth
                            />
                             {/*<span className="text-danger">{Registration_Err.PurPrice_Err}</span>*/}
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Address"
                            id="Address"
                            label="Address"
                            value={Registration_Data.Address}
                            onChange={e=>{
                                setRegistration_Data({...Registration_Data,Address:e.target.value})
                                if(e.target.value.length > 100){
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Invalid length',
                                        text: `Please Use 100 character address`,
                                      })
                                      setRegistration_Data({...Registration_Data,Address:''})
                                }
                            }}
                            onInput={toInputUppercase}
                            fullWidth
                        />

                       {/* <span className="text-danger">{Registration_Err.Name_Err}</span>*/}
                    </Col>
                  
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right m-4" >

                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="CreatedDate"
                                id="CreatedDate"
                                label="CreatedDate"
                                type="date"
                                 value={Registration_Data.CreatedDate}
                                onChange={e=>setRegistration_Data({...Registration_Data,CreatedDate:e.target.value})}
                                fullWidth
                            />
                        </Col>    
                        <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="Ward"
                            id="Ward"
                            options={WardsList}
                            getOptionLabel={(option) => option.Ward ? option.Ward : ""} 
                            value={SelectedWards}
                            onChange={(e,value) =>{
                                debugger
                            if(value)
                            {

                                setSelectedWards(value)
                                SelectRoomsWRTWard(value.WardID, Comp_Id, ProjectID, (mRooms)=>{
                                    setRoomList(mRooms)
                                    if(mRooms.length > 0){
                                        SelectBedNoWRTRoom(Comp_Id,ProjectID,mRooms[0].RoomID,value.WardID,(mWards)=>{
                                            setBedNoList(mWards)
                                        })
                                    }
                                  
                                })
                              
                            
                            }
                            else{
                                setSelectedWards(null)
                                setSelectedRoom(null)
                                setSelectedBedNo(null)
                                setRoomList([])
                                setBedNoList([])
                              
                            }
                           
                            }}
                            renderInput={(params) => 
                            <TextField {...params} name="Ward" label="Wards"
                            />}
                        />
                       <span className="text-danger">{Registration_Err.Ward_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                            <Autocomplete
                                name="Room"
                                id="Room"
                                options={RoomList}
                                getOptionLabel={(option) => option.Room ? option.Room : ""} 
                               
                                value={SelectedRoom}
                                onChange={(e,value) =>{
                                    
                                if(value)
                                {
                                    setSelectedRoom(value)
                                  
                                
                                }
                                else{
                                    setSelectedRoom(null)
                                    setSelectedBedNo(null)
                                    setBedNoList([])
                                  
                                }
                               
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Gender" label="Rooms"
                                />}
                            />
                            <span className="text-danger">{Registration_Err.Room_Err}</span>
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <Autocomplete
                                name="BedNo"
                                id="BedNo"
                                options={BedNoList}
                                getOptionLabel={(option) => option.BedNo ? option.BedNo : ""} 
                                value={SelectedBedNo}
                                onChange={(e,value) =>{
                                    
                                if(value)
                                {
                                    setSelectedBedNo(value)
                                  
                                
                                }
                                else{
                                    setSelectedBedNo(null)
                                  
                                }
                               
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Gender" label="BedNo"
                                />}
                            />
                            <span className="text-danger">{Registration_Err.Bedno_Err}</span>
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <Autocomplete
                                name="RoomCategory"
                                id="RoomCategory"
                                options={RoomCategoryList}
                                getOptionLabel={(option) => option.RoomCategory ? option.RoomCategory : ""} 
                                value={SelectedRoomCategory}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedRoomCategory(value)
                                  
                                
                                }
                                else{
                                    setSelectedRoomCategory(null)
                                  
                                }
                               
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="RoomCategory" label="Room Category"
                                />}
                            />
                            <span className="text-danger">{Registration_Err.RoomCategory_Err}</span>
                        </Col>
                    <Col lg={1} xl={1} md={1} className={"p-0"} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Registration_Data.CancleRegistration}
                                    id="CancleRegistration"
                                    label="Cancel Registration"
                                    onChange={e => setRegistration_Data({ ...Registration_Data, CancleRegistration: e.target.checked })}
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Cancel Registration"}
                            style={{
                                padding: "0px",
                                marginTop: "20%"
                            }}
                        />

                    </Col>     

                    </Row>
                   
                 
                        
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                    <Link id="btnnav" to="/IPDRegistrationReport" ></Link>
                    <button  type="button" className="btn btn-primary" style={{ border: "none" }} onClick={Print_Handler} > <Print /> Save & Print</button>
                        {// btn_edit &&
                             (<button type="button" className="btn btn-primary" style={{ border: "none" }} onClick={Save_Handler} > <Save />  Save</button>)}
                        {/* <Link id="btnPrint" className="btn btn-outline-primary" style={{ border: "none" }} target ="_blank" to='/IPD_Registration_Report' onClick={Print_Handler}> <Print /> <u>Print</u></Link> */}
                        <Link id="btnCancel" className="btn btn-primary" style={{ border: "none" }} to='/IPDRegistration' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                <Loader
                show={IsLoader}
                hide={hideLoader}
              />
            </Card>
        </> , document.getElementById('mainContent')
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.IPD_Reg && state.IPD_Reg.isAddNew,
    RegistrationID: state.IPD_Reg && state.IPD_Reg.pt_id,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(IPDRegistrationEntry)
