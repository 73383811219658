import React, { useState, useEffect, useRef } from 'react'
import { useStateCallback } from "use-state-callback"
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Button  } from "react-bootstrap"
import { Cancel, Save, Print,Add, Delete } from '@material-ui/icons'
import { Typography, TextField } from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import MUIDataTable from "mui-datatables";
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { GetCurrentDate,GetCurrentTime } from '../../Services/Common'
import { SelectPatinetDetailsWRTRegNo,SelectDoctorandVisistsDetails,SaveIPDBill, SelectServicesDetails, SelectMedicainDetails } from '../../Services/IPDBillingAPI'
import {SelectServices} from '../../Services/ServicesAPI'
import TextAreas from './TextArea'

const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);
  
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  });
  
  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
  }
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
  };
  
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);
  
  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  });
  
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <SettingsIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  
  function getSteps() {
    return ['INDOOR DETAIL', 'DOCTOR VISITS', 'SERVICES','MEDICAINS','DISCHARGE'];
  }
const IPDBilling = ({Comp_Id,ProjectID,UserID,RegistrationID}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [IPDBilling_Data,setIPDBilling_Data] = useState([])
    const [ServicesList,setServicesList] = useState([])
    const [SelectedServices,setSelectedServices]= useState(null)
    const [Services_Data,setServices_Data] = useState([])
    const [MedicainData, setMedicainData] = useState([])
    const [Quantity,setQuantity] = useState('')
    const [Price,setPrice] = useState('')
    const [TotalPrice,setTotalPrice] = useState('')
    const [Total_Services,setTotal_Services] = useState(0)
    const [Total_Medicain,setTotal_Medicain] = useState(0)
    const [Total_Visits,setTotal_Visits] = useState(0)
    const [Total_RoomRent,setTotal_RoomRent] = useStateCallback(0)
    const [Discount, setDiscount] = useState(0)
    const [Gross_Total,setGross_Total] = useState(0)
    const [Services_Err,setServices_Err] = useState({
        Services_Err1:'',Quantity_Err:'',Price_Err:'',TotalPrice_Err:'',
    })
        // DISCHARGE TAB
        const [CareTaker,setCareTaker] = useState('')
        const [CNIC,setCNIC] = useState('')
        const [DischargeDate,setDischargeDate] = useState(GetCurrentDate())
        const [DischargeTime,setDischargeTime] = useState(GetCurrentTime())

        const [HistoryIllness, setHistoryIllness] = useState('')
        const [ClinicalFinding, setClinicalFinding] = useState('')
        const [ProcedurePerformed, setProcedurePerformed] = useState('')
        const [Complications, setComplications] = useState('')
        const [Investigations, setInvestigations] = useState('')

        const [TreatmentGivenAtHospital, setTreatmentGivenAtHospital] = useState('')
        const [MedicalForHome, setMedicalForHome] = useState('')
        const [PrecautionObservedAtHome, setPrecautionObservedAtHome] = useState('')
        const [FollowUp, setFollowUp] = useState('')


        const [Care_Taker_Err,setCare_Taker_Err] = useState({
            CareTaker_Err : '',CNIC:''
        })
        const [btn_edit, setBtn_edit] = useState(true)
       
    const [Reg_Data,setReg_Data] = useState({
        RegistrationID:0,
        Comp_Id:Comp_Id,
        RegistrationNo:'',
        PatientName:'',
        Age:0,
        Guardian:'',
        Gender:'',
        PhoneNo:'',
        CNIC:'',
        Address:'',
        RegistrationDate:GetCurrentDate(),
        RegistrationFee:0,
        Ward:'',
        Room:'',
        BedNo: '',
        RoomRent:'',
        pt_date:'',
        Days:''
    })
    useEffect(()=>{
        document.title="IPD Billing"
        Select_Services_List()
    },[])

    const Select_Services_List = () => {
        SelectServices(Comp_Id,ProjectID, (mServices) => {
            setServicesList(mServices)
        })
    }
    const Add_Service = () => {
        if (SelectedServices) {
            if (Quantity) {
                if (Price) {
                    let count = 0;
                    for(let i = 0; i<Services_Data.length; i++)
                    {
                        if( SelectedServices.ServicesID === Services_Data[i].ServicesID)
                        {
                            count ++ ;
                        }
                    }
                    if(count > 0)
                    {
                        Swal.fire({
                            icon: 'info',
                            title: 'Oops...',
                            text: `${SelectedServices.Services} already exists`,
                          })
                          Reset_Services()
                          setServices_Err({ ...Services_Err, Services_Err1: '', Quantity_Err: '', Price_Err: '' })
                          return
                    }
                    else{

                        var mArray = []
                        var mObj = {
                            Services: SelectedServices.Services,
                            ServicesID: SelectedServices.ServicesID,
                            Quantity: Quantity,
                            Price: Price,
                            TotalPrice: TotalPrice
                        }
                        if(Services_Data.length > 0)
                        {
                            mArray = [...Services_Data]
                        }
                        mArray.push(mObj)
                        
                      
                        setServices_Data(mArray)
                        Reset_Services()
                        Calculate_Total_Services('add',null)
                        setServices_Err({ ...Services_Err, Services_Err1: '', Quantity_Err: '', Price_Err: '' })
                    }

                }
                else {
                    setServices_Err({ ...Services_Err, Services_Err1: '', Quantity_Err: '', Price_Err: 'Price is required' })
                }
            }
            else {
                setServices_Err({ ...Services_Err, Services_Err1: '', Quantity_Err: 'Quantity is required' })
            }

        }
        else {
            setServices_Err({ ...Services_Err, Services_Err1: 'Service must be Selected' })
        }

    }
    const Calculate_Total_Services=(action,delData)=>{
        
        let SumAmt = 0;
        if(Services_Data.length > 0)
        {
            for(let i = 0; i<Services_Data.length; i++)
            {
                
                if(action === 'del')
                {
                    if(delData.ServicesID === Services_Data[i].ServicesID)
                    {
                        Services_Data.splice(i,1)
                        continue;
                    }
                }

                SumAmt  = SumAmt + Services_Data[i].TotalPrice
            }
            if(action === 'add')
            {
                SumAmt = SumAmt + TotalPrice
            }
            
            setTotal_Services(SumAmt)
             CalCulate_Total_With_Discount(Total_RoomRent,Total_Visits,SumAmt,Reg_Data.RegistrationFee,Discount, Total_Medicain)

        }
        else
        {
            setTotal_Services(TotalPrice)
            CalCulate_Total_With_Discount(Total_RoomRent,Total_Visits,TotalPrice,Reg_Data.RegistrationFee,Discount, Total_Medicain)

        }
    }
    const column_IPDBilling = [
        {
            name: "Doctor",
            label: "Doctor Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        
        
        {
            name: "VisitDate",
            label: "Visit Date",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "DoctorFee",
            label: "DoctorFee",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Visits",
            label: "Visits",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
    ];
    const Save_Handler = () =>{
        if(CareTaker)
        {
            if(CNIC)
            {
                var RegData = {
                    BillID: 0,
                    PatientID:Reg_Data.RegistrationID,
                    BillStatus:'DISCHARGED',
                    //b_billstatus:Reg_Data.b_billstatus,
                    GrossTotal:parseFloat(Gross_Total),
                    BillDate:GetCurrentDate(),
                    RoomRent:Reg_Data.RoomRent,
                    TotalDays:Reg_Data.Days,
                    //because we change senario one to multiple 
                    DoctorFee:0,
                    TotalVisits:0,
                    TotalMedicain: Total_Medicain,
                    TotalRoomRent:Total_RoomRent,
                    TotalDoctorFee:Total_Visits,
                    Discount:Discount,
                    UserID:UserID,
                    CreatedDate:GetCurrentDate(),
                    ProjectID:ProjectID,
                    Comp_Id:Comp_Id,
                }
                // var mArray = [];
                for(let i = 0; i< Services_Data.length; i++)
                {
                    Services_Data[i].ServiceID =Services_Data[i].ServicesID 
                    Services_Data[i].Qty =Services_Data[i].Quantity 
                    Services_Data[i].Price =Services_Data[i].Price 
                    Services_Data[i].TotalPrice =Services_Data[i].TotalPrice 
        
                }
                var CareTakerData = {
                    DischargeDate: DischargeDate,
                    DischargeTime:DischargeTime,
                    CareTaker:CareTaker,
                    CareTakerCNIC:CNIC,
                    HistoryIllness: HistoryIllness,
                    ClinicalFinding: ClinicalFinding,
                    ProcedurePerformed:ProcedurePerformed,
                    Complications:Complications,
                    Investigations:Investigations,
                    TreatmentGivenAtHospital:TreatmentGivenAtHospital,
                    MedicalForHome:MedicalForHome,
                    PrecautionObservedAtHome:PrecautionObservedAtHome,
                    FollowUp:FollowUp
                }
                debugger
                SaveIPDBill(RegData,Services_Data,CareTakerData,true,()=>{
                    document.getElementById('list').click()
                })
            }
            else
            {
                Swal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: `Please Enter CareTaker CNIC on Discharge TAB`,
                  })
                  document.getElementById('CNIC').focus()
                  return 
            }
        }
        else
        {
            Swal.fire({
                icon: 'info',
                title: 'Oops...',
                text: `Please Enter CareTaker Name on Discharge TAB`,
              })
              document.getElementById('CareTaker').focus()
              return 
        } 
    }
    const Reset_Services = () => {
        setSelectedServices(null)
        setQuantity('')
        setPrice('')
        setTotalPrice('')
    }
    // ################## END DOCTOR VISITS ##################

    // #################### SERVICES #########################
    const column_Services = [
        {
            name: "Services",
            label: "Service Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        
        
        {
            name: "Qty",
            label: "Quantity",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Price",
            label: "Price",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "TotalPrice",
            label: "Total Price",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
     
    ]
    const column_Medicain = [
        {
            name: "ItemDiscription",
            label: "Medicains",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        
        
        {
            name: "Qty",
            label: "Quantity",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Price",
            label: "Price",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "TotalPrice",
            label: "Total Price",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
      
    
    ]
   
    const handleTextAreas_OnChange = (e, setFunc)=>{
        setFunc(e.target.value)
    }   
    function getStepContent(step) {
        switch (step) {
           //IPD Indoor
          case 0:
            return (
                <>
                    <Card>
                        <Row className="row_margin_right row_margin_left row_margin_top" style={{marginBottom:'4%'}} >
                        <Col lg={3} xl={3} md={3} ></Col>
                            <Col lg={3} xl={3} md={3} >
                                <TextField
                                    name="Ward"
                                    id="Ward"
                                    label="Ward"
                                    value={Reg_Data.Ward}
                                    // onChange={e=>setReg_Data({...Reg_Data,pt_date:e.target.value})}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={2} xl={2} md={2} >
                                <TextField
                                    name="Room"
                                    id="Room"
                                    label="Room No"
                                     value={Reg_Data.Room}
                                    // onChange={e=>setReg_Data({...Reg_Data,pt_date:e.target.value})}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={4} xl={4} md={4} ></Col>
                            <Col lg={3} xl={3} md={3} ></Col>
                            <Col lg={3} xl={3} md={3} >
                                <TextField
                                    name="Bed"
                                    id="Bed"
                                    label="Bed No"
                                     value={Reg_Data.BedNo}
                                    // onChange={e=>setReg_Data({...Reg_Data,pt_date:e.target.value})}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={2} xl={2} md={2} >
                                <TextField
                                    name="RoomRent"
                                    id="RoomRent"
                                    label="Room Rent"
                                     value={Reg_Data.RoomRent}
                                    // onChange={e=>setReg_Data({...Reg_Data,pt_date:e.target.value})}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={2} xl={2} md={2} ></Col>
                            <Col lg={2} xl={2} md={2} ></Col>
                            <Col lg={3} xl={3} md={3} ></Col>
                            <Col lg={3} xl={3} md={3} >
                                <TextField
                                    name="Days"
                                    id="Days"
                                    label="Days"
                                    value={Reg_Data.Days}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    fullWidth
                                />
                            </Col>

                        </Row>
                    </Card>
                </>
            );
           //Visits
          case 1:
            return (
                <>
                <Card>
                <Row className="row_margin_right row_margin_left" style={{marginBottom:'4%'}} >
                <Col lg={2} xl={2} md={2}> </Col>
                <Col lg={8} xl={8} md={8}>              
                <div className="table-responsive" >
                    <MUIDataTable
                        name = "IPDBilling_Datatables"
                        data={IPDBilling_Data}
                        columns={column_IPDBilling}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: false,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        pagination:false
                        }}
                    />
                </div>
              </Col>
              <Col lg={2} xl={2} md={2}> </Col>
              </Row>
                </Card>
                </>
            );
          //Services
          case 2:
            return (
                <>
                    <Card>
                       {/* <Row className="row_margin_right row_margin_left">
                        <Col xl={1} lg={1} md={1}></Col>
                            <Col xl={3} lg={3} md={3}>
                                <Autocomplete
                                    id="Services"
                                    name="Services"
                                    options={ServicesList}
                                    value={SelectedServices}
                                    getOptionLabel={(option) => option.Services}
                                    onChange={(e, value) => {

                                        if (value) {
                                            
                                            setSelectedServices(value)
                                            setQuantity(1)
                                            setPrice(value.Fees)
                                            setTotalPrice(value.Fees)
                                        }
                                        else {
                                            setSelectedServices(null)
                                            setQuantity(1)
                                            setPrice(0)
                                            setTotalPrice(0)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select" />}
                                    fullWidth
                                />
                        <span className="text-danger">{Services_Err.Services_Err1}</span>
                            </Col>
                            <Col lg={2} xl={2} md={2} >
                                <TextField
                                    name="Quantity"
                                    id="Quantity"
                                    type="number"
                                    label="Quantity"
                                     value={Quantity}
                                     onChange={e=>{
                                         if(e.target.value)
                                         {
                                            setQuantity(e.target.value)
                                            setTotalPrice(parseInt(e.target.value) * Price)
                                         }
                                         else
                                         {
                                            setQuantity(0) 
                                         }
                                        }}
                                    fullWidth
                                />
                        <span className="text-danger">{Services_Err.Quantity_Err}</span>

                            </Col>
                            <Col lg={2} xl={2} md={2} >
                                <TextField
                                    name="Price"
                                    id="Price"
                                    label="Price"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                     value={Price}
                                    fullWidth
                                />
                        <span className="text-danger">{Services_Err.Price_Err}</span>

                            </Col>
                            <Col lg={2} xl={2} md={2} >
                                <TextField
                                    name="TotalPrice"
                                    id="TotalPrice"
                                    label="Total Price"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    value={TotalPrice}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={2} xl={2} md={2} >
                                <button className="btn btn-primary" style={{ border: "none",marginTop:"4%" }}  onClick={Add_Service}> <Add />  Add</button>
                            </Col>
                        </Row>*/}
                        <Row className="row_margin_right row_margin_left row_margin_top" style={{ marginBottom: '4%' }} >
                            <Col lg={3} xl={3} md={3}> </Col>
                            <Col lg={6} xl={6} md={6}>
                                <div className="table-responsive" >
                                    <MUIDataTable
                                        name="Services_Datatables"
                                        data={Services_Data}
                                        columns={column_Services}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                            pagination: false
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col lg={3} xl={3} md={3}> </Col>
                        </Row>
                    </Card>
                </>
            );
            case 3: 
            return(
                <Card>
                 <Row className="row_margin_right row_margin_left row_margin_top" style={{ marginBottom: '4%' }} >
                     <Col lg={3} xl={3} md={3}> </Col>
                     <Col lg={6} xl={6} md={6}>
                         <div className="table-responsive" >
                             <MUIDataTable
                                 name="Services_Datatables"
                                 data={MedicainData}
                                 columns={column_Medicain}
                                 options={{
                                     selectableRows: 'none',
                                     print: false,
                                     search: false,
                                     download: false,
                                     viewColumns: false,
                                     filter: false,
                                     pagination: false
                                 }}
                             />
                         </div>
                     </Col>
                     <Col lg={3} xl={3} md={3}> </Col>
                 </Row>
             </Card> 
            );
            case 4:
                return (
                    <>
                        <Card>
                            <Row className="row_margin_right row_margin_left row_margin_top" style={{marginBottom:'4%'}} >
                           
                            <Col lg={3} xl={3} md={3} ></Col>
                                <Col lg={3} xl={3} md={3} >
                                    <TextField
                                        name="Care Taker"
                                        id="CareTaker"
                                        label="Care Taker"
                                        value={CareTaker}
                                        onChange={e=>setCareTaker(e.target.value)}
                                        onInput={toInputUppercase}
                                        fullWidth
                                    />
                                </Col>
                                <Col lg={2} xl={2} md={2} >
                                    <TextField
                                        name="CNIC"
                                        type="number"
                                        id="CNIC"
                                        label="CNIC No"
                                         value={CNIC}
                                         onChange={e=>setCNIC(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col lg={4} xl={4} md={4} ></Col>
                               
                                <Col lg={3} xl={3} md={3} ></Col>
                                <Col lg={3} xl={3} md={3} >
                                    <TextField
                                        name="DischargeDate"
                                        id="DischargeDate"
                                        type="date"
                                        label="Discharge Date"
                                         value={DischargeDate}
                                         onChange={e=>setDischargeDate(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col lg={2} xl={2} md={2} >
                                    <TextField
                                        name="DischargeTime"
                                        id="DischargeTime"
                                        type="time"
                                        label="Discharge Time"
                                         value={DischargeTime}
                                         onChange={e=>setDischargeTime(e.target.value)}
                                        fullWidth
                                    />
                                </Col>
                                <Col lg={4} xl={4} md={4} ></Col>
                               <TextAreas placeholder="History of present illness" id="history"  value={HistoryIllness} onChange={e=>handleTextAreas_OnChange(e, setHistoryIllness) }/>
                               <TextAreas placeholder="Clinical Finding"  value={ClinicalFinding} onChange={e=> handleTextAreas_OnChange(e,setClinicalFinding)}/>
                               <TextAreas placeholder="Procedure Performed"  value={ProcedurePerformed} onChange={e=> handleTextAreas_OnChange(e,setProcedurePerformed)}/>
                               <TextAreas placeholder="Complications"  value={Complications} onChange={e=> handleTextAreas_OnChange(e,setComplications)}/>
                               <TextAreas placeholder="Investigations"  value={Investigations} onChange={e=> handleTextAreas_OnChange(e,setInvestigations)}/>
                               <TextAreas placeholder="Treatment Given at hospital"  value={TreatmentGivenAtHospital} onChange={e=> handleTextAreas_OnChange(e,setTreatmentGivenAtHospital)}/>
                               <TextAreas placeholder="Medical to be taken at home"  value={MedicalForHome} onChange={e=> handleTextAreas_OnChange(e,setMedicalForHome)}/>
                               <TextAreas placeholder="Precautions to be observed at home"  value={PrecautionObservedAtHome} onChange={e=> handleTextAreas_OnChange(e,setPrecautionObservedAtHome)}/>
                               <TextAreas placeholder="Follow up"  value={FollowUp} onChange={e=> handleTextAreas_OnChange(e,setFollowUp)}/>
                            </Row>
                        </Card>
                    </>
                );
          default:
            return 'Unknown step';
        }
      }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    const CalCulate_Total_With_Discount = (RoomRent,Visits,Services,Reg_Fee,Discount, TotalMedicain) =>{
        let TotalAmt = 0;
        TotalAmt = ( RoomRent + Visits + Services + Reg_Fee + TotalMedicain) - Discount;
        setGross_Total(TotalAmt)
    }
    const ResetHandler = () =>{
        setIPDBilling_Data([])
        setReg_Data({
            RegistrationID:0,
            Comp_Id:0,
            RegistrationNo: '',
            PatientName: '',
            Age: 0,
            Guardian : '',
            Gender: '',
            PhoneNo: '',
            CNIC: '',
            Address:'',
            RegistrationDate:'',
            RegistrationFee: '',
            Ward:'',
            Room: '',
            BedNo:'',
            RoomRent:0,
            Days:0,

        })
        setTotal_RoomRent(0)
        setTotal_Visits(0)
        setGross_Total(0)
        setDischargeDate(GetCurrentDate)
        setDischargeTime(GetCurrentTime())
        setDiscount(0)
        setTotal_Services(0)
        setTotalPrice(0)
        setServices_Data([])
        setMedicainData([])
        setCareTaker('')
        setCNIC('')

    }
    return ReactDOM.createPortal (
        <>
            <Card>
                <Row className="row_margin_top row_margin_left row_margin_right p-2" >
                    <Col lg={6} xl={6} md={6} className="header_title" >
                        <h4 >IPD Billing Entry</h4>
                    </Col>
                    <Col lg={6} xl={6} md={6} style={{textAlign:'right'}} >
                    <Link to={"#"} className="text-danger" onClick={ResetHandler} > <u>Reset controls</u></Link> 
                    <Link id="list" to={"/IPDBillingList"} > <u>Back to List</u></Link> 
                    </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right pl-3 pr-3" >
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="RegistrationNo"
                            id="RegistrationNo"
                            type="number"
                            label="Registration No"
                            defaultValue={Reg_Data.RegistrationNo}
                             onChange={e=>{
                                
                                 if(e.target.value.length === 6)
                                 {
                                    setReg_Data({...Reg_Data,RegistrationNo:e.target.value})
                                    SelectPatinetDetailsWRTRegNo(Comp_Id,ProjectID,e.target.value,(mData)=>{
                                       
                                        if(mData.length > 0){
                                            var date1 = new Date(mData[0].RegistrationDate);
                                            var date2 = new Date();
                                            // To calculate the no. of days between two dates
                                            var Difference_In_Time =
                                              date2.getTime() - date1.getTime();
                                            var Difference_In_Days = Math.ceil(
                                              Difference_In_Time /
                                                (1000 * 3600 * 24)
                                            );
                                            setReg_Data({
                                                RegistrationID:mData[0].RegistrationID,
                                                Comp_Id: mData[0].Comp_Id,
                                                RegistrationNo: mData[0].RegistrationNo,
                                                PatientName: mData[0].PatientName,
                                                Age: mData[0].Age,
                                                Guardian : mData[0].Guardian,
                                                Gender: mData[0].Gender,
                                                PhoneNo: mData[0].PhoneNo,
                                                CNIC: mData[0].CNIC,
                                                Address: mData[0].Address,
                                                RegistrationDate: mData[0].RegistrationDate,
                                                RegistrationFee: mData[0].RegistrationFee,
                                                Ward: mData[0].Ward,
                                                Room: mData[0].Room,
                                                BedNo: mData[0].BedNo,
                                                RoomRent:mData[0].Fees,
                                                Days:Difference_In_Days,

                                            })
                                            let TotalRoomRent = parseFloat(mData[0].Fees) * parseFloat(Difference_In_Days);
                                            setTotal_RoomRent(TotalRoomRent)
                                            
                                            SelectDoctorandVisistsDetails(Comp_Id,ProjectID,e.target.value,(mData1)=>{
                                                
                                                if(mData1.length != 0){
                                                    
                                                    let sum_Visits = 0;
                                                    mData1.map(i=> {
                                                        sum_Visits+= parseFloat(i.DoctorFee) * parseInt(i.Visits) 
                                                       
                                                    })
                                                    setIPDBilling_Data(mData1)
                                                    setTotal_Visits(sum_Visits)
                                                    
                                                    CalCulate_Total_With_Discount(TotalRoomRent,sum_Visits,Total_Services,mData[0].RegistrationFee,Discount, Total_Medicain)
                                                }
                                               
                                            })
                                            SelectServicesDetails(Comp_Id,ProjectID,e.target.value,(mData2)=>{
                                                var Sum_ServicesFee = 0;
                                                if(mData2.length != 0){
                                                   
                                                    mData2.map(i=>{
                                                        Sum_ServicesFee+= parseFloat(i.TotalPrice)
                                                    })
                                                    CalCulate_Total_With_Discount(TotalRoomRent,Total_Visits,Sum_ServicesFee,mData[0].RegistrationFee,Discount, Total_Medicain)
                                                    setServices_Data(mData2)
                                                    setTotal_Services(Sum_ServicesFee)
                                                    
                                                }else{
                                                    setServices_Data([])
                                                }
                                             
                                                SelectMedicainDetails(Comp_Id,ProjectID,e.target.value,(mData3)=>{
                                                    if(mData3.length != 0){
                                                    
                                                        let Sum_MedicainFee = 0;
                                                        mData3.map(i=>{
                                                            Sum_MedicainFee+= parseFloat(i.TotalPrice)
                                                        })
                                                        CalCulate_Total_With_Discount(TotalRoomRent,Total_Visits,Sum_ServicesFee,mData[0].RegistrationFee,Discount,Sum_MedicainFee)
                                                        setMedicainData(mData3)
                                                        setTotal_Medicain(Sum_MedicainFee)
                                                    }else{
                                                        setMedicainData([])
                                                    }
                                                  
                                                })
                                            })
                                           
                                            
                                        }else{
                                           
                                            Swal.fire({
                                                icon: 'info',
                                                title: 'NO DATA FOUND',
                                                text: "Possible Reasons: \n 1-Patient Already Discharged \n 2- Patinet Not Registered",
                                              })
                                        }
                                      
                                    })
                                    // Select_DoctorandVisists_Details(e.target.value)
                                 }
                                 else
                                 {
                                    ResetHandler()
                    
                                 }
                                
                                }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="PatientName"
                            id="PatientName"
                            label="Patient Name"
                            value={Reg_Data.PatientName}
                            inputProps={{
                                readOnly:true
                            }}
                            onChange={e => setReg_Data({ ...Reg_Data, PatientName: e.target.value })}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Guardian"
                            id="Guardian"
                            label="Father / Mother / Guardian"
                            value={Reg_Data.Guardian}
                            onChange={e => setReg_Data({ ...Reg_Data, Guardian: e.target.value })}
                           // onInput={toInputUppercase}
                           inputProps={{
                            readOnly:true
                        }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="Gender"
                            id="Gender"
                            label="Gender"
                            value={Reg_Data.Gender}
                            //onChange={e => setReg_Data({ ...Reg_Data, Gender: e.target.value })}
                            //onInput={toInputUppercase}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            type="number"
                            name="Age"
                            id="Age"
                            label="Age"
                            value={Reg_Data.Age}
                            onChange={e => setReg_Data({ ...Reg_Data, Age: e.target.value })}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="PhoneNo"
                            id="PhoneNo"
                            label="Phone No"
                            value={Reg_Data.PhoneNo}
                            onChange={e => setReg_Data({ ...Reg_Data, PhoneNo: e.target.value })}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="CNIC"
                            id="CNIC"
                            label="CNIC"
                             value={Reg_Data.CNIC}
                             onChange={e=>setReg_Data({...Reg_Data,CNIC:e.target.value})}
                             inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Address"
                                id="Address"
                                label="Address"
                                value={Reg_Data.Address}
                              //  onChange={e=>setReg_Data({...Reg_Data,Address:e.target.value})}
                              inputProps={{
                                readOnly:true
                            }}
                                onInput={toInputUppercase}
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="RegistrationDate"
                                id="RegistrationDate"
                                label="Registration Date"
                                value={Reg_Data.RegistrationDate}
                                inputProps={{
                                    readOnly:true
                                }}
                                fullWidth
                            />
                        </Col>
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right pl-3 pr-3" >
                        <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="RegistrationFee"
                            id="RegistrationFee"
                            label="RegistrationFee"
                            value={Reg_Data.RegistrationFee}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="TotalRoomRent"
                                id="TotalRoomRent"
                                label="RoomRent Fee"
                                value={Total_RoomRent}
                                inputProps={{
                                    readOnly:true
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="Total_Visits"
                                id="Total_Visits"
                                label="Visits Fee"
                                value={Total_Visits}
                                inputProps={{
                                    readOnly:true
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="Total_Services"
                                id="Total_Services"
                                label="Services Fee"
                                value={Total_Services}
                                inputProps={{
                                    readOnly:true
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="Total_Medicain"
                            type="number"
                            id="Total_Medicain"
                            label="Total Medication Amount"
                            value={Total_Medicain}
                            onChange = {e=>{
                                if(!Number.isNaN(e.target.valueAsNumber))
                                {
                                    setTotal_Medicain(e.target.valueAsNumber)
                                     CalCulate_Total_With_Discount(Total_RoomRent,Total_Visits,Total_Services,Reg_Data.RegistrationFee,Discount,e.target.valueAsNumber)
                                }
                            }}
                            fullWidth
                        />
                    </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="Discount"
                                type="number"
                                id="Discount"
                                label="Discount"
                                value={Discount}
                                onChange = {e=>{
                                    if(!Number.isNaN(e.target.valueAsNumber))
                                    {
                                        setDiscount(e.target.valueAsNumber)
                                         CalCulate_Total_With_Discount(Total_RoomRent,Total_Visits,Total_Services,Reg_Data.RegistrationFee,e.target.valueAsNumber, Total_Medicain)
                                    }
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col lg={2} xl={2} md={2} >
                            <TextField
                                name="Gross_Total"
                                type="number"
                                id="Gross_Total"
                                label="Gross Total"
                                value={Gross_Total}
                                fullWidth
                            />
                        </Col>
                        </Row>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography component={'span'}  className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Button variant="btn btn-primary" onClick={e => {
                                handleReset()
                                if (e.target.innerText === "Reset") {
                                  //  ClearFields()
                                }
                            }} className={classes.button}>
                                Reset
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Typography component={'span'}  className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div>
                                <Button disabled={activeStep === 0} variant="btn btn-primary" onClick={handleBack} className={classes.button}>
                                    Back
                                </Button>
                                <Button
                                    variant="btn btn-primary"
                                    color="primary"
                                    onClick={e => {
                                        handleNext()
                                        if(btn_edit)
                                        {
                                            if (e.target.innerText === "Save") {
                                                Save_Handler()
                                            }
                                        }
                                        
                                    }}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? "Save" : "Next"}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>   
            </Card>
        </> , document.getElementById('mainContent')
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    // isAddNew:state.IPD_Reg && state.IPD_Reg.isAddNew,
    // RegistrationID: state.IPD_Reg && state.IPD_Reg.pt_id,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    // show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(IPDBilling)


