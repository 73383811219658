export const LOGIN_USER = "LOGIN_USER"
export const COMPANIES = "COMPANIES";
export const GLOBAL_VARIABLE = "GLOBAL_VARIABLE";
export const DONOR = "DONOR"
export const RECEIVER = "RECEIVER"
export const EXCHANGER = "EXCHANGER"
export const USERACCESS = "USERACCESS"
export const USERS = "USERS"




export const TURN_NO = "TURN_NO";
export const SHOW_SCREEN = "SHOW_SCREEN"
// used for new project 
export const SUPPLIER = "SUPPLIER" 
export const CUSTOMER = "CUSTOMER"
export const OPENING = "OPENING"
export const MEDICINE = "MEDICINE"
export const PURCHASE_ORDER = "PURCHASE_ORDER" 
export const SALE_ORDER = "SALE_ORDER" 
export const CONFIGURATION = "CONFIGURATION" 
export const STOCKTRANSFER = "STOCKTRANSFER"
export const STOCKADJUSTMENT = "STOCKADJUSTMENT"
export const PURCHASE_INVOICE = "PURCHASE_INVOICE"
export const SALE_INVOICE = "SALE_INVOICE"
export const PAYMENTS = "PAYMENTS"
export const RECEIPTS = "RECEIPTS"
export const GLOBAL_PROJECT = "GLOBAL_PROJECT"
export const GLOBAL_CONFIG = "GLOBAL_CONFIG"
export const GLOBAL_PROJECTS_LIST = "GLOBAL_PROJECTS_LIST"
export const OPD_REGISTRATION = "OPD_REGISTRATION"
export const IPD_REGISTRATION = "IPD_REGISTRATION"
export const DOCTOR_VISITS = "DOCTOR_VISITS"
export const PENDING_PATIENT = "PENDING_PATIENT"
export const Grid_Data = "Grid_Data"