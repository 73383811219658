import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptpurchaseSummaryItemWise } from '../../../Services/Purchase_ReportAPI'
import  { SelectItemsListWRTProject } from '../../../Services/ItemsAPI'

function PurchaseSummaryItemWiseReport({Comp_Name,Comp_Id,User_Name}) {
    const { ProjectID,FromDate,ToDate,FromItem,ToItem } = useParams();
    const [ItemData, setItemData] = useState([])
    const [TotalQty, setTotalQty] = useState(0)
    const [SumOfPur, setSumOfPur] = useState(0)
    const [SumOfPurReturns, setSumOfPurReturns] = useState(0)
    const [TotalPurchase, setTotalPurchase] = useState(0)
    const [SelectedFromItem, setSelectedFromItem] = useState(null)
    const [SelectedToItem, setSelectedToItem] = useState(null)

    useEffect(() => {
        document.title = "Pharmacy / Sales Ledger Item Wise" 

        document.getElementById("mainContent").hidden = true;
        document.getElementById("chat-wrapper").hidden = true;
        document.getElementById("rightsidebar").hidden = true;
        document.getElementById("leftsidebar").hidden = true;
        document.getElementById("TopBar").hidden = true;
        document.getElementById("chat-launcher").hidden = true;

        rptpurchaseSummaryItemWise(Comp_Id,ProjectID,FromDate,ToDate,FromItem,ToItem,(mData)=>{
            if(mData.length > 0){
            var Totals = mData.splice(-1);
            setTotalQty(Totals[0].PurchaseInvoiceNo.split('~')[1])
            setSumOfPur(Totals[0].WareHouse.split('~')[1])
            setSumOfPurReturns(Totals[0].ItemDiscription.split('~')[1])
            setTotalPurchase(Totals[0].PurchaseRate.split('~')[1])
            setItemData(mData)
            }
        
        })
        SelectItemsListWRTProject(Comp_Id,ProjectID,(mitems)=>{
            mitems.map( value =>{
                if(value.ItemID == FromItem){
                    setSelectedFromItem(value)
                }
                if(value.ItemID == ToItem){
                    setSelectedToItem(value)
                }
            })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={12} md={12} xl={12}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Purchase Summary Item Wise</h3>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                        <b>From Item:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6}  style={{textAlign:'left'}}>
                        {SelectedFromItem && SelectedFromItem.ItemName}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                        <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                        <b>To Item:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} style={{textAlign:'left'}}>
                        {SelectedToItem && SelectedToItem.ItemName}
                        </Col>
                    </Row>
                </Col>

            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >INVNo.</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Item</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Bonus Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >DisAmt</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >TaxAmt</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold",borderTop:"2px solid #000" }} >Value</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.PurchaseDate || Itemdata.PurchaseDate != '' && ReverseDate(Itemdata.PurchaseDate)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PurchaseInvoiceNo === "" ? "-" : Itemdata.PurchaseInvoiceNo}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemDiscription}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.Qty} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.BonusQty} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TransactionType}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.DisAmt}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TaxAmt} </td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Value}</td>
                                    </tr>
                                  
                                </>
                            ))
                        }
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total Qty:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalQty}</td>
                        </tr>
                        <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Total Purchase Return:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {SumOfPurReturns}</td>
                        </tr>
                     <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Total Purchase:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {SumOfPur}</td>
                        </tr> 
                        <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Balance:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalPurchase}</td>
                        </tr> 
                    </tbody>


                </table>
                {/* </div> */}

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})
export default connect(mapStateToProps)(PurchaseSummaryItemWiseReport)
