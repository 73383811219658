import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Delete, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import DoctorVisitsEntry from './DoctorVisitsEntry';
import { SelectDoctorVisitsList,DeleteDocVisit } from "../../Services/DoctorVisitsAPI";
import { SelectUserProjects } from "../../Services/Projects"
import { setDoctor_Visits, SHOW_SCREEN } from '../../React_Redux/Actions'
import { GetCurrentDate } from '../../Services/Common'
import Swal from 'sweetalert2'
import { domain } from './../../Services/Common'


    const DoctorVisitsList = ({Comp_Id,setDoctor_Visits,DoctorVisits,p_userid}) => {
    const [ListDate , setListDate] = useState(GetCurrentDate)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
        Project: ''
      })
      const [VisitID, setVisitID] = useState(null)
      const [IsAddNew, setIsAddNew] = useState(null)
      const [Visits_Modal, setVisits_Modal] = useState(false)
      const [btn_add, setBtn_add] = useState(true)
      const [btn_delete, setBtn_delete] = useState(true)
      const [IsLoader, setIsLoader] = useState(false)
      const [visits_Data, setvisits_Data] = useState([])
    useEffect(()=>{
        document.title="Doctor Visits List"
        SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

            setProjectList(ProjectData)
            if(DoctorVisits.Visits_Data)
            {
              
              Select_Visits_List(Comp_Id,DoctorVisits.Visits_Data.SelectedProject.Project_ID,ListDate)
              setSelectedProject(ProjectData.find(x => x.Project_ID ===  DoctorVisits.Visits_Data.SelectedProject.Project_ID))
              setvisits_Data(null,0,false)
            }
            else
            {
              setSelectedProject(ProjectData[0])
              Select_Visits_List(Comp_Id,ProjectData[0].Project_ID,ListDate)
            }
          })

    },[])
    const show_Modal = () =>{
        setVisits_Modal(true);
    }
    const hide_Modal = () =>{
        setVisits_Modal(false)
        setIsAddNew(null)
        Select_Visits_List(Comp_Id,SelectedProject.Project_ID,ListDate)
    }
    const Select_Visits_List = (Comp_Id,ProjectID,ListDate) =>{
        showLoader()
        SelectDoctorVisitsList(Comp_Id,ProjectID,ListDate,(mData)=>{
          hideLoader()
          if(mData)
          setvisits_Data(mData)
        })
       }
       const Delete_Handler = (VisitID)=>{
        
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          
          },
          buttonsStyling: false
        })
      
        swalWithBootstrapButtons.fire({
          
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes Delete it',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
          background: ' rgba(43, 165, 137, 0.45)',
        }).then((result) => {
          if (result.isConfirmed) {
            DeleteDocVisit(Comp_Id,VisitID.VisitID,()=>{
              swalWithBootstrapButtons.fire(
               
                'Awww! Success',
                'Your Record has been deleted',
                'success'
              )
              Select_Visits_List(Comp_Id,SelectedProject.Project_ID,ListDate)
            })
          
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
          }
        })
    
       
       }
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const column_Visits = [
        {
         name: "Doctor",
         label: "Doctor",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
       
        {
         name: "PatientName",
         label: "Patient Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
            name: "Visits",
            label: "No of Visits",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "VisitsDate",
            label: "Visits Date",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
            var _VisitID =visits_Data
            if (_VisitID[rowIndex.rowIndex] != null)
            
              return (
                <Link to={"#"} onClick={e=>Delete_Handler(_VisitID[rowIndex.rowIndex])} > <Delete /></Link>
            
              );
              
          }
          }
         }
    ];
    const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
      return ReactDOM.createPortal(
        <>
         <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col lg={6} xl={6} md={6} className="header_title text-white" >
                <h4> Doctor Visits</h4>
              </Col>
            </Row>
        <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right m-4" >
  
                      <Col lg={3} xl={3} md={3} >
                      <TextField
                      name="VisitsDate"
                      id="VisitsDate"
                      label="VisitsDate"
                      type="date"
                       value={ListDate}
                      onChange={e=>{setListDate(e.target.value)
                        Select_Visits_List(Comp_Id,SelectedProject.Project_ID,ListDate)
                      }}
                      fullWidth
                  />
                      </Col>
  
                      <Col lg={3} xl={3} md={3} >
                          <Autocomplete
                              name="Project"
                              id="Project"
                              options={ProjectList}
                              getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                              value={SelectedProject}
                              onChange={(e, value) => {
                                  if (value)
                                  {
                                      setSelectedProject(value)
  
                                     
                                       Select_Visits_List(Comp_Id,value.Project_ID,ListDate)
                                  }
                                  else
                                  {
                                    setSelectedProject(null)
                                    //   setIPDRegistration_Data([])
                                  }
                              }}
                              renderInput={(params) =>
                                  <TextField {...params} name="Project" label="Select Project"
                                  />}
                          />
                           <span style={{color: 'red'}} > {error_message.Project} </span>
                      </Col>
  
              <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
                <Link to="#" id='btn_navigate' ></Link>
                {btn_add && (<Link variant="btn btn-primary"  to="#" onClick={e =>{
                    show_Modal()
                    setIsAddNew(true)
                  }} style={{border: "none"}} > <Add />  Create New</Link> )}
             
              </Col>
            </Row>
            <Row className="row_margin_top row_margin_left row_margin_right" >
                <Col>    
                          
                  <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                      <MUIDataTable
                          data={visits_Data}
                          columns={column_Visits}
                          options={{
                          selectableRows: 'none',
                          print: false,
                          search: true,
                          download: false,
                          viewColumns: false,
                          filter: false,
                          }}
                      />
                      
                      </MuiThemeProvider>
                  </div>
                </Col>
            </Row>
            <Loader
            show={IsLoader}
            hide={hideLoader}
          />
        </Card>
        <DoctorVisitsEntry 
        show = { Visits_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        VisitID = {VisitID}
      />              
        </>  , document.getElementById('mainContent')
      )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    DoctorVisits: state.DoctorVisits && state.DoctorVisits,
    Group_Id : state.Login_User.User_Data.Group_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  })
  export default connect(mapStateToProps,{setDoctor_Visits})(DoctorVisitsList)
