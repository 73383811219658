import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Card, Row, Col, Button } from "react-bootstrap"
import { Add } from "@material-ui/icons"
import { connect } from "react-redux"
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import "@syncfusion/ej2-react-grids/styles/material.css";
import { TextField } from "@material-ui/core"
import Swal from "sweetalert2"
import {SelectWards } from "../../Services/WardsAPI"
import { SelectRooms,Saverooms } from "../../Services/RoomAPI"
import { Autocomplete } from "@material-ui/lab"
import {SelectGroupScreenAccess} from "../../Services/SecurityAPI"
import Loader from '../Loader'


function Rooms({Comp_Id,ProjectID}) {
    const [IsLoader, setIsLoader] = useState(false)
    const [Rooms, setRooms] = useState([])
    const [DeletedRooms, setDeletedRooms] = useState([])
    const [WardData, setWardData] = useState([])
    const [SelectedWard, setSelectedWard] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_edit, setBtn_edit] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    var grid = null;

    // var Comp_Id = this.props.Comp_Id
    // var ProjectID = this.props.ProjectID
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    
    useEffect(() => {

        document.title = "IPD / Ward"
        Select_Rooms_List()
        // SelectGroupScreenAccess(Group_Id,10,89,(data)=>{
            
        //     setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
        //     setBtn_edit(parseInt(data.Edit) === 0 ? false : true)
        //     setBtn_delete(parseInt(data.Del) === 0 ? false : true)
        //     setOtherAccess(data.OtherAccess)
      
            
        // })    

        SelectWards(Comp_Id,ProjectID, (data) => {

            for (let i = 0; i < data.length; i++)
            {
                WardData.push(data[i])
            }
        })

    }, [])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const Select_Rooms_List = () => {
        showLoader()
        SelectRooms(Comp_Id,ProjectID, (data) => {
            for (let i = 0; i < data.length; i++) {
                data[i].SrNo = i + 1;
            }
            if(data.length > 0)
            setRooms(data)
        })
        hideLoader()
    }

    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.action = rowValue.action
                        //rowValue.data.r_price = document.getElementById("Price").value
                        // rowValue.data.Comp_Id = this.Comp_Id

                        if (SelectedWard) {
                            rowValue.data.WardID = SelectedWard.WardID; // value member
                            rowValue.data.Ward = SelectedWard.Ward; //  display member
                        }
                    }
                }
                else {
                    rowValue.data.SrNo = Rooms.length + 1
                }
            }
        }
    }

    const afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "delete") {
            if (rowValue.data[0].RoomID) {
                var Deleted_Array = [...DeletedRooms];
                Deleted_Array.push(rowValue.data[0]);
                setDeletedRooms(Deleted_Array)
            }
        }

        if (rowValue.requestType == "add") {
            document.getElementById('Ward').focus()
        }
        else if (rowValue.requestType == "beginEdit") {

            document.getElementById('Ward').focus()
            let _WardData = WardData.find(x => x.WardID === rowValue.rowData.WardID)
            setSelectedWard(_WardData)
        }
        else if (rowValue.requestType == "save") {
            for (let i = 0; i < Rooms.length; i++) {
                if (rowValue.data.SrNo === Rooms[i].SrNo) {
                    Rooms[i].WardID = rowValue.data.WardID;
                    Rooms[i].Room = rowValue.data.Room;
                    Rooms[i].RoomID = rowValue.data.RoomID;
                }
            }
        }
    }

    const Room = (rowvalue) => {
        return (
            <TextField
                className="textbox"
                name="Room"
                id="Room"
                defaultValue={rowvalue.Room}
                onInput={toInputUppercase}
                fullWidth
            />
        )
    }

    const Ward = (rowValue) => {

        return (
            <Autocomplete
                id="Ward"
                name="Ward"
                options={WardData}
                defaultValue={WardData.find(x => x.WardID === rowValue.WardID)}
                getOptionLabel={(option) => option.Ward ? option.Ward : "" } 
                onChange={(e, value) => {

                    if (value) {
                        setSelectedWard(value)
                    }
                    else {
                        setSelectedWard(null)
                    }
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
            />
        )
    }


    const sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };


    const save = () => {

        for (let i = 0; i < Rooms.length; i++) {
            if (!Rooms[i].Ward) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Row No. ${Rooms[i].SrNo} Enter Name`,
                })
                return;
            }
            else if (Rooms[i].Room === undefined || Rooms[i].Room === '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Row No. ${Rooms[i].SrNo} Enter Room`,
                })
                return;
            }
        }
        Saverooms(Rooms, DeletedRooms, Comp_Id,ProjectID, () => {
           Select_Rooms_List()
        })
    }


    return ReactDOM.createPortal(
        <>
         <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>Rooms
                        </h2>
                    </div>
                </div>
            </div>
            <Card>
               
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{width:'80%', marginLeft:'10%'}} >
                    <Col>
                        <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%" }}>
                                <GridComponent dataSource={Rooms} width="100%" height="100%" ref={x => grid = x} actionBegin={beforeRowInsert} allowSorting={true} actionComplete={afterRowInsert} allowFiltering={true} editSettings={{ allowEditing: btn_edit, allowDeleting: btn_delete, allowAdding: btn_add, newRowPosition: 'Bottom' }} filterSettings={{ type: 'Menu' }} sortSettings={sortingOptions} toolbar={toolbarOptions}>
                                    <ColumnsDirective>
                                        <ColumnDirective width='60' field='SrNo' Format="{0:n}" allowEditing={false} headerText='Sr No.' textAlign='Left' />
                                        <ColumnDirective width='100' field='Ward' headerText='Ward' editTemplate={Ward} textAlign='Left' />
                                        <ColumnDirective width='100' field='Room' headerText='Room' editTemplate={Room} textAlign="Left" />
                                        <ColumnDirective width='100' field='WardID' hideAtMedia={true} />
                                        <ColumnDirective width='150' field='RoomID' hideAtMedia={true} />
                                        <ColumnDirective width='100' field='Comp_Id' defaultValue={Comp_Id} hideAtMedia={true} />
                                        <ColumnDirective width='150' field='ProjectID'  defaultValue={ProjectID} hideAtMedia={true} />
                                    </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            <button type="button" className="btn btn-primary"  style={{border: "none"}} onClick={e=>{save()}} > <Save /> <u>Save</u></button>
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'#'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row> */}
                     <Row className="row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                <Col lg={7} xl={7}>
                </Col>
                
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                        <Button variant="btn btn-primary"  style={{border: "none"}} onClick={e => {
                            save()
                        }} > <Add />  Save</Button>
                    </Col>
                    <Col lg={2} xl={2}>
                    </Col>
                </Row>
                <Loader
          show={IsLoader}
          hide={hideLoader}
        />
            </Card>
        </>, document.getElementById('mainContent')
    )
}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data.Group_Id,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps)(Rooms)
