import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { connect } from "react-redux"
import { Save, Cancel } from "@material-ui/icons"
import { _ConfirmPassword, UpdatePassword } from "../../Services/ChangePasswordAPI"

function ChangePasswordModal({ show, hide, LoginID }) {
    const [Current_Password, setCurrent_Password] = useState('')
    const [NewPassword, setNewPassword] = useState('')
    const [ConfirmPassword, setConfirmPassword] = useState('')
    const [Confirm_Status, setConfirm_Status] = useState(false)
    const [Confirm_Message, setConfirm_Message] = useState("")
    const [Error, setError] = useState({
        Current_Password: "",
        NewPassword: "",
        ConfirmPassword: ""
    })

    useEffect(() => {

        if (show) {
            document.getElementById("Current_Password").focus()
        }
    }, [show])

    const validation = () => {

        if (Current_Password === "") {
            setError({ ...Error, Current_Password: "must be required" });
            document.getElementById("Current_Password").focus();
            return false;
        }
        else if (NewPassword === "") {
            setError({ ...Error, NewPassword: "must be required", Current_Password: "" });
            document.getElementById("New_Password").focus();
            return false;
        }
        else if (ConfirmPassword === "") {
            setError({ ...Error, ConfirmPassword: "must be required", Current_Password: "", NewPassword: "" });
            document.getElementById("Confirm_Password").focus();
            return false;
        }
        else if (!Confirm_Status) {
            setError({ ...Error, Current_Password: "Enter Valid Password" });
            document.getElementById("Current_Password").focus();
            return false;
        }

        setError({ Current_Password: "", NewPassword: "", ConfirmPassword: "" });
        return true;
    }

    const Confirm_Password = () => {

        _ConfirmPassword(LoginID, Current_Password, (data) => {
            debugger
            if (data.status === false) {
                setError({ Current_Password: data.message })
                setConfirm_Message(data.message)
                setConfirm_Status(false)
            }
            else {
                setError({ Current_Password: "" })
                setConfirm_Message("")
                setConfirm_Status(true)
            }

        })
    }

    const Save_Password = (e) => {

        e.preventDefault();
        if (validation() && Confirmation(ConfirmPassword)) {

            UpdatePassword(LoginID, NewPassword, (data) => {

                Close_Modal()
            })
        }

    }

    const Close_Modal = () => {
        clearFields()
        hide()
    }

    const clearFields = () => {

        setCurrent_Password('')
        setNewPassword('')
        setConfirmPassword('')
        setError({ Current_Password: "", NewPassword: "", ConfirmPassword: "" })

    }

    const Confirmation = (Confirm_Value) => {

        if (NewPassword !== Confirm_Value) {
            setError({ ...Error, ConfirmPassword: "Confirm Password is not matching", Current_Password: "", NewPassword: "" });
            document.getElementById("Confirm_Password").focus();
            return false
        }
        else {
            setError({ ...Error, ConfirmPassword: "", Current_Password: "", NewPassword: "" });
            return true
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={hide}
                backdrop="static"
                dialogClassName="modal-90w"
                size="md"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className=" header_Color" >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form" method="post" >
                    <Card>
                        <Row>
                            <Col style={{ textAlign: "right" }} >
                                    <button type="submit" onClick={Save_Password} className="btn btn-primary waves-effect height-of-button"> <Save /> Save</button>
                                    <button type="button" onClick={Close_Modal} className="btn btn-primary waves-effect height-of-button"> <Cancel /> Close</button>
                            </Col>
                        </Row>
                        <Row className=" row-margin-left row-margin-right row-margin-top" >
                            <Col>
                                <TextField
                                    name="Current_Password"
                                    id="Current_Password"
                                    type="password"
                                    value={Current_Password}
                                    onBlur={Confirm_Password}
                                    onChange={e => {

                                        setCurrent_Password(e.target.value)
                                    }}
                                    label="Current Password "
                                    fullWidth
                                />
                                <span style={{ color: "red" }} > {Error.Current_Password} </span>
                            </Col>
                        </Row>
                        <Row className=" row-margin-left row-margin-right row-margin-top" >
                            <Col>
                                <TextField
                                    name="New_Password"
                                    id="New_Password"
                                    type="password"
                                    value={NewPassword}
                                    onChange={e => {
                                        setNewPassword(e.target.value)
                                    }}
                                    label="New Password "
                                    fullWidth
                                />
                                <span style={{ color: "red" }} > {Error.NewPassword} </span>
                            </Col>
                        </Row>
                        <Row className=" row-margin-left row-margin-right row-margin-top" >
                            <Col>
                                <TextField
                                    name="Confirm_Password"
                                    id="Confirm_Password"
                                    type="password"
                                    value={ConfirmPassword}
                                    onChange={e => {

                                        setConfirmPassword(e.target.value)

                                        if (e.target.value !== "") {
                                            Confirmation(e.target.value)
                                        }
                                    }}
                                    label="Confirm Password"
                                    fullWidth
                                />
                                <span style={{ color: "red" }} > {Error.ConfirmPassword} </span>
                            </Col>
                        </Row>
                    </Card>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    LoginID: state.Login_User.User_Data && state.Login_User.User_Data.User_Login,
})

export default connect(mapStateToProps)(ChangePasswordModal)
