import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { InputSwitch } from 'primereact/inputswitch';
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectSaleInvoiceList,SelectSaleInvoiceListBetweenDates } from "../../Services/SaleInvoiceAPI";
import { set_SI, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { GetCurrentDate } from '../../Services/Common';

function SaleInvoiceList(props) {
  const [IsLoader, setIsLoader] = useState(false)
    const {  set_SI,SALE_INVOICE, Comp_Id,p_userid, Group_Id, SHOW_SCREEN  } = props;
    const [SI_Data, setSI_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Month: '',
      Project: ''
    })
    const [IsDateWise, setIsDateWise] = useState(true)
    const [FromDate,setFromDate] = useState(GetCurrentDate())
    const [ToDate, setToDate] = useState(GetCurrentDate())

    useEffect(()=>{
    document.title = " PHARMACY / Sale Invoice"
    SelectGroupScreenAccess(Group_Id,11,103,(data)=>{
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)
      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })
SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{
  setProjectList(ProjectData)
  
  if(SALE_INVOICE.SI_data)
  {
     if(!IsDateWise){
      Select_SaleInvoiceList(SALE_INVOICE.SI_data.value, SALE_INVOICE.Project_ID)
     }else{
          Select_SaleInvoiceList_DateWise(SALE_INVOICE.Project_ID)
     }
    
    setSelected_Month(SALE_INVOICE.SI_data)
    setSelectedProject(ProjectData.find(x => x.Project_ID === SALE_INVOICE.Project_ID))
      set_SI(null,0,false, null)
  }
  else
  {
    setSelectedProject(ProjectData[0])
    var _Date = new Date()
    setSelected_Month(Month_List.find(x => x.value === (_Date.getMonth())+1))
    if(!IsDateWise){
      Select_SaleInvoiceList((_Date.getMonth())+1, ProjectData[0].Project_ID)
    }else{
      Select_SaleInvoiceList_DateWise(ProjectData[0].Project_ID)
    }
  
  }
})
},[])
const showLoader = () => {
  setIsLoader(true)
}
const hideLoader = () => {
  setIsLoader(false)
}
    const validation = () =>{

      if(!Selected_Month)
      {
        setError_message({Month: 'must be selected', Project: ''})
        return false
      }
      else if(!SelectedProject)
      {
        setError_message({Month: '', Project: 'must be selected'})
        return false
      }

      setError_message({Month: '', Project: ''})
      return true
    }

    const column_SI = [
        {
         name: "SaleInvoiceNo",
         label: "Invoice No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "CustomerName",
         label: "Customer Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "Invoice_Date",
         label: "Invoice Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var SaleInvoiceID = SI_Data
             if (SaleInvoiceID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/SaleInvoiceEntry"} onClick={e => edit_SI(SI_Data[rowIndex.rowIndex].SaleInvoiceID)}> <Edit /></Link>
               );
           }
          }
         }
    ];
    const edit_SI = (SaleInvoiceID) =>{
       set_SI(Selected_Month,SaleInvoiceID,false, SelectedProject.Project_ID)
    }
    const add_SI = () =>{
      if(validation())
      {
        set_SI(Selected_Month,0,true, SelectedProject.Project_ID) 
        document.getElementById("btn_navigate").click()
      }
    }
  const Month_List = [
      {title:"JANUARY",value:1},
      {title:"FEBURARY",value:2},
      {title:"MARCH",value:3},
      {title:"APRIL",value:4},
      {title:"MAY",value:5},
      {title:"JUNE",value:6},
      {title:"JULY",value:7},
      {title:"AUGUST",value:8},
      {title:"SEPTEMBER",value:9},
      {title:"OCTOBER",value:10},
      {title:"NOVEMBER",value:11},
      {title:"DECEMBER",value:12},
  ]
   const Select_SaleInvoiceList = (Month, Project_Id) =>{
    showLoader()
    SelectSaleInvoiceList(Comp_Id,Project_Id,'INV',Month,(mData)=>{
      hideLoader()  
      setSI_Data(mData)
    })
   }
   const Select_SaleInvoiceList_DateWise = ( Project_ID) =>{
    showLoader()
    SelectSaleInvoiceListBetweenDates(Comp_Id,Project_ID,'INV', FromDate, ToDate, (mData)=>{
      hideLoader()
      setSI_Data(mData)
    })
   }
   const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Sale Invoice List</h4>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right m-4" >

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Month(value)
                                    if(IsDateWise && SelectedProject){
                                      Select_SaleInvoiceList_DateWise(SelectedProject.Project_ID)
                                    }else{
                                    if(SelectedProject)
                                    Select_SaleInvoiceList(value.value, SelectedProject.Project_ID)
                                    }
                                    
                                }
                                else
                                {
                                    setSelected_Month(null)
                                    setSI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Month"
                                />}
                        />
                        <span style={{color: 'red'}} > {error_message.Month} </span>
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)
                                  if(IsDateWise && Selected_Month){
                                   Select_SaleInvoiceList_DateWise(value.Project_ID)
                                  }else{
                                  if(Selected_Month)
                                    Select_SaleInvoiceList(Selected_Month.value,value.Project_ID)
                                  }
                                   
                                }
                                else
                                {
                                  setSelectedProject(null)
                                    setSI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Project"
                                />}
                        />
                        <span style={{color: 'red'}} > {error_message.Project} </span>
                    </Col>

            <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
              <Link to={"/SaleInvoiceEntry"} id='btn_navigate' ></Link>
              { btn_add &&(<Link type="button" className="btn btn-primary" to="#" onClick={add_SI} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
         <Row className="row_margin_top row_margin_left row_margin_right m-4">
         <Col lg={2} xl={2} md={2} >
         <input type='checkbox'
          id="IsDateWise"
           name='IsDateWise'
            checked={IsDateWise}
            onChange={e=> {
              setIsDateWise(e.target.checked) 
              if(e.target.checked){
                Select_SaleInvoiceList_DateWise(SelectedProject.Project_ID)
              }else{
                 var _Date = new Date()
                 Select_SaleInvoiceList((_Date.getMonth())+1, SelectedProject.Project_ID)
              }
            }}
             style={{width:'20px', height:'20px'}} />
         <label for="IsDateWise" style={{fontWeight:'bold'}} className='pl-2 mt-4' >Date Wise </label>
         </Col>
         <Col lg={2} xl={2} md={2} >
         <TextField
         name="FromDate"
         id="FromDate"
         label="From Date"
         type="date"
        value={FromDate}
        disabled={!IsDateWise}
         onChange={e=> {
          setFromDate(e.target.value)
          Select_SaleInvoiceList_DateWise(SelectedProject.Project_ID)
        }}
         fullWidth
     />
         </Col>
         <Col lg={2} xl={2} md={2} >
         <TextField
         name="ToDate"
         id="ToDate"
         label="To Date"
         type="date"
          value={ToDate}
          disabled={!IsDateWise}
         onChange={e=> {setFromDate(e.target.value)
          Select_SaleInvoiceList_DateWise(SelectedProject.Project_ID)
        }}
         fullWidth
     />
         </Col>
         </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={SI_Data}
                        columns={column_SI}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  SALE_INVOICE: state.SI && state.SI,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps,{set_SI, SHOW_SCREEN})(SaleInvoiceList)
