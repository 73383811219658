
// For Developers Edition
//  export const domain = "http://localhost:1330/api"
// export const domain_for_image = "http://localhost:5555"
// For Developer locally deployment
// export const domain = "http://192.168.0.105:1330/api"

// For jamshid PC client
// export const domain = "http://192.168.100.22:1330/api"
// export const domain_for_image = "http://localhost:5555"

// https://lordstech.lthms.com/api/Beds/SelectBeds/1/1
// // For Live Hostig Edition
export const domain = "https://lordstech.lthms.com/api"
export const domain_for_image = "https://lordstech.lthms.com:5555"

export const GetCurrentDate = () =>{

    var date = new Date();
    
    return `${date.getFullYear()}-${((date.getMonth())+1)<=9 ? '0'+((date.getMonth())+1).toString() : (date.getMonth())+1}-${date.getDate() <= 9 ? '0'+ (date.getDate()).toString() : date.getDate()}`
}

export const GetCurrentTime = () =>{
    var today = new Date()
    return today.getHours() + ":" + today.getMinutes() 
}

export const GetCurrentTimeAM_PM = () => {
    var date = new Date();
    var current_hour = date.getHours() <= 12 ? date.getHours() : date.getHours() % 12;
    var am_pm = date.getHours() <= 12 ? 'AM' : 'PM'
    var current_time = (current_hour <= 9 ? '0' + current_hour : current_hour) + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() :date.getMinutes()) + ':' + ( date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()) + am_pm;
    return current_time
}
export const ThousandSeparater = (Num) => { //function to add commas to textboxes
    var x, x1, x2
    Num += '';
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
   x = Num.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
}


export const ReverseDate = (Date) => {
    return ((((Date).split('-')).reverse()).join('-'))
}
 

export const setDateFormat =(date) =>{
    return date.getFullYear() + "-" +((date.getMonth()) + 1) +"-"+ date.getDate()
}


export const numFormatter = (num) => {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 1000000){
        return (num/1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
    }else if(num < 900){
        return num.toFixed(2); // if value < 1000, nothing to do
    }else{
        return num.toFixed(2);  
    }
}