import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import index from "./Component/index/index"
import Sidebar from "./Component/layout/Sidebar"
import RightSidebar from "./Component/layout/RightSidebar"
import TopBar from "./Component/layout/TopBar"
import ChatWraper from "./Component/layout/ChatWraper"
import UOM from './Component/UOM/UOM';
import ItemSubType from './Component/ItemSubType/ItemSubType';
import City from './Component/City/City';
import WareHouse from './Component/WareHouse/WareHouse';
import ItemMainType from './Component/ItemMainType/ItemMainType';
import manufacture from './Component/ManufactureBy/manufacture';
import ExpenseClassification from './Component/ExpenseClassification/ExpenseClassification';
import ExpenseType from './Component/ExpenseType/ExpenseType';
import ExpenseList from './Component/Expense/ExpenseList';
import Bank from './Component/Bank/bank';
import CustomerList from './Component/Customers/CustomerList';
import SuppliersList from './Component/Suppliers/SuppliersList';
import SupplierEntry from './Component/Suppliers/SupplierEntry';
import CustomerEntry from './Component/Customers/CustomerEntry';
import OpeningList from './Component/Opening/OpeningList';
import OpeningEntry from './Component/Opening/OpeningEntry';
import ItemList from './Component/Items/ItemList';
import ItemEntry from './Component/Items/ItemEntry';
import PurchaseOrderList from './Component/PurchaseOrder/PurchaseOrderList';
import PurchaseInvoiceList from './Component/PurchaseInvoice/PurchaseInvoiceList';
import SaleOrderList from './Component/SaleOrder/SaleOrderList';
import ControlPanel from './Component/ControlPanel/ControlPanel';
import StockTransferList from './Component/StockTransfer/StockTransferList'
import StockTransferEntry from './Component/StockTransfer/StockTransferEntry'
import StockAdjustmentList from './Component/StockAdjustment/StockAdjustmentList'
import StockAdjustmentEntry from './Component/StockAdjustment/StockAdjustmentEntry';
import SaleInvoiceList from './Component/SaleInvoice/SaleInvoiceList';
import PurchaseOrderEntry from './Component/PurchaseOrder/PurchaseOrderEntry';
import PurchaseInvoiceEntry from './Component/PurchaseInvoice/PurchaseInvoiceEntry';
import SaleInvoiceEntry from './Component/SaleInvoice/SaleInvoiceEntry';
import SaleOrderEntry from './Component/SaleOrder/SaleOrderEntry';
import PurchaseReturnList from './Component/PurchaseReturn/PurchaseReturnList';
import SaleReturnList from './Component/SaleReturn/SaleReturnList';
import PurchaseReturn from './Component/PurchaseInvoice/PurchaseReturn';
import Login from './Component/Login/Login';
import SaleReturnEntry from './Component/SaleReturn/SaleReturnEntry';
import CustomerLedger from './Component/Reports/SaleReports/CustomerLedger';
import SaleSummaryCustWise from './Component/Reports/SaleReports/SaleSummaryCustWise';
import SaleSummarySalesManWise from './Component/Reports/SaleReports/SaleSummarySalesManWise';
import SaleLedgerItemWise from './Component/Reports/SaleReports/SaleLedgerItemWise';
import SaleMarginCustomerWise from './Component/Reports/SaleReports/SaleMarginCustomerWise';
import SaleReturnLedgerCustWise from './Component/Reports/SaleReports/SaleReturnLedgerCustWise';
import MostSoldItems from './Component/Reports/SaleReports/MostSoldItems';
import MinimumSoldItems from './Component/Reports/SaleReports/MinimumSoldItems';
import DailySaleOfLastMOnth from './Component/Reports/SaleReports/DailySaleOfLastMOnth';
import Receiveable from './Component/Reports/SaleReports/Receiveable';
import SupplierLedgerReport from './Component/Reports/PurchaseReports/SupplierLedgerReport';
import SupplierWiseLedgerReport from './Component/Reports/PurchaseReports/SupplierWiseLedgerReport';
import PurchaseSummaryItemWiseReport from './Component/Reports/PurchaseReports/PurchaseSummaryItemWiseReport';
import PayablesReport from './Component/Reports/PurchaseReports/PayablesReport';
import StockBalanceReport from './Component/Reports/InventoryReports/StockBalanceReport';
import StockWareHouseWiseReport from './Component/Reports/InventoryReports/StockWareHouseWiseReport';
import StockBatchWiseReport from './Component/Reports/InventoryReports/StockBatchWiseReport';
import ShortInventoryItemsReport from './Component/Reports/InventoryReports/ShortInventoryItemsReport';
import MaxInventoryItemsReport from './Component/Reports/InventoryReports/MaxInventoryItemsReport';
import DailyActivityReport from './Component/Reports/PL/DailyActivityReport';
import PLReport from './Component/Reports/PL/PLReport';
import ExpenseReport from './Component/Reports/ExpenseReports/ExpenseReport';
import ExpenseClassificationReport from './Component/Reports/ExpenseReports/ExpenseClassificationReport';
import ExpenseTypeReport from './Component/Reports/ExpenseReports/ExpenseTypeReport';
import CashInHandReport from './Component/Reports/FinanceReports/CashInHandReport';
import CashInBankReport from './Component/Reports/FinanceReports/CashInBankReport';
import PurchaseInvoiceReport from './Component/PurchaseInvoice/PurchaseInvoiceReport';
import PurchaseOrderReport from './Component/PurchaseOrder/PurchaseOrderReport';
import PurchaseReturnReport from './Component/PurchaseReturn/PurchaseReturnReport';
import SaleInvoiceReport from './Component/SaleInvoice/SaleInvoiceReport';
import SaleReturnReport from './Component/SaleReturn/SaleReturnReport';
import PaymentsList from "./Component/Payments/PaymentsList"
import PaymentEntry from "./Component/Payments/PaymentEntry"
import ReceiptsList from "./Component/Receipt/ReceiptsList";
import ReceiptEntry from './Component/Receipt/ReceiptEntry'
import PaymentPrint from './Component/Payments/PaymentPrint';
import RecieptPrint from './Component/Receipt/RecieptPrint';
import SaleOrderReport from './Component/SaleOrder/SaleOrderReport';
import Symptoms from './Component/Symptoms/Symptoms';
import Tests from './Component/Tests/Tests';
import Diseases from './Component/Diseases/Diseases';
import Groups from './Component/Groups/Groups';
import OPDRegistrationList from './Component/OPDRegistration/OPDRegistrationList';
import OPDRegistrationEntry from './Component/OPDRegistration/OPDRegistrationEntry';
import OPDCheckup from './Component/OPDCheckup/OPDCheckup';
import Wards from './Component/Wards/Wards';
import RoomCategory from './Component/RoomCategory/RoomCategory';
import Rooms from './Component/Rooms/Rooms';
import BedNo from './Component/BedNo/BedNo';
import Beds from './Component/Beds/Beds';
import Services from './Component/Services/Services';
import IPDRegistrationList from './Component/IPDRegistration/IPDRegistrationList';
import IPDRegistrationEntry from './Component/IPDRegistration/IPDRegistrationEntry';
import DoctorVisitsList from './Component/DoctorVisits/DoctorVisitsList';
import PendingPatientList from './Component/PendingPatinets/PendingPatientList';
import OPDBilling from './Component/OPDBilling/OPDBilling';
import DoctorStatisticsList from './Component/DoctorStatistics/DoctorStatisticsList';
import DoctorStatistics_Report from './Component/DoctorStatistics/DoctorStatistics_Report';
import IPDBillingList from './Component/IPDBilling/IPDBillingList';
import IPDBilling from './Component/IPDBilling/IPDBilling';
import DosageList from './Component/Dosage/DosageList';
import Test_Report from './Component/OPDCheckup/Test_Report';
import Prescription_Report from './Component/OPDBilling/Prescription_Report';
import Report_DischargeSlip from './Component/DoctorStatistics/Report_DischargeSlip';
import IPDBilling_Report from './Component/IPDBilling/IPDBilling_Report';
import IncomeStatment from './Component/Reports/PL/IncomeStatment';
import HospitalDashboard from './Component/index/HospitalDashboard';
import OPT from './Component/OPT/OPT';
import OPTAssignLIst from './Component/OPTAssign/OPTAssignLIst';
import InvoicePrintThermal from './Component/OPDRegistration/InvoicePrintThermal';
import Days from './Component/Days/Days';
import PatientServicesList from './Component/PatientServices/PatientServicesList';
import PatientMedicainList from './Component/PatientMedicain/PatientMedicainList';
import IPDRegistration_Report from './Component/IPDRegistration/IPDRegistration_Report';
function App() {
  return (
    <>
        <Router>
        <TopBar />
          <Sidebar />
          <ChatWraper />
          <RightSidebar />
          <Switch>
            <Route component={ Login } path = "/" exact />
            <Route component={ index } path="/Index" exact />
            <Route component={ UOM } path = "/UOM" exact />
            <Route component={ ItemSubType} path = "/ItemSubType" exact />
            <Route component={ City} path = "/City" exact />
            <Route component={ WareHouse} path = "/WareHouse" exact />
            <Route component={ ItemMainType} path = "/ItemMainType" exact />
            <Route component={ manufacture} path = "/manufacture" exact />
            <Route component={ ExpenseClassification} path = "/ExpenseClassification" exact />
            <Route component={ ExpenseType} path = "/ExpenseType" exact />
            <Route component={ ExpenseList} path = "/ExpenseList" exact />
            <Route component={ Bank } path = "/Bank" exact />
            <Route component={ CustomerList} path = "/CustomerList" exact />
            <Route component={ SuppliersList} path = "/SuppliersList" exact />
            <Route component={ SupplierEntry} path = "/SupplierEntry" exact />
            <Route component={ CustomerEntry} path = "/CustomerEntry" exact />
            <Route component={ OpeningList} path = "/OpeningList" exact />
            <Route component={ OpeningEntry} path = "/OpeningEntry" exact />
            <Route component={ ItemList} path = "/ItemList" exact />
            <Route component={ ItemEntry} path = "/ItemEntry" exact />
            <Route component={ PurchaseOrderList} path = "/PurchaseOrderList" exact />
            <Route component={ PurchaseOrderEntry} path = "/PurchaseOrderEntry" exact />
            <Route component={ PurchaseInvoiceList} path = "/PurchaseInvoiceList" exact />
            <Route component={ SaleOrderList} path = "/SaleOrderList" exact />
            <Route component={ SaleOrderEntry} path = "/SaleOrderEntry" exact />
            <Route component={ SaleInvoiceList} path = "/SaleInvoiceList" exact />
            <Route component={ ControlPanel} path = "/ControlPanel" exact />
            <Route component={ StockTransferList} path = "/StockTransferList" exact />
            <Route component={ StockTransferEntry} path = "/StockTransferEntry" exact />
            <Route component={ StockAdjustmentList} path = "/StockAdjustmentList" exact />
            <Route component={ StockAdjustmentEntry} path = "/StockAdjustmentEntry" exact />
            <Route component={ PurchaseInvoiceEntry} path = "/PurchaseInvoiceEntry" exact />
            <Route component={ PurchaseReturnList} path = "/PurchaseReturnList" exact />
            <Route component={ SaleInvoiceEntry} path = "/SaleInvoiceEntry" exact />
            <Route component={ SaleReturnList} path = "/SaleReturnList" exact />
            <Route component={ PurchaseReturn} path = "/PurchaseReturn" exact />
            <Route component={ SaleReturnEntry} path = "/SaleReturnEntry" exact />
            <Route component={ Symptoms} path = "/Symptoms" exact />
            <Route component={ Tests} path = "/Tests" exact />
            <Route component={ Diseases} path = "/Diseases" exact />
            <Route component={ Groups} path = "/Groups" exact />
            <Route component={OPDRegistrationList} path="/OPDRegistration" exact />
            <Route component={OPDRegistrationEntry} path="/OPDRegistrationEntry" exact />
            <Route component={OPDCheckup} path="/OPDCheckup" exact />
            <Route component={Wards} path="/Wards" exact />
            <Route component={RoomCategory} path="/RoomCategory" exact />
            <Route component={Rooms} path="/Room" exact />
            <Route component={BedNo} path="/BedNo" exact />
            <Route component={Beds} path="/Beds" exact />
            <Route component={Days} path="/Days" exact />
            <Route component={Services} path="/Services" exact />
            <Route component={IPDRegistrationList} path="/IPDRegistration" exact />
            <Route component={IPDRegistrationEntry} path="/IPDRegistrationEntry" exact />
            <Route component={DoctorVisitsList} path="/DoctorVisitsList" exact />
            <Route component={PatientServicesList} path="/PatientServices" exact />
            <Route component={PatientMedicainList} path="/PatientMedicain" exact />
            <Route component={PendingPatientList} path="/PendingPatients" exact />
            <Route component={OPDBilling} path="/OPDBilling" exact />
            <Route component={DoctorStatisticsList} path="/DoctorStattistics" exact />
            <Route component={DoctorStatistics_Report} path="/DoctorStatisticsReport" exact />
            <Route component={IPDBillingList} path="/IPDBillingList" exact />
            <Route component={IPDBilling} path="/IPDBilling" exact />
            <Route component={DosageList} path="/DosageList" exact />
            <Route component={Test_Report } path="/Test-Report" exact />
            <Route component={Prescription_Report} path="/Prescription/:AppointmentID" exact />
            <Route component={Report_DischargeSlip} path="/DischargeSlip/:Id" exact />
            <Route component={IPDBilling_Report} path="/IPDBilling_Report/:Id" exact />
            <Route component={HospitalDashboard} path="/hmsdashboard" exact />
            <Route component={OPT} path="/OPT" exact />
            <Route component={OPTAssignLIst} path="/OPTAssignLIst" exact />
            <Route component={InvoicePrintThermal} path="/InvoicePrintThermal" exact />
            <Route component={IPDRegistration_Report} path="/IPDRegistrationReport/:Id" exact />
                                {/* Reports */}
                            {/* Sale Reports */}
            <Route component={ CustomerLedger } path={"/CustomerLedgerReport/:ProjectID/:FromDate/:ToDate/:FinYear/:CustomerID"} exact />
            <Route component={ SaleSummaryCustWise } path={"/SaleSummaryCustomerWiseReport/:ProjectID/:FromDate/:ToDate/:CustomerID"} exact />
            <Route component={ SaleSummarySalesManWise } path={"/SaleSummarySalesManWise/:ProjectID/:FromDate/:ToDate/:User_Id"} exact />
            <Route component={ SaleLedgerItemWise } path={"/SaleLedgerItemWise/:ProjectID/:FromDate/:ToDate/:FromItem/:ToItem"} exact />
            <Route component={ SaleMarginCustomerWise } path={"/SaleMarginCustomerWise/:ProjectID/:FromDate/:ToDate/:FromCustomer/:ToCustomer"} exact />
            <Route component={ SaleReturnLedgerCustWise } path={"/SaleReturnLedgerCustWise/:ProjectID/:FromDate/:ToDate"} exact />
            <Route component={ MostSoldItems } path={"/MostSoldItems/:ProjectID/:FromDate/:ToDate/:Num"} exact />
            <Route component={ MinimumSoldItems } path={"/MinimumSoldItems/:ProjectID/:FromDate/:ToDate/:Num"} exact />
            <Route component={ MinimumSoldItems } path={"/MinimumSoldItems/:ProjectID/:FromDate/:ToDate/:Num"} exact />
            <Route component={ DailySaleOfLastMOnth } path={"/DailySaleOfLastMOnth/:ProjectID"} exact />
            <Route component={ Receiveable } path={"/Receiveable/:ProjectID/:FromDate/:ToDate"} exact />
            
                        {/* Purchase Reports */}
            <Route component={ SupplierLedgerReport } path={"/SupplierLedgerReport/:ProjectID/:FromDate/:ToDate/:FinYear/:SupplierID"} exact />
            <Route component={ SupplierWiseLedgerReport } path={"/SupplierWiseLedgerReport/:ProjectID/:FromDate/:ToDate"} exact />
            <Route component={ PurchaseSummaryItemWiseReport } path={"/PurchaseSummaryItemWiseReport/:ProjectID/:FromDate/:ToDate/:FromItem/:ToItem"} exact />
            <Route component={ PayablesReport } path={"/Payables/:ProjectID/:FromDate/:ToDate"} exact />
                        
                {/* Inventory Reports */}
            <Route component={ StockBalanceReport } path={"/StockBalanceReport/:ProjectID"} exact />
            <Route component={ StockWareHouseWiseReport } path={"/StockWareHouseWiseReport/:ProjectID/:WareHouseID/:FromItem/:ToItem"} exact />
            <Route component={ StockBatchWiseReport } path={"/StockBatchWiseReport/:ProjectID"} exact />
            <Route component={ ShortInventoryItemsReport } path={"/ShortInventoryItemsReport/:ProjectID"} exact />
            <Route component={ MaxInventoryItemsReport } path={"/MaxInventoryItemsReport/:ProjectID"} exact />

            {/* PL Reports */}
            <Route component={ DailyActivityReport } path={"/DailyActivityReport/:ProjectID/:FromDate/:ToDate"} exact />
          {/*  <Route component={ PLReport } path={"/PLReport/:ProjectID/:FromDate/:ToDate"} exact />*/}
             <Route component={ IncomeStatment } path={"/PLReport/:ProjectID/:FromDate/:ToDate"} exact />
            {/* Expense Report */}
            <Route component={ ExpenseReport } path={"/ExpenseReport/:ProjectID/:FromDate/:ToDate"} exact />
            <Route component={ ExpenseClassificationReport } path={"/ExpenseClassificationReport/:ProjectID/:FromDate/:ToDate/:ClassificationID"} exact />
            <Route component={ ExpenseTypeReport } path={"/ExpenseTypeReport/:ProjectID/:FromDate/:ToDate/:TypeID"} exact />

             {/* Finance Report */}
            <Route component={ CashInHandReport } path={"/CashInHandReport/:ProjectID/:FromDate/:ToDate"} exact />
            <Route component={ CashInBankReport } path={"/CashInBankReport/:ProjectID/:FromDate/:ToDate/:BankID"} exact />

            <Route component={ PurchaseInvoiceReport } path = "/PurchaseReport" exact />
            <Route component={ PurchaseOrderReport } path = "/PurchaseOrderReport" exact />
            <Route component={ PurchaseReturnReport } path = "/PurchaseReturnReport" exact />
            <Route component={  SaleOrderReport  } path = "/SaleOrderReport" exact />
            <Route component={ SaleInvoiceReport } path = "/SaleInvoiceReport/:SaleInvoiceID" exact />
            <Route component={ SaleReturnReport } path = "/SaleReturnReport" exact />
            <Route component={ PaymentsList } path={"/Payments"} exact />
            <Route component={ PaymentEntry } path={"/PaymentEntry"} exact /> 
            <Route component={ PaymentPrint } path={'/PaymentPrint'} exact />
            <Route component={ ReceiptsList } path={"/Receipts"} exact />
            <Route component={ ReceiptEntry } path={"/ReceiptEntry"} exact /> 
            <Route component={RecieptPrint}  path={"/ReceiptPrint"} exact/>

          </Switch>
        </Router>
    </>
  );
}

export default App;
