import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectIPDRegistrationList } from "../../Services/IPDRegistrationAPI";
import { SelectUserProjects } from "../../Services/Projects"
import { setIPD_Regstration, SHOW_SCREEN } from '../../React_Redux/Actions'
import { GetCurrentDate } from '../../Services/Common'

 const IPDRegistrationList = ({Comp_Id,p_userid,IPD_Reg,setIPD_Regstration}) => {
    const [IsLoader, setIsLoader] = useState(false)
    const [IPDRegistration_Data, setIPDRegistration_Data] = useState([])
    const [ListDate , setListDate] = useState(GetCurrentDate)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Project: ''
    })
    useEffect(()=>{
        document.title = "IPD Registration List"
        SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

            setProjectList(ProjectData)
            if(IPD_Reg.RegData)
            {
              
              Select_IPDRegistration_List(Comp_Id,IPD_Reg.RegData.SelectedProject.Project_ID,ListDate)
              setSelectedProject(ProjectData.find(x => x.Project_ID ===  IPD_Reg.RegData.SelectedProject.Project_ID))
              setIPD_Regstration(null,0,false)
            }
            else
            {
              setSelectedProject(ProjectData[0])
              Select_IPDRegistration_List(Comp_Id,ProjectData[0].Project_ID,ListDate)
            }
          })
    },[])
    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }
    const column_IPDReg = [
        {
         name: "RegistrationNo",
         label: "Registration No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
       
        {
         name: "PatientName",
         label: "Patient Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
            name: "Guardian",
            label: "Guardian",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "Ward",
            label: "Ward",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "Room",
            label: "Room",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
        {
         name: "BedNo",
         label: "Bed No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
     
           {
            name: "Status",
            label: "Status",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var RegistrationID =IPDRegistration_Data
             if (RegistrationID[rowIndex.rowIndex] != null)
             
               return (
                 <Link to={"/IPDRegistrationEntry"} onClick={  e => {edit_IPDRegistration(RegistrationID[rowIndex.rowIndex].RegistrationID)
                
                }}> <Edit /></Link> 
             
               );
               
           }
          }
         },
         {
          name: "Print",
          label: "Print",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var RegistrationID =IPDRegistration_Data
             if (RegistrationID[rowIndex.rowIndex] != null)
             
               return (
                 <Link to={`/IPDRegistrationReport/${RegistrationID[rowIndex.rowIndex].RegistrationID}`}> <Print /></Link> 
             
               );
               
           }
          }
         }

    ];
    const validation = () =>{

       if(!SelectedProject)
        {
          setError_message({Project: 'must be selected'})
          return false
        }
  
        setError_message({ Project: ''})
        return true
      }
      const edit_IPDRegistration = (RegistrationID) =>{
        var mobj = {
            SelectedProject
        }
       setIPD_Regstration(mobj,RegistrationID,false)
    }
    const add_IPDRegistration = () =>{
        if(validation())
        {
          var mobj = {
              
              SelectedProject
          }
          setIPD_Regstration(mobj,0,true)
          document.getElementById("btn_navigate").click()
        }
        
      }
      const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
      const Select_IPDRegistration_List = (Comp_Id,ProjectID,ListDate) =>{
        showLoader()
        SelectIPDRegistrationList(Comp_Id,ProjectID,ListDate,(mData)=>{
          hideLoader()
          if(mData)
            setIPDRegistration_Data(mData)
        })
       }
       return ReactDOM.createPortal(
        <>
         <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col lg={6} xl={6} md={6} className="header_title text-white" >
                <h4> IPD Patient Registration</h4>
              </Col>
            </Row>
        <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right m-4" >
  
                      <Col lg={3} xl={3} md={3} >
                      <TextField
                      name="RegistrationDate"
                      id="RegistrationDate"
                      label="RegistrationDate"
                      type="date"
                       value={ListDate}
                      onChange={e=>setListDate(e.target.value)}
                      fullWidth
                  />
                      </Col>
  
                      <Col lg={3} xl={3} md={3} >
                          <Autocomplete
                              name="Project"
                              id="Project"
                              options={ProjectList}
                              getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                              value={SelectedProject}
                              onChange={(e, value) => {
                                  if (value)
                                  {
                                      setSelectedProject(value)
  
                                     
                                      Select_IPDRegistration_List(Comp_Id,value.Project_ID,ListDate)
                                  }
                                  else
                                  {
                                    setSelectedProject(null)
                                      setIPDRegistration_Data([])
                                  }
                              }}
                              renderInput={(params) =>
                                  <TextField {...params} name="Project" label="Select Project"
                                  />}
                          />
                           <span style={{color: 'red'}} > {error_message.Project} </span>
                      </Col>
  
              <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
                <Link to="/IPDRegistrationEntry" id='btn_navigate' ></Link>
                { btn_add &&(<Link type="button" className="btn btn-primary" to="/IPDRegistrationEntry" onClick={add_IPDRegistration} style={{ border: "none" }} > <Add />  Create New</Link>)}
              </Col>
            </Row>
            <Row className="row_margin_top row_margin_left row_margin_right" >
                <Col>    
                          
                  <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                      <MUIDataTable
                          data={IPDRegistration_Data}
                          columns={column_IPDReg}
                          options={{
                          selectableRows: 'none',
                          print: false,
                          search: true,
                          download: false,
                          viewColumns: false,
                          filter: false,
                          }}
                      />
                      
                      </MuiThemeProvider>
                  </div>
                </Col>
            </Row>
            <Loader
            show={IsLoader}
            hide={hideLoader}
          />
        </Card>
                          
        </>  , document.getElementById('mainContent')
      )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    IPD_Reg: state.IPD_Reg && state.IPD_Reg,
    Group_Id : state.Login_User.User_Data.Group_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  })
  export default connect(mapStateToProps,{setIPD_Regstration, SHOW_SCREEN})(IPDRegistrationList)
  
