import React from 'react'
import { Row, Col } from 'react-bootstrap'

const PatientInformation = ({label, value}) =>{
    return(
        <Row>
        <Col Col xl={6} lg={6} md={6} sm={6} xs={6}>
         <h4 style={{fontWeight:'bold'}}>{label}</h4> 
        </Col>
        <Col Col xl={6} lg={6} md={6} sm={6} xs={6}>
        <span >{value}</span>
        </Col>
        </Row>
    )
}

export default PatientInformation