import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import  { connect } from 'react-redux'
import Loader from '../Loader'

import { SelectSaleOrderLists } from '../../Services/SaleOrderAPI'
import  { set_SO, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { Autocomplete } from '@material-ui/lab'
import { TextField } from "@material-ui/core"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'

function SaleOrderList(props) {
  const [IsLoader, setIsLoader] = useState(false)
    const {  set_SO,ItemCat, Comp_Id,SaleOrder ,p_userid, Group_Id, SHOW_SCREEN } = props;
    const [SaleOrderList_Data, setSaleOrderList_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Project: ''
    })

    useEffect(()=>{
    document.title = " Pharmacy / SaleOrder"

    SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

      setProjectList(ProjectData)
      if(SaleOrder.ProjectID)
      {
        Select_SaleOrderLists(SaleOrder.ProjectID)
        setSelectedProject(ProjectData.find(x => x.Project_ID === SaleOrder.ProjectID))
      }
      else
      {
        setSelectedProject(ProjectData[0])
        Select_SaleOrderLists(ProjectData[0].Project_ID)

      }
    })
    set_SO(null,null,null, null)
    SelectGroupScreenAccess(Group_Id,11,102,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })
    },[])

    const validation = () =>{

      if(!SelectedProject)
      {
        setError_message({Project: 'must be selected'})
        return false
      }

      setError_message({Project: ''})
      return true
    }
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const column_SaleOrder = [
        {
         name: "SaleOrderID",
         label: "SaleOrderID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "SaleOrderNo",
            label: "SaleOrderNo",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
         name: "CustomerName",
         label: "Customer Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "OrderDate",
          label: "OrderDate",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
       
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var SaleOrderID = SaleOrderList_Data
             if (SaleOrderID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/SaleOrderEntry"} onClick={e => edit_Customer(SaleOrderList_Data[rowIndex.rowIndex].SaleOrderID)}> <Edit /></Link>
               );
           }
          }
         }
    ];
    const edit_Customer = (SaleOrderID) =>{
      set_SO(SaleOrderList_Data,SaleOrderID,false, SelectedProject.Project_ID)
    }
    const add_Customer = () =>{
      if(validation())
      {
        set_SO(SaleOrderList_Data,null,true, SelectedProject.Project_ID)
        document.getElementById("btn_navigate").click()
      }
    }
 
   const Select_SaleOrderLists = (Project_Id) =>{
    showLoader()
    SelectSaleOrderLists(Comp_Id,Project_Id,(mData)=>{
      hideLoader()
        setSaleOrderList_Data(mData)
    })
   }
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4 > Sale Order List</h4>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right m-4" >
          <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)
                                    Select_SaleOrderLists(value.Project_ID)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                  setSaleOrderList_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Project"
                                />}
                        />
                    <span style={{color: 'red'}} >{error_message.Project}</span>
            </Col>
            <Col  style={{ textAlign: "right" }} >
              <Link to={"/SaleOrderEntry"} id='btn_navigate' ></Link>
              { btn_add &&(<Link type="button" className="btn btn-primary" to="#" onClick={add_Customer} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                    <MUIDataTable
                        data={SaleOrderList_Data}
                        columns={column_SaleOrder}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.Items && state.Items.ItemData,
  Group_Id : state.Login_User.User_Data.Group_Id,
  SaleOrder: state.SO,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
// export default SaleOrderList
export default connect(mapStateToProps,{set_SO, SHOW_SCREEN})(SaleOrderList)
