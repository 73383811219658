import React, { useEffect, useState } from 'react'
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import "./ReportArchitect.css"
import { PrescriptionReport } from "../../Services/PendingPatinetListAPI"
import {connect} from "react-redux"
import FooterImg from "../../Images/SGHFooter.jpg"
import HeaderImg from "../../Images/SGHHeader.jpg"
import DRZHeader from "../../Images/DRZHeader.jpg"
import DRZFooter from "../../Images/DRZFooter.jpg"
function Prescription_Report({Comp_Name,Comp_Address,MobileNo,PhoneNo,HMS_User,User_Name,Comp_Id,UserType}) {

    const { AppointmentID } = useParams()
    const [PatientAppointment, setPatientAppointment] = useState(null)
    const [TPB_Data, setTPB_Data] = useState(null)
    const [PatientSymptoms, setPatientSymptoms] = useState([])
    const [Test_Data, setTest_Data] = useState([])
    const [Disease_Data, setDisease_Data] = useState([])
    const [Medicine_Data, setMedicine_Data] = useState([])
    const [Final_Diseases, setFinal_Diseases] = useState([])
    useEffect(() => {
    debugger
        document.title  = "Prescription "
        document.getElementById('TopBar').hidden = true
        document.getElementById('leftsidebar').hidden = true
        document.getElementById('rightsidebar').hidden = true
        document.getElementById('chat-wrapper').hidden = true
        document.getElementById('mainContent').hidden = true
                PrescriptionReport(AppointmentID, (data) => {
                    setPatientAppointment(data.Patient_Appointment[0])
                    setTPB_Data(data.Basic_Data[0])
                    setPatientSymptoms(data.Symptoms_Data)
                    setTest_Data(data.Test_Data)
                    setDisease_Data(data.Disease_Data)
                    setMedicine_Data(data.Medicain_Data)
                    setFinal_Diseases(data.Final_Disease)
        })
    }, [])


  
    return (
        <Row className="row_margin_right m-3" style={{color: 'black', backgroundColor:'white'}}>
            <Col>

                    <Row  >
                        <Col lg={12} md={12}>
                            {
                                Comp_Id === 3 // Surriya Gyne Image
                                    ?
                                    <img src={HeaderImg} style={{ height: '55%', width: '100%' }} />
                                     :
                                     <img src={DRZHeader} style={{ height: '55%', width: '100%' }} />
                                    // <div className="header1">
                                      
                                    //     <div className="credentials1">
                                    //         <h4>{User_Name}</h4>
                                    //         <p>{HMS_User[0].Qualification}</p>
                                    //         {/* <small>Adress</small> */}
                                    //         <p>{HMS_User[0].PhoneNo}</p>
                                    //     </div>
                                    // </div>
                            }


                        </Col>
                    </Row>
             
              
                <Row  style={{marginTop:'-170px'}}>
                    <Col xl={6} lg={6} md={6} sm={6} className="Patient-Detail1">
                        <div className="title1" >
                            &nbsp;&nbsp;  Patient Details &nbsp;&nbsp; (مریض کی تفصیلات)
                        </div>
                        <div className="body1" style={{height: '110px', color:'black'}} >

                            <Row style={{color:'black'}}>
                            <Col xl={1} lg={1} md={1} sm={1}></Col>
                            <Col xl={4} lg={4} md={4} sm={4}><strong> Patient Name:</strong></Col>
                            <Col xl={7} lg={7} md={7} sm={7} style={{textAlign:'left'}}>{PatientAppointment && PatientAppointment.PatientName}</Col>
                               
                            </Row>
                            <Row>
                            <Col xl={1} lg={1} md={1} sm={1}></Col>
                            <Col xl={4} lg={4} md={4} sm={4}><strong> Age:</strong></Col>
                            <Col xl={7} lg={7} md={7} sm={7} style={{textAlign:'left'}}>{PatientAppointment && PatientAppointment.Age + ' ' +PatientAppointment.AgeType}</Col>
                              
                                
                            </Row>
                            <Row>
                            <Col xl={1} lg={1} md={1} sm={1}></Col>
                            <Col xl={4} lg={4} md={4} sm={4}><strong> Gardian:</strong></Col>
                            <Col xl={7} lg={7} md={7} sm={7} style={{textAlign:'left'}}>{PatientAppointment && PatientAppointment.Guardian}</Col>
                            
                                
                            </Row>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} className="Patient-Detail1">
                        <div className="title1" >
                            &nbsp;&nbsp;  Appointment Details &nbsp;&nbsp; (مشاورت کی تفصیلات)
                        </div>
                        <div className="body1" style={{height: '110px'}} >
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} style={{ textAlign: "right" }} ><strong> Appointment ID: </strong></Col>
                                <Col xl={1} lg={1} md={1} sm={1} >{PatientAppointment && PatientAppointment.AppointmentID}</Col>
                                <Col xl={3} lg={3} md={3} sm={1} style={{ textAlign: "right" }} ><strong>Date:</strong></Col>
                                <Col xl={4} lg={4} md={4} sm={4}>{PatientAppointment && PatientAppointment.AppointmentDate}</Col>
                               
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} style={{ textAlign: "right" }} ></Col>
                                <Col xl={2} lg={2} md={2} sm={2} ></Col>
                                <Col xl={4} lg={4} md={4} sm={4}  style={{ textAlign: "right" }} ></Col>
                                <Col xl={2} lg={2} md={2} sm={2}  > </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} style={{ textAlign: "right" }} ><strong>Consulted By:</strong></Col>
                                <Col xl={8} lg={8} md={8} sm={8} >{PatientAppointment && PatientAppointment.Doctor} </Col>
                               
                            </Row>
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={4} style={{ textAlign: "right" }} ><strong>Status:</strong></Col>
                                <Col xl={8} lg={8} md={8} sm={8}> {PatientAppointment && PatientAppointment.Status} </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row  style={{marginTop:'-10px'}}>
                    <Col xl={2} lg={2} md={2} sm={2} className="text-align1 " > Pulse: {TPB_Data && TPB_Data.Pulse} </Col>
                    <Col xl={2} lg={2} md={2} sm={2} className="text-align1 " > Temperature: {TPB_Data && TPB_Data.Temerature ? TPB_Data.Temerature : ''} </Col>
                    <Col xl={2} lg={2} md={2} sm={2} className="text-align1 " > Blood Suger / Diabites: {TPB_Data && TPB_Data.Goloco} </Col>
                    <Col xl={2} lg={2} md={2} sm={2} className="text-align1 " > Weight: {TPB_Data && TPB_Data.Weight} </Col>
                    <Col xl={2} lg={2} md={2} sm={2} className="text-align1 " > Height: {TPB_Data && TPB_Data.Height} </Col>
                    <Col xl={2} lg={2} md={2} sm={2} className="text-align1 " > BP: {TPB_Data && TPB_Data.BP} </Col>
                </Row>
                <Row style={{width:'98%',height:'850px',marginLeft:'0.5%',border:'2px solid black'}} >
                    <Col xl={3} lg={3} md={3} sm={3} >
                        <Row>
                            <Col style={{ fontSize: "15px" }} className='pt-3' >
                                <h5 style={{ fontSize: "16px", paddingBottom: "3%" }} > &nbsp;&nbsp;<b>Symptoms  &nbsp;&nbsp; (علامات) </b> </h5>
                                {PatientSymptoms.map((value, index) => {
                                   
                                   return (<div key={index} >&nbsp;&nbsp;&nbsp;&nbsp;&rarr; {value.Symptoms} </div>)
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-height1" >
                                <h5 style={{ fontSize: "16px", paddingBottom: "3%" }} ><b> &nbsp;&nbsp; Tests  &nbsp;&nbsp; (جانج پڑتال)</b> </h5>
                                {Test_Data.map((value, index) => {
                                   return <div key={index} >&nbsp;&nbsp;&nbsp;&nbsp;&rarr; {value.Tests} </div>
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-height1" >
                                <h5 style={{ fontSize: "16px", paddingBottom: "3%" }} ><b> &nbsp;&nbsp; Diagnose  &nbsp;&nbsp; (تشخیص) </b></h5>
                                {Disease_Data.map((value, index) => {
                                   return <div key={index} >&nbsp;&nbsp;&nbsp;&nbsp;&rarr; {value.Diseases} </div>
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-height1" >
                                <h5 style={{ fontSize: "16px", paddingBottom: "3%" }} ><b> &nbsp;&nbsp; Final Diagnose  &nbsp;&nbsp; (بیماریاں) </b></h5>
                                {Final_Diseases.map((value, index) => {
                                   return <div key={index} >&nbsp;&nbsp;&nbsp;&nbsp;&rarr; {value.Diseases} </div>
                                })}
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={9} style={{borderLeft:'2px solid black'}} >
                        <Row>
                            <Col style={{ fontSize: "15px" }}  className='pt-3' >
                                <h5 style={{ fontSize: "16px", paddingBottom: "3%" }} > <b>&nbsp;&nbsp; Use of Medicines  &nbsp;&nbsp; (ادویات کا استعمال)</b> </h5>


                                {Medicine_Data.map((value, index) => {
                                    return(
                                        <Row key={index}>
                                            <Col lg={6} xl={6} md={6} sm={6} >
                                                <div> &nbsp;&nbsp;&nbsp;&nbsp;&rarr; {value.ItemDiscription}</div>
                                            </Col>
                                            <Col lg={6} xl={6} md={6} sm={6} style={{ textAlign: "right" }} >
                                                <div > {value.Dosage}  {value.Days} </div>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row>                

                <Row style={{marginTop:'50px'}}>
                    {
                     Comp_Id === 3
                      ? 
                    <Col lg={12} md={12}><img src={FooterImg} style={{height:'75%',width:'100%'}} /></Col>
                    :
                    <Col lg={12} md={12}><img src={DRZFooter} style={{height:'75%',width:'100%'}} /></Col>
                //     <><Col sm={3} md={3} lg={3} xl={3} style={{textAlign:'center'}}>
                //     <Row>
                //        <Col>
                //             Ph: {PhoneNo}
                //        </Col> 
                //     </Row>
                //     <Row>
                //         <Col>
                //             Mob: {MobileNo}
                //         </Col>
                //     </Row>
                // </Col>
                // <Col sm={9} md={9} lg={9} xl={9} style={{marginTop:'10px'}}>
                // {Comp_Address}
                // </Col>
                // </>
                }
                </Row>
                </Col>
        </Row>
    )
}

const mapStateToProps = state =>({
    Comp_Name: state.GlobalVariables.Company.Name,
    Comp_Id:state.GlobalVariables && state.GlobalVariables.Company.Branch_Id,
    Comp_Address: state.GlobalVariables.Company.Address,
    PhoneNo :  state.GlobalVariables.Company.PhoneNo,
    MobileNo: state.GlobalVariables.Company.MobileNo,
    UserType : state.Login_User && state.Login_User.User_Data.User_Type,
    // HMS_User : state.HMS_USERS && state.HMS_USERS.HMS_USERS,
    User_Name: state.Login_User.User_Data && state.Login_User.User_Data.User_name,
})

export default connect(mapStateToProps)(Prescription_Report)
