import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectItemsList =(Comp_Id,ProjectID,ItemMainTypeID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemsList/${Comp_Id}/${ProjectID}/${ItemMainTypeID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const GetMaxItemCode =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/GetMaxItemCode/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectItem =(Comp_Id,ProjectID,ItemID,Callback) =>{
  axios.get(`${domain}/Items/SelectItem/${Comp_Id}/${ProjectID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectData =(Callback) =>{
  axios.get(`${domain}/Items/SelectData`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SaveItems = (ItemsData,isAddNew,callback) =>{

  axios.post(`${domain}/Items/SaveItems`,{
    ItemsData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}



export const SelectItemsListWRTProject =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemsListWRTProject/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
