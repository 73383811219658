import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import  { connect } from 'react-redux'
import Loader from '../Loader'
import { SelectPendingPatientList, SelectPendingPatientTests } from '../../Services/PendingPatinetListAPI'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { setPENDING_PATIENT, setGrid_Data} from '../../React_Redux/Actions'
function PendingPatientList(props) {

    const {  Comp_Id,ProjectID} = props;
    const [IsLoader, setIsLoader] = useState(false)
    const [PendingPatientList_Data, setPendingPatientList_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)

    useEffect(()=>{
    document.title = "Pending Patient"
//     SelectGroupScreenAccess(Group_Id,11,92,(data)=>{
            
//       setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
//       setBtn_delete(parseInt(data.Del) === 0 ? false : true)
//       SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
//       parseInt(data.Edit) === 0 ? false : true,
//       parseInt(data.Del) === 0 ? false : true,
//       data.OtherAccess
//       )
      
//   })

Select_PendingPatinetList()
    },[])
    const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const column_PendingPatient = [
        {
         name: "AppointmentDate",
         label: "Appointment Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "PatientName",
            label: "Patient Name",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
         name: "Doctor",
         label: "Doctor Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "DoctorFee",
          label: "DoctorFee",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
        
         {
          name: "Checkup",
          label: "Checkup",
          options: {
              customHeadLabelRender: (columnMeta) => {
                  return (
                      <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
                  )
              },
              customBodyRender: (dataIndex, rowIndex) => {
                  const _Grid_Data = PendingPatientList_Data
                  if (_Grid_Data[rowIndex.rowIndex] != null)
                      return (
                          <>
                              <Link to={"/OPDCheckup"}  onClick={e => {
                                   props.setPENDING_PATIENT(_Grid_Data[rowIndex.rowIndex])
                              }} > <Edit /> </Link>
                          </>
                      );
              }
          }
      },
      {
          name: "PrintTest",
          label: "Print Test Report",
          options: {
              customHeadLabelRender: (columnMeta) => {

                  return (
                      <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
                  )
              },
              customBodyRender: (dataIndex, rowIndex) => {
                  const _Grid_Data = PendingPatientList_Data
                  if (_Grid_Data[rowIndex.rowIndex] != null)
                      return (
                          <>
                              <Link to={"/Test-Report"} target="_blank" onClick={e => {
                                    Print_Test_Report(_Grid_Data[rowIndex.rowIndex])
                              }} > <Print /> </Link>
                          </>
                      );
              }
          }
      }
    ];
 
    const Print_Test_Report = (PatientData)=>{
      var obj = {
          Patient_Name: PatientData.PatientName
        }
        SelectPendingPatientTests(Comp_Id,ProjectID,PatientData.AppointmentID,(mData)=>{
        props.setGrid_Data(mData)
      })
        localStorage.setItem('token',sessionStorage.getItem('token'))
        window.localStorage.setItem("test", JSON.stringify(obj))
  }
   const Select_PendingPatinetList = () =>{
    showLoader()
    
    SelectPendingPatientList(Comp_Id,ProjectID,(mData)=>{
      hideLoader()
      setPendingPatientList_Data(mData)
    })
   }
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4 >Pending Patinets</h4>
            </Col>
          </Row>
      <Card>
         
          
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                    <MUIDataTable
                        data={PendingPatientList_Data}
                        columns={column_PendingPatient}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                </div>
              </Col>
          </Row>
          <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('mainContent')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.Items && state.Items.ItemData,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Group_Id : state.Login_User.User_Data.Group_Id,

})
export default connect(mapStateToProps,{setPENDING_PATIENT,setGrid_Data})(PendingPatientList)
