import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectToDayPatient =(Comp_Id,ProjectID,date,Callback) =>{
  axios.get(`${domain}/Checkup/SelectToDayPatient/${Comp_Id}/${ProjectID}/${date}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectToDayPatientWRTDoctor =(Comp_Id,ProjectID,DoctorID,date,Callback) =>{
    axios.get(`${domain}/Checkup/SelectToDayPatientWRTDoctor/${Comp_Id}/${ProjectID}/${DoctorID}/${date}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  
export const CallPatient =(Comp_Id,ProjectID,RegistrationID,Callback) =>{
    axios.get(`${domain}/Checkup/CallPatient/${Comp_Id}/${ProjectID}/${RegistrationID}`)
         .then(response=>{
             Callback(response.data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SaveCheckup = (Disease_Data,Medicine_Data,Checkup_Data,Scan_Test_Data,Symptoms_Data,Remarks_Data,Comp_Id,ProjectID,AppointmentID,Status,FinalDisease, DeletedSymptoms_Data,DeletedDisease_Data,DeletedTest_Data,callback) =>{

    axios.post(`${domain}/Checkup/SaveCheckup`,{
        Disease_Data,
        Medicine_Data,Checkup_Data,Scan_Test_Data,Symptoms_Data,Remarks_Data,Comp_Id,ProjectID,AppointmentID,Status,FinalDisease
        ,DeletedSymptoms_Data,DeletedDisease_Data,DeletedTest_Data,
    })
    .then(response=>{
        toast.configure()
        toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        callback()
    })
    .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
            })
            callback()
        })
  }