import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Save, Cancel } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Loader from '../Loader'
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
//  import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
 import { connect} from 'react-redux'
 import { SelectWards } from '../../Services/WardsAPI'
 import { SelectBedNo } from '../../Services/BedNoAPI'

import { SelectBeds , SaveBeds,SelectRoomsWRTWard } from '../../Services/BedsAPI'


export class Beds extends React.Component {
  constructor(props){
      super(props)
      this.state = {
        Bed:[],
        DeletedBed:[],
        Wards:[],
        SelectedWards:null,
        Rooms:[],
        SelectedRooms:null,
        BedNo:[],
        SelectedBedNo: null,
       btn_Add: true,
       btn_Edit: true,
       btn_Delete: true,
       Others: null,
       IsLoader:false,
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.grid = null
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.BGValidation = {
        required: [this.customBGValidation, 'Beds must be Entered'],
      };
  }
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
 
  customBGValidation(args) {
  return getValue('value', args) != ''   
  }

 
  componentDidMount()
  {
    document.title = "Beds"
    // SelectGroupScreenAccess(this.props.Group_Id,10,87,(data)=>{
    //     this.setState({ btn_Add: parseInt(data.AddNew) === 0 ? false : true, 
    //                     btn_Edit: parseInt(data.Edit) === 0 ? false : true,
    //                     btn_Delete: parseInt(data.Del) === 0 ? false : true,
    //                     Others:data.OtherAccess})
    // })
    SelectWards(this.Comp_Id,this.ProjectID, (data) => {

        for (let i = 0; i < data.length; i++)
        {
            this.state.Wards.push(data[i])
        }
       
    })
    SelectBedNo(this.Comp_Id,this.ProjectID,(mdata)=>{
       this.setState({BedNo:mdata})
    })
   
   
 this.Select_Bed();
  }
  showLoader = () => {
   
    this.setState({IsLoader:true})
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }
  Select_Bed =() =>{
    this.showLoader()
    SelectBeds(this.Comp_Id,this.ProjectID,(mBed)=>{
        
        mBed.map((value,index)=>{
            mBed[index].SrNo = index + 1
        })
        if(mBed.length > 0 )
        this.setState({Bed:mBed})
    })
    this.hideLoader()
  }


 
 BedNo = (rowValue) => {
debugger
    return (
        <Autocomplete
            id="BedNo"
            name="BedNo"
            options={this.state.BedNo}
            defaultValue={this.state.BedNo.length > 0 ? this.state.BedNo.find(x => x.BedNoID === rowValue.BedNoID) : {BedNoID: rowValue.BedNoID,BedNo: rowValue.BedNo} }
            getOptionLabel={(option) => option.BedNo ? option.BedNo : "" } 
            onChange={(e, value) => {
                if (value) {
                    this.setState({SelectedBedNo:value})
                }
                else {
                    this.setState({SelectedBedNo:null})
                }
            }}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
        />
    )
}
Ward = (rowValue) => {

    return (
        <Autocomplete
            id="Ward"
            name="Ward"
            options={this.state.Wards}
            defaultValue={this.state.Wards.find(x => x.WardID === rowValue.WardID)}
            getOptionLabel={(option) => option.Ward ? option.Ward : "" } 
            onChange={(e, value) => {
                if (value) {
                    this.setState({SelectedWards:value})
                    SelectRoomsWRTWard(value.WardID, this.Comp_Id, this.ProjectID,(mRooms)=>{ 
                        if(this.RoomRef){
                            this.RoomRef.props.options.splice(0, this.RoomRef.props.options.length)
                            for (var i = 0; i < mRooms.length; i++) {
                                this.RoomRef.props.options.push(mRooms[i])
                            }
                            this.setState({Rooms:mRooms})
                        }
                    })
                }
                else {
                    this.setState({SelectedWards:null})
                }
            }}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
        />
    )
}
Room = (rowValue) => {

    this.RoomRef = <Autocomplete
    id="Room"
    name="Room"
    options={this.state.Rooms}
    defaultValue={ this.state.Rooms.length > 0 ? this.state.Rooms.find(x => x.RoomID === rowValue.RoomID) : {RoomID: rowValue.RoomID,Room: rowValue.Room} }
    getOptionLabel={(option) => option.Room ? option.Room : "" } 
    onChange={(e, value) => {
        
        if (value) {
            this.setState({SelectedRooms:value})
        }
        else {
            this.setState({SelectedRooms:null})
        }
    }}
    renderInput={(params) => <TextField {...params} />}
    fullWidth
/>
    return (this.RoomRef)
}
 Status = (rowvalue) => {
    return (
        <TextField
            className="textbox"
            name="Status"
            id="Status"
            defaultValue={rowvalue.Status}
            inputProps={{
                readOnly:true
            }}
            fullWidth
        />
    )
}
SaveHandler =()=>{
        if(this.state.Bed.length > 0 || this.state.DeletedBed.length > 0)
        {
            
            for (let i = 0; i < this.state.Bed.length; i++) {
                
                if(this.state.Bed[i].WardID === undefined || this.state.Bed[i].WardID === '')
                {
                    
                    Swal.fire({
                        icon:'error',
                        text:`Bed at Sr. No. ${this.state.Bed[i].SrNo} is required`
                    })
                    return
                }
                
            }
            
            SaveBeds(this.state.Bed,this.state.DeletedBed,()=>{
                this.Select_Bed();
            })
        }
        else
        { 
            Swal.fire({
                icon: 'info',
                text: `Bed Must be Entered...`,
            })
            return
        }
}


beforeRowInsert = (rowValue) =>{
    
    if(rowValue.rowData)
    {
        
        if(rowValue.data)
        {
            if(rowValue.data.SrNo)
            {
                if(rowValue.requestType != "cancel")
                {
                    // frond end column grid field wala = db table ka column 
                    rowValue.data.Ward = this.state.SelectedWards.Ward
                    rowValue.data.WardID = this.state.SelectedWards.WardID
                    rowValue.data.RoomID = this.state.SelectedRooms.RoomID
                    rowValue.data.Room = this.state.SelectedRooms.Room
                    rowValue.data.BedNoID = this.state.SelectedBedNo.BedNoID
                    rowValue.data.BedNo = this.state.SelectedBedNo.BedNo
                    
                    if(rowValue.data.BedID === undefined){
                        rowValue.data.action = "add"
                    }else{
                        rowValue.data.action = rowValue.action
                    }
                  
                }
            }    
            else
            {
                rowValue.data.SrNo = this.state.Bed.length + 1
                rowValue.data.Status = "FREE"
                 this.setState({SelectBedNo:null, SelectWards:null, SelectedRooms:null,Rooms:[]})
            }
        }
    }
  
}
afterRowInsert = (rowValue) =>{ 
 
    if(rowValue.requestType === 'delete')
    {
        if(rowValue.data[0].BedID)
        {
            this.setState({DeletedBed:this.state.DeletedBed.concat(rowValue.data)})
            
        }
    }
    if (rowValue.requestType == "add") {
        document.getElementById('Ward').focus()
    }
    else if (rowValue.requestType == "beginEdit") {

        document.getElementById('Ward').focus()
      
        let _BedNoData = this.state.BedNo.find(x => x.BedNoID === rowValue.rowData.BedNoID)
        
        let _WardData = this.state.Wards.find(x => x.WardID === rowValue.rowData.WardID)
        SelectRoomsWRTWard(rowValue.rowData.WardID,this.Comp_Id, this.ProjectID,(mRooms)=>{   
            let _RoomData = mRooms.find(x => x.RoomID === rowValue.rowData.RoomID)
            
            if(this.RoomRef){
                this.RoomRef.props.options.splice(0, this.RoomRef.props.options.length)
             
           
                // var _RoomData = mdata.find(x => x.RoomID === rowValue.rowData.RoomID)
                for (var i = 0; i < mRooms.length; i++) {
                    this.RoomRef.props.options.push(mRooms[i])
                  
                }
                this.setState({Rooms:mRooms})
            }
          
            this.setState({SelectBedNo:_BedNoData, SelectWards:_WardData,SelectedRooms:_RoomData})
                         }) 
    
    }
    else if (rowValue.requestType == "save") {
        // for (let i = 0; i < this.state.Bed.length; i++) {
        //     if (rowValue.data.SrNo === this.state.Bed[i].SrNo) {
        //         this.state.Bed[i].BedNoID = rowValue.data.BedNoID;
        //         this.state.Bed[i].Bed = rowValue.data.WardID;
        //         this.state.Bed[i].BedID = rowValue.data.RoomID;
                
               
        //     }
        // }
    }
}

toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
} 

render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','Bed']);
         enableRipple(true);
    return ReactDOM.createPortal (
        <>
            <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>Beds 
                        </h2>
                    </div>
                </div>
            </div>
    <Card>     
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{width:'80%', marginLeft:'10%'}} >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}> 
                            <GridComponent dataSource={this.state.Bed} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: this.state.btn_Edit, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='200' field='Ward' headerText='Ward'  editTemplate={this.Ward} textAlign='Left' /> 
                                    <ColumnDirective width='200' field='Room' headerText='Room'  editTemplate={this.Room} textAlign='Left' />   
                                    <ColumnDirective width='250' field='BedNo' headerText='BedNo' editTemplate={this.BedNo} textAlign='Left'/> 
                                   <ColumnDirective width='200' field='Status' headerText='Status'  editTemplate={this.Status} textAlign='Left' /> 
                                    
                                    <ColumnDirective field='BedID' visible={false}></ColumnDirective>
                                    <ColumnDirective field='RoomID' visible={false}></ColumnDirective>
                                    <ColumnDirective field='WardID'   visible={false}></ColumnDirective>
                                    <ColumnDirective field='BedNoID'   visible={false}></ColumnDirective>
                                    <ColumnDirective field='Comp_Id'   defaultValue={this.Comp_Id} hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='ProjectID' defaultValue={this.ProjectID}  hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            <button type="button" className="btn btn-primary"  style={{border: "none"}} onClick={this.SaveHandler} > <Save /> <u>Save</u></button>
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'#'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                  />
                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})

export default connect(mapStateToProps)(Beds)