import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import {SelectDoctorsList} from '../../Services/OPDRegistrationAPI'
import { SelectOperationTheatorList  } from '../../Services/OPTAPI'
import {SelectWards} from '../../Services/WardsAPI'
import { GetCurrentDate, GetCurrentTime } from '../../Services/Common'

function OPTAssign_Modal({show, onHide,Comp_Id,Project_ID,UserID, mSaveOPTAssign,addNew,mdopt, show_screen}) {

    const [Doctors_List, setDoctors_List] = useState([])
    const [dopt_id,setdopt_id] = useState(0)
    const [SelectedDoctorOption, setSelectedDoctorOption] = useState(null)
    const [Wards_List, setWards_List] = useState([])
    const [SelectedWard, setSelectedWard] = useState(null)
    const [OPT_List, setOPT_List] = useState([])
    const [SelectedOPT,setSelectedOPT] = useState(null)
    const [OperationDate,setOperationDate] = useState(GetCurrentDate())
    const [FromTime,setFromTime] = useState(GetCurrentTime())
    const [ToTime,setToTime] = useState(GetCurrentTime())
    const [rent, setrent] = useState(0)
    const [CreatedDate,setCreatedDate] = useState(GetCurrentDate())
    const [Error_List, setError_List] = useState({
        doctor_Err : '',Ward_Err:'',OPT_Err:'',OpDate_Err:'',FromTime_Err:'',ToTime_Err:'',Rent_Err:''
    })
    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    useEffect(()=>{
        SelectDoctorsList(Comp_Id,(mdoctors)=>{
            setDoctors_List(mdoctors)
        })
        SelectWards(Comp_Id,Project_ID,(mWards)=>{
            setWards_List(mWards)
        })
        SelectOperationTheatorList(Comp_Id,Project_ID,(mOPT)=>{
            setOPT_List(mOPT)
        })
        if(addNew)
        {
            setdopt_id(0)
        }
        else 
        {
            // setBtn_edit(show_screen.edit)
          if(mdopt.length > 0)
          {
            
            Doctors_List.map(value=>{
                if(value.User_id === mdopt[0].DoctorID)
                {
                    setSelectedDoctorOption(value)
                }
            })
            Wards_List.map(value=>{
                if(value.WardID === mdopt[0].WardID)
                {
                    setSelectedWard(value)
                }
            })
            OPT_List.map(value=>{
                if(value.OPTID === mdopt[0].OPTID)
                {
                    setSelectedOPT(value)
                }
            })
            setdopt_id(mdopt[0].OPTAssignID)
            setOperationDate(mdopt[0].AssignDate)
            setFromTime(mdopt[0].FromTime)
            setToTime(mdopt[0].ToTime)
            setrent(mdopt[0].Rent)
          }
        
        }

    },[mdopt])
    const save_Handler = ()=>{
        if(Validate())
        {
            var mOPTData= {
                OPTAssignID:dopt_id,
                DoctorID:SelectedDoctorOption.User_id,
                WardID:SelectedWard.WardID,
                OPTID:SelectedOPT.OPTID,
                Rent:rent,
                AssignDate:OperationDate,
                FromTime:FromTime,
                ToTime:ToTime,
                CreatedDate:CreatedDate,
                UserID:UserID,
                Comp_Id:Comp_Id,
                ProjectID:Project_ID
            }
            mSaveOPTAssign(mOPTData,addNew,()=>{
                clear_fields()
            })

        } 
    }
    const clear_fields = ()=>{
        setSelectedDoctorOption(null)
        setSelectedWard(null)
        setSelectedOPT(null)
        setOperationDate(GetCurrentDate())
        setFromTime(GetCurrentTime())
        setToTime(GetCurrentTime())
        setrent(0)
        setError_List({ doctor_Err : '',Ward_Err:'',OPT_Err:'',OpDate_Err:'',FromTime_Err:'',ToTime_Err:'',Rent_Err:''})
        onHide()
    }

    const Validate = ()=>{
        
        var  doctorErr = '',WardErr='',OPTErr='',
            OpDateErr='',FromTimeErr='',ToTimeErr='',RentErr=''
            var regex = new RegExp(':', 'g')
        
            if(!SelectedDoctorOption)
            {
            doctorErr = 'Doctor is required'
            }
            if(!SelectedWard)
            {
                WardErr = 'Ward is required'
                
            }
            if(!SelectedOPT)
            {
                OPTErr = 'OPT is required'
            }
            if(FromTime === ToTime)
            {
                FromTimeErr = "From Time & ToTime Are Same"
                
            }
            if(parseInt(FromTime.replace(regex, ''), 10) > parseInt(ToTime.replace(regex, ''), 10)){
                FromTimeErr = "From Time must be smaller than To Time"
                
            }
            // if(rent === 0)
            // {
            //     RentErr = "Rent must be greater than zero"
            // }
            if(doctorErr)
            {
                setError_List({...Error_List,doctor_Err:doctorErr})
                document.getElementById('Doctor').focus()
                return false 
            }
            if(WardErr)
            {
                setError_List({...Error_List,doctor_Err:'',Ward_Err:WardErr})
                document.getElementById('Wards').focus()
                return false
            }
            if(OPTErr)
            {
                setError_List({...Error_List,doctor_Err:'',Ward_Err:'',OPT_Err:OPTErr})
                document.getElementById('OPT').focus()
                return false
            }
            if(FromTimeErr)
            {
                setError_List({...Error_List,doctor_Err:'',Ward_Err:'',OPT_Err:'',FromTime_Err:FromTimeErr})
                document.getElementById('FromTime').focus()
                return false   
            }
            setError_List({ doctor_Err : '',Ward_Err:'',OPT_Err:'',OpDate_Err:'',FromTime_Err:'',ToTime_Err:'',Rent_Err:''})
            return true
    }
    
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header  className="header_title" >
            <Modal.Title>OPT Assign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="Doctor"
                                id="Doctor"
                                options={Doctors_List}
                                getOptionLabel={(option) => option.User_name ? option.User_name : ""} 
                                value={SelectedDoctorOption}
                                onChange={(e,value) =>{
                                if(value)
                                setSelectedDoctorOption(value)
                                else
                                setSelectedDoctorOption(null)
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Doctor" label="Doctor"
                                />}
                            />
                             <span style={{color: "red"}} >{Error_List.doctor_Err}</span>
                        </Col>
                           <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="Wards"
                                id="Wards"
                                options={Wards_List}
                                getOptionLabel={(option) => option.Ward ? option.Ward : ""} 
                                value={SelectedWard}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedWard(value)
                                
                                }
                                else
                                setSelectedWard(null)
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Wards" label="Wards"
                                />}
                            />
                             <span style={{color: "red"}} >{Error_List.Ward_Err}</span>

                        </Col>
                        <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="OPT"
                                id="OPT"
                                options={OPT_List}
                                getOptionLabel={(option) => option.OPT ? option.OPT : ""} 
                                value={SelectedOPT}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedOPT(value)
                                
                                }
                                else
                                setSelectedOPT(null)
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="OPT" label="OPT"
                                />}
                            />
                             <span style={{color: "red"}} >{Error_List.OPT_Err}</span>

                        </Col>
                    </Row>

                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="AssignDate"
                                id="AssignDate"
                                label="Operation Date"
                                type="date"
                                value={OperationDate}
                                onChange={e=>{
                                    setOperationDate(e.target.value)
                                }}
                                fullWidth
                            />
                             <span style={{color: "red"}} >{Error_List.OpDate_Err}</span>
                            
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="FromTime"
                                id="FromTime"
                                label="From Time"
                                type="time"
                                value={FromTime}
                                onChange={e=>{
                                    setFromTime(e.target.value)
                                }}
                                fullWidth
                            />
                             <span style={{color: "red"}} >{Error_List.FromTime_Err}</span>

                        </Col>
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="ToTime"
                                id="ToTime"
                                label="To Time"
                                type="time"
                                value={ToTime}
                                onChange={e=>{
                                    setToTime(e.target.value)
                                }}
                                fullWidth
                            />
                             <span style={{color: "red"}} >{Error_List.ToTime_Err}</span>
                            
                        </Col>
                    </Row>

                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col lg={4} xl={4} md={4} >
                            <TextField
                                name="Rent"
                                id="Rent"
                                label="Rent"
                                type="number"
                                value={rent}
                                onChange={e=>{
                                setrent(e.target.valueAsNumber)
                                }}
                                fullWidth
                            />
                             <span style={{color: "red"}} >{Error_List.Rent_Err}</span>
                            
                        </Col>
                        <Col lg={4} xl={4} md={4} ></Col>
                        <Col lg={4} xl={4} md={4} >
                        <span style={{color: "red"}} >* Please Enter <b>ToTime</b> 1 min less than the Original Time. <br />e.g: For 1:30 PM Enter 01:29 PM</span>
                        </Col>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col style={{textAlign: "right", marginTop: "1%"}} >
                            { btn_edit&&(<button type="button" className="btn btn-primary"  style={{border: "none"}} onClick={save_Handler} > <Save /> <u>Save</u></button>)}
                            <button type="button" className="btn btn-primary" onClick={clear_fields} style={{border: "none"}} > <Cancel /> <u>Cancel</u></button>
                        </Col>
                    </Row>
                </Card>
            </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  })
export default connect(mapStateToProps)(OPTAssign_Modal)
