import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectProjects =(Branch_Id,Callback) =>{
  axios.get(`${domain}/Projects/SelectProjects/${Branch_Id}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}



export const SelectUserProjects =(Branch_Id,User_Id,Callback) =>{
  axios.get(`${domain}/Projects/SelectUserProjects/${Branch_Id}/${User_Id}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}