import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Loader from '../Loader'
import { GetCurrentDate } from '../../Services/Common'
import { SelectSupplierList } from "../../Services/SuppliersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { GetMaxPurchaseNo, SelectItemDetails,SavePurchaseOrder,SelectPurchaseOrderMaster,SelectPurchaseOrderDetail,SelectItemsWRTMinStock } from '../../Services/PurchaseOrderAPI'
//  import MinStockModal from './MinStockModal'
  import ReOrderStockModal from './ReorderStockModal'
export class PurchaseOrderEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
          PurchaseOrderNo: '',
          SupplierList: [],
          SelectedSupplier: null,
          PurchaseOrderDate: GetCurrentDate(),
          CreatedDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Discription: '',
          GrandTotal:0.00,
          PO_Detail_Data: [],
          Supplier_Err: '',
        UOMID:0,
        // Modal 
        Stock_Modal : false,
        ReStock_Modal : false,
        btn_edit : true,
        btn_Disabled:false,
        IsLoader:false,
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
    //   this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }

  componentDidMount(){

    document.title = " Pharmacy / Purchase Order"
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse})
    
    SelectSupplierList(this.Comp_Id,this.ProjectID,(mSupplier)=>{
        this.setState({SupplierList:mSupplier})
  
        if(this.props.isAddNew) // save
        {
            
            this.Max_Purchase_OrderNo() // new reciver NO genrate
        }

        else // update
        {
            this.showLoader()
            SelectPurchaseOrderMaster(this.Comp_Id,this.ProjectID,this.props.PurchaseOrderID,(mData)=>{
                this.setState({
                    PurchaseOrderNo:mData[0].PurchaseOrderNo,
                    PurchaseOrderDate:mData[0].PurchaseOrderDate,
                    Discription:mData[0].Discription,
                    GrandTotal:mData[0].GrandTotal,
                    btn_edit: this.props.show_screen.edit
                })
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                mSupplier.map(val=>{
                    if(val.SupplierID === mData[0].SupplierID)
                    {
                        this.setState({SelectedSupplier:val})
                    }
                })
                SelectPurchaseOrderDetail(this.Comp_Id,this.ProjectID,this.props.PurchaseOrderID,(mDetailData)=>{
                    mDetailData.map((value,index)=>{
                        value.SrNo = index + 1 
                    })
                    if(mDetailData.length > 0)
                    this.setState({PO_Detail_Data:mDetailData})
                })
            })
            this.hideLoader()
        }
        this.Select_Item_Details()
       })
    })
  }
  showLoader = () => {
   
    this.setState({IsLoader:true})
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }
  Select_Item_Details = () =>{
    SelectItemDetails(this.Comp_Id,this.ProjectID,(mItems)=>{ // Api
        this.setState({Item_List:mItems})
    })
  }
  Show_Min_Stock_Modal = ()=>{
      this.setState({Stock_Modal:true})
  }
  Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
}
Show_ReOrder_Stock_Modal = ()=>{
    this.setState({ReStock_Modal:true})
}
Hide_ReOrder_Stock_Modal = ()=>{
  this.setState({ReStock_Modal:false})
}
    Max_Purchase_OrderNo = () => {
        this.showLoader()
        GetMaxPurchaseNo(this.Comp_Id,this.ProjectID, (mPurchaseOrderNo) => { // API Function
            this.hideLoader()  
            this.setState({ PurchaseOrderNo: mPurchaseOrderNo })  // set state here 
        })
    }
   
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        
                        this.setState({ Selected_item: value,UOMID:value.UOMID })
                        document.getElementById('UOM').value = value.UOM
                        document.getElementById('PurchaseRate').value = value.PurchaseRate
                        document.getElementById('value').value = value.PurchaseRate

                    }
                    else {
                        this.setState({ Selected_item: null })
                        document.getElementById('UOM').value = ''
                        document.getElementById('PurchaseRate').value = ''
                        document.getElementById('value').value = ''

                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
              
                // this.setState({GrandTotal:0})
                this.Calculate_Total()
            }}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    PurchaseRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="PurchaseRate"
            defaultValue={rowValue.PurchaseRate}
            className="textbox"
            name="PurchaseRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    SaveHandler = () => {
        if(this.state.SelectedSupplier)
        {
            this.setState({Supplier_Err:''})
            if(this.state.PO_Detail_Data.length > 0)
            {
                var OrderData = {
                    PurchaseOrderID:this.props.PurchaseOrderID,
                    PurchaseOrderNo:this.state.PurchaseOrderNo,
                    CreatedDate:this.state.CreatedDate,
                    SupplierID:this.state.SelectedSupplier.SupplierID,
                    Discription:this.state.Discription,
                    WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                    PurchaseOrderDate:this.state.PurchaseOrderDate,
                    GrandTotal:this.state.GrandTotal,
                    UserID:this.UserID,
                    Comp_Id:this.Comp_Id,
                    ProjectID:this.ProjectID,
                    
                
                }

                for(let i = 0; i< this.state.PO_Detail_Data.length; i++)
                {
                    if(typeof this.state.PO_Detail_Data[i].Qty === 'undefined' || this.state.PO_Detail_Data[i].Qty == '' || this.state.PO_Detail_Data[i].Qty == null )
                    this.state.PO_Detail_Data[i].Qty = 0
                    else if (typeof this.state.PO_Detail_Data[i].Qty === 'string')
                    this.state.PO_Detail_Data[i].Qty =  parseFloat(this.state.PO_Detail_Data[i].Qty)

                    if(typeof this.state.PO_Detail_Data[i].TaxPer === 'undefined' || this.state.PO_Detail_Data[i].TaxPer == '' || this.state.PO_Detail_Data[i].TaxPer == null )
                    this.state.PO_Detail_Data[i].TaxPer = 0
                    else if (typeof this.state.PO_Detail_Data[i].TaxPer === 'string')
                    this.state.PO_Detail_Data[i].TaxPer =  parseFloat(this.state.PO_Detail_Data[i].TaxPer)

                    if(typeof this.state.PO_Detail_Data[i].DisPer === 'undefined' || this.state.PO_Detail_Data[i].DisPer == '' || this.state.PO_Detail_Data[i].DisPer == null )
                    this.state.PO_Detail_Data[i].DisPer = 0
                    else if (typeof this.state.PO_Detail_Data[i].DisPer === 'string')
                    this.state.PO_Detail_Data[i].DisPer =  parseFloat(this.state.PO_Detail_Data[i].DisPer)

                    if(typeof this.state.PO_Detail_Data[i].TaxAmt === 'undefined' || this.state.PO_Detail_Data[i].TaxAmt == '' || this.state.PO_Detail_Data[i].TaxAmt == null )
                    this.state.PO_Detail_Data[i].TaxAmt = 0
                    else if (typeof this.state.PO_Detail_Data[i].TaxAmt === 'string')
                    this.state.PO_Detail_Data[i].TaxAmt =  parseFloat(this.state.PO_Detail_Data[i].TaxAmt)

                    if(typeof this.state.PO_Detail_Data[i].DisAmt === 'undefined' || this.state.PO_Detail_Data[i].DisAmt == '' || this.state.PO_Detail_Data[i].DisAmt == null )
                    this.state.PO_Detail_Data[i].DisAmt = 0
                    else if (typeof this.state.PO_Detail_Data[i].DisAmt === 'string')
                    this.state.PO_Detail_Data[i].DisAmt =  parseFloat(this.state.PO_Detail_Data[i].DisAmt)

                    if(typeof this.state.PO_Detail_Data[i].PurchaseRate === 'undefined' || this.state.PO_Detail_Data[i].PurchaseRate == '' || this.state.PO_Detail_Data[i].PurchaseRate == null )
                    this.state.PO_Detail_Data[i].PurchaseRate = 0
                    else if (typeof this.state.PO_Detail_Data[i].PurchaseRate === 'string')
                    this.state.PO_Detail_Data[i].PurchaseRate =  parseFloat(this.state.PO_Detail_Data[i].PurchaseRate)
                    
                    if(typeof this.state.PO_Detail_Data[i].Value === 'undefined' || this.state.PO_Detail_Data[i].Value == '' || this.state.PO_Detail_Data[i].Value == null )
                    this.state.PO_Detail_Data[i].Value = 0
                    else if (typeof this.state.PO_Detail_Data[i].Value === 'string')
                    this.state.PO_Detail_Data[i].Value =  parseFloat(this.state.PO_Detail_Data[i].Value)
                }
                this.setState({btn_Disabled:true})
                SavePurchaseOrder(OrderData,this.state.PO_Detail_Data,this.props.isAddNew,()=>{
                    this.setState({btn_Disabled:false})
                    document.getElementById('btnCancel').click()
                })
            }
            else
            { 
                Swal.fire({
                    icon: 'info',
                    text: `Order Details Must be Entered...`,
                })
                return
            }
        }
        else
        {
            this.setState({Supplier_Err:'Supplier is required'})
        }
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
                        rowValue.data.ItemID = this.state.Selected_item.ItemID
                        rowValue.data.UOMID = this.state.UOMID
                        if (rowValue.data.PurchaseOrderDetailID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.PO_Detail_Data.length + 1
                    rowValue.data.Qty = 1
                    rowValue.data.DisPer = 0.00
                    rowValue.data.DisAmt = 0.00
                    rowValue.data.TaxPer = 0.00
                    rowValue.data.TaxAmt = 0.00

                    this.setState({ Selected_item: null,UOMID:0 })
                }
            }
            // if (rowValue.requestType === 'beginEdit') {
            // }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID },UOMID: rowValue.rowData.UOMID })
          
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Grand_Total();
        }
        if (rowValue.requestType == "delete") {
             this.Calculate_Grand_Total();
        }
    }
Calculate_Grand_Total = () =>{
    var _GrandTotal = 0;
    for(let i = 0; i< this.state.PO_Detail_Data.length; i++)
    {
       if( typeof this.state.PO_Detail_Data[i].Value === 'string' )
       {
        this.state.PO_Detail_Data[i].Value = parseFloat((this.state.PO_Detail_Data[i].Value).replace(/,/g, ''))
       }
       else if (this.state.PO_Detail_Data[i].Value == '')
       {
        this.state.PO_Detail_Data[i].Value = 0 
       }
       _GrandTotal += this.state.PO_Detail_Data[i].Value
    }
    this.setState({GrandTotal:_GrandTotal})
}
    Calculate_Total = () => {
        var Qty = 0, Rate = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0;


        
        
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

        Rate = document.getElementById('PurchaseRate').value == "" ? 0 : parseFloat(document.getElementById('PurchaseRate').value)
        DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)
        TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)

        DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        document.getElementById('DisAmt').value = DisAmt;
        document.getElementById('TaxAmt').value = TaxAmt;
        document.getElementById('value').value = Total_Value;


       

    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        
        if(this.state.PO_Detail_Data.length > 0 )
        {
            for(let i = 0; i<this.state.PO_Detail_Data.length; i++){
               var test =  mData.find(x=> x.ItemID === this.state.PO_Detail_Data[i].ItemID )
              
               if(test)
               {
                   mData.splice(test,1) 
                   Swal.fire({
                    icon: 'info',
                    text: `Same Products are Eliminated...`,
                })
               
               }
            }
        }
            this.setState({ PO_Detail_Data: this.state.PO_Detail_Data.concat(mData) },()=>{
                var  mArray = [...this.state.PO_Detail_Data]
                   mArray.map((value,index)=>{
                       value.SrNo = index + 1
                   })
                   this.setState({mArray})
               })


               
               if(this.state.Stock_Modal === true)
                this.Hide_Min_Stock_Modal()
                else if (this.state.ReStock_Modal)
                this.Hide_ReOrder_Stock_Modal()


                
    }
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
           <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Purchase Order</h4>
            </Col>
          </Row>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="PurchaseOrderNo"
                            label="Po No" 
                            value={this.state.PurchaseOrderNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="SupplierName"
                            id="SupplierName"
                            options={this.state.SupplierList}
                            getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                            value={this.state.SelectedSupplier}
                            onChange={(e, value) => {
                                
                                if (value)
                                {
                                    this.setState({SelectedSupplier:value})
                                    debugger
                                    SelectItemsWRTMinStock(this.Comp_Id,this.ProjectID,value.SupplierID,(mData)=>{
                                        debugger
                                        mData.map((value,index)=>{
                                            value.SrNo = index + 1 
                                            value.Qty = 1
                                            value.DisPer = 0
                                            value.DisAmt = 0
                                            value.TaxPer = 0
                                            value.TaxAmt = 0
                                            value.Value = value.PurchaseRate
                                       
                                            value.action = 'add'
                                         
                                           
                                        })
                                        if(mData.length > 0){
                                            this.setState({PO_Detail_Data:mData})
                                            var total = 0;
                                            for(let obj of mData){
                                              total += obj.PurchaseRate;
                                            }
                                           this.setState({GrandTotal:total})
                                        }
                                      
                                        else
                                        {
                                            this.setState({PO_Detail_Data:[]})
                                           this.setState({GrandTotal:0})
                                        }
                                       
                                      
                                    })
                              
                                }
                                else
                                {
                                    this.setState({SelectedSupplier:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="SupplierName" label="Supplier"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.PurchaseOrderDate}
                            name="PurchaseOrderDate"
                            label="Order Date" 
                            type= 'date'
                            onChange= {e => this.setState({PurchaseOrderDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={2} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            value={this.state.GrandTotal}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            fullWidth
                            inputProps={
                                {readOnly: true}
                            }
                        />
                    </Col> 
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.PO_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'dropdownedit'  editTemplate={this.ItemDiscription}   edit={this.cbParams}  validationRules={this.NAmeValidation} textAlign='Left'/> {/*validationRules={true} */}
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right'/>
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    {/* <ColumnDirective width='170' field='Expairy' headerText='Expiry Date'  editTemplate={this.Expairy} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='150' field='PurchaseRate' headerText='PurchaseRate'   editTemplate={this.PurchaseRate} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis%'   editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt'   editTemplate={this.DisAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText='Tax%'   editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' headerText='TaxAmt'   editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='right'></ColumnDirective>
                                    
                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='PurchaseOrderDetailID' headerText='PurchaseOrderDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseOrderID' headerText='PurchaseOrderID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='UOMID' headerText='UOMID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                {/*   <Link id="btnReStock" className="btn btn-primary" style={{border: "none"}} to={'#'} onClick={this.Show_ReOrder_Stock_Modal}>  <ShowChart /><u>Reorder. Stock</u></Link>
                          <Link id="btnStock" className="btn btn-primary" style={{border: "none"}} to='#' onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Min. Stock</u></Link>*/} 
                             {this.state.btn_edit &&(
                                <button type="button"
                                 className="btn btn-primary"
                                   style={{border: "none"}}
                                    onClick={this.SaveHandler}
                                    disabled={this.state.btn_Disabled}
                                    > 
                                    {this.state.btn_Disabled?(
                                        <><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Saving... </> ):<><Save /> <u>Save</u></>}
                                    </button>)}
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'/PurchaseOrderList'} > <Cancel /> <u>Close</u></Link>
                            <Link target={"_blank"}
                             className="btn btn-primary" 
                             style={{border: "none"}} 
                             to={'/PurchaseOrderReport'} 
                             onClick={e =>{

                                var obj = {
                                    PurchaseOrderNo:this.state.PurchaseOrderNo,
                                    PurchaseOrderDate:this.state.PurchaseOrderDate,
                                    Discription:this.state.Discription,
                                    GrandTotal:this.state.GrandTotal,
                                    Detail: this.state.PO_Detail_Data,
                                    Supplier: this.state.SelectedSupplier,
                                    Warehouse: this.state.SelectedWareHouse
                                }

                                localStorage.setItem("PurchaseOrder", JSON.stringify(obj))
                                this.SaveHandler()
                             }}
                             > <Print /> <u>Print</u></Link>
                        
                        </Col>
                    </Row>
                  {/*
                    <MinStockModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                    />
                     <MinStockModal 
                        show={this.state.ReStock_Modal}
                        onHide = {this.Hide_ReOrder_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                    />*/}
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                  />

                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.PO && state.PO.isAddNew,
    PurchaseOrderID:state.PO && state.PO.PurchaseOrderID,
    Project_ID:state.PO && state.PO.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(PurchaseOrderEntry)